<div class="form-wrapper">
  <lsz-edit-form-header
    [headerText]="'Regenerationsanlagen'"
    [numEntries]="amount"
    [editMode]="editMode"
    (clickEdit)="onClickEdit()"
    (clickEditDone)="onClickEditDone()"
  ></lsz-edit-form-header>

  <table [class.editMode]="editMode" [formGroup]="allForms">
    <thead>
    <tr>
      <th i18n="@@tableHeaderName">Name</th>
      <th i18n="@@tableHeaderIsAvailable">Vorhanden</th>
      <th i18n="@@tableHeaderRemark">Bemerkung</th>
      <th *ngIf="editMode" i18n="@@tableHeaderOptions" class="column-options">Optionen</th>
      <th>{{'lastEdit' | translate}}</th>
    </tr>
    </thead>
    <tbody *ngIf="iceBarrel"
           lsz-regeneration-facility-typed-form
           [entry]="iceBarrel"
           (entrySaved)="onTypedEntrySaved(iceBarrel, $event, 'iceBarrel')"
           [editMode]="editMode"
           [form]="allForms.get('iceBarrel')">
    </tbody>
    <tbody *ngIf="sauna"
           lsz-regeneration-facility-typed-form
           [entry]="sauna"
           (entrySaved)="onTypedEntrySaved(sauna, $event, 'sauna')"
           [editMode]="editMode"
           [form]="allForms.get('sauna')"
    ></tbody>
    <tbody *ngIf="relaxationBath"
           lsz-regeneration-facility-typed-form
           [entry]="relaxationBath"
           (entrySaved)="onTypedEntrySaved(relaxationBath, $event, 'relaxationBath')"
           [editMode]="editMode"
           [form]="allForms.get('relaxationBath')">
    </tbody>
    <tbody *ngIf="relaxationRoom"
           lsz-regeneration-facility-typed-form
           [entry]="relaxationRoom"
           (entrySaved)="onTypedEntrySaved(relaxationRoom, $event, 'relaxationRoom')"
           [editMode]="editMode"
           [form]="allForms.get('relaxationRoom')">
    </tbody>
    <tbody *ngIf="coldChamber"
           lsz-regeneration-facility-typed-form
           [entry]="coldChamber"
           (entrySaved)="onTypedEntrySaved(coldChamber, $event, 'coldChamber')"
           [editMode]="editMode"
           [form]="allForms.get('coldChamber')">
    </tbody>
    <tbody lsz-regeneration-facility-form
           *ngFor="let form of customEntryForms.controls; let i = index"
           (entrySaved)="onUpdate(i)"
           (entryDeleted)="onDelete(i)"
           [editMode]="editMode"
           formArrayName="customEntries"
           [index]="i"
           [form]="form">
    </tbody>
    <tbody *ngIf="addMode" formGroupName="newEntry">
    <tr>
      <td>
        <input type="text" placeholder="Neuer Eintrag" i18n-placeholder="@@placeholderNewEntry" formControlName="name"
               required />
        <div *ngIf="name.invalid && (name.dirty || name.touched)">
          <div class="input-error" *ngIf="name.errors.required">Name wird benötigt.</div>
          <div class="input-error" *ngIf="name.errors.repeatedName">Name kann sich nicht wiederholen.</div>
        </div>
      </td>
      <td>&nbsp;</td>
      <td><textarea formControlName="remark"></textarea></td>
      <td>
        <div class="form-options" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
          <button
            type="button"
            [disabled]="!newEntryForm.valid"
            (click)="createNewEntry()"
            class="nav-button"
            [ngClass]="{'notice': newEntryForm.valid}">
            <fa-icon [icon]="faSave"></fa-icon>
          </button>
          <button type="button" (click)="exitEditMode()" class="nav-button">
            <fa-icon [icon]="faTrash"></fa-icon>
          </button>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

  <button *ngIf="editMode && !addMode" type="button" (click)="addNewRow()" i18n="@@dataButtonAdd" class="add-button">
    <fa-icon [icon]="faPlus"></fa-icon>
    Neuen Eintrag hinzufügen
  </button>
</div>
