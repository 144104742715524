import { Component, OnChanges } from '@angular/core';
import { Room } from '../../models/models';
import { RoomService } from '../../services/room.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Validators } from '../../validators';
import { ConfirmationDialogService } from '../../../shared/confirmation-dialog/services/confirmation-dialog.service';
import { BaseTableViewComponent } from '../../../shared/base-table-view/base-table-view.component';

@Component({
  selector: 'lsz-room-form-collection',
  templateUrl: './room-form-collection.component.html',
  styleUrls: ['./room-form-collection.component.css'],
})
export class RoomFormCollectionComponent extends BaseTableViewComponent<Room> implements OnChanges {

  public canteen: Room;
  public conferenceRoom: Room;
  public office: Room;
  public studyRoom: Room;
  public workplace: Room;

  public get amount(): number {
    let amount: number = 0;
    for (const entry of this.entries) {
      amount += entry.count;
    }

    return amount;
  }

  constructor(protected service: RoomService,
              protected confirmationDialog: ConfirmationDialogService) {
    super(service, confirmationDialog);
  }

  public ngOnChanges(): void {
    if (this.soccerClub && this.season) {
      this.service.get(this.soccerClub, this.season.id).subscribe(entries => {
        this.entries = entries;

        this.allForms = this.initializeAllForms();

        this.updateTypedEntries();

        const formNames: string[] = [
          'canteen',
          'conferenceRoom',
          'office',
          'studyRoom',
          'workplace',
        ];

        for (const formName of formNames) {
          const form: UntypedFormGroup = this.allForms.get(formName) as UntypedFormGroup;
          form.patchValue({
            remark: this[formName].remark as string,
            count: (formName === 'canteen' ? this[formName].count === 1 : this[formName].count) as number,
          });
        }
      });
    }
  }

  public initializeAllForms(): UntypedFormGroup {
    return new UntypedFormGroup({
      canteen: this.formGroup(this.findEntryByType('Canteen')),
      conferenceRoom: this.formGroup(this.findEntryByType('ConferenceRoom')),
      office: this.formGroup(this.findEntryByType('Office')),
      studyRoom: this.formGroup(this.findEntryByType('StudyRoom')),
      workplace: this.formGroup(this.findEntryByType('Workplace')),
    });
  }

  public exitEditMode(): void {
    this.editMode = false;

    const resetValues = {
      canteen: {
        remark: this.canteen.remark,
        count: this.canteen.count === 1,
      },
      conferenceRoom: this.objectToForm(this.conferenceRoom),
      office: this.objectToForm(this.office),
      studyRoom: this.objectToForm(this.studyRoom),
      workplace: this.objectToForm(this.workplace),
    };
    this.allForms.reset(resetValues);
  }

  protected formGroup(entry?: Room): UntypedFormGroup {
    return new UntypedFormGroup({
      remark: new UntypedFormControl(entry?.remark),
      count: new UntypedFormControl(entry?.count, Validators.required),
      updatedAtUtc: new UntypedFormControl(entry?.updatedAtUtc),
      createdAtUtc: new UntypedFormControl(entry?.createdAtUtc),
    });
  }

  protected objectToForm(entry: Room): Partial<Room> {
    return {
      remark: entry?.remark,
      count: entry?.count,
      updatedAtUtc: entry?.updatedAtUtc,
      createdAtUtc: entry?.createdAtUtc,
    };
  }

  protected updateTypedEntries(): void {
    this.canteen = this.findEntryByType('Canteen');
    this.conferenceRoom = this.findEntryByType('ConferenceRoom');
    this.office = this.findEntryByType('Office');
    this.studyRoom = this.findEntryByType('StudyRoom');
    this.workplace = this.findEntryByType('Workplace');
  }

  private findEntryByType(type: string): Room {
    for (const entry of this.entries) {
      if (entry.roomType === type) {
        return entry;
      }
    }

    return {
      id: null,
      soccerClubId: this.soccerClub?.id,
      seasonId: this.season?.id,
      count: 0,
      remark: null,
      roomType: type,
      updatedAtUtc: null,
      createdAtUtc: null,
    };
  }
}
