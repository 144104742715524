import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgbAccordionModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgChartsModule } from 'ng2-charts';
import { NgxSpinnerModule } from 'ngx-spinner';

import { HeaderModule } from '../shared/header/header.module';
import { SharedModule } from '../shared/shared.module';

import { CategoryKpiChartComponent } from './components/category-kpi-chart/category-kpi-chart.component';
import { CategoryKpiDetailsComponent } from './components/category-kpi-details/category-kpi-details.component';
import { CategoryKpiHistoryTooltipComponent } from './components/category-kpi-history-tooltip/category-kpi-history-tooltip.component';
import { CategoryKpiNameComponent } from './components/category-kpi-name/category-kpi-name.component';
import { CategoryKpiSelectComponent } from './components/category-kpi-select/category-kpi-select.component';
import { CategoryRatingComponent } from './components/category-rating/category-rating.component';
import { CategorySummaryComponent } from './components/category-summary/category-summary.component';
import { FieldsDisplayComponent } from './components/fields-display/fields-display.component';
import { DataPresenterRoutingModule } from './data-presenter-routing.module';
import { DataPresenterComponent } from './data-presenter.component';
import { ConvertDetailToPercentagePipe } from './pipes/convert-detail-to-percentage.pipe';
import { ConvertToPercentagePipe } from './pipes/convert-to-percentage.pipe';
import { IsDetailPercentagePipe } from './pipes/is-detail-percentage.pipe';
import { KpiService } from './services/kpi.service';
import { AllClubsTableModalComponent } from './components/category-kpi-details/partials/all-clubs-table-modal/all-clubs-table-modal.component';
import { CoreModule } from '../core/core.module';
import { BinaryTableComponent } from './components/category-kpi-details/partials/all-clubs-table-modal/partials/binary-table/binary-table.component';
import { AggregationTableComponent } from './components/category-kpi-details/partials/all-clubs-table-modal/partials/aggregation-table/aggregation-table.component';

@NgModule({
  declarations: [
    DataPresenterComponent,
    FieldsDisplayComponent,
    CategorySummaryComponent,
    CategoryKpiSelectComponent,
    CategoryKpiDetailsComponent,
    CategoryRatingComponent,
    CategoryKpiChartComponent,
    CategoryKpiNameComponent,
    ConvertToPercentagePipe,
    ConvertDetailToPercentagePipe,
    IsDetailPercentagePipe,
    CategoryKpiHistoryTooltipComponent,
    AllClubsTableModalComponent,
    BinaryTableComponent,
    AggregationTableComponent,
  ],
  imports: [
    CommonModule,
    HeaderModule,
    HttpClientModule,
    SharedModule,
    NgbAccordionModule,
    NgbTooltipModule,
    DataPresenterRoutingModule,
    NgxSpinnerModule,
    NgChartsModule,
    CoreModule,
  ],
  providers: [
    KpiService,
  ],
})
export class DataPresenterModule {
}
