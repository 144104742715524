import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { Moment } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { v4 as UUIDv4 } from 'uuid';

import { Validators } from '../../../data-editor/validators';
import { SeasonService } from '../../../services/season/season.service';
import { DateValidator } from '../../../shared/datepicker/date.validator';
import { TimeRepresentation } from '../../../shared/time-input/model/timeRepresentation';
import { LockPeriod } from '../../model/lockPeriod';
import { Season } from '../../../services/season/models/season';
import { LockPeriodDto } from '../../model/lockPeriodDto';

const INIT_TIME: unknown = { hour: 0, minute: 0, second: 0 };

@Component({
  selector: 'lsz-set-lock-period',
  templateUrl: './set-lock-period.component.html',
  styleUrls: ['./set-lock-period.component.scss'],
})
export class SetLockPeriodComponent implements OnInit, OnDestroy {

  public faCalendar: unknown;
  public formGroup: UntypedFormGroup;

  public get startDate(): UntypedFormControl {
    return this.formGroup.get('startDate') as UntypedFormControl;
  }

  public get startTime(): UntypedFormControl {
    return this.formGroup.get('startTime') as UntypedFormControl;
  }

  public season: Season;
  public spinnerId: string;
  private unsubscribe: Subject<void>;

  constructor(private spinnerService: NgxSpinnerService,
              private seasonService: SeasonService) {
    this.faCalendar = faCalendar;
    this.spinnerId = UUIDv4();
    this.unsubscribe = new Subject<void>();

    this.formGroup = new UntypedFormGroup({
      startDate: new UntypedFormControl(undefined),
      endDate: new UntypedFormControl(undefined),
      startTime: new UntypedFormControl(INIT_TIME, Validators.compose([Validators.required])),
      endTime: new UntypedFormControl(INIT_TIME, Validators.compose([Validators.required]),
      ),
    });

    // the FormControls need to be initialized to pass them to the validator functions.
    // also, it is not possible to add validators later on.
    // so the validators are assigned all at once after FormControl initialization.
    this.startDate.setValidators([
      Validators.required,
      DateValidator.dateValidator,
      DateValidator.isNotPast(this.startTime),
      DateValidator.isBeforeOtherDate(this.endDate, this.startTime, this.endTime),
    ]);
    this.endDate.setValidators([
      Validators.required,
      DateValidator.dateValidator,
      DateValidator.isNotPast(this.endTime),
    ]);

    // trigger startDate validation on startTime changes as the time might influence the 'isNotPast' validation
    this.startTime.valueChanges.pipe(
      takeUntil(this.unsubscribe),
    ).subscribe(() => {
      this.startDate.updateValueAndValidity();
    });
    // trigger endDate validation on endTime changes as the time might influence the 'isNotPast' validation
    this.endTime.valueChanges.pipe(
      takeUntil(this.unsubscribe),
    ).subscribe(() => {
      this.endDate.updateValueAndValidity();
    });
    // trigger startDate validation on endDate changes to validate 'isBeforeOtherDate' validator
    this.endDate.valueChanges.pipe(
      takeUntil(this.unsubscribe),
    ).subscribe(() => {
      this.startDate.updateValueAndValidity();
    });
  }

  public get endDate(): UntypedFormControl {
    return this.formGroup.get('endDate') as UntypedFormControl;
  }

  public get endTime(): UntypedFormControl {
    return this.formGroup.get('endTime') as UntypedFormControl;
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public ngOnInit(): void {
    this.spinnerService.show(this.spinnerId);
    this.seasonService.getLatestSeason().subscribe((season: Season) => {
      this.season = season;
      this.initForm(this.season);
    }, (err: unknown) => {
      console.error(err);
    }, () => {
      this.spinnerService.hide(this.spinnerId);
    });
  }

  public onSave(): void {
    const seasonId: string = this.season.id;
    const periodStartDate: string = this.startDate.value as string;
    const periodStartTime: TimeRepresentation = this.startTime.value as TimeRepresentation;
    const periodEndDate: string = this.endDate.value as string;
    const periodEndTime: TimeRepresentation = this.endTime.value as TimeRepresentation;

    const startUtcStr: string = this.dateAndTimeToUtcString(periodStartDate, periodStartTime);
    const endUtcStr: string = this.dateAndTimeToUtcString(periodEndDate, periodEndTime);

    const lockPeriod: LockPeriod = {
      seasonId,
      start: startUtcStr,
      end: endUtcStr,
    };


    this.updateLockPeriod(new LockPeriodDto(lockPeriod));
  }

  private dateAndTimeToUtcString(dateStr: string, time: TimeRepresentation): string {
    return moment.utc(dateStr, 'DD.MM.YYYY')
      .add(time.hour, 'hours')
      .add(time.minute, 'minutes')
      .add(time.second, 'seconds')
      .toISOString();
  }

  private initForm(season: Season): void {
    this.setTimeForForm(this.startDate, this.startTime, season.dataInputEndsAtUtc);
    this.setTimeForForm(this.endDate, this.endTime, season.seasonClosesAtUtc);
  }

  private setTimeForForm(dateForm: UntypedFormControl, timeForm: UntypedFormControl, utcDate: string): void {
    const periodMoment: Moment = moment(utcDate);
    dateForm.setValue(periodMoment.format('DD.MM.YYYY'));
    const hour: number = periodMoment.get('hour');
    const minute: number = periodMoment.get('minute');
    const time: TimeRepresentation = {
      hour,
      minute,
      second: 0,
    };
    timeForm.setValue(time);
  }

  private updateLockPeriod(lockPeriod: LockPeriodDto): void {
    this.spinnerService.show(this.spinnerId);
    this.seasonService.updateLockPeriod(lockPeriod).subscribe(() => {
      this.spinnerService.hide(this.spinnerId);
    }, (err: unknown) => {
      console.error(err);
      this.spinnerService.hide(this.spinnerId);
    });
  }

}
