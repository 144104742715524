import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationDialogService } from '../../../shared/confirmation-dialog/services/confirmation-dialog.service';
import { DateValidator } from '../../../shared/datepicker/date.validator';
import { FromDatepickerPipe } from '../../../shared/pipes/from-datepicker.pipe';
import { ToDatepickerPipe } from '../../../shared/pipes/to-datepicker.pipe';
import { EducationEmployee } from '../../models/models';
import { EducationEmployeeService } from '../../services/education-employee.service';
import { Validators } from '../../validators';
import { BaseTableViewComponent } from '../../../shared/base-table-view/base-table-view.component';

@Component({
  selector: 'lsz-education-employee-form-collection',
  templateUrl: './education-employee-form-collection.component.html',
  styleUrls: ['./education-employee-form-collection.component.css'],
})
export class EducationEmployeeFormCollectionComponent extends BaseTableViewComponent<EducationEmployee> {

  public get birthdate(): AbstractControl {
    return this.newEntryForm.get('birthdate');
  }

  public get dateOfJoiningClub(): AbstractControl {
    return this.newEntryForm.get('dateOfJoiningClub');
  }

  public get personnelFunction(): AbstractControl {
    return this.newEntryForm.get('personnelFunction');
  }

  @Input() public headerText: string;
  @Input() public personnelArea: string;

  public faCalendar: unknown;

  constructor(protected service: EducationEmployeeService,
              protected confirmationDialog: ConfirmationDialogService,
              private toDatepickerPipe: ToDatepickerPipe,
              private fromDatepickerPipe: FromDatepickerPipe) {
    super(service, confirmationDialog);
    this.faCalendar = faCalendar;
  }

  public ngOnChanges(): void {
    if (this.soccerClub && this.season) {
      this.service.get(this.soccerClub, this.season.id).subscribe((entries: EducationEmployee[]) => {
        this.entries = entries.filter((entry: EducationEmployee) => entry.personnelArea === this.personnelArea);
        this.allForms = this.initializeAllForms();

        for (const entry of this.entries) {
          this.customEntryForms.push(this.formGroup(entry));
        }
      });
    }
  }

  public createNewEntry(): void {
    const entry: Partial<EducationEmployee> = {
      ...this.newEntryForm.value as EducationEmployee,
      firstName: undefined,
      lastName: undefined,
      birthdate: this.fromDatepickerPipe.transform(this.birthdate.value),
      personnelArea: this.personnelArea,
      dateOfJoiningClub: this.fromDatepickerPipe.transform(this.dateOfJoiningClub.value),
    };

    this.addEntry(entry);
  }

  public onUpdate(index: number): void {
    const entry: Partial<EducationEmployee> = {
      birthdate: this.fromDatepickerPipe.transform(this.customEntryFormForIndex(index).get('birthdate').value),
      dateOfJoiningClub: this.fromDatepickerPipe.transform(this.customEntryFormForIndex(index).get('dateOfJoiningClub').value),
    };

    this.updateEntry(index, entry);
  }

  protected formGroup(entry?: EducationEmployee): UntypedFormGroup {
    let birthdate: string;
    let dateOfJoiningClub: string;
    if (entry) {
      birthdate = this.toDatepickerPipe.transform(entry.birthdate);
      dateOfJoiningClub = this.toDatepickerPipe.transform(entry.dateOfJoiningClub);
    }

    return new UntypedFormGroup({
      name: new UntypedFormControl(entry?.name, [Validators.required, this.repeatedNameValidator()]),
      birthdate: new UntypedFormControl(birthdate, Validators.compose([Validators.required, DateValidator.dateValidator])),
      personnelFunction: new UntypedFormControl(entry?.personnelFunction, [Validators.required]),
      personnelArea: new UntypedFormControl(entry?.personnelArea),
      dateOfJoiningClub: new UntypedFormControl(dateOfJoiningClub, Validators.compose([
        Validators.required,
        DateValidator.dateValidator,
        DateValidator.dateValidatorAtLeastThreeYears,
      ])),
      updatedAtUtc: new UntypedFormControl(entry?.updatedAtUtc),
      createdAtUtc: new UntypedFormControl(entry?.createdAtUtc),
    });
  }

  protected objectToForm(entry?: EducationEmployee): any {
    return {
      name: entry.name,
      birthdate: this.toDatepickerPipe.transform(entry.birthdate),
      personnelFunction: entry.personnelFunction,
      personnelArea: entry.personnelArea,
      dateOfJoiningClub: this.toDatepickerPipe.transform(entry.dateOfJoiningClub),
      updatedAtUtc: entry.updatedAtUtc,
      createdAtUtc: entry.createdAtUtc,
    };
  }
}
