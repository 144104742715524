import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lsz-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css'],
})
export class PrivacyPolicyComponent implements OnInit {

  public ngOnInit(): void {
    setTimeout(() => {
      window.scrollTo({top: 1, behavior: 'smooth'});
    }, 1);
  }
}
