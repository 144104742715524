<div class="spinner-container">

  <ngx-spinner [name]="spinnerId" [fullScreen]="false" type="ball-clip-rotate" size="medium" bdColor=""
               color="#b3c1ca">
  </ngx-spinner>

  <h4>Saison-Neuberechnung</h4>

  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">

    <select class="lsz-select" size="1" [formControl]="formControlSeason">
      <option *ngFor="let season of seasons" [value]="season.name">{{season.name}}</option>
    </select>

    <button class="lsz-button" type="button" [disabled]="startDisabled" (click)="submitJob()">
      Neuberechnung starten
    </button>

    <div class="input-error" *ngIf="startDisabled && startDisabledReason">{{startDisabledReason}}</div>

  </div>

  <div class="accordion-wrapper">
    <ngb-accordion [activeIds]="activePanelIds" (panelChange)="onPanelChange($event)" [animation]="false">
      <ngb-panel [id]="panelIdJobs">
        <ng-template ngbPanelHeader>
          <button ngbPanelToggle class="btn btn-link container-fluid category-header">
            <span></span>
            Saison-Berechnungen
            <fa-icon [icon]="activePanelIds.length === 0 ? faChevronDown : faChevronUp" size="2x"></fa-icon>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="accordion-content">
            <div class="table-container" [id]="jobTableContainerId">
              <table>
                <tr>
                  <th>Saison</th>
                  <th>Status</th>
                  <th>Startzeit</th>
                  <th>Hinweis</th>
                </tr>
                <tbody>
                <tr *ngFor="let job of kpiJobs; trackBy: trackByFn"
                    [ngClass]="{'session-job': job | isKpiJobCreatedInSession: sessionJobs}">
                  <td>{{job.season}}</td>
                  <td>{{job.state | translatedJobState}}</td>
                  <td>{{job.startAtUtc | date : 'medium'}}</td>
                  <td>{{job.reason}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>

</div>
