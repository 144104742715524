import { Component, OnChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SoccerTrainingPartTimeEmployee } from '../../models/models';
import { SoccerTrainingPartTimeEmployeeService } from '../../services/soccer-training-part-time-employee.service';
import { Validators } from '../../validators';
import { ConfirmationDialogService } from '../../../shared/confirmation-dialog/services/confirmation-dialog.service';
import { BaseTableViewComponent } from '../../../shared/base-table-view/base-table-view.component';

@Component({
  selector: 'lsz-part-time-employee-soccer-training-form-collection',
  templateUrl: './part-time-employee-soccer-training-form-collection.component.html',
  styleUrls: ['./part-time-employee-soccer-training-form-collection.component.scss'],
})
export class PartTimeEmployeeSoccerTrainingFormCollectionComponent
  extends BaseTableViewComponent<SoccerTrainingPartTimeEmployee>
  implements OnChanges {

  public get numAvailables(): number {
    let numAvailables = 0;
    for (const entry of this.entries) {
      numAvailables += entry.aLicenses + entry.aPlusLicenses + entry.bLicenses + entry.bPlusLicenses + entry.cLicenses +
        + entry.goalKeeperLicenses + entry.proLicenses;
    }
    return numAvailables;
  }

  public sportAreaManager: SoccerTrainingPartTimeEmployee;
  public teamTrainer: SoccerTrainingPartTimeEmployee;
  public goalKeeperTrainer: SoccerTrainingPartTimeEmployee;
  public specialTrainer: SoccerTrainingPartTimeEmployee;
  public sportPsychologist: SoccerTrainingPartTimeEmployee;
  public matchAnalysis: SoccerTrainingPartTimeEmployee;
  public athleticTrainer: SoccerTrainingPartTimeEmployee;
  public sportScientist: SoccerTrainingPartTimeEmployee;
  public transitionCoordinator: SoccerTrainingPartTimeEmployee;

  constructor(protected service: SoccerTrainingPartTimeEmployeeService,
              protected confirmationDialog: ConfirmationDialogService) {
    super(service, confirmationDialog);
  }

  public ngOnChanges(): void {
    if (this.soccerClub && this.season) {
      this.service.get(this.soccerClub, this.season.id).subscribe(
        entries => {
          this.entries = entries;

          this.initializeAllForms();

          this.updateTypedEntries();

          const formNames = [
            'sportAreaManager',
            'teamTrainer',
            'goalKeeperTrainer',
            'specialTrainer',
            'sportPsychologist',
            'matchAnalysis',
            'athleticTrainer',
            'sportScientist',
            'transitionCoordinator',
          ];
          for (const formName of formNames) {
            const form = this.allForms.get(formName) as UntypedFormGroup;
            const values: Partial<SoccerTrainingPartTimeEmployee> = this.objectToForm(this[formName]);
            if (formName === 'goalKeeperTrainer') {
              values.goalKeeperLicenses = this[formName].goalKeeperLicenses;
            }
            form.patchValue(values);
          }
        });
    }
  }

  public initializeAllForms(): UntypedFormGroup {
    return this.formGroup();
  }

  public exitEditMode(): void {
    this.editMode = false;

    const resetValues = {
      sportAreaManager: {
        aLicenses: this.sportAreaManager.aLicenses,
        aPlusLicenses: this.sportAreaManager.aPlusLicenses,
        bLicenses: this.sportAreaManager.bLicenses,
        bPlusLicenses: this.sportAreaManager.bPlusLicenses,
        cLicenses: this.sportAreaManager.cLicenses,
        proLicenses: this.sportAreaManager.proLicenses,
        updatedAtUtc: this.sportAreaManager.updatedAtUtc,
        createdAtUtc: this.sportAreaManager.createdAtUtc,
      },
      teamTrainer: {
        aLicenses: this.teamTrainer.aLicenses,
        aPlusLicenses: this.teamTrainer.aPlusLicenses,
        bLicenses: this.teamTrainer.bLicenses,
        bPlusLicenses: this.teamTrainer.bPlusLicenses,
        cLicenses: this.teamTrainer.cLicenses,
        proLicenses: this.teamTrainer.proLicenses,
        updatedAtUtc: this.teamTrainer.updatedAtUtc,
        createdAtUtc: this.teamTrainer.createdAtUtc,
      },
      goalKeeperTrainer: {
        aLicenses: this.goalKeeperTrainer.aLicenses,
        aPlusLicenses: this.goalKeeperTrainer.aPlusLicenses,
        bLicenses: this.goalKeeperTrainer.bLicenses,
        bPlusLicenses: this.goalKeeperTrainer.bPlusLicenses,
        cLicenses: this.goalKeeperTrainer.cLicenses,
        goalKeeperLicenses: this.goalKeeperTrainer.goalKeeperLicenses,
        proLicenses: this.goalKeeperTrainer.proLicenses,
        updatedAtUtc: this.goalKeeperTrainer.updatedAtUtc,
        createdAtUtc: this.goalKeeperTrainer.createdAtUtc,
      },
      specialTrainer: {
        aLicenses: this.specialTrainer.aLicenses,
        aPlusLicenses: this.specialTrainer.aPlusLicenses,
        bLicenses: this.specialTrainer.bLicenses,
        bPlusLicenses: this.specialTrainer.bPlusLicenses,
        cLicenses: this.specialTrainer.cLicenses,
        proLicenses: this.specialTrainer.proLicenses,
        updatedAtUtc: this.specialTrainer.updatedAtUtc,
        createdAtUtc: this.specialTrainer.createdAtUtc,
      },
      sportPsychologist: {
        aLicenses: this.sportPsychologist.aLicenses,
        aPlusLicenses: this.sportPsychologist.aPlusLicenses,
        bLicenses: this.sportPsychologist.bLicenses,
        bPlusLicenses: this.sportPsychologist.bPlusLicenses,
        cLicenses: this.sportPsychologist.cLicenses,
        proLicenses: this.sportPsychologist.proLicenses,
        updatedAtUtc: this.sportPsychologist.updatedAtUtc,
        createdAtUtc: this.sportPsychologist.createdAtUtc,
      },
      matchAnalysis: {
        aLicenses: this.matchAnalysis.aLicenses,
        aPlusLicenses: this.matchAnalysis.aPlusLicenses,
        bLicenses: this.matchAnalysis.bLicenses,
        bPlusLicenses: this.matchAnalysis.bPlusLicenses,
        cLicenses: this.matchAnalysis.cLicenses,
        proLicenses: this.matchAnalysis.proLicenses,
        updatedAtUtc: this.matchAnalysis.updatedAtUtc,
        createdAtUtc: this.matchAnalysis.createdAtUtc,
      },
      athleticTrainer: {
        aLicenses: this.athleticTrainer.aLicenses,
        aPlusLicenses: this.athleticTrainer.aPlusLicenses,
        bLicenses: this.athleticTrainer.bLicenses,
        bPlusLicenses: this.athleticTrainer.bPlusLicenses,
        cLicenses: this.athleticTrainer.cLicenses,
        proLicenses: this.athleticTrainer.proLicenses,
        updatedAtUtc: this.athleticTrainer.updatedAtUtc,
        createdAtUtc: this.athleticTrainer.createdAtUtc,
      },
      sportScientist: {
        aLicenses: this.sportScientist.aLicenses,
        aPlusLicenses: this.sportScientist.aPlusLicenses,
        bLicenses: this.sportScientist.bLicenses,
        bPlusLicenses: this.sportScientist.bPlusLicenses,
        cLicenses: this.sportScientist.cLicenses,
        proLicenses: this.sportScientist.proLicenses,
        updatedAtUtc: this.sportScientist.updatedAtUtc,
        createdAtUtc: this.sportScientist.createdAtUtc,
      },
      transitionCoordinator: {
        aLicenses: this.transitionCoordinator.aLicenses,
        aPlusLicenses: this.transitionCoordinator.aPlusLicenses,
        bLicenses: this.transitionCoordinator.bLicenses,
        bPlusLicenses: this.transitionCoordinator.bPlusLicenses,
        cLicenses: this.transitionCoordinator.cLicenses,
        proLicenses: this.transitionCoordinator.proLicenses,
        updatedAtUtc: this.transitionCoordinator.updatedAtUtc,
        createdAtUtc: this.transitionCoordinator.createdAtUtc,
      },
    };
    this.allForms.reset(resetValues);
  }

  protected updateTypedEntries(): void {
    this.sportAreaManager = this.findEntryByType('SportAreaManager');
    this.teamTrainer = this.findEntryByType('TeamTrainer');
    this.goalKeeperTrainer = this.findEntryByType('GoalKeeperTrainer');
    this.specialTrainer = this.findEntryByType('SpecialTrainer');
    this.sportPsychologist = this.findEntryByType('SportPsychologist');
    this.matchAnalysis = this.findEntryByType('MatchAnalysis');
    this.athleticTrainer = this.findEntryByType('AthleticTrainer');
    this.sportScientist = this.findEntryByType('SportScientist');
    this.transitionCoordinator = this.findEntryByType('TransitionCoordinator');
  }

  protected formGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      sportAreaManager: this.getNormalTrainerForm(),
      teamTrainer: this.getNormalTrainerForm(),
      goalKeeperTrainer: this.getGoalKeeperTrainerForm(),
      specialTrainer: this.getNormalTrainerForm(),
      sportPsychologist: this.getNormalTrainerForm(),
      matchAnalysis: this.getNormalTrainerForm(),
      athleticTrainer: this.getNormalTrainerForm(),
      sportScientist: this.getNormalTrainerForm(),
      transitionCoordinator: this.getNormalTrainerForm(),
    });
  }

  protected objectToForm(entry?: SoccerTrainingPartTimeEmployee): Partial<SoccerTrainingPartTimeEmployee> {
    return {
      aLicenses: entry.aLicenses,
      aPlusLicenses: entry.aPlusLicenses,
      bLicenses: entry.bLicenses,
      bPlusLicenses: entry.bPlusLicenses,
      cLicenses: entry.cLicenses,
      proLicenses: entry.proLicenses,
      updatedAtUtc: entry?.updatedAtUtc,
      createdAtUtc: entry?.createdAtUtc,
    };
  }

  private findEntryByType(type: string): SoccerTrainingPartTimeEmployee {
    for (const entry of this.entries) {
      if (entry.personnelFunction === type) {
        return entry;
      }
    }
    return {
      id: null,
      soccerClubId: this.soccerClub.id,
      seasonId: this.season.id,
      personnelFunction: type,
      aLicenses: 0,
      aPlusLicenses: 0,
      bLicenses: 0,
      bPlusLicenses: 0,
      cLicenses: 0,
      proLicenses: 0,
      goalKeeperLicenses: 0,
      updatedAtUtc: null,
      createdAtUtc: null,
    };
  }

  private getNormalTrainerForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      aLicenses: new UntypedFormControl('', [Validators.required]),
      aPlusLicenses: new UntypedFormControl('', [Validators.required]),
      bLicenses: new UntypedFormControl('', [Validators.required]),
      bPlusLicenses: new UntypedFormControl('', [Validators.required]),
      cLicenses: new UntypedFormControl('', [Validators.required]),
      proLicenses: new UntypedFormControl('', [Validators.required]),
      updatedAtUtc: new UntypedFormControl(''),
      createdAtUtc: new UntypedFormControl(''),
    });
  }

  private getGoalKeeperTrainerForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      goalKeeperLicenses: new UntypedFormControl('', [Validators.required]),
      updatedAtUtc: new UntypedFormControl(''),
      createdAtUtc: new UntypedFormControl(''),
    });
  }
}
