import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../../services/user/model/user';
import { UserService } from '../../services/user/user.service';
import { Role } from '../services/auth/models/role.enum';
import { StsAuthService } from 'sts-auth';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {

  constructor(private authService: StsAuthService,
              private userService: UserService,
              private router: Router) {
  }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const isLoggingIn: boolean = Boolean(localStorage.getItem('isLoggingIn'));

    return this.userService.userInformation$.pipe(
      map((user: User) => {
        if (user) {
          localStorage.removeItem('isLoggingIn');
          if (state.url.indexOf('callback') > -1) {
            return false;
          }

          if (user.roles.find((role: Role) => role === Role.USER)) {
            return true;
          }

          this.router.navigateByUrl('/login');

          return false;
        }

        if (state.url.indexOf('callback') > -1 && isLoggingIn) {
          return true;
        }
        this.router.navigateByUrl('/login');

        return false;
      }),
    );
  }

}
