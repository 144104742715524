import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap/accordion/accordion';
import { NgxSpinnerService } from 'ngx-spinner';
import { EMPTY, Observable, Subject, timer } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { v4 as UUIDv4 } from 'uuid';

import { SeasonService } from '../../../services/season/season.service';
import { ConfirmationDialogService } from '../../../shared/confirmation-dialog/services/confirmation-dialog.service';

import { KpiJobService } from './kpi-job.service';
import { Job, JobState } from '../../model/job';
import { ConfirmationDialogResult } from '../../../shared/confirmation-dialog/confirmationDialogResult';
import { Season } from '../../../services/season/models/season';

const JOBS_POLLING_INTERVAL_MS: number = 5000;
const REASON_SEASON_HAS_JOB: string = 'Neuberechnung für diese Saison läuft bereits';
const PANEL_ID_JOBS: string = 'panel-jobs';
const JOB_TABLE_CONTAINER_ID: string = 'jobTableContainer';

@Component({
  selector: 'lsz-recalculate-season',
  templateUrl: './recalculate-season.component.html',
  styleUrls: ['./recalculate-season.component.scss'],
})
export class RecalculateSeasonComponent implements OnInit, OnDestroy {
  public activePanelIds: string[];
  public faChevronDown: unknown;
  public faChevronUp: unknown;
  public formControlSeason: UntypedFormControl;
  public jobTableContainerId: string;
  public kpiJobs: Job[];
  public panelIdJobs: string;
  public seasons: Season[];
  public selectedSeason: Season;
  public sessionJobs: Job[];
  public spinnerId: string;
  public startDisabled: boolean;
  public startDisabledReason: string;

  private unsubscribe: Subject<void>;

  constructor(private spinnerService: NgxSpinnerService,
              private seasonService: SeasonService,
              private kpiJobService: KpiJobService,
              private confirmationDialog: ConfirmationDialogService) {
    this.activePanelIds = [];
    this.faChevronDown = faChevronDown;
    this.faChevronUp = faChevronUp;
    this.jobTableContainerId = JOB_TABLE_CONTAINER_ID;
    this.panelIdJobs = PANEL_ID_JOBS;
    this.sessionJobs = [];
    this.spinnerId = UUIDv4();
    this.startDisabled = true;
    this.unsubscribe = new Subject();
    this.formControlSeason = new UntypedFormControl();

    // update jobs on season select change
    this.formControlSeason.valueChanges.pipe(
      takeUntil(this.unsubscribe),
      tap((seasonName: string) => {
        this.showSpinner();
        this.selectedSeason = this.seasons.find((season: Season) => season.name === seasonName);
      }),
      switchMap(() => this.getJobs().pipe(
        tap({
          complete: () => {
            this.hideSpinner();
          },
        }),
      )),
    ).subscribe();
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public ngOnInit(): void {
    this.showSpinner();

    this.sessionJobs = this.kpiJobService.sessionJobs;

    // get seasons then subscribe to job interval
    this.seasonService.getSeasons().pipe(
      map((seasons: Season[]) =>
        seasons.filter((season: Season) => season.isClosed && season.hasKpi).reverse()),
      tap((seasons: Season[]) => {
        this.seasons = seasons;
        this.formControlSeason.setValue(this.seasons[0].name);
      }),
      switchMap(() => timer(0, JOBS_POLLING_INTERVAL_MS).pipe(
        takeUntil(this.unsubscribe),
        switchMap(() => this.getJobs().pipe(
          catchError(() => EMPTY),
        )),
      )),
    ).subscribe();

  }

  public onPanelChange(event: NgbPanelChangeEvent): void {
    const opened: boolean = event.nextState;
    if (opened) {
      this.openJobsPanel();
    } else {
      this.closeJobsPanel();
    }
  }

  public submitJob(): void {
    const modalRef: NgbModalRef = this.confirmationDialog.open(
      `Neuberechnung für Saison ${this.selectedSeason.name} starten?`,
      '',
      'Neuberechnung starten',
    );

    modalRef.closed.pipe(
      filter((result: ConfirmationDialogResult) => result === ConfirmationDialogResult.CONFIRM),
      tap(() => {
        this.showSpinner();
      }),
      switchMap(() => this.kpiJobService.submitJob(this.selectedSeason.name)),
      switchMap(() => this.getJobs()),
    ).subscribe(() => {
      this.hideSpinner();
      this.openJobsPanel();
      this.scrollToJobTableTop();
    }, () => {
      this.hideSpinner();
    });
  }

  public trackByFn(index: number, kpiJob: Job): string {
    return kpiJob.id;
  }

  private closeJobsPanel(): void {
    this.activePanelIds = [];
  }

  private disableStartButton(reason: string): void {
    this.startDisabled = true;
    this.startDisabledReason = reason;
  }

  private enableStartButton(): void {
    this.startDisabled = false;
    this.startDisabledReason = undefined;
  }

  private getJobs(): Observable<Job[]> {
    return this.kpiJobService.getJobs().pipe(
      tap({
        next: (jobs: Job[]) => {
          this.kpiJobs = jobs;

          const isSeasonBusy: boolean = this.kpiJobs.some((job: Job) =>
            job.season === this.selectedSeason.name &&
            (job.state === JobState.IN_PROGRESS || job.state === JobState.SCHEDULED));

          if (isSeasonBusy) {
            this.disableStartButton(REASON_SEASON_HAS_JOB);
          } else {
            this.enableStartButton();
          }
        },
      }),
    );
  }

  private hideSpinner(): void {
    this.spinnerService.hide(this.spinnerId);
  }

  private openJobsPanel(): void {
    this.activePanelIds = [this.panelIdJobs];
  }

  private scrollToJobTableTop(): void {
    const jobTableContainer: HTMLElement = document.getElementById(this.jobTableContainerId);
    if (jobTableContainer) {
      jobTableContainer.scrollTop = 0;
    }
  }

  private showSpinner(): void {
    this.spinnerService.show(this.spinnerId);
  }
}
