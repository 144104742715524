import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { firstValueFrom, forkJoin, map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { SoccerClubSeasonService } from 'src/app/data-editor/services/soccer-club-season.service';
import { Season } from 'src/app/services/season/models/season';
import { SeasonService } from 'src/app/services/season/season.service';
import { BaseTableViewComponent } from 'src/app/shared/base-table-view/base-table-view.component';
import { ConfirmationDialogService } from 'src/app/shared/confirmation-dialog/services/confirmation-dialog.service';
import { SoccerClub } from '../../../models/soccerClub';
import { BudgetService } from '../../services/budget.service';
import { Budget, InfrastructureObject, League, SoccerClubSeason } from '../../models/models';
import { Validators } from '../../validators';
import { LeagueService } from '../../services/league.service';
import { SoccerClubService } from '../../../services/soccer-club/soccer-club.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lsz-active-teams-form-collection',
  templateUrl: './active-teams-form-collection.component.html',
  styleUrls: ['./active-teams-form-collection.component.scss'],
})
export class ActiveTeamsFormCollectionComponent
  extends BaseTableViewComponent<SoccerClubSeason>
  implements OnInit, OnDestroy
{
  public seasons: Season[];

  protected allClubs: SoccerClub[];
  protected filteredClubs: SoccerClub[];
  protected allLeagues: League[];
  protected allBudgets: Budget[];
  protected formControlSeason: FormControl<string>;
  protected clubLogos: Map<string, string>;

  private onDestroy$: Subject<void>;

  public get budget(): FormControl<string> {
    return this.newEntryForm.get('budget') as FormControl;
  }

  public get league(): FormControl<string> {
    return this.newEntryForm.get('league') as FormControl;
  }

  constructor(
    protected soccerClubSeasonService: SoccerClubSeasonService,
    protected confirmationDialog: ConfirmationDialogService,
    private seasonService: SeasonService,
    private budgetService: BudgetService,
    private leagueService: LeagueService,
    private soccerClubService: SoccerClubService,
  ) {
    super(soccerClubSeasonService, confirmationDialog);
    this.formControlSeason = new FormControl('');
    this.clubLogos = new Map();
    this.onDestroy$ = new Subject();
  }

  public ngOnInit(): void {
    forkJoin([
      this.seasonService.getSeasons().pipe(
        map((seasons: Season[]) => [...seasons].reverse()),
        tap((seasons: Season[]) => {
          this.seasons = seasons;
          this.formControlSeason.setValue(this.seasons[0].id);
        }),
        switchMap(() => this.loadEntries()),
        switchMap(() => this.soccerClubService.get()),
        tap((soccerClubs: SoccerClub[]) => {
          this.allClubs = soccerClubs;
          this.filteredClubs = this.filterClubs();
          this.clubLogos = new Map(soccerClubs.map((club: SoccerClub) => [club.name, club.logoImageUrl]));
        }),
      ),
      this.budgetService.get().pipe(tap((budgets: Budget[]) => (this.allBudgets = budgets))),
      this.leagueService.get().pipe(tap((leagues: League[]) => (this.allLeagues = leagues))),
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();

    this.formControlSeason.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => firstValueFrom(this.loadEntries()).then(() => this.exitAddMode()));
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public override exitAddMode(resetForm: boolean = true) {
    super.exitAddMode(resetForm);
    this.filteredClubs = this.filterClubs();
  }

  public override exitEditMode(): void {
    this.sortEntries(this.entries);
    super.exitEditMode();
  }

  public override async onDelete(index: number): Promise<void> {
    await super.onDelete(index);
    this.filteredClubs = this.filterClubs();
  }

  protected createNewEntry(): void {
    const entry: SoccerClubSeason = { ...(this.newEntryForm.value as SoccerClubSeason) };
    entry.seasonId = this.formControlSeason.value;
    entry.soccerClubId = this.name.value;
    this.addEntry(entry);
  }

  protected override formGroup(entry?: SoccerClubSeason): UntypedFormGroup {
    return new UntypedFormGroup({
      name: new UntypedFormControl(entry?.soccerClubName ?? '', [Validators.required]),
      budget: new FormControl<number>(entry?.budgetId),
      budgetName: new FormControl<string>(entry?.budgetName ?? ''),
      league: new FormControl<number>(entry?.leagueId),
      leagueName: new FormControl<string>(entry?.leagueName ?? ''),
      createdAtUtc: new FormControl<string>(entry?.createdAtUtc ?? ''),
      updatedAtUtc: new UntypedFormControl(entry?.updatedAtUtc ?? ''),
    });
  }

  protected override objectToForm(entry?: SoccerClubSeason): any {
    return {
      name: entry?.soccerClubName,
      budget: entry?.budgetId,
      budgetName: entry?.budgetName,
      league: entry?.leagueId,
      leagueName: entry?.leagueName,
      createdAtUtc: entry?.createdAtUtc,
      updatedAtUtc: entry?.updatedAtUtc,
    };
  }

  private loadEntries(): Observable<InfrastructureObject[]> {
    if (this.formControlSeason.value) {
      const params: HttpParams = new HttpParams().set('seasonId', this.formControlSeason.value);
      return this.service.get(params).pipe(
        tap((entries: SoccerClubSeason[]) => {
          this.sortEntries(entries);
          this.entries = entries;
          this.allForms = this.initializeAllForms();

          for (const entry of this.entries) {
            this.customEntryForms.push(this.formGroup(entry));
          }
        }),
      );
    } else {
      return of(null);
    }
  }

  private filterClubs(): SoccerClub[] {
    return this.allClubs?.filter(
      (club: SoccerClub) => !this.entries.find((scs: SoccerClubSeason) => scs.soccerClubId === club.id),
    );
  }

  private sortEntries(entries: SoccerClubSeason[]): void {
    entries.sort((a: SoccerClubSeason, b: SoccerClubSeason) => a.soccerClubName.localeCompare(b.soccerClubName));
  }
}
