<div class="wrapper">

  <div class="headline">
    <div [class.disabled]="disabled">Bezugsgruppe
      <ng-template #tipContent>Auswahlregler zur Anpassung der Vergleichswerte (Alle LZ, Liga [1. Mannschaft],
        Budgetgruppe)
      </ng-template>
      <fa-icon class="fa-lg iconcolor"
               [icon]="faInfoCircle"
               placement="bottom"
               tooltipClass="tipclass"
               [ngbTooltip]="!disabled ? tipContent: null">
      </fa-icon>
    </div>
  </div>
  <lsz-tick-slider [(value)]="_value"
                   [labels]="['Alle', 'Liga', 'Budget']"
                   [disabled]="disabled"
                   (valueChange)="switch($event)">
  </lsz-tick-slider>
</div>
