<div class="kpi-select">
  <ng-template #tipContent>Übersicht der einzelnen KPIs. Klickbar zu Anpassung der Detailansicht!</ng-template>
  <h3 class="detail-category-header" placement="bottom" tooltipClass="tipclass" [ngbTooltip]="tipContent">Einzelne KPIs</h3>
  <div class="detail-category-content">
    <div class="detail-category-content-sub">
      <ul class="kpi-list">
        <li *ngFor="let kpi of kpis" (click)="onKpiClicked(kpi)" [class.highlight]="kpi.name === selectedKpi?.name"
              placement="bottom"
              container="body">
          <ng-container *ngIf="kpi.rating.isVisible">
            <div *ngIf="kpi.rating[baseline] === 1" class="rating-above">
              <fa-icon [icon]="faThumbsUp"></fa-icon>
            </div>
            <div *ngIf="kpi.rating[baseline] === 0" class="rating-on">
              OK
            </div>
            <div *ngIf="kpi.rating[baseline] === -1" class="rating-below">
              <fa-icon [icon]="faExclamation"></fa-icon>
            </div>
            <div *ngIf="kpi.rating[baseline] === null" class="rating-none">&nbsp;</div>
          </ng-container>
          <ng-container *ngIf="!kpi.rating.isVisible">
            <div class="rating-none">&nbsp;</div>
          </ng-container>
          <lsz-category-kpi-name [name]="kpi.name"></lsz-category-kpi-name>
          <br style="clear: left;"/>
        </li>
      </ul>
    </div>
  </div>
</div>
