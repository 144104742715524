import { Action } from '@ngrx/store';

import { SoccerClub } from '../../models/soccerClub';

export enum EClubActions {
  SET_CLUB = '[Club] Set Club',
}

export class SetClub implements Action {
  public readonly type: string = EClubActions.SET_CLUB;

  constructor(public payload: SoccerClub) {
  }
}

export type ClubActions = SetClub;
