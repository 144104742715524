import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SoccerClub } from '../../../models/soccerClub';
import { FullTimeEmployee } from '../../models/models';
import { FullTimeEmployeeService } from '../../services/full-time-employee.service';
import { Season } from '../../../services/season/models/season';

@Component({
  selector: 'lsz-category-full-time-employees',
  templateUrl: './category-full-time-employees.component.html',
  styleUrls: ['./category-full-time-employees.component.scss'],
})
export class CategoryFullTimeEmployeesComponent implements OnInit, OnChanges {
  @Input() public season: Season;
  @Input() public soccerClub: SoccerClub;

  public fullTimeEmployees$: BehaviorSubject<FullTimeEmployee[]>;
  public fullTimeEmployeesLeadership$: Observable<FullTimeEmployee[]>;
  public fullTimeEmployeesManagement$: Observable<FullTimeEmployee[]>;
  public fullTimeEmployeesSoccerTraining$: Observable<FullTimeEmployee[]>;
  public fullTimeEmployeesSupport$: Observable<FullTimeEmployee[]>;

  constructor(private service: FullTimeEmployeeService) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.soccerClub && changes.soccerClub.previousValue !== changes.soccerClub.currentValue ||
      changes.season && changes.season.previousValue !== changes.season.currentValue) {
      this.service.getFullTimeEmployees(this.soccerClub, this.season.id);
    }
  }

  public ngOnInit(): void {
    this.fullTimeEmployees$ = this.service.fullTimeEmployees$;
    this.fullTimeEmployeesLeadership$ = this.fullTimeEmployees$.pipe(
      map((fullTimeEmployees: FullTimeEmployee[]) => fullTimeEmployees
        .filter((fullTimeEmployee: FullTimeEmployee) => fullTimeEmployee.personnelArea === 'Leadership')),
    );
    this.fullTimeEmployeesManagement$ = this.fullTimeEmployees$.pipe(
      map((fullTimeEmployees: FullTimeEmployee[]) => fullTimeEmployees
        .filter((fullTimeEmployee: FullTimeEmployee) => fullTimeEmployee.personnelArea === 'Management')),
    );
    this.fullTimeEmployeesSoccerTraining$ = this.fullTimeEmployees$.pipe(
      map((fullTimeEmployees: FullTimeEmployee[]) => fullTimeEmployees
        .filter((fullTimeEmployee: FullTimeEmployee) => fullTimeEmployee.personnelArea === 'SoccerTraining')),
    );
    this.fullTimeEmployeesSupport$ = this.fullTimeEmployees$.pipe(
      map((fullTimeEmployees: FullTimeEmployee[]) => fullTimeEmployees
        .filter((fullTimeEmployee: FullTimeEmployee) => fullTimeEmployee.personnelArea === 'Support')),
    );
  }

}
