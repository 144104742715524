<lsz-header [showLeagueSelect]="showLeagueSelect"
            [showBudgetSelect]="showBudgetSelect"
            [soccerClub]="soccerClub"
            [season]="season"
            (leagueSelect)="onLeagueSelect($event)"
            (budgetSelect)="onBudgetSelect($event)"
            (filterSwitch)="onFilterSwitch($event)"
            (temporalSwitch)="onTemporalSwitch($event)"
            (adminFilterSwitch)="onAdminFilterSwitch($event)">
</lsz-header>

<div class="background">

    <div id="evaluation-content">
        <ngx-spinner [fullScreen]="false" type="ball-clip-rotate" size="medium" bdColor="" color="#b3c1ca">
            <p class="loading">Daten werden geladen...</p>
        </ngx-spinner>
        <div class="no-club-div" *ngIf="!kpisProcessResult && !soccerClub" fxLayoutAlign="center center">
            <h1 i18n>Bitte wählen Sie einen Club aus</h1>
        </div>
        <div class="error-msg" *ngIf="kpiLoadingFailed" fxLayoutAlign="center center">
            <h1 i18n>Es liegen noch keine Daten vor.</h1>
        </div>
        <div *ngIf="kpisProcessResult && soccerClub">

            <div class="criterion-wrapper">
                <lsz-data-entry-count-down [season]="openSeason" [isPresenter]="true"></lsz-data-entry-count-down>
            </div>

            <div *ngIf="soccerClubHasKpis">
                <div id="infrastructure"></div>

                <div class="criterion-wrapper">

                    <div class="criterion-header">
                        <div>
                            <h2>Infrastruktur</h2>
                            <div i18n="@@categoryCount"
                                 class="category-count">{kpisProcessResult.criteria.Infrastructure.numCategories, plural, =1 {1 Kategorie} other {{{kpisProcessResult.criteria.Infrastructure.numCategories}} Kategorien}}
                                <small>&nbsp;*(1 ohne Wertung)</small>
                            </div>
                        </div>
                    </div>

                    <ngb-accordion (panelChange)="beforeAccordionPanelChange($event)" [animation]="false">
                        <ngb-panel [id]="'panel-Infrastructure-Fields'">
                            <ng-template ngbPanelHeader>
                                <button ngbPanelToggle class="btn btn-link container-fluid category-header">
                                    <lsz-category-rating
                                            [rating]="kpisProcessResult.criteria.Infrastructure.categories.Fields.ratings[baseline]">
                                    </lsz-category-rating>
                                    Plätze
                                    <fa-icon [icon]="accordionPanelToggleIcons.Infrastructure.Fields"
                                             size="2x"></fa-icon>
                                </button>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <lsz-fields-display
                                        [category]="kpisProcessResult.criteria.Infrastructure.categories.Fields"
                                        [soccerClub]="adminFilter.type === ADMIN_FILTER_CLUB ? soccerClub : undefined"
                                        [baseline]="baseline"
                                        [criterion]="'Infrastructure'">
                                </lsz-fields-display>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel [id]="'panel-Infrastructure-Halls'">
                            <ng-template ngbPanelHeader>
                                <button ngbPanelToggle class="btn btn-link container-fluid category-header">
                                    <lsz-category-rating
                                            [rating]="kpisProcessResult.criteria.Infrastructure.categories.Halls.ratings[baseline]">
                                    </lsz-category-rating>
                                    Hallen
                                    <fa-icon [icon]="accordionPanelToggleIcons.Infrastructure.Halls"
                                             size="2x"></fa-icon>
                                </button>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <lsz-fields-display
                                        [category]="kpisProcessResult.criteria.Infrastructure.categories.Halls"
                                        [soccerClub]="adminFilter.type === ADMIN_FILTER_CLUB ? soccerClub : undefined"
                                        [baseline]="baseline"
                                        [criterion]="'Infrastructure'">
                                </lsz-fields-display>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel [id]="'panel-Infrastructure-SpecialEquipments'">
                            <ng-template ngbPanelHeader>
                                <button ngbPanelToggle class="btn btn-link container-fluid category-header">
                                    <lsz-category-rating
                                            [rating]="kpisProcessResult.criteria.Infrastructure.categories.SpecialEquipments.ratings[baseline]"></lsz-category-rating>
                                    Sonderausstattung
                                    <fa-icon [icon]="accordionPanelToggleIcons.Infrastructure.SpecialEquipments"
                                             size="2x"></fa-icon>
                                </button>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <lsz-fields-display
                                        [category]="kpisProcessResult.criteria.Infrastructure.categories.SpecialEquipments"
                                        [soccerClub]="adminFilter.type === ADMIN_FILTER_CLUB ? soccerClub : undefined"
                                        [baseline]="baseline"
                                        [criterion]="'Infrastructure'">
                                </lsz-fields-display>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel [id]="'panel-Infrastructure-FunctionalRooms'">
                            <ng-template ngbPanelHeader>
                                <button ngbPanelToggle class="btn btn-link container-fluid category-header">
                                    <lsz-category-rating
                                            [rating]="kpisProcessResult.criteria.Infrastructure.categories.FunctionalRooms.ratings[baseline]"></lsz-category-rating>
                                    Funktionsräume
                                    <fa-icon [icon]="accordionPanelToggleIcons.Infrastructure.FunctionalRooms"
                                             size="2x"></fa-icon>
                                </button>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <lsz-fields-display
                                        [category]="kpisProcessResult.criteria.Infrastructure.categories.FunctionalRooms"
                                        [soccerClub]="adminFilter.type === ADMIN_FILTER_CLUB ? soccerClub : undefined"
                                        [baseline]="baseline"
                                        [criterion]="'Infrastructure'">
                                </lsz-fields-display>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel [id]="'panel-Infrastructure-RegenerationFacilities'">
                            <ng-template ngbPanelHeader>
                                <button ngbPanelToggle class="btn btn-link container-fluid category-header">
                                    <lsz-category-rating
                                            [rating]="kpisProcessResult.criteria.Infrastructure.categories.RegenerationFacilities.ratings[baseline]">
                                    </lsz-category-rating>
                                    Regenerationsanlagen
                                    <fa-icon [icon]="accordionPanelToggleIcons.Infrastructure.RegenerationFacilities"
                                             size="2x"></fa-icon>
                                </button>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <lsz-fields-display
                                        [category]="kpisProcessResult.criteria.Infrastructure.categories.RegenerationFacilities"
                                        [soccerClub]="adminFilter.type === ADMIN_FILTER_CLUB ? soccerClub : undefined"
                                        [baseline]="baseline"
                                        [criterion]="'Infrastructure'"
                                >
                                </lsz-fields-display>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel [id]="'panel-Infrastructure-Rooms'">
                            <ng-template ngbPanelHeader>
                                <button ngbPanelToggle class="btn btn-link container-fluid category-header">
                                    <lsz-category-rating
                                            [rating]="kpisProcessResult.criteria.Infrastructure.categories.Rooms.ratings[baseline]"></lsz-category-rating>
                                    Büros/Besprechungs- und Gemeinschaftsräume
                                    <fa-icon [icon]="accordionPanelToggleIcons.Infrastructure.Rooms"
                                             size="2x"></fa-icon>
                                </button>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <lsz-fields-display
                                        [category]="kpisProcessResult.criteria.Infrastructure.categories.Rooms"
                                        [soccerClub]="adminFilter.type === ADMIN_FILTER_CLUB ? soccerClub : undefined"
                                        [baseline]="baseline"
                                        [criterion]="'Infrastructure'"
                                >
                                </lsz-fields-display>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel [id]="'panel-Infrastructure-Colleges'">
                            <ng-template ngbPanelHeader>
                                <button ngbPanelToggle class="btn btn-link container-fluid category-header">
                                    <lsz-category-rating
                                            [rating]="kpisProcessResult.criteria.Infrastructure.categories.Colleges.ratings[baseline]"></lsz-category-rating>
                                    Unterbringung von Spielern
                                    <fa-icon [icon]="accordionPanelToggleIcons.Infrastructure.Colleges"
                                             size="2x"></fa-icon>
                                </button>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <lsz-fields-display
                                        [category]="kpisProcessResult.criteria.Infrastructure.categories.Colleges"
                                        [soccerClub]="adminFilter.type === ADMIN_FILTER_CLUB ? soccerClub : undefined"
                                        [baseline]="baseline"
                                        [criterion]="'Infrastructure'"
                                >
                                </lsz-fields-display>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>

                </div>

                <div id="personnel"></div>
                <hr/>

                <div class="criterion-wrapper">
                    <div class="criterion-header">
                        <div>
                            <h2>Personal</h2>
                            <div i18n="@@categoryCount"
                                 class="category-count">{kpisProcessResult.criteria.Personnel.numCategories, plural, =1 {1 Kategorie} other {{{kpisProcessResult.criteria.Personnel.numCategories}} Kategorien}}
                                <small>&nbsp;*(3 ohne Wertung)</small>
                            </div>
                        </div>
                    </div>

                    <ngb-accordion (panelChange)="beforeAccordionPanelChange($event)" [animation]="false">
                        <ngb-panel [id]="'panel-Personnel-Employment'">
                            <ng-template ngbPanelHeader>
                                <button ngbPanelToggle class="btn btn-link container-fluid category-header">
                                    <lsz-category-rating
                                            [rating]="kpisProcessResult.criteria.Personnel.categories.Employment.ratings[baseline]"></lsz-category-rating>
                                    Anstellung Vollzeit
                                    <fa-icon [icon]="accordionPanelToggleIcons.Personnel.Employment"
                                             size="2x"></fa-icon>
                                </button>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <lsz-fields-display
                                        [category]="kpisProcessResult.criteria.Personnel.categories.Employment"
                                        [soccerClub]="adminFilter.type === ADMIN_FILTER_CLUB ? soccerClub : undefined"
                                        [baseline]="baseline"
                                        [criterion]="'Personnel'">
                                </lsz-fields-display>
                            </ng-template>
                        </ngb-panel>

                        <ngb-panel [id]="'panel-Personnel-Employment-DoubleArea'">
                            <ng-template ngbPanelHeader>
                                <button ngbPanelToggle class="btn btn-link container-fluid category-header">
                                    <lsz-category-rating
                                            [rating]="kpisProcessResult.criteria.Personnel.categories.EmploymentDoubleArea.ratings[baseline]"></lsz-category-rating>
                                    Anstellung Vollzeit mit Doppelfunktion (DF) Lizenz
                                    <fa-icon [icon]="accordionPanelToggleIcons.Personnel.Employment"
                                             size="2x"></fa-icon>
                                </button>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <lsz-fields-display
                                        [category]="kpisProcessResult.criteria.Personnel.categories.EmploymentDoubleArea"
                                        [soccerClub]="adminFilter.type === ADMIN_FILTER_CLUB ? soccerClub : undefined"
                                        [baseline]="baseline"
                                        [criterion]="'Personnel'">
                                </lsz-fields-display>
                            </ng-template>
                        </ngb-panel>

                        <ngb-panel [id]="'panel-Personnel-PartTimeEmployment'">
                            <ng-template ngbPanelHeader>
                                <button ngbPanelToggle class="btn btn-link container-fluid category-header">
                                    <lsz-category-rating
                                            [rating]="kpisProcessResult.criteria.Personnel.categories.PartTimeEmployment.ratings[baseline]"></lsz-category-rating>
                                    Anstellung Teilzeit
                                    <fa-icon [icon]="accordionPanelToggleIcons.Personnel.PartTimeEmployment"
                                             size="2x"></fa-icon>
                                </button>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <lsz-fields-display
                                        [category]="kpisProcessResult.criteria.Personnel.categories.PartTimeEmployment"
                                        [soccerClub]="adminFilter.type === ADMIN_FILTER_CLUB ? soccerClub : undefined"
                                        [baseline]="baseline"
                                        [criterion]="'Personnel'">
                                </lsz-fields-display>
                            </ng-template>
                        </ngb-panel>

                        <ngb-panel [id]="'panel-Personnel-Qualification'">
                            <ng-template ngbPanelHeader>
                                <button ngbPanelToggle class="btn btn-link container-fluid category-header">
                                    <lsz-category-rating
                                            [rating]="kpisProcessResult.criteria.Personnel.categories.Qualification.ratings[baseline]"></lsz-category-rating>
                                    Qualifikation
                                    <fa-icon [icon]="accordionPanelToggleIcons.Personnel.Qualification"
                                             size="2x"></fa-icon>
                                </button>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <lsz-fields-display
                                        [category]="kpisProcessResult.criteria.Personnel.categories.Qualification"
                                        [soccerClub]="adminFilter.type === ADMIN_FILTER_CLUB ? soccerClub : undefined"
                                        [baseline]="baseline"
                                        [criterion]="'Personnel'">
                                </lsz-fields-display>
                            </ng-template>
                        </ngb-panel>

                        <ngb-panel [id]="'panel-Personnel-Membership'">
                            <ng-template ngbPanelHeader>
                                <button ngbPanelToggle class="btn btn-link container-fluid category-header">
                                    <lsz-category-rating
                                            [rating]="kpisProcessResult.criteria.Personnel.categories.Membership.ratings[baseline]"></lsz-category-rating>
                                    Zugehörigkeit
                                    <fa-icon [icon]="accordionPanelToggleIcons.Personnel.Membership"
                                             size="2x"></fa-icon>
                                </button>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <lsz-fields-display
                                        [category]="kpisProcessResult.criteria.Personnel.categories.Membership"
                                        [soccerClub]="adminFilter.type === ADMIN_FILTER_CLUB ? soccerClub : undefined"
                                        [baseline]="baseline"
                                        [criterion]="'Personnel'">
                                </lsz-fields-display>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>

                </div>

                <div id="effectivity"></div>
                <hr/>

                <div class="criterion-wrapper">
                    <div class="criterion-header">
                        <div>
                            <h2>Effektivität</h2>
                            <div i18n="@@categoryCount"
                                 class="category-count">{kpisProcessResult.criteria.Effectiveness.numCategories, plural, =1 {1 Kategorie} other {{{kpisProcessResult.criteria.Effectiveness.numCategories}} Kategorien}}</div>
                        </div>
                        <div class="note">* Diese Auswertungen basieren auf Daten der vorherigen Saison.</div>
                    </div>

                    <ngb-accordion (panelChange)="beforeAccordionPanelChange($event)" [animation]="false">

                        <ngb-panel [id]="'panel-Effectiveness-MinimumAffiliation'">
                            <ng-template ngbPanelHeader>
                                <button ngbPanelToggle class="btn btn-link container-fluid category-header">
                                    <lsz-category-rating
                                            [rating]="kpisProcessResult.criteria.Effectiveness.categories.MinimumAffiliation.ratings[baseline]"></lsz-category-rating>
                                    Zugehörigkeit im LZ - Anteil Spieler nach Mindestausbildungszeit im LZ
                                    <fa-icon [icon]="accordionPanelToggleIcons.Effectiveness.MinimumAffiliation"
                                             size="2x"></fa-icon>
                                </button>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <lsz-fields-display
                                        [category]="kpisProcessResult.criteria.Effectiveness.categories.MinimumAffiliation"
                                        [soccerClub]="adminFilter.type === ADMIN_FILTER_CLUB ? soccerClub : undefined"
                                        [baseline]="baseline"
                                        [criterion]="'Effectiveness'">
                                </lsz-fields-display>
                            </ng-template>
                        </ngb-panel>

                        <ngb-panel [id]="'panel-Effectiveness-OverallAffiliation'">
                            <ng-template ngbPanelHeader>
                                <button ngbPanelToggle class="btn btn-link container-fluid category-header">
                                    <lsz-category-rating
                                            [rating]="kpisProcessResult.criteria.Effectiveness.categories.OverallAffiliation.ratings[baseline]"></lsz-category-rating>
                                    Zugehörigkeit im LZ - Gesamtausbildungsanteil am Jahrgang
                                    <fa-icon [icon]="accordionPanelToggleIcons.Effectiveness.OverallAffiliation"
                                             size="2x"></fa-icon>
                                </button>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <lsz-fields-display
                                        [category]="kpisProcessResult.criteria.Effectiveness.categories.OverallAffiliation"
                                        [soccerClub]="adminFilter.type === ADMIN_FILTER_CLUB ? soccerClub : undefined"
                                        [baseline]="baseline"
                                        [criterion]="'Effectiveness'">
                                </lsz-fields-display>
                            </ng-template>
                        </ngb-panel>

                        <ngb-panel [id]="'panel-Effectiveness-U20U22'">
                            <ng-template ngbPanelHeader>
                                <button ngbPanelToggle class="btn btn-link container-fluid category-header">
                                    <lsz-category-rating
                                            [rating]="kpisProcessResult.criteria.Effectiveness.categories.U20U22.ratings[baseline]"></lsz-category-rating>
                                    U20-U22
                                    <fa-icon [icon]="accordionPanelToggleIcons.Effectiveness.U20U22"
                                             size="2x"></fa-icon>
                                </button>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <lsz-fields-display
                                        [category]="kpisProcessResult.criteria.Effectiveness.categories.U20U22"
                                        [soccerClub]="adminFilter.type === ADMIN_FILTER_CLUB ? soccerClub : undefined"
                                        [baseline]="baseline"
                                        [criterion]="'Effectiveness'">
                                </lsz-fields-display>
                            </ng-template>
                        </ngb-panel>

                        <ngb-panel [id]="'panel-Effectiveness-LicenseTeam'">
                            <ng-template ngbPanelHeader>
                                <button ngbPanelToggle class="btn btn-link container-fluid category-header">
                                    <lsz-category-rating
                                            [rating]="kpisProcessResult.criteria.Effectiveness.categories.LicenseTeam.ratings[baseline]"></lsz-category-rating>
                                    Eigene Lizenzmannschaft
                                    <fa-icon [icon]="accordionPanelToggleIcons.Effectiveness.LicenseTeam"
                                             size="2x"></fa-icon>
                                </button>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <lsz-fields-display
                                        [category]="kpisProcessResult.criteria.Effectiveness.categories.LicenseTeam"
                                        [soccerClub]="adminFilter.type === ADMIN_FILTER_CLUB ? soccerClub : undefined"
                                        [baseline]="baseline"
                                        [criterion]="'Effectiveness'">
                                </lsz-fields-display>
                            </ng-template>
                        </ngb-panel>

                        <ngb-panel [id]="'panel-Effectiveness-ForeignLicenseTeam'">
                            <ng-template ngbPanelHeader>
                                <button ngbPanelToggle class="btn btn-link container-fluid category-header">
                                    <lsz-category-rating
                                            [rating]="kpisProcessResult.criteria.Effectiveness.categories.ForeignLicenseTeam.ratings[baseline]"></lsz-category-rating>
                                    Andere Lizenzmannschaften
                                    <fa-icon [icon]="accordionPanelToggleIcons.Effectiveness.ForeignLicenseTeam"
                                             size="2x"></fa-icon>
                                </button>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <lsz-fields-display
                                        [category]="kpisProcessResult.criteria.Effectiveness.categories.ForeignLicenseTeam"
                                        [soccerClub]="adminFilter.type === ADMIN_FILTER_CLUB ? soccerClub : undefined"
                                        [baseline]="baseline"
                                        [criterion]="'Effectiveness'">
                                </lsz-fields-display>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
            </div>
        </div>
    </div>

</div>
