import { ActionReducerMap } from '@ngrx/store';

import { baselineReducers } from './reducers/baseline.reducers';
import { clubReducers } from './reducers/club.reducers';
import { AppState } from './states/app.state';

export const appReducers: ActionReducerMap<AppState, any> = {
  clubs: clubReducers,
  baseline: baselineReducers,
};
