<div *ngIf="tooltip?.body" class="tooltip-container">
  <table>
    <thead *ngIf="tooltip?.body?.length > 1">
      <tr>
        <th>
          <div class="line-count">{{ tooltip?.body?.length }}</div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let bodyLine of tooltip?.body; index as index">
        <td>
          <ng-container *ngIf="tooltip?.body?.length > 1 && index === 0; else league">
            <img [src]="pointStyleOwn">
          </ng-container>
          <ng-template #league>
            <img *ngIf="tooltip?.dataPoints as dataPoints"
                [src]="pointStyleLeague[dataPoints[0]?.dataIndex]">
          </ng-template>
        </td>
        <td>{{ bodyLine?.lines }}</td>
      </tr>
    </tbody>
  </table>
</div>
