import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, ViewChild } from '@angular/core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import * as Chart from 'chart.js';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';

import { Category } from '../../models/category';
import { Criterions } from '../../models/criterions.enum';
import { Detail } from '../../models/detailCalculations';
import { KpiType } from '../../models/kpi-type.enum';

enum Steps {
  one = 1,
  two = 2,
  five = 5,
  ten = 10,
  twentyFive = 25,
  hundred = 100,
  twoHundred = 200,
  fiveHundred = 500,
  thousand = 1000,
  twoThousand = 2000,
}

const COLOR_GRID_LINE: string = '#f0f3f5';
const COLOR_ZERO_LINE: string = '#99a8b2';
const COLOR_FONT: string = '#666666';
const COLOR_BAR_FILL: string = '#d0D6d9';
const COLOR_INVISIBLE: string = '#ffffff';
const COLOR_LINE_OWN: string = '#000000';
const COLOR_LINE_LEAGUE: string = '#d6636a';
const UPDATE_INTERVAL: number = 100;
const CLUB_ICON_NORMAL: number = 24;
const CLUB_ICON_SMALL: number = 20;

const CHART_TYPE_BAR: ChartType = 'bar';
const CHART_TYPE_LINE: ChartType = 'line';

@Component({
  selector: 'lsz-category-kpi-chart',
  templateUrl: './category-kpi-chart.component.html',
  styleUrls: ['./category-kpi-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CategoryKpiChartComponent implements OnChanges, AfterViewInit {

  @ViewChild(BaseChartDirective) public baseChart: BaseChartDirective;
  @ViewChild('chartTooltip') public chartTooltip: any;
  @Input() public category: Category;
  @Input() public criterion: Criterions | string;
  @Input() public detail: Detail;

  public readonly CHART_TYPE_BAR: string = CHART_TYPE_BAR;
  public readonly CHART_TYPE_LINE: string = CHART_TYPE_LINE;
  public chartData: ChartDataset[];
  public chartLabels: string[] = [];
  public chartLegend: boolean;

  public chartOptions: ChartOptions;
  public chartPlugins: unknown;
  public chartType: ChartType = CHART_TYPE_BAR;
  public criterions = Criterions;

  public faInfoCircle: unknown;
  public pointStyleLeague: string[];
  public pointStyleOwn: string;

  public tooltip: Chart.TooltipModel<keyof Chart.ChartTypeRegistry>;

  constructor(private cdr: ChangeDetectorRef) {
    this.chartPlugins = [ChartDataLabels];
    this.chartLegend = false;
    this.faInfoCircle = faInfoCircle;
    this.initChartOptions();
    this.initChartData();
  }

  public ngAfterViewInit(): void {
    this.cdr.detectChanges(); // trigger re-render for correct info icon positions
  }

  public ngOnChanges(): void {
    this.initChartOptions();
    this.convertDetailToChartData(this.detail);
    setTimeout(() => {
      this.baseChart.update();
      this.cdr.markForCheck();
    }, UPDATE_INTERVAL);
  }

  private convertDetailToChartData(detail: Detail): void {
    let keys: string[] = [];
    let values: number[];
    let ownValueLine: number[] = [];
    const isClustered: boolean = !!detail.clusterDetail;

    let ownValueLogo: HTMLImageElement;
    if (detail.soccerClub) {
      ownValueLogo = new Image();
      ownValueLogo.src = detail.soccerClub.logoImageUrl;
      ownValueLogo.width = CLUB_ICON_NORMAL;
      ownValueLogo.height = CLUB_ICON_NORMAL;
    }
    if (detail.multiValues) {
      const [ownValue, avgValues] = detail.multiValues;
      keys = Object.keys(ownValue);
      values = detail.own !== -1 ? Object.values(ownValue) : [];
      const values2: number[] = Object.values(avgValues);
      this.chartType = CHART_TYPE_LINE;
      this.chartLabels = keys;

      if (ownValueLogo) {
        ownValueLogo.width = CLUB_ICON_SMALL;
        ownValueLogo.height = CLUB_ICON_SMALL;
        this.pointStyleOwn = ownValueLogo.src;
      }

      const leagueValueLogos: HTMLImageElement[] = detail.leagues?.map((league: string) => {
        const _leagueValueLogo: HTMLImageElement = new Image();

        if (!league) {
          _leagueValueLogo.src = '/assets/lz-all.png';
        }
        if (league === 'PremierLeague') {
          _leagueValueLogo.src = 'https://d3ag-weblib.azureedge.net/competitions/png/51.png';
        }
        if (league === 'SecondLeague') {
          _leagueValueLogo.src = 'https://d3ag-weblib.azureedge.net/competitions/png/52.png';
        }
        if (league === 'ThirdLeague') {
          _leagueValueLogo.src = 'http://d3ag-weblib.azureedge.net/competitions/png/35.png';
        }
        if (league === 'FourthLeague') {
          _leagueValueLogo.src = 'http://d3ag-weblib.azureedge.net/competitions/png/30.png';
        }
        if (league === 'A') {
          _leagueValueLogo.src = '/assets/budget-a.png';
        }
        if (league === 'B') {
          _leagueValueLogo.src = '/assets/budget-b.png';
        }
        if (league === 'C') {
          _leagueValueLogo.src = '/assets/budget-c.png';
        }
        if (league === 'D') {
          _leagueValueLogo.src = '/assets/budget-d.png';
        }
        if (league === 'E') {
          _leagueValueLogo.src = '/assets/budget-e.png';
        }
        _leagueValueLogo.width = CLUB_ICON_SMALL;
        _leagueValueLogo.height = CLUB_ICON_SMALL;

        return _leagueValueLogo;
      });

      this.pointStyleLeague = leagueValueLogos.map((value: HTMLImageElement) => value.src);

      this.initChartData({
        dataSet: {
          data: values,
          pointStyle: ownValueLogo,
          borderColor: COLOR_LINE_OWN,
          backgroundColor: 'transparent',
          hoverBackgroundColor: 'transparent',
          fill: false,
          borderDash: [1, 2],
          tension: 0,
          borderWidth: 1,
        },
        dataSet2: {
          data: values2,
          pointStyle: leagueValueLogos,
          fill: false,
          borderColor: COLOR_LINE_LEAGUE,
          borderDash: [1, 2],
          tension: 0,
          borderWidth: 1,
        },
        logo: { data: ownValueLine, pointStyle: ownValueLogo, type: CHART_TYPE_LINE },
      });
      this.chartOptions.layout.padding = { top: 15 };
      this.chartOptions.scales.y.display = true;
      if (detail.type === KpiType.Binary) {
        this.chartOptions.scales.y.ticks = {
          ...this.chartOptions.scales.y.ticks,
          callback: (value: number | string): string | number | null | undefined => {
            if (value === 1) {
              return 'Ja';
            }
            if (value === 0) {
              return 'Nein';
            }
          },
        };
      }
      this.chartOptions.scales.x.grid.offset = true;
      this.chartOptions.scales.x.grid.drawTicks = true;

      const position: { min: number, max: number } = this.getMinMaxYScala(true);

      if (position.min != null) {
        this.chartOptions.scales.y.min = position.min;
      }
      if (!!position.max) {
        this.chartOptions.scales.y.max = position.max;
      }

      this.chartPlugins = [ChartDataLabels];
    } else {
      this.chartPlugins = [ChartDataLabels];
      this.chartOptions.scales.x.grid.drawTicks = false;

      if (detail && detail.type === KpiType.Aggregation) {

        keys = Object.keys(detail.distribution);
        values = Object.values(detail.distribution);
        ownValueLine = Object.entries(detail.distribution).map(([key, value]: [string, number]) => {
          const numKey: number = Math.round(parseFloat(key));
          const numOwn: number = detail.own !== null ?
            Math.round(isClustered ? detail.clusterDetail.own : detail.own) : null;

          return numKey === numOwn ? value : null;
        });

        this.chartType = CHART_TYPE_BAR;
        this.chartLabels = keys;
        this.chartType = CHART_TYPE_BAR;
        this.chartLabels = isClustered ? detail.clusterDetail.labels : keys;

        this.initChartData({
          dataSet: { data: values, maxBarThickness: 30 },
          logo: detail.soccerClub ? { data: ownValueLine, pointStyle: ownValueLogo, type: CHART_TYPE_LINE } : null,
        });
        this.chartOptions.layout.padding = { top: 15 };
        this.chartOptions.plugins.datalabels.display = false;
        this.chartOptions.scales.x.grid.offset = true;
        this.chartOptions.scales.x.grid.drawTicks = false;
        this.chartOptions.scales.x.grid.drawOnChartArea = false;

        const maxYScala: number = this.getMinMaxYScala()?.max;
        if (!!maxYScala) {
          this.chartOptions.scales.y.max = maxYScala;
        }

      } else {
        values = Object.values(detail.distribution);
        values = values.reverse(); // reverse values to match the reverted y-axis ticks

        if (detail.soccerClub && detail.own === 1 || detail.soccerClub === undefined) {
          values = values.reverse();
        }
        this.chartType = CHART_TYPE_BAR;
        this.chartLabels = ['', ''];

        this.initChartData({
          dataSet: { data: values },
        });
        this.chartOptions.layout.padding = { top: 0 };
        this.chartOptions.plugins.datalabels.display = true;
        this.chartOptions.scales.x.grid.offset = false;
        this.chartOptions.scales.y.grid.color = COLOR_INVISIBLE;
        this.chartOptions.scales.y.reverse = true;
        this.chartOptions.scales.x.grid.drawTicks = false;
        this.chartOptions.indexAxis = 'y';
      }
    }
  }

  private customTooltip(tooltip: Chart.TooltipModel<keyof Chart.ChartTypeRegistry>): void {
    this.tooltip = { ...tooltip } as Chart.TooltipModel<keyof Chart.ChartTypeRegistry>;
    this.cdr.markForCheck();
  }

  private initChartData({
    dataSet,
    dataSet2,
    logo,
  }: { dataSet?: ChartDataset; dataSet2?: ChartDataset; logo?: ChartDataset } = {}): void {
    this.chartData = [
      {
        data: [],
        backgroundColor: COLOR_BAR_FILL,
        hoverBackgroundColor: COLOR_BAR_FILL,
        ...(dataSet || {}),
      },
    ];
    if (logo && this.chartType !== CHART_TYPE_LINE) {
      this.chartData.unshift(logo);
    }
    if (this.detail?.multiValues) {
      // push league avg
      this.chartData.push({
        data: [],
        backgroundColor: 'transparent',
        hoverBackgroundColor: 'transparent',
        ...(dataSet2 || {}),
      });
    }
  }

  private initChartOptions(): void {
    this.chartOptions = {
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 15,
        },
      },
      scales: {
        x: {
          display: true,
          border: {
            color: COLOR_ZERO_LINE,
            display: true,
          },
          grid: {
            color: COLOR_ZERO_LINE,
            drawOnChartArea: false,
          },
          ticks: {
            font: {
              family: 'Lato',
              size: 12,
              weight: 'bold',
            },
            color: COLOR_FONT,
            padding: 8,
            maxRotation: 0,
            minRotation: 0,
            autoSkipPadding: 10,
            precision: 0,
          },
        },
        y: {
          display: true,
          border: {
            color: COLOR_ZERO_LINE,
            display: true,
          },
          grid: {
            color: COLOR_GRID_LINE,
            drawTicks: false,
          },
          ticks: {
            font: {
              family: 'Lato',
              size: 12,
              weight: 'bold',
            },
            color: COLOR_FONT,
            padding: 12,
            precision: 0,
          },
        },
      },
      animation: {
        duration: 0,
      },
      plugins: {
        datalabels: {
          display: false,
          color: COLOR_FONT,
          align: 'end',
          font: {
            family: 'Lato',
            size: 28,
            weight: 'bold',
          },
        },
        tooltip: {
          enabled: false,
          mode: 'index',
          position: 'nearest',
          external: (args: {
            chart: Chart.Chart<keyof Chart.ChartTypeRegistry, (number | Chart.ScatterDataPoint | Chart.BubbleDataPoint)[], unknown>,
            tooltip: Chart.TooltipModel<keyof Chart.ChartTypeRegistry>
          }) => this.customTooltip(args.tooltip),
        },
      },
    };
  }

  private getMinMaxYScala(isMultiValue: boolean = false): { min: number; max: number } {
    const position: { minImg: number, maxImg: number, minValue: number, maxValue: number }[] = [{
      minImg: null,
      maxImg: null,
      minValue: null,
      maxValue: null,
    }];
    this.chartData.forEach((dataset: Chart.ChartDataset) => {
      const data: number[] = dataset.data.filter((item: number | null) => item != null).map((item: number) => item);
      if (isMultiValue) {
        position.push({
          minImg: Math.min(...data),
          maxImg: Math.max(...data),
          minValue: Math.min(...data),
          maxValue: Math.max(...data),
        });
      } else if (!!dataset?.['pointStyle']) {
        position[0].minImg = Math.min(...data);
        position[0].maxImg = Math.max(...data);
      } else {
        position[0].minValue = Math.min(...data);
        position[0].maxValue = Math.max(...data);
      }
    });

    return this.calcMinMaxValue(position);
  }

  private calcMinMaxValue(_value: { minImg: number, maxImg: number, minValue: number, maxValue: number }[]): {
    min: number;
    max: number
  } | null {
    const minMax: { min: number; max: number } = { min: null, max: null };
    const value: { minImg: number, maxImg: number, minValue: number, maxValue: number } = {
      minImg: this.getMinMaxValue('minImg', _value),
      maxImg: this.getMinMaxValue('maxImg', _value),
      minValue: this.getMinMaxValue('minValue', _value),
      maxValue: this.getMinMaxValue('maxValue', _value),
    };

    let maxValue: { value: number; step: Steps };
    if (value.maxImg === value.maxValue) {
      maxValue = this.nextMaxStep(value.maxValue);
      minMax.max = maxValue.value;
    }
    if (value.minImg === value.minValue) {
      minMax.min = this.nextMinStep(value.minValue, maxValue?.step);
    }

    return minMax;
  }

  private getMinMaxValue(_key: string, _position: { minImg: number, maxImg: number, minValue: number, maxValue: number }[]): number {
    const methode: string = _key.substring(0, 3);
    return Math[methode](..._position
      .filter((value: { minImg: number, maxImg: number, minValue: number, maxValue: number }) => value[_key] != null)
      .map((value: { minImg: number, maxImg: number, minValue: number, maxValue: number }) => value[_key]),
    );
  }

  private nextMaxStep(max: number): { value: number; step: Steps } {
    let step: Steps = this.getStep(max);
    const tolerance: number = this.getTolerance(step);

    if (step === Steps.one) {
      return { value: Math.trunc(max + tolerance), step };
    }
    return { value: Math.ceil((max + tolerance) / step) * step, step };
  }

  private nextMinStep(min: number, step: Steps): number {
    step = step ?? this.getStep(min);
    const tolerance: number = this.getTolerance(step);

    if (step === Steps.one) {
      return Math.ceil(min - tolerance);
    }
    return Math.trunc((min - tolerance) / step) * step;
  }

  private getStep(value: number): Steps {
    if (value >= 10000) {
      return Steps.twoThousand;
    } else if (value >= 4000) {
      return Steps.thousand;
    } else if (value >= 2000) {
      return Steps.fiveHundred;
    } else if (value >= 1000) {
      return Steps.twoHundred;
    } else if (value >= 500) {
      return Steps.hundred;
    } else if (value >= 100) {
      return Steps.twentyFive;
    } else if (value >= 40) {
      return Steps.ten;
    } else if (value >= 20) {
      return Steps.five;
    } else if (value >= 10) {
      return Steps.two;
    } else if (value >= 0) {
      return Steps.one;
    }
    return Steps.one;
  }

  private getTolerance(step: Steps): number {
    switch (step) {
      case Steps.twoThousand:
        return 401;
      case Steps.thousand:
        return 201;
      case Steps.fiveHundred:
        return 101;
      case Steps.twoHundred:
        return 61;
      case Steps.hundred:
        return 31;
      case Steps.ten:
        return 3;
      case Steps.five:
        return 2;
      default:
        return 1;
    }
  }
}
