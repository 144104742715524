import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SoccerClub } from '../models/soccerClub';
import { SeasonService } from '../services/season/season.service';
import { getClub } from '../store/selectors/club.selectors';
import { AppState } from '../store/states/app.state';
import { DataImporterEnum } from './partials/data-import/models/data-import-job';
import { Season } from '../services/season/models/season';

@Component({
  selector: 'lsz-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  public seasons$: Observable<Season[]>;
  public soccerClub$: Observable<SoccerClub>;
  public dataImportEnum: typeof DataImporterEnum;

  constructor(private store: Store<AppState>,
              private seasonService: SeasonService) {
    this.dataImportEnum = DataImporterEnum;
  }

  public ngOnInit(): void {
    this.soccerClub$ = this.store.pipe(
      select(getClub),
    );

    this.seasons$ = this.seasonService.getSeasons();
  }

}
