<tr [formGroup]="form">
  <td i18n="@@specialEquipmentType">
    {entry.equipmentType, select,
      Helix {Helix}
      Footbonaut {Footbonaut o.Ä.}
      SprintHill {Sprinthügel}
      HeaderPendulum {Kopfballpendel}
      FootballTennis {Fußballtennis}
      OtherSports {andere Sportarten (Beachvolleyball, Basketball, Kletterwand etc.)}
      SprintTrack {Sprintbahn}
      VirtualReality {Virtual Reality}
      SpeedCourt {Speedcourt}
      AltitudeChamber {Höhenkammer}
    }
  </td>
  <td>
    <lsz-binary-display *ngIf="!editMode" [value]="isAvailable.value"></lsz-binary-display>
    <lsz-binary-select *ngIf="editMode" [form]="form" [name]="'isAvailable'"></lsz-binary-select>
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{remark.value}}</ng-container>
    <textarea *ngIf="editMode" formControlName="remark"></textarea>
  </td>
  <td *ngIf="editMode">
    <lsz-dataset-options [form]="form" [withoutDelete]="true" (save)="onClickSave()"></lsz-dataset-options>
  </td>
  <td>
    <lsz-last-edit [form]="form"></lsz-last-edit>
  </td>
</tr>
