import { Component } from '@angular/core';
import { StsAuthService } from 'sts-auth';

@Component({
  selector: 'lsz-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {

  constructor(private authService: StsAuthService) {
  }

  public login(): void {
    this.authService.login();
  }
}
