import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { KpiType } from '../../../../models/kpi-type.enum';
import { SoccerClub } from '../../../../../models/soccerClub';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lsz-all-clubs-table-modal',
  templateUrl: './all-clubs-table-modal.component.html',
  styleUrls: ['./all-clubs-table-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AllClubsTableModalComponent implements OnInit {
  public clubs: SoccerClub[];
  public kpiName: string;
  public kpiType: KpiType;
  public kpiTypeEnum: typeof KpiType;
  public mappedClubKeys: number[];
  public mappedClubs: Map<number, SoccerClub[]>;

  public faTimes;

  constructor(private modal: NgbActiveModal) {
    this.kpiTypeEnum = KpiType;
    this.faTimes = faTimes;
  }

  public ngOnInit(): void {
    this.clubs = this.clubs?.filter((soccerClub: SoccerClub) => soccerClub?.sum >= 0).sort((a: SoccerClub, b: SoccerClub) => a.sum > b.sum ? 1 : -1);
    const indexArray: Map<number, SoccerClub[]> = new Map<number, SoccerClub[]>();

    this.clubs?.forEach((soccerClub: SoccerClub) => {
      const keys: SoccerClub[] = indexArray.get(soccerClub.sum);
      if (keys) {
        keys.push(soccerClub);
      } else {
        indexArray.set(soccerClub.sum, [soccerClub]);
      }
    });
    this.mappedClubs = indexArray;
    this.mappedClubKeys = Array.from(indexArray.keys());
  }

  public dismiss(): void {
    this.modal.dismiss();
  }

}
