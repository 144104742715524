<tr [formGroup]="form">
  <td i18n="@@schoolCooperationType">
    {entry.personnelFunction, select,
      SportAreaManager {Sportlicher Bereichsleiter}
      TeamTrainer {Mannschaftstrainer}
      GoalKeeperTrainer {Torwarttrainer}
      SpecialTrainer {Spezialtrainer}
      SportPsychologist {Sportpsychologische<br/>Begleitung (Sport/Soziales)}
      MatchAnalysis {Spielanalyse}
      AthleticTrainer {Athletik-/Reha-Trainer}
      SportScientist {Sportwissenschaftler<br/>(inkl. Leistungsdiagnostik)}
      TransitionCoordinator {Übergangsbereich}
    }
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{form.get('cLicenses')?.value}}</ng-container>
    <input *ngIf="editMode && form.get('cLicenses')" type="number" min="0" formControlName="cLicenses"/>
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{form.get('bLicenses')?.value}}</ng-container>
    <input *ngIf="editMode && form.get('bLicenses')" type="number" min="0" formControlName="bLicenses"/>
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{form.get('bPlusLicenses')?.value}}</ng-container>
    <input *ngIf="editMode && form.get('bPlusLicenses')" type="number" min="0" formControlName="bPlusLicenses"/>
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{form.get('aLicenses')?.value}}</ng-container>
    <input *ngIf="editMode && form.get('aLicenses')" type="number" min="0" formControlName="aLicenses"/>
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{form.get('aPlusLicenses')?.value}}</ng-container>
    <input *ngIf="editMode && form.get('aPlusLicenses')" type="number" min="0" formControlName="aPlusLicenses"/>
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{form.get('proLicenses')?.value}}</ng-container>
    <input *ngIf="editMode && form.get('proLicenses')" type="number" min="0" formControlName="proLicenses"/>
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{ form.get('goalKeeperLicenses')?.value }}</ng-container>
    <ng-container *ngIf="editMode">
      <input *ngIf="form.get('goalKeeperLicenses')" type="number" min="0" formControlName="goalKeeperLicenses" />
    </ng-container>
  </td>
  <td *ngIf="editMode" class="frozen-column-options">
    <lsz-dataset-options [form]="form" [withoutDelete]="true" (save)="onClickSave()"></lsz-dataset-options>
  </td>
  <td>
    <lsz-last-edit [form]="form"></lsz-last-edit>
  </td>
</tr>
