export enum Role {
  USER = 'lsz:user',
  DFL_ADMIN = 'lsz:dfl:admin',
  DFL_DATA_ANALYST = 'lsz:dfl:data-analyst',
  DFL_USER = 'lsz:dfl:user',
  DFB_USER = 'lsz:dfb:user',
  DFB_DATA_ANALYST = 'lsz:dfb:data-analyst',
  CLUB_USER = 'lsz:club:user',
  CLUB_EDITOR = 'lsz:club:editor',
}
