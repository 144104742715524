<div class="form-wrapper">
  <lsz-edit-form-header
    [headerText]="'Schulkooperationen'"
    [numEntries]="amount"
    [editMode]="editMode"
    (clickEdit)="onClickEdit()"
    (clickEditDone)="onClickEditDone()"
  ></lsz-edit-form-header>

  <table [class.editMode]="editMode" [formGroup]="allForms">
    <thead>
    <tr>
      <th i18n="@@tableHeaderName">Name</th>
      <th i18n="@@tableHeaderCount">Anzahl</th>
      <th *ngIf="editMode" i18n="@@tableHeaderOptions" class="column-options">Optionen</th>
      <th>{{'lastEdit' | translate}}</th>
    </tr>
    </thead>
    <tbody
      *ngIf="eliteSchool"
      lsz-school-cooperation-typed-form
      [entry]="eliteSchool"
      (entrySaved)="onTypedEntrySaved(eliteSchool, $event, 'eliteSchool')"
      [editMode]="editMode"
      [form]="allForms.get('eliteSchool')"
    ></tbody>
    <tbody
      *ngIf="commonSchool"
      lsz-school-cooperation-typed-form
      [entry]="commonSchool"
      (entrySaved)="onTypedEntrySaved(commonSchool, $event, 'commonSchool')"
      [editMode]="editMode"
      [form]="allForms.get('commonSchool')"
    ></tbody>
  </table>
</div>
