import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Moment } from 'moment/moment';

@Injectable()
export class CustomDatepickerAdapter extends NgbDateAdapter<string> {

  public fromModel(value: string): NgbDateStruct {
    const momentDate: Moment = moment(value, 'DD.MM.YYYY');

    return {
      year: momentDate.get('year'),
      month: momentDate.get('month') + 1,
      day: momentDate.get('date'),
    };
  }

  public toModel(date: NgbDateStruct): string {
    const currentDate: NgbDateStruct = date ? {...date, month: date.month - 1} : date;

    return moment(currentDate).format('DD.MM.YYYY');
  }

}
