import { Component, Input } from '@angular/core';
import { Room } from '../../models/models';
import { BaseEditFormComponent } from '../../../shared/base-edit-form/base-edit-form.component';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'tbody[lsz-room-typed-form]',
  templateUrl: './room-typed-form.component.html',
  styleUrls: ['./room-typed-form.component.css'],
})
export class RoomTypedFormComponent extends BaseEditFormComponent<Room> {
  @Input() toggle: boolean;

  public get count(): AbstractControl {
    return this.form.get('count');
  }

  constructor() {
    super();
  }

  public onClickSave(): void {
    this.entrySaved.emit({
      ...this.entry,
      count: Number(this.count.value),
      remark: this.remark.value as string,
    } as Room);
  }
}
