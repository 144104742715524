import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ImprintComponent } from './static-websites/imprint/imprint.component';
import { LegalNoticeComponent } from './static-websites/legal-notice/legal-notice.component';
import { PrivacyPolicyComponent } from './static-websites/privacy-policy/privacy-policy.component';

const routes: Routes = [
  {path: '', redirectTo: 'show-data', pathMatch: 'full'},
  {path: 'impressum', component: ImprintComponent},
  {path: 'datenschutz', component: PrivacyPolicyComponent},
  {path: 'rechtliche-hinweise', component: LegalNoticeComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {anchorScrolling: 'enabled'})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
