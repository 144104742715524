import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lsz-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.css'],
})
export class ImprintComponent implements OnInit {

  public ngOnInit(): void {
    setTimeout(() => {
      window.scrollTo({top: 1, behavior: 'smooth'});
    }, 1);
  }

}
