import { Component } from '@angular/core';
import { OpenSpaceField } from '../../models/models';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Validators } from '../../validators';
import { OpenSpaceFieldService } from '../../services/open-space-field.service';
import { ConfirmationDialogService } from '../../../shared/confirmation-dialog/services/confirmation-dialog.service';
import { BaseTableViewComponent } from '../../../shared/base-table-view/base-table-view.component';

@Component({
  selector: 'lsz-open-space-field-form-collection',
  templateUrl: './open-space-field-form-collection.component.html',
  styleUrls: ['./open-space-field-form-collection.component.css'],
})
export class OpenSpaceFieldFormCollectionComponent extends BaseTableViewComponent<OpenSpaceField> {

  public get surface(): AbstractControl {
    return this.newEntryForm.get('surface');
  }

  public get length(): AbstractControl {
    return this.newEntryForm.get('length');
  }

  public get width(): AbstractControl {
    return this.newEntryForm.get('width');
  }

  public get isHeated(): AbstractControl {
    return this.newEntryForm.get('isHeated');
  }

  constructor(protected service: OpenSpaceFieldService,
              protected confirmationDialog: ConfirmationDialogService) {
    super(service, confirmationDialog);
  }

  public createNewEntry(): void {
    const entry: Partial<OpenSpaceField> = {
      size: {
        length: this.length.value as number,
        width: this.width.value as number,
      },
    };

    this.addEntry(entry);
  }

  public onUpdate(index: number): void {
    const form: UntypedFormGroup = this.customEntryForms.at(index) as UntypedFormGroup;
    const entry: Partial<OpenSpaceField> = {
      size: {
        length: form.get('length').value as number,
        width: form.get('width').value as number,
      },
    };

    this.updateEntry(index, entry);
  }

  protected formGroup(entry?: OpenSpaceField): UntypedFormGroup {
    return new UntypedFormGroup({
      name: new UntypedFormControl(entry?.name, [Validators.required, this.repeatedNameValidator()]),
      remark: new UntypedFormControl(entry?.remark),
      length: new UntypedFormControl(entry?.size.length, Validators.required),
      width: new UntypedFormControl(entry?.size.width, Validators.required),
      surface: new UntypedFormControl(entry?.surface, Validators.required),
      isHeated: new UntypedFormControl(entry?.isHeated, Validators.required),
      updatedAtUtc: new UntypedFormControl(entry?.updatedAtUtc),
      createdAtUtc: new UntypedFormControl(entry?.createdAtUtc),
    });
  }

  protected objectToForm(entry?: OpenSpaceField): any {
    return {
      name: entry.name,
      remark: entry.remark,
      length: entry.size.length,
      width: entry.size.width,
      surface: entry.surface,
      isHeated: entry.isHeated,
      updatedAtUtc: entry?.updatedAtUtc,
      createdAtUtc: entry?.createdAtUtc,
    };
  }
}
