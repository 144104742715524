<h4>{{title}}</h4>

<input type="file" class="file-input" [accept]="validExtensions.toString()" (change)="onFileSelected($event)"
       #fileUpload>
<div class="file-upload" fxLayout="column" fxLayoutGap="10px">
  <div *ngIf="dataImport; else noDataImportJob" fxLayout="column">
    <span>Es wurde bereits ein Import am {{(dataImport.updatedAtUtc ? dataImport.updatedAtUtc : dataImport.startAtUtc) | date:'medium':'+0400'}}
      Uhr durchgeführt</span>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
      <span>Status: {{dataImport.state | translatedJobState}}</span>
      <span *ngIf="importError">
        <button class="lsz-button" (click)="showErrorDetail()">
         <fa-icon [icon]="faInfoCircle"></fa-icon>
      </button></span>
    </div>

    <span *ngIf="error" class="input-error">
      {{error.detail}}
    </span>

  </div>

  <ng-template #noDataImportJob>
    <span *ngIf="!file && currentSeason">
      Es wurden noch keine Daten hochgeladen
    </span>
    <span *ngIf="file && validFile && !error">
      {{file.name}}
    </span>

    <span *ngIf="file && !validFile && !error">
      Falsches Dateiformat - Es ist nur das .csv-Dateiformat möglich
    </span>
  </ng-template>

  <div fxLayout="row" fxLayoutGap="10px">
    <button class="lsz-button" [disabled]="dataImport &&  !jobSucceededOrFailed()" (click)="fileUpload.click()">
      {{'.csv-Datei auswählen'}}
    </button>

    <button *ngIf="file" [disabled]="!file || !validFile" class="lsz-button" (click)="upload()">
      {{'.csv-Datei hochladen' }}
    </button>
  </div>

</div>