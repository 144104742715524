import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmationDialogComponent } from '../models/confirmation-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationDialogService {

  constructor(private modalService: NgbModal) {
  }


  public open(title: string, text?: string, labelConfirm?: string, labelCancel?: string): NgbModalRef {
    const modalRef: NgbModalRef = this.modalService.open(ConfirmationDialogComponent);
    modalRef.componentInstance.title = title;
    if (text) {
      modalRef.componentInstance.text = text;
    }
    if (labelConfirm) {
      modalRef.componentInstance.labelConfirm = labelConfirm;
    }
    if (labelCancel) {
      modalRef.componentInstance.labelCancel = labelCancel;
    }

    return modalRef;
  }

  public openEditDoneConfirmation(): NgbModalRef {
    return this.open(
      'Nicht alle Einträge gespeichert',
      'Du hast nicht alle Einträge abgespeichert. Beendest du nun die Editierfunktion, gehen alle nicht gespeicherten Daten verloren. ' +
      'Nicht gespeicherte Einträge werden dir mit einem roten Speicher-Symbol angezeigt.',
      'Trotzdem fortfahren',
      'Abbrechen',
    );
  }
}
