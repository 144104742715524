import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerConfig,
  NgbDatepickerI18n,
  NgbDatepickerModule,
  NgbTimepickerModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';

import { ClubSwitchComponent } from './club-switch/club-switch.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/models/confirmation-dialog.component';
import { DataEntryCountDownModule } from './data-entry-count-down/data-entry-count-down.module';
import { DateInputComponent } from './date-input/date-input.component';
import { CustomDatepickerAdapter } from './datepicker/custom-datepicker-adapter';
import { CustomDatepickerFormat } from './datepicker/custom-datepicker-format';
import { CustomDatepickerLanguage } from './datepicker/custom-datepicker-language';
import { HandleClickOutsideDirective } from './handle-click-outside/handle-click-outside.directive';
import { FromDatepickerPipe } from './pipes/from-datepicker.pipe';
import { ToDatepickerPipe } from './pipes/to-datepicker.pipe';
import { YearsAgoPipe } from './pipes/years-ago.pipe';
import { SeasonSelectionBarComponent } from './season-selection-bar/season-selection-bar.component';
import { TimeInputComponent } from './time-input/time-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { LastEditComponent } from './last-edit/last-edit.component';
import { TickSliderComponent } from './tick-slider/tick-slider.component';
import { MatSliderModule } from '@angular/material/slider';

@NgModule({
  declarations: [
    ClubSwitchComponent,
    ToDatepickerPipe,
    YearsAgoPipe,
    FromDatepickerPipe,
    DateInputComponent,
    TimeInputComponent,
    ConfirmationDialogComponent,
    HandleClickOutsideDirective,
    SeasonSelectionBarComponent,
    LastEditComponent,
    TickSliderComponent,
  ],
  imports: [
    CommonModule,
    FlexModule,
    FlexLayoutModule.withConfig({ disableDefaultBps: true }),
    NgbDatepickerModule,
    NgbTimepickerModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    FormsModule,
    NgbTypeaheadModule,
    RouterModule,
    DataEntryCountDownModule,
    TranslateModule,
    MatSliderModule,
  ],
  exports: [
    FlexLayoutModule,
    ClubSwitchComponent,
    FontAwesomeModule,
    ToDatepickerPipe,
    YearsAgoPipe,
    FromDatepickerPipe,
    DateInputComponent,
    TimeInputComponent,
    DataEntryCountDownModule,
    HandleClickOutsideDirective,
    SeasonSelectionBarComponent,
    TranslateModule,
    LastEditComponent,
    TickSliderComponent,
  ],
  providers: [
    ToDatepickerPipe,
    FromDatepickerPipe,
    NgbDatepickerConfig,
    { provide: NgbDateParserFormatter, useClass: CustomDatepickerFormat },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerLanguage },
    { provide: NgbDateAdapter, useClass: CustomDatepickerAdapter },
  ],
})
export class SharedModule {

  constructor(config: NgbDatepickerConfig) {
    config.minDate = { year: 1900, month: 1, day: 1 };
    config.maxDate = { year: 2099, month: 12, day: 31 };
  }

}
