import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'lsz-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
})
export class DateInputComponent {
  @Input() public dateBeforeOtherErrorMsg: string = 'Start muss vor dem Ende liegen';
  @Input() public datePastErrorMsg: string = 'Datum liegt in der Vergangenheit';
  @Input() public formCtrl: UntypedFormControl;
  @Input() public invalidErrorMsg: string = 'Fehlerhaftes Datum';
  @Input() public requiredErrorMsg: string = 'Angabe wird benötigt';

  public faCalendar: unknown;

  constructor() {
    this.faCalendar = faCalendar;
  }

}
