import { Component, EventEmitter, Output } from '@angular/core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { TemporalReference } from './model/constants';
import { TemporalReferenceOption } from './model/temporalReferenceOption';

@Component({
  selector: 'lsz-temporal-reference-switch',
  templateUrl: './temporal-reference-switch.component.html',
  styleUrls: ['./temporal-reference-switch.component.scss'],
})
export class TemporalReferenceSwitchComponent {
  @Output() public switched: EventEmitter<TemporalReference>;

  public faInfoCircle: unknown;
  public numValue: number;
  public sliderLabels: string[];
  public ticks: number[];

  private temporalReferenceOptions: TemporalReferenceOption[];

  constructor() {
    this.switched = new EventEmitter<TemporalReference>();
    this.faInfoCircle = faInfoCircle;
    this.numValue = 0;
    this.temporalReferenceOptions = [
      {
        title: 'Aktuell',
        reference: TemporalReference.CURRENT,
      },
      {
        title: 'Historie',
        reference: TemporalReference.HISTORY,
      },
    ];
    this.sliderLabels = this.temporalReferenceOptions.map((option: TemporalReferenceOption) => option.title);
    this.ticks = this.temporalReferenceOptions.map((_: TemporalReferenceOption, index: number) => index);
  }

  public switch(newValue: number): void {
    this.switched.emit(this.temporalReferenceOptions[newValue].reference);
  }
}
