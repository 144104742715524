import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SupportPartTimeEmployee } from '../models/models';

import { InfrastructureService } from './infrastructure.service';

@Injectable({
  providedIn: 'root',
})
export class SupportPartTimeEmployeeService extends InfrastructureService<SupportPartTimeEmployee> {
  protected readonly endpoint: string = '/SupportPartTimeEmployees';

  constructor(protected http: HttpClient) {
    super(http);
  }

}
