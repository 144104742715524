import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { Filter } from './model/constants';
import { FilterOption } from './model/filterOption';

@Component({
  selector: 'lsz-filter-switch',
  templateUrl: './filter-switch.component.html',
  styleUrls: ['./filter-switch.component.scss'],
})
export class FilterSwitchComponent {

  @Output() public switched: EventEmitter<Filter>;
  @Input() public disabled: boolean;

  @Input()
  public set value(value: Filter) {
    const foundIndex: number = this.filterOptions.findIndex((_value: FilterOption) => _value.filter === value);
    if (foundIndex > -1) {
      this._value = foundIndex;
    }
  }

  public faInfoCircle: unknown;
  public ticks: number[];
  public sliderLabels: string[];
  public _value: number = 0;

  private filterOptions: FilterOption[];

  constructor() {
    this.faInfoCircle = faInfoCircle;
    this.switched = new EventEmitter<Filter>();
    this.filterOptions = [
      {
        title: 'Alle',
        filter: Filter.ALL,
      },
      {
        title: 'Liga',
        filter: Filter.LEAGUE,
      },
      {
        title: 'Budget',
        filter: Filter.BUDGET,
      },
    ];
    this.sliderLabels = this.filterOptions.map((option: FilterOption) => option.title);
    this.ticks = this.filterOptions.map((_: FilterOption, index: number) => index);

  }

  public switch(newValue: number): void {
    this.switched.emit(this.filterOptions[newValue].filter);
  }
}
