<lsz-header [showLeagueSelect]="false"
            [showBudgetSelect]="false"
            [soccerClub]="soccerClub$ | async">
</lsz-header>

<div id="admin-content">
  <div>
    <div id="editor-nav">
      <nav>
        <header class="header-title">
          <a href="/admin#lock-period">Sperrzeitraum</a>
        </header>
      </nav>
      <nav>
        <header class="header-title">
          <a href="/admin#data-import">Stammdatenimport</a>
        </header>
      </nav>
      <nav>
        <header class="header-title">
          <a href="/admin#colleges-import">Datenimport untergebrachte Spieler</a>
        </header>
      </nav>
      <nav>
        <header class="header-title">
          <a href="/admin#effectivity-import">Effektivitätsdatenimport</a>
        </header>
      </nav>
      <nav>
        <header class="header-title">
          <a href="/admin#recalculate-season">Saison-Neuberechnung</a>
        </header>
      </nav>
      <nav>
        <header class="header-title">
          <a href="/admin#active-teams">Aktive Teams</a>
        </header>
      </nav>
    </div>
  </div>

  <div id="editor-main" class="form-wrapper">

    <h2>Admin</h2>

    <div id="lock-period">
      <lsz-set-lock-period></lsz-set-lock-period>
    </div>

    <div id="data-import">
      <lsz-data-import [importer]="dataImportEnum.SOCCER_CLUBS"></lsz-data-import>
    </div>

    <div id="colleges-import">
      <lsz-data-import [importer]="dataImportEnum.COLLEGES"></lsz-data-import>
    </div>

    <div id="effectivity-import">
      <lsz-data-import [importer]="dataImportEnum.EFFECTIVITY"></lsz-data-import>
    </div>

    <div id="recalculate-season">
      <lsz-recalculate-season></lsz-recalculate-season>
    </div>

    <div id="active-teams">
      <lsz-active-teams-form-collection></lsz-active-teams-form-collection>
    </div>

  </div>

</div>
