import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[lszHandleClickOutside]',
})
export class HandleClickOutsideDirective {
  @Output() public outsideClick: EventEmitter<void> = new EventEmitter<void>();

  private compEvent: MouseEvent;

  @HostListener('click', ['$event'])
  public onComponentClick(event: MouseEvent): void {
    this.compEvent = event;
  }

  @HostListener('document: click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    // if the last known component click event and the given document click event are the same,
    // we know that the event originated within the host component and then bubbled up to the document.
    // as such, it must be an "inside" click event and therefore we ignore it.
    // all other events are "outside" click events.
    if (this.compEvent === event) {
      return;
    }
    this.outsideClick.emit();
  }
}
