import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';

import { AdminModule } from './admin/admin.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthCallbackModule } from './auth-callback/auth-callback.module';
import { CoreModule } from './core/core.module';
import { DataEditorModule } from './data-editor/data-editor.module';
import { DataPresenterModule } from './data-presenter/data-presenter.module';
import { LoginModule } from './login/login.module';
import { SeasonService } from './services/season/season.service';
import { SoccerClubService } from './services/soccer-club/soccer-club.service';
import { SharedModule } from './shared/shared.module';
import { ImprintComponent } from './static-websites/imprint/imprint.component';
import { LegalNoticeComponent } from './static-websites/legal-notice/legal-notice.component';
import { PrivacyPolicyComponent } from './static-websites/privacy-policy/privacy-policy.component';
import { appReducers } from './store/store';
import { StsAuthModule } from 'sts-auth';
import { environment } from '../environments/environment';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

registerLocaleData(localeDe, 'de-DE');

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ImprintComponent,
    PrivacyPolicyComponent,
    LegalNoticeComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    DataEditorModule,
    DataPresenterModule,
    AdminModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot(appReducers),
    FormsModule,
    CoreModule,
    LoginModule,
    AuthCallbackModule,
    StsAuthModule.forRoot(environment),
    FlexLayoutModule.withConfig({ disableDefaultBps: true }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    SoccerClubService,
    SeasonService,
    {provide: LOCALE_ID, useValue: 'de-DE'},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
