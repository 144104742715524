import { Component } from '@angular/core';
import { ManagementPartTimeEmployee } from '../../models/models';
import { AbstractControl } from '@angular/forms';
import { BaseEditFormComponent } from '../../../shared/base-edit-form/base-edit-form.component';

@Component({
  selector: 'tbody[lsz-part-time-employee-management-typed-form]',
  templateUrl: './part-time-employee-management-typed-form.component.html',
  styleUrls: ['./part-time-employee-management-typed-form.component.css'],
})
export class PartTimeEmployeeManagementTypedFormComponent extends BaseEditFormComponent<ManagementPartTimeEmployee> {
  public get personnelCount(): AbstractControl {
    return this.form.get('personnelCount');
  }

  constructor() {
    super();
  }

  public onClickSave(): void {
    this.entrySaved.emit({
      ...this.entry,
      personnelCount: this.personnelCount.value as number,
    } as ManagementPartTimeEmployee);
  }
}
