import { LockPeriod } from './lockPeriod';

export class LockPeriodDto {
  public dataInputEndsAtUtc: string;
  public seasonClosesAtUtc: string;
  public seasonId: string;

  constructor(lockPeriod: LockPeriod) {
    this.dataInputEndsAtUtc = lockPeriod.start;
    this.seasonId = lockPeriod.seasonId;
    this.seasonClosesAtUtc = lockPeriod.end;
  }
}
