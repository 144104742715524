<div class="headline">Darstellung
  <ng-template #tipContent>{{ tooltipText }}</ng-template>
  <fa-icon class="fa-lg iconcolor"
           [icon]="faInfoCircle"
           placement="bottom"
           tooltipClass="tipclass"
           [ngbTooltip]="tipContent">
  </fa-icon>
</div>
<lsz-tick-slider [(value)]="baselineValue"
                 [labels]="['Median', 'Mittelwert']"
                 [disabled]="false"
                 (valueChange)="switch($event)">
</lsz-tick-slider>
