import { Pipe, PipeTransform } from '@angular/core';

import { DetailIsPercentage } from '../helpers/detailIsPercentage';

@Pipe({
  name: 'convertToPercentage',
})
export class ConvertToPercentagePipe implements PipeTransform {

  public transform(value: number, detailName: string, maxFractionDigits?: number): number | string {
    if (value !== undefined && value !== null) {
      let _value: string = value.toString();
      if (DetailIsPercentage.isPercentage(detailName)) {
        _value = (value * 100).toString();
        if (!isNaN(maxFractionDigits)) {
          _value = (value * 100).toFixed(maxFractionDigits);
        }

        return `${_value} %`;

      } else {
        return _value;
      }
    }

    return value;
  }

}
