import { BaseKpisProcessor } from './baseKpisProcessor';
import { Criteria } from './criteria';
import { Kpis } from './kpis';
import { KpisProcessResult } from './kpisProcessResult';

export class KpisProcessor extends BaseKpisProcessor {

  public process(kpis: Kpis): KpisProcessResult {
    const result: Criteria = {};

    this.initCriteria(kpis, result);
    this.initCategories(kpis, result);
    this.addKpis(kpis, result);

    const kpisProcessResult: KpisProcessResult = {criteria: result};
    this.rateAll(kpisProcessResult, kpis, this.soccerClub);

    return kpisProcessResult;
  }

}
