<div class="chart-container">
  <fa-icon *ngIf="chartType === CHART_TYPE_BAR || chartType === CHART_TYPE_LINE"
           [icon]="faInfoCircle"
           size="lg"
           class="info-icon-y"
           tooltipClass="tipclass" [ngbTooltip]="tooltipY" placement="right"
           [style.top]="baseChart?.chart.chartArea?.top + 'px'"
           [style.left]="baseChart?.chart.chartArea?.left + 'px'">
  </fa-icon>
  <ng-template #tooltipY>
    <ng-container *ngIf="chartType === CHART_TYPE_BAR">
      <span i18n>Anzahl Club</span>
    </ng-container>
    <ng-container *ngIf="chartType === CHART_TYPE_LINE; ">
      <span i18n>Anzahl</span>&nbsp;<lsz-category-kpi-name [name]="detail?.name"></lsz-category-kpi-name>
    </ng-container>
  </ng-template>
  <fa-icon
    [icon]="faInfoCircle"
    size="lg"
    class="info-icon-x"
    tooltipClass="tipclass" [ngbTooltip]="tooltipX" placement="left"
    [style.left]="'100%'"
    [style.top]="baseChart?.chart.chartArea?.bottom + 'px'">
  </fa-icon>
  <ng-template #tooltipX>
    <div [ngSwitch]="criterion">
      <div *ngSwitchCase="criterions.Infrastructure">
        <div [ngSwitch]="chartType">
          <span i18n *ngSwitchCase="CHART_TYPE_BAR">
            Anzahl&nbsp;<lsz-category-kpi-name [name]="detail?.name"></lsz-category-kpi-name>
          </span>
          <span i18n *ngSwitchCase="CHART_TYPE_LINE">Saison</span>
          <span i18n *ngSwitchDefault>Anzahl Clubs</span>
        </div>
      </div>
      <div *ngSwitchCase="criterions.Personnel">
        <div [ngSwitch]="chartType">
          <span *ngSwitchCase="CHART_TYPE_BAR">
            <span *ngIf="category?.name === 'Membership'; else default" i18n>Anzahl Jahre</span>
            <ng-template #default>
              <span i18n>Anzahl&nbsp;<lsz-category-kpi-name [name]="detail?.name"></lsz-category-kpi-name></span>
            </ng-template>
          </span>
          <span i18n *ngSwitchCase="CHART_TYPE_LINE">Saison</span>
          <span *ngSwitchDefault i18n>Anzahl&nbsp;<lsz-category-kpi-name [name]="detail?.name"></lsz-category-kpi-name></span>
        </div>
      </div>
      <div *ngSwitchCase="criterions.Effectiveness">
        <div [ngSwitch]="chartType">
          <span i18n *ngSwitchCase="CHART_TYPE_LINE">Saison</span>
          <span i18n *ngSwitchDefault><lsz-category-kpi-name [name]="detail?.name"></lsz-category-kpi-name></span>
        </div>
      </div>
    </div>
  </ng-template>
  <canvas baseChart
          [datasets]="chartData"
          [labels]="chartLabels"
          [options]="chartOptions"
          [plugins]="chartPlugins"
          [legend]="chartLegend"
          [type]="chartType">
  </canvas>
  <div #chartTooltip
       id="chartjs-tooltip"
       class="chart-tooltip"
       [ngClass]="tooltip?.yAlign"
       [style.opacity]="tooltip?.opacity"
       [style.left]="baseChart?.chart.canvas.offsetLeft + tooltip?.caretX + 'px'"
       [style.top]="baseChart?.chart.canvas.offsetTop + tooltip?.caretY + 'px'">
    <div class="chart-tooltip-wrapper" *ngIf="!detail?.multiValues; else historyTooltip">
      <table>
        <thead>
        <tr *ngFor="let tooltipTitle of tooltip?.title">
          <th>
            <ng-container *ngTemplateOutlet="tooltipX"></ng-container>
          </th>
          <th>{{ tooltipTitle }}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let bodyLine of tooltip?.body | slice: 0 : 1; let i = index">
          <td>
            <ng-container *ngTemplateOutlet="tooltipY"></ng-container>
          </td>
          <td>{{ bodyLine?.lines }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <ng-template #historyTooltip>
      <lsz-category-kpi-history-tooltip [tooltip]="tooltip"
                                        [pointStyleOwn]="pointStyleOwn"
                                        [pointStyleLeague]="pointStyleLeague">
      </lsz-category-kpi-history-tooltip>
    </ng-template>
  </div>

</div>
