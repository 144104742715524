import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { SoccerClub } from '../../../models/soccerClub';
import { Category } from '../../models/category';
import { Detail, DetailCalculator } from '../../models/detailCalculations';
import { DetailCalculatorAverage } from '../../models/detailCalculatorAverage';
import { DetailCalculatorMedian } from '../../models/detailCalculatorMedian';
import { Kpi } from '../../models/kpi';

@Component({
  selector: 'lsz-fields-display',
  templateUrl: './fields-display.component.html',
  styleUrls: ['./fields-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldsDisplayComponent implements OnChanges {
  @Input() public baseline: string;
  @Input() public soccerClub: SoccerClub | null;
  @Input() public category: Category;
  @Input() public criterion: string;

  public detail: Detail;
  public selectedKpi: Kpi;

  public ngOnChanges(changes: SimpleChanges): void {
    if ('category' in changes) {
      this.selectedKpi = changes.category.currentValue.kpis[0] as Kpi;
      if (this.selectedKpi) {
        this.calculateDetail();
      }
    }
    if (this.selectedKpi) {
      this.calculateDetail();
    }
  }

  public onKpiClicked(kpi: Kpi): void {
    this.selectedKpi = kpi;
    this.calculateDetail();
  }

  private calculateDetail(): void {
    const detailCalculator: DetailCalculator = this.baseline === 'median' ?
      new DetailCalculatorMedian() : new DetailCalculatorAverage();
    this.detail = detailCalculator.calculate(this.selectedKpi, this.soccerClub);
  }
}
