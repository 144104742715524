import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { SoccerClub, SoccerClubLeague } from '../../models/soccerClub';

import { SeasonService } from '../season/season.service';
import { Season } from '../season/models/season';

const routes = {
  soccerClub: (clubId: number, seasonId: string) => `/SoccerClubs/${clubId}?seasonId=${seasonId}`,
  soccerClubs: (seasonId?: string) => '/SoccerClubs' + (seasonId ? `?seasonId=${seasonId}` : ''),
};

@Injectable({
  providedIn: 'root',
})
export class SoccerClubService {
  public allSoccerClubs: SoccerClub[];

  constructor(
    private http: HttpClient,
    private seasonService: SeasonService,
  ) {}

  public get(): Observable<SoccerClub[]> {
    const operation: string = 'get';
    return this.http.get<SoccerClub[]>(routes.soccerClubs()).pipe(catchError(this.handleError(operation)));
  }

  public getLeaguesBySeason(clubId: string, seasons: Season[]): Observable<SoccerClubLeague[]> {
    const filteredSeasons: Season[] = seasons.filter((season: Season) => season.hasKpi);

    return combineLatest(filteredSeasons.map((season: Season) => this.http.get(routes.soccerClubs(season.id)))).pipe(
      map((clubsBySeason: SoccerClub[][]) =>
        clubsBySeason.map(
          (clubs: SoccerClub[], index: number): SoccerClubLeague => ({
            league: clubs?.find((club: SoccerClub) => club.id === clubId)?.league,
            season: filteredSeasons[index].name,
          }),
        ),
      ),
    );
  }

  public getSoccerClubs(isClosed: boolean = true): Observable<SoccerClub[]> {
    if (this.allSoccerClubs?.length > 0) {
      return of(this.allSoccerClubs);
    }

    return this.seasonService.getLatestSeason(isClosed).pipe(
      switchMap((season: Season) => this.http.get<SoccerClub[]>(routes.soccerClubs(season.id))),
      map((soccerClubs: SoccerClub[]) =>
        soccerClubs.map((soccerClub: SoccerClub) => ({
          ...soccerClub,
          logoImageUrl: soccerClub.logoImageUrl.replace('/svg/', '/svg_v2/'),
        })),
      ),
      catchError(this.handleError('getSoccerClubs')),
    ) as Observable<SoccerClub[]>;
  }

  public getSoccerClubsById(clubId: number): Observable<unknown | SoccerClub> {
    return this.seasonService.getLatestSeason(true).pipe(
      switchMap((season: Season) => this.http.get<SoccerClub>(routes.soccerClub(clubId, season.id))),
      map((soccerClub: SoccerClub) => ({
        ...soccerClub,
        logoImageUrl: soccerClub.logoImageUrl.replace('/svg/', '/svg_v2/'),
      })),
      catchError(this.handleError('getSoccerClubsById')),
    );
  }

  // TODO: workaround for users without permission for the endpoint used in 'getLeaguesBySeason'.
  public getLeaguesBySeasons(clubId: number, seasons: Season[]): Observable<SoccerClubLeague[]> {
    const filteredSeasons: Season[] = seasons.filter((season) => season.hasKpi);
    return combineLatest(filteredSeasons.map((season) => this.http.get(routes.soccerClub(clubId, season.id)))).pipe(
      map((clubBySeason: SoccerClub[]) =>
        clubBySeason.map(
          (club: SoccerClub, index: number): SoccerClubLeague => ({
            league: club.league,
            season: filteredSeasons[index].name,
          }),
        ),
      ),
    );
  }

  /**
   * Returns a function that handles Http operation failures.
   * This error handler lets the app continue to run as if no error occurred.
   *
   * @param operation - name of the operation that failed
   */
  private handleError<T>(operation: string = 'operation'): any {
    return (error: HttpErrorResponse): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      const message: string =
        error.error instanceof ErrorEvent
          ? error.error.message
          : `server returned code ${error.status} with body "${error.error as string}"`;

      // TODO: better job of transforming error for user consumption
      throw new Error(`${operation} failed: ${message}`);
    };
  }
}
