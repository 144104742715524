import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'toDatePicker',
})
export class ToDatepickerPipe implements PipeTransform {

  public transform(value: string): string {
    return moment(value).format('DD.MM.YYYY');
  }

}
