<div class="form-wrapper">
  <lsz-edit-form-header
    [headerText]="'Weitere Kooperationen'"
    [numEntries]="entries.length"
    [editMode]="editMode"
    (clickEdit)="onClickEdit()"
    (clickEditDone)="onClickEditDone()"
  ></lsz-edit-form-header>

  <table [class.editMode]="editMode" [formGroup]="allForms">
    <thead>
    <tr>
      <th i18n="@@tableHeaderName">Name</th>
      <th i18n="@@tableHeaderRemark">Kooperationspartner</th>
      <th i18n="@@tableHeaderFloodlight">Kooperationsinhalt</th>
      <th *ngIf="editMode" i18n="@@tableHeaderOptions" class="column-options">Optionen</th>
      <th>{{'lastEdit' | translate}}</th>
    </tr>
    </thead>
    <tbody
      lsz-common-cooperation-form
      *ngFor="let form of customEntryForms.controls; let i = index"
      (entrySaved)="onUpdate(i)"
      (entryDeleted)="onDelete(i)"
      [editMode]="editMode"
      formArrayName="customEntries"
      [index]="i"
      [form]="form"
    >
    </tbody>
    <tbody *ngIf="addMode" formGroupName="newEntry">
    <tr>
      <td>
        <input type="text" formControlName="name" required placeholder="Neuer Eintrag"
               i18n-placeholder="@@placeholderNewEntry"/>
        <div *ngIf="name.invalid && (name.dirty || name.touched)">
          <div class="input-error" *ngIf="name.errors.required">Name wird benötigt.</div>
          <div class="input-error" *ngIf="name.errors.repeatedName">Name kann sich nicht wiederholen.</div>
        </div>
      </td>
      <td>
        <input type="text" formControlName="cooperationPartner" required/>
        <div *ngIf="cooperationPartner.invalid && (cooperationPartner.dirty || cooperationPartner.touched)">
          <div class="input-error" *ngIf="cooperationPartner.errors.required">Angabe wird benötigt.</div>
        </div>
      </td>
      <td>
        <textarea formControlName="cooperationContent" required></textarea>
        <div *ngIf="cooperationContent.invalid && (cooperationContent.dirty || cooperationContent.touched)">
          <div class="input-error" *ngIf="cooperationContent.errors.required">Angabe wird benötigt.</div>
        </div>
      </td>
      <td>
        <div class="form-options" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
          <button
            type="button"
            [disabled]="!newEntryForm.valid"
            (click)="createNewEntry()"
            class="nav-button"
            [ngClass]="{'notice': newEntryForm.valid}"
          >
            <fa-icon [icon]="faSave"></fa-icon>
          </button>
          <button type="button" (click)="exitAddMode()" class="nav-button">
            <fa-icon [icon]="faTrash"></fa-icon>
          </button>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

  <button *ngIf="editMode && !addMode" type="button" (click)="addNewRow()" i18n="@@dataButtonAdd" class="add-button">
    <fa-icon [icon]="faPlus"></fa-icon>
    Neuen Eintrag hinzufügen
  </button>
</div>
