import { NgModule } from '@angular/core';
import {
  FlexOrderTbDirective,
  FlexTbDirective,
  LayoutAlignTabletDirective,
  LayoutGapTabletDirective,
  LayoutTbDirective,
  ShowHideTbDirective,
} from './tablet.directive';
import { TabletBreakpointProvider } from './tablet.breakpoint';


@NgModule({
  declarations: [
    ShowHideTbDirective,
    LayoutTbDirective,
    LayoutAlignTabletDirective,
    LayoutGapTabletDirective,
    FlexTbDirective,
    FlexOrderTbDirective,
  ],
  providers: [
    TabletBreakpointProvider,
  ],
  exports: [
    ShowHideTbDirective,
    LayoutTbDirective,
    LayoutAlignTabletDirective,
    LayoutGapTabletDirective,
    FlexTbDirective,
    FlexOrderTbDirective,
  ],
})
export class BreakpointsModule {
}
