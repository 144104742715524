<tr [formGroup]="form">
  <td i18n="@@regenerationFacilityType">
    {entry.facilityType, select,
      IceBarrel {Eistonne}
      Sauna {Sauna}
      RelaxationBath {Entmüdungsbecken}
      RelaxationRoom {Ruheraum}
      ColdChamber {Kältekammer}
    }
  </td>
  <td>
    <lsz-binary-display *ngIf="!editMode" [value]="isAvailable.value"></lsz-binary-display>
    <lsz-binary-select *ngIf="editMode" [form]="form" [name]="'isAvailable'"></lsz-binary-select>
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{remark.value}}</ng-container>
    <textarea *ngIf="editMode" formControlName="remark"></textarea>
  </td>
  <td *ngIf="editMode">
    <lsz-dataset-options [form]="form" [withoutDelete]="true" (save)="onClickSave()"></lsz-dataset-options>
  </td>
  <td>
    <lsz-last-edit [form]="form"></lsz-last-edit>
  </td>
</tr>
