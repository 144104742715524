<tr [formGroup]="form">
  <td>Management &amp; Administration</td>
  <td>
    <ng-container *ngIf="!editMode">{{personnelCount.value}}</ng-container>
    <input *ngIf="editMode" type="number" min="0" formControlName="personnelCount" />
  </td>
  <td *ngIf="editMode">
    <lsz-dataset-options [form]="form" [withoutDelete]="true" (save)="onClickSave()"></lsz-dataset-options>
  </td>
  <td>
    <lsz-last-edit [form]="form"></lsz-last-edit>
  </td>
</tr>
