<div class="analysis-container" fxLayout="row">
  <div class="detail-category kpi-select-wrapper" fxFlex="25">
    <lsz-category-kpi-select [kpis]="category.kpis"
                             [baseline]="baseline"
                             (kpiClicked)="onKpiClicked($event)">
    </lsz-category-kpi-select>
  </div>

  <div class="category-wrapper" fxFlex="75" fxShow.tb fxHide>
    <div class="detail-category summary-wrapper" fxFlex="25">
      <lsz-category-summary [detail]="detail" [baseline]="baseline"></lsz-category-summary>
    </div>
    <div class="detail-category distribution-wrapper" fxFlex="75">
      <lsz-category-kpi-details [detail]="detail"
                                [criterion]="criterion"
                                [category]="category">
      </lsz-category-kpi-details>
    </div>
  </div>

  <div class="detail-category summary-wrapper" fxFlex="25" fxHide.tb fxShow>
    <lsz-category-summary [detail]="detail" [baseline]="baseline"></lsz-category-summary>
  </div>
  <div class="detail-category distribution-wrapper" fxFlex="50" fxHide.tb fxShow>
    <lsz-category-kpi-details [detail]="detail" [criterion]="criterion"
                              [category]="category"></lsz-category-kpi-details>
  </div>

</div>
