<div class="form-wrapper">
  <lsz-edit-form-header
    [headerText]="'Unterstützung (Bitte nur Teilzeitmitarbeiter eintragen!)'"
    [numEntries]="amount"
    [editMode]="editMode"
    (clickEdit)="onClickEdit()"
    (clickEditDone)="onClickEditDone()"
  ></lsz-edit-form-header>

  <table [class.editMode]="editMode" [formGroup]="allForms">
    <thead>
    <tr>
      <th i18n="@@tableHeaderFunction">Funktion</th>
      <th i18n="@@tableHeaderCount">Anzahl</th>
      <th *ngIf="editMode" i18n="@@tableHeaderOptions" class="column-options">Optionen</th>
      <th>{{'lastEdit' | translate}}</th>
    </tr>
    </thead>
    <tbody
      *ngIf="pedagogicalSupport"
      lsz-part-time-employee-support-typed-form
      [entry]="pedagogicalSupport"
      (entrySaved)="onTypedEntrySaved(pedagogicalSupport, $event, 'pedagogicalSupport')"
      [editMode]="editMode"
      [form]="allForms.get('pedagogicalSupport')"
    ></tbody>
    <tbody
      *ngIf="scouting"
      lsz-part-time-employee-support-typed-form
      [entry]="scouting"
      (entrySaved)="onTypedEntrySaved(scouting, $event, 'scouting')"
      [editMode]="editMode"
      [form]="allForms.get('scouting')"
    ></tbody>
    <tbody
      *ngIf="medicine"
      lsz-part-time-employee-support-typed-form
      [entry]="medicine"
      (entrySaved)="onTypedEntrySaved(medicine, $event, 'medicine')"
      [editMode]="editMode"
      [form]="allForms.get('medicine')"
    ></tbody>
  </table>
</div>
