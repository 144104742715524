import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { InfrastructureService } from './infrastructure.service';
import { Observable, catchError } from 'rxjs';
import { SoccerClubSeason } from '../models/models';

@Injectable({
  providedIn: 'root',
})
export class SoccerClubSeasonService extends InfrastructureService<SoccerClubSeason> {
  protected readonly endpoint: string = '/SoccerClubSeasons';

  constructor(protected http: HttpClient) {
    super(http);
  }

  public override update(entity: SoccerClubSeason): Observable<SoccerClubSeason> {
    const operation: string = 'update';

    const url: string = `${this.endpoint}`;

    return this.http.put<SoccerClubSeason>(url, entity).pipe(catchError(this.handleError(operation)));
  }

  public override delete(arg: SoccerClubSeason): Observable<SoccerClubSeason> {
    const operation: string = 'delete';
    const url: string = `${this.endpoint}`;
    const params: HttpParams = new HttpParams().set('seasonId', arg.seasonId).set('soccerClubId', arg.soccerClubId);

    return this.http.delete<SoccerClubSeason>(url, { params }).pipe(catchError(this.handleError(operation)));
  }
}
