<table class="table table-striped binary">
  <thead>
  <tr>
    <th scope="col">JA ({{mappedClubs.get(1).length}})</th>
    <th scope="col">NEIN ({{mappedClubs.get(0).length}})</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td *ngFor="let key of mappedClubKeys">
      <div class="cell club-container" *ngFor="let soccerClub of mappedClubs.get(key)" fxLayout="row"
           fxLayoutAlign="start center">
        <div class="club-div" fxFlex="65px" fxLayoutGap="5px" fxLayoutAlign="start cener">
          <img [src]="soccerClub.logoImageUrl">
          <span class="club-name">{{soccerClub.threeLetterCode}}</span>
        </div>
        <span class="league">{{soccerClub.league | translate}}</span>
      </div>
    </td>
  </tr>

  </tbody>
</table>