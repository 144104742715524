import { Role } from './services/auth/models/role.enum';


const unique = (roles: Role[]): Role[] => Array.from(new Set(roles));

export const CAN_ENTER_ADMIN_PAGE: Role[] = [
  Role.DFL_ADMIN,
];

export const CAN_SEE_DATA: Role[] = [
  Role.DFL_ADMIN,
  Role.DFL_DATA_ANALYST,
  Role.DFL_USER,
  Role.DFB_USER,
  Role.DFB_DATA_ANALYST,
  Role.CLUB_USER,
];

export const CAN_EDIT_DATA: Role[] = [
  Role.DFL_ADMIN,
  Role.DFL_DATA_ANALYST,
  Role.DFB_DATA_ANALYST,
  Role.CLUB_EDITOR,
];

export const CAN_SWITCH_CLUB: Role[] = [
  Role.DFL_ADMIN,
  Role.DFL_DATA_ANALYST,
  Role.DFB_DATA_ANALYST,
];

export const CAN_SELECT_LEAGUE: Role[] = [
  Role.DFL_ADMIN,
  Role.DFL_DATA_ANALYST,
  Role.DFB_DATA_ANALYST,
  Role.DFL_USER,
  Role.DFB_USER,
];

export const CAN_SELECT_BUDGET_GROUP: Role[] = [
  ...CAN_SELECT_LEAGUE,
];

export const CAN_SELECT_ALL: Role[] = [
  ...CAN_SELECT_LEAGUE,
];

export const CAN_SWITCH_SEASONS_IN_EDIT: Role[] = [
  ...CAN_ENTER_ADMIN_PAGE,
];

export const CAN_USE_ADMIN_DROPDOWN: Role[] = unique(
  [
    ...CAN_SWITCH_CLUB,
    ...CAN_SELECT_LEAGUE,
    ...CAN_SELECT_BUDGET_GROUP,
    ...CAN_SELECT_ALL,
  ],
);
