import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

import { SoccerClub } from '../../models/soccerClub';
import { SetClub } from '../../store/actions/club.actions';
import { AppState } from '../../store/states/app.state';
import { SoccerClubService } from '../soccer-club/soccer-club.service';

import { User } from './model/user';
import { StsAuthService } from 'sts-auth';
import { AuthResult } from 'sts-auth/lib/models/authResult';
import { Role } from '../../core/services/auth/models/role.enum';
import { UserMetaData } from './model/userMetaData';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  public userInformation$: BehaviorSubject<User>;
  private readonly METADATA_KEY: string = 'https://www.deltatre.com/lsz';

  private readonly ROLES_KEY: string = 'https://www.deltatre.com/roles';

  constructor(private store: Store<AppState>,
              private authService: StsAuthService,
              private router: Router,
              private soccerClubService: SoccerClubService) {

    const user: User = this.getUser();
    const accessToken: string = this.authService.accessToken;

    if (user && accessToken) {
      this.userInformation$ = new BehaviorSubject(user);
      const authResult: AuthResult = {accessToken} as AuthResult;
      this.authService.getAuthResult$.next(authResult);
      this.authService.isAuthenticated$.next(accessToken);
      this.getSoccerClub(user);
    } else {
      this.userInformation$ = new BehaviorSubject<User>(null);
      this.createUser();
    }
  }

  private createUser(): void {
    this.authService.getAuthResult$.subscribe((authResult: AuthResult) => {
      if (authResult) {
        const payload = authResult.idTokenPayload;
        const user: User = {
          username: payload.name,
          roles: payload[this.ROLES_KEY] as Role[],
          metaData: payload[this.METADATA_KEY] as UserMetaData,
        };

        localStorage.setItem('User', JSON.stringify(user));

        this.router.navigateByUrl('/show-data');
        this.userInformation$.next(user);

        this.getSoccerClub(user);
      }
    });
  }

  private getSoccerClub(user: User): void {
    if (user.metaData?.clubId) {
      this.soccerClubService.getSoccerClubsById(user.metaData.clubId)
        .subscribe((club: SoccerClub) => {
          this.store.dispatch(new SetClub(club));
        });
    }
  }

  private getUser(): User {
    return JSON.parse(localStorage.getItem('User')) as User;
  }
}
