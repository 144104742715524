<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col">WERT</th>
    <th scope="col">CLUBS / LIGAZUGEHÖRIGKEIT</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let key of mappedClubKeys">
    <td><span class="key cell">{{key | convertToPercentage:kpiName:2}}</span></td>
    <td>
      <div fxLayout="row wrap" fxLayoutGap="15px">
        <div class="cell club-container"
             *ngFor="let soccerClub of mappedClubs.get(key)"
             fxLayout="row"
             fxFlex="150px"
             fxLayoutAlign="start center">
          <div class="club-div" fxFlex="65px" fxLayoutGap="5px" fxLayoutAlign="start center">
            <img [src]="soccerClub.logoImageUrl">
            <span class="club-name">{{soccerClub.threeLetterCode}}</span>
          </div>
          <span class="league">{{soccerClub.league | translate}}</span>
        </div>
      </div>

    </td>
  </tr>
  </tbody>
</table>
