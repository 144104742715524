import { Directive } from '@angular/core';
import {
  FlexDirective,
  FlexOrderDirective,
  LayoutAlignDirective,
  LayoutDirective,
  LayoutGapDirective,
  ShowHideDirective,
} from '@angular/flex-layout';


@Directive({
  selector: '[fxHide.tb], [fxShow.tb]',
  // tslint:disable-next-line:no-inputs-metadata-property
  inputs: ['fxHide.tb', 'fxShow.tb'],
})
export class ShowHideTbDirective extends ShowHideDirective {
  protected inputs = ['fxHide.tb', 'fxShow.tb'];
}

@Directive({
  selector: '[fxLayout.tb]',
  // tslint:disable-next-line:no-inputs-metadata-property
  inputs: ['fxLayout.tb'],
})
export class LayoutTbDirective extends LayoutDirective {
  protected inputs = ['fxLayout.tb'];
}

@Directive({
  selector: '[fxLayoutAlign.tb]',
  // tslint:disable-next-line:no-inputs-metadata-property
  inputs: ['fxLayoutAlign.tb'],
})
export class LayoutAlignTabletDirective extends LayoutAlignDirective {
  protected inputs = ['fxLayoutAlign.tb'];
}

@Directive({
  selector: '[fxLayoutGap.tb]',
  // tslint:disable-next-line:no-inputs-metadata-property
  inputs: ['fxLayoutGap.tb'],
})
export class LayoutGapTabletDirective extends LayoutGapDirective {
  protected inputs = ['fxLayoutGap.tb'];
}

@Directive({
  selector: '[fxFlex.tb]',
  // tslint:disable-next-line:no-inputs-metadata-property
  inputs: ['fxFlex.tb'],
})
export class FlexTbDirective extends FlexDirective {
  protected inputs = ['fxFlex.tb'];
}

@Directive({
  selector: '[fxFlexOrder.tb]',
  // tslint:disable-next-line:no-inputs-metadata-property
  inputs: ['fxFlexOrder.tb'],
})
export class FlexOrderTbDirective extends FlexOrderDirective {
  protected inputs = ['fxFlexOrder.tb'];
}
