import { Component } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ConfirmationDialogService } from '../../../shared/confirmation-dialog/services/confirmation-dialog.service';
import { CommonCooperation } from '../../models/models';
import { CommonCooperationService } from '../../services/common-cooperation.service';
import { Validators } from '../../validators';
import { BaseTableViewComponent } from '../../../shared/base-table-view/base-table-view.component';

@Component({
  selector: 'lsz-common-cooperation-form-collection',
  templateUrl: './common-cooperation-form-collection.component.html',
  styleUrls: ['./common-cooperation-form-collection.component.css'],
})
export class CommonCooperationFormCollectionComponent extends BaseTableViewComponent<CommonCooperation> {

  public get cooperationContent(): AbstractControl {
    return this.newEntryForm.get('cooperationContent');
  }

  public get cooperationPartner(): AbstractControl {
    return this.newEntryForm.get('cooperationPartner');
  }

  constructor(protected service: CommonCooperationService,
              protected confirmationDialog: ConfirmationDialogService) {
    super(service, confirmationDialog);
  }

  public createNewEntry(): void {
    const entry: CommonCooperation = { ...this.newEntryForm.value as CommonCooperation };
    this.addEntry(entry);
  }

  protected formGroup(entry?: CommonCooperation): UntypedFormGroup {
    return new UntypedFormGroup({
      name: new UntypedFormControl(entry?.name, [Validators.required, this.repeatedNameValidator()]),
      cooperationPartner: new UntypedFormControl(entry?.cooperationPartner, Validators.required),
      cooperationContent: new UntypedFormControl(entry?.cooperationContent, Validators.required),
      updatedAtUtc: new UntypedFormControl(entry?.updatedAtUtc),
      createdAtUtc: new UntypedFormControl(entry?.createdAtUtc),
    });
  }

  protected objectToForm(entry?: CommonCooperation): any {
    return {
      name: entry.name,
      cooperationPartner: entry.cooperationPartner,
      cooperationContent: entry.cooperationContent,
      updatedAtUtc: entry?.updatedAtUtc,
      createdAtUtc: entry?.createdAtUtc,
    };
  }
}
