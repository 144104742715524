<div class="form-wrapper">
  <lsz-edit-form-header
    [headerText]="'Sonderausstattungen'"
    [numEntries]="amount"
    [editMode]="editMode"
    (clickEdit)="onClickEdit()"
    (clickEditDone)="onClickEditDone()"
  ></lsz-edit-form-header>

  <table [class.editMode]="editMode" [formGroup]="allForms">
    <thead>
    <tr>
      <th i18n="@@tableHeaderName">Name</th>
      <th i18n="@@tableHeaderIsAvailable">Vorhanden</th>
      <th i18n="@@tableHeaderRemark">Bemerkung</th>
      <th *ngIf="editMode" i18n="@@tableHeaderOptions" class="column-options">Optionen</th>
      <th>{{'lastEdit' | translate}}</th>
    </tr>
    </thead>
    <tbody *ngIf="helix"
           lsz-special-equipment-typed-form
           [entry]="helix"
           (entrySaved)="onTypedEntrySaved(helix, $event, 'helix')"
           [editMode]="editMode"
           [form]="allForms.get('helix')">
    </tbody>
    <tbody *ngIf="footbonaut"
           lsz-special-equipment-typed-form
           [entry]="footbonaut"
           (entrySaved)="onTypedEntrySaved(footbonaut, $event, 'footbonaut')"
           [editMode]="editMode"
           [form]="allForms.get('footbonaut')">
    </tbody>
    <tbody *ngIf="sprintHill"
           lsz-special-equipment-typed-form
           [entry]="sprintHill"
           (entrySaved)="onTypedEntrySaved(sprintHill, $event, 'sprintHill')"
           [editMode]="editMode"
           [form]="allForms.get('sprintHill')">
    </tbody>
    <tbody *ngIf="headerPendulum"
           lsz-special-equipment-typed-form
           [entry]="headerPendulum"
           (entrySaved)="onTypedEntrySaved(headerPendulum, $event, 'headerPendulum')"
           [editMode]="editMode"
           [form]="allForms.get('headerPendulum')">
    </tbody>
    <tbody *ngIf="footballTennis"
           lsz-special-equipment-typed-form
           [entry]="footballTennis"
           (entrySaved)="onTypedEntrySaved(footballTennis, $event, 'footballTennis')"
           [editMode]="editMode"
           [form]="allForms.get('footballTennis')">
    </tbody>
    <tbody *ngIf="otherSports"
           lsz-special-equipment-typed-form
           [entry]="otherSports"
           (entrySaved)="onTypedEntrySaved(otherSports, $event, 'otherSports')"
           [editMode]="editMode"
           [form]="allForms.get('otherSports')">
    </tbody>
    <tbody *ngIf="sprintTrack"
           lsz-special-equipment-typed-form
           [entry]="sprintTrack"
           (entrySaved)="onTypedEntrySaved(sprintTrack, $event, 'sprintTrack')"
           [editMode]="editMode"
           [form]="allForms.get('sprintTrack')">
    </tbody>
    <tbody *ngIf="virtualReality"
           lsz-special-equipment-typed-form
           [entry]="virtualReality"
           (entrySaved)="onTypedEntrySaved(virtualReality, $event, 'virtualReality')"
           [editMode]="editMode"
           [form]="allForms.get('virtualReality')">
    </tbody>
    <tbody *ngIf="speedCourt"
           lsz-special-equipment-typed-form
           [entry]="speedCourt"
           (entrySaved)="onTypedEntrySaved(speedCourt, $event, 'speedCourt')"
           [editMode]="editMode"
           [form]="allForms.get('speedCourt')">
    </tbody>
    <tbody *ngIf="altitudeChamber"
           lsz-special-equipment-typed-form
           [entry]="altitudeChamber"
           (entrySaved)="onTypedEntrySaved(altitudeChamber, $event, 'altitudeChamber')"
           [editMode]="editMode"
           [form]="allForms.get('altitudeChamber')">
    </tbody>
    <tbody lsz-special-equipment-form
           *ngFor="let form of customEntryForms.controls; let i = index"
           (entrySaved)="onUpdate(i)"
           (entryDeleted)="onDelete(i)"
           [editMode]="editMode"
           formArrayName="customEntries"
           [index]="i"
           [form]="form">
    </tbody>
    <tbody *ngIf="addMode" formGroupName="newEntry">
    <tr>
      <td>
        <input type="text" placeholder="Neuer Eintrag" i18n-placeholder="@@placeholderNewEntry" formControlName="name"
               required />
        <div *ngIf="name.invalid && (name.dirty || name.touched)">
          <div class="input-error" *ngIf="name.errors.required">Name wird benötigt.</div>
          <div class="input-error" *ngIf="name.errors.repeatedName">Name kann sich nicht wiederholen.</div>
        </div>
      </td>
      <td>&nbsp;</td>
      <td><textarea formControlName="remark"></textarea></td>
      <td>
        <div class="form-options" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
          <button
            type="button"
            [disabled]="!newEntryForm.valid"
            (click)="createNewEntry()"
            class="nav-button"
            [ngClass]="{'notice': newEntryForm.valid}"
          >
            <fa-icon [icon]="faSave"></fa-icon>
          </button>
          <button type="button" (click)="exitAddMode()" class="nav-button">
            <fa-icon [icon]="faTrash"></fa-icon>
          </button>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

  <button *ngIf="editMode && !addMode" type="button" (click)="addNewRow()" i18n="@@dataButtonAdd" class="add-button">
    <fa-icon [icon]="faPlus"></fa-icon>
    Neuen Eintrag hinzufügen
  </button>
</div>
