import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lsz-legal-notice',
  templateUrl: './legal-notice.component.html',
  styleUrls: ['./legal-notice.component.css'],
})
export class LegalNoticeComponent implements OnInit {

  public ngOnInit(): void {
    setTimeout(() => {
      window.scrollTo({top: 1, behavior: 'smooth'});
    }, 1);
  }
}
