import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SoccerClub } from '../../../../../../../models/soccerClub';

@Component({
  selector: 'lsz-binary-table',
  templateUrl: './binary-table.component.html',
  styleUrls: ['./binary-table.component.scss'],
})
export class BinaryTableComponent implements OnChanges {
  @Input() public mappedClubKeys: number[];
  @Input() public mappedClubs: Map<number, SoccerClub[]>;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.mappedClubs) {
      this.mappedClubKeys = this.mappedClubKeys.reverse();
    }

  }
}
