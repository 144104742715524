import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DataImportService } from './services/data-import/data-import.service';
import { switchMap, take } from 'rxjs/operators';
import { DataImporterEnum, DataImportJob, ImportError } from './models/data-import-job';
import { timer } from 'rxjs';
import { SeasonService } from '../../../services/season/season.service';
import { JobState } from '../../model/job';
import { Season } from '../../../services/season/models/season';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ErrorDialogComponent } from '../../../data-editor/modules/data-editor-shared/components/error-dialog/error-dialog.component';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { HttpErrorResponse } from '@angular/common/http';

const JOBS_POLLING_INTERVAL_MS: number = 5000;

@Component({
  selector: 'lsz-data-import',
  templateUrl: './data-import.component.html',
  styleUrls: ['./data-import.component.scss'],
})
export class DataImportComponent implements OnInit {
  @ViewChild('fileUpload') public fileUploadRef: ElementRef;

  @Input() public importer: DataImporterEnum;

  public faInfoCircle: typeof faInfoCircle;
  public validExtensions: string[];
  public validFile: boolean;
  public file: File;
  public dataImport: DataImportJob;
  public error: ImportError;
  public currentSeason: Season;
  public jobState: typeof JobState;
  public importerEnum: typeof DataImporterEnum;
  public title: string;
  public importError: string;

  constructor(private dataImportService: DataImportService,
              private seasonService: SeasonService,
              private modalService: NgbModal) {
    this.validExtensions = ['.csv'];
    this.jobState = JobState;
    this.importerEnum = DataImporterEnum;
    this.faInfoCircle = faInfoCircle;
  }

  public async ngOnInit(): Promise<void> {
    this.title = 'Stammdatenimport';
    if (this.importer === DataImporterEnum.COLLEGES) {
      this.title = 'Untergebrachte Spieler';
    } else if (this.importer === DataImporterEnum.EFFECTIVITY) {
      this.title = 'Effektivitätsdaten';
    }

    this.currentSeason = await this.seasonService.getLatestSeason().pipe(take(1)).toPromise();
    await this.getImportJobs();
  }

  public onFileSelected(event: Event): void {
    this.file = (event.target as any).files[0];
    if (this.file) {
      this.validFile = this.isValidExtension(this.file);
    }
  }

  public async upload(): Promise<void> {
    if (this.file) {
      try {
        await this.dataImportService.upload(this.file, this.importer);
      } catch (error: unknown) {
        this.error = ((error as HttpErrorResponse).error as ImportError);
        this.file = undefined;
        this.fileUploadRef.nativeElement.value = '';
      }
      try {
        await this.getImportJobs();
      } catch (error: unknown) {
        this.error = error as ImportError;
      }
    }
  }

  private async getImportJobs(): Promise<void> {
    const jobs: DataImportJob[] = await this.dataImportService.getAllImportJobs();
    if (jobs?.length > 0) {
      const seasonJobs: DataImportJob[] = jobs.filter((job: DataImportJob) => job.importer === this.importer);
      if (seasonJobs?.length > 0) {
        const latestJob: DataImportJob = seasonJobs[seasonJobs.length - 1];
        const source = timer(0, JOBS_POLLING_INTERVAL_MS).pipe(
          switchMap(() => {
            return this.dataImportService.getImportStatus(latestJob.id);
          }),
        ).subscribe(async (dataImport: DataImportJob) => {
          this.dataImport = dataImport;
          if (this.jobSucceededOrFailed()) {
            source.unsubscribe();
            if (this.dataImport.state === JobState.FAILED) {
              this.dataImportService.getReasonForFailedJob(this.dataImport.id).pipe(
                take(1),
              ).subscribe((error: string) => {
                this.importError = error;
              });
            }
          }
        });
      }
    }
  }

  public jobSucceededOrFailed(): boolean {
    return [JobState.SUCCEEDED, JobState.FAILED].includes(this.dataImport.state);
  }

  public showErrorDetail(): void {
    const modalRef: NgbModalRef = this.modalService.open(ErrorDialogComponent);
    modalRef.componentInstance.infoText = this.importError;
  }

  private isValidExtension(file: File): boolean {
    const extension: string = file.name.substring(file.name.lastIndexOf('.'));
    return this.validExtensions.indexOf(extension) >= 0;
  }
}
