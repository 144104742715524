import { Kpi } from './kpi';

export enum CategoryRating {
  Positive,
  Neutral,
  Negative,
  None,
}

export interface CategoryRatings {
  average: CategoryRating;
  median: CategoryRating;
}

export class CategoryRater {
  /**
   * Rates a category on if there are more positively rated KPIs than negatively rated ones.
   *
   * @param kpis The KPIs of the category to rate.
   */
  public rate(kpis: Kpi[]): CategoryRatings {
    let medianRating: number | null = null;
    let averageRating: number | null = null;

    // might use Array.prototype.reduce() here, but I'm going with an arguably simpler loop-based solution for now.
    for (const kpi of kpis) {
      if (!kpi.rating?.isVisible) {
        continue;
      }

      if (medianRating === null) {
        medianRating = 0;
      }

      if (kpi.rating.median === 1) {
        medianRating += 1;
      } else if (kpi.rating.median === -1) {
        medianRating -= 1;
      }

      if (averageRating === null) {
        averageRating = 0;
      }

      if (kpi.rating.average === 1) {
        averageRating += 1;
      } else if (kpi.rating.average === -1) {
        averageRating -= 1;
      }
    }

    if (medianRating === null || averageRating === null) {
      return {median: CategoryRating.None, average: CategoryRating.None};
    }

    return {
      median: medianRating > 0 ?
        CategoryRating.Positive :
        (medianRating === 0 ? CategoryRating.Neutral : CategoryRating.Negative),

      average: averageRating > 0 ?
        CategoryRating.Positive :
        (averageRating === 0 ? CategoryRating.Neutral : CategoryRating.Negative),
    };
  }
}
