export const environment = {
  production: false,
  serverUrl: 'https://apidev.lz-vergleichsdatenbank.de/api/v1',
  auth0Config: {
    clientID: 'mYQ15bZwcNrrHzf5NS3Fd40VYBK5rjVv',
    domain: 'login.dev.sportec-solutions.de',
    responseType: 'token id_token',
    audience: 'https://apidev.lz-vergleichsdatenbank.de',
    redirectUri: 'https://dev.lz-vergleichsdatenbank.de/callback',
    returnTo: 'https://dev.lz-vergleichsdatenbank.de',
    scope: 'openid profile email',
  },
};
