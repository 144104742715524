import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Season, SeasonPeriod } from './models/season';
import { LockPeriodDto } from '../../admin/model/lockPeriodDto';

const BASE_URL: string = '/Seasons';

const routes = {
  seasons: () => `${BASE_URL}`,
  editSeason: (seasonId: string) => `${BASE_URL}/${seasonId}`,
};

@Injectable({
  providedIn: 'root',
})
export class SeasonService {

  constructor(private http: HttpClient) {
  }

  public getLatestSeason(isClosed: boolean = false): Observable<Season> {
    return this.getSeasons().pipe(
      map((seasons: Season[]) => {
        const lastIndex: number = seasons.map((season: Season) => season.isClosed).lastIndexOf(isClosed);
        return seasons[lastIndex];
      }),
    );
  }

  public getSeasons(): Observable<Season[]> {
    return this.http.get<Season[]>(routes.seasons())
        .pipe(
            map((seasons: Season[]) => {
              return seasons.sort((a: Season, b: Season) => a.startYear - b.startYear);
            }),
            catchError(this.handleError('getSeasons'))
        );
  }

  public updateLockPeriod(lockPeriod: LockPeriodDto): Observable<SeasonPeriod> {
    const operation: string = 'updateLockPeriod';

    return this.http.put(routes.editSeason(lockPeriod.seasonId), lockPeriod)
      .pipe(
          catchError(this.handleError(operation)),
      );
  }

  /**
   * Returns a function that handles Http operation failures.
   * This error handler lets the app continue to run as if no error occurred.
   *
   * @param operation - name of the operation that failed
   */
  private handleError<T>(operation: string = 'operation'): any {
    return (error: HttpErrorResponse): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      const message: string = (error.error instanceof ErrorEvent) ?
        error.error.message : `server returned code ${error.status} with body "${error.error as string}"`;

      // TODO: better job of transforming error for user consumption
      throw new Error(`${operation} failed: ${message}`);
    };
  }
}
