import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { StsAuthService } from 'sts-auth';

const TIMEOUT_MS: number = 25;

@Component({
  selector: 'lsz-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.css'],
})
export class AuthCallbackComponent implements OnInit {

  constructor(private spinnerService: NgxSpinnerService,
              private authService: StsAuthService) {
  }

  public ngOnInit(): void {
    this.authService.handleAuthentication();

    setTimeout(() => {
      this.spinnerService.show();
    }, TIMEOUT_MS);
  }
}
