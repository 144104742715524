import { Pipe, PipeTransform } from '@angular/core';

import { DetailIsPercentage } from '../helpers/detailIsPercentage';

@Pipe({
  name: 'isDetailPercentage',
})
export class IsDetailPercentagePipe implements PipeTransform {

  public transform(detailName: string): boolean {
    return DetailIsPercentage.isPercentage(detailName);
  }

}
