import { Component } from '@angular/core';
import { BaseEditFormComponent } from '../../../shared/base-edit-form/base-edit-form.component';

@Component({
  selector: 'tbody[lsz-regeneration-facility-form]',
  templateUrl: './regeneration-facility-form.component.html',
  styleUrls: ['./regeneration-facility-form.component.css'],
})
export class RegenerationFacilityFormComponent extends BaseEditFormComponent {

  constructor() {
    super();
  }
}
