import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { Component } from '@angular/core';
import browserUpdate from 'browser-update';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lsz-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
/* eslint-disable */
export class AppComponent {

  constructor(private translateService: TranslateService) {
    this.translateService.setDefaultLang('de');
    this.translateService.use('de');
    registerLocaleData(localeDe, 'de-DE');
    browserUpdate({
      required: { e: -4, f: -3, o: -3, s: -1, c: -3 },
      test: false,
      no_permanent_hide: true,
      nostatistics: true,
    });
  }

}
