import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { faChevronCircleDown, faChevronCircleUp, faPen, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RoleGuard } from '../../core/guards/role.guard';
import { CAN_EDIT_DATA, CAN_ENTER_ADMIN_PAGE, CAN_SWITCH_CLUB, CAN_USE_ADMIN_DROPDOWN } from '../../core/permissionGroups';
import { SoccerClub } from '../../models/soccerClub';
import { User } from '../../services/user/model/user';
import { UserService } from '../../services/user/user.service';

import { HeaderNavigationItem } from './model/headerNavigationItem';
import { AdminFilterSetting } from './partials/admin-dropdown/model/adminFilterSetting';
import { AdminFilter, DEFAULT_ADMIN_FILTER_SETTING } from './partials/admin-dropdown/model/constants';
import { Filter } from './partials/filter-switch/model/constants';
import { TemporalReference } from './partials/temporal-reference-switch/model/constants';
import { StsAuthService } from 'sts-auth';
import { Season } from '../../services/season/models/season';

@Component({
  selector: 'lsz-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() public adminFilterSwitch: EventEmitter<AdminFilterSetting>;
  @Output() public budgetSelect: EventEmitter<string>;
  @Output() public filterSwitch: EventEmitter<Filter>;
  @Output() public leagueSelect: EventEmitter<string>;
  @Output() public temporalSwitch: EventEmitter<string>;
  @Input() public season: Season;
  @Input() public showBudgetSelect: boolean;
  @Input() public showLeagueSelect: boolean;
  @Input() public soccerClub: SoccerClub;

  public ADMIN_TYPE_CLUB: string = AdminFilter.CLUB;
  public adminFilter: AdminFilterSetting;
  public canEditData$: Observable<boolean>;
  public canSwitchClub$: Observable<boolean>;
  public canUseAdminDropdown$: Observable<boolean>;
  public faChevronCircleDown: unknown;
  public faChevronCircleUp: unknown;
  public faPen: unknown;
  public faSignOutAlt: unknown;
  public filter: Filter;
  public isAdmin$: Observable<boolean>;
  public navigationItems: HeaderNavigationItem[];
  public showAdminDropdown: boolean;
  public showMode: boolean;
  public temporalReference: string;
  public temporalReferenceEnum = TemporalReference;
  public user$: BehaviorSubject<User>;
  private budget: string;

  private league: string;

  constructor(private authService: StsAuthService,
              private userService: UserService,
              private activatedRoute: ActivatedRoute) {
    this.temporalReference = TemporalReference.CURRENT;
    this.adminFilterSwitch = new EventEmitter<AdminFilterSetting>();
    this.budgetSelect = new EventEmitter<string>();
    this.filterSwitch = new EventEmitter<Filter>();
    this.leagueSelect = new EventEmitter<string>();
    this.temporalSwitch = new EventEmitter<string>();

    this.faChevronCircleDown = faChevronCircleDown;
    this.faChevronCircleUp = faChevronCircleUp;
    this.faPen = faPen;
    this.faSignOutAlt = faSignOutAlt;
    this.filter = Filter.ALL;
    this.adminFilter = DEFAULT_ADMIN_FILTER_SETTING;

    this.navigationItems = [
      {
        title: 'Infrastruktur',
        route: '/show-data#infrastructure',
      },
      {
        title: 'Personal',
        route: '/show-data#personnel',
      },
      {
        title: 'Effektivität',
        route: '/show-data#effectivity',
      },
    ];
  }

  public logout(): void {
    this.authService.logout();
  }

  public ngOnInit(): void {
    this.user$ = this.userService.userInformation$;
    this.canEditData$ = this.userService.userInformation$.pipe(
      map((user: User) =>
        RoleGuard.isAllowed(CAN_EDIT_DATA, user?.roles)),
    );
    this.isAdmin$ = this.userService.userInformation$.pipe(
      map((user: User) =>
        RoleGuard.isAllowed(CAN_ENTER_ADMIN_PAGE, user.roles)),
    );
    this.canSwitchClub$ = this.userService.userInformation$.pipe(
      map((user: User) =>
        RoleGuard.isAllowed(CAN_SWITCH_CLUB, user.roles)),
    );
    this.canUseAdminDropdown$ = this.userService.userInformation$.pipe(
      map((user: User) =>
        RoleGuard.isAllowed(CAN_USE_ADMIN_DROPDOWN, user.roles)),
    );
    this.activatedRoute.url.subscribe((segments: UrlSegment[]) => {
      const path: string = segments[0].path;
      this.showMode = path === 'show-data';
    });
  }

  public onAdminFilterSwitch(filter: AdminFilterSetting): void {
    this.adminFilter = filter;
    this.adminFilterSwitch.emit(this.adminFilter);
  }

  public onBudgetSelect(budget: string): void {
    this.budget = budget;
    this.budgetSelect.emit(this.budget);
  }

  public onFilterSwitch(filter: Filter): void {
    this.filter = filter;
    this.filterSwitch.emit(this.filter);
  }

  public onLeagueSelect(league: string): void {
    this.league = league;
    this.leagueSelect.emit(this.league);
  }

  public onTemporalSwitch(temporalReference: TemporalReference): void {
    this.temporalReference = temporalReference;
    if (this.temporalReference === TemporalReference.HISTORY) {
      this.onFilterSwitch(Filter.ALL);
    }
    this.temporalSwitch.emit(this.temporalReference);
  }

  public toggleAdminDropdown(show: boolean): void {
    this.showAdminDropdown = show;
  }

}
