import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Kpis, SeasonKpi } from '../models/kpis';

export interface KpiFilter {
  name: string;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class KpiService {
  public readonly kpisUrl: string = '/Kpi';
  public seasonKpi: SeasonKpi[];

  constructor(private http: HttpClient) {
  }

  /**
   * GET lawn fields from the server
   *
   * if no season name is given, an array of kpis is returned
   *
   * @param seasonName - name of the selected season or undefined
   * @param filter - KpiFilter e.g. budget or league
   */
  public getKpis(seasonName: string, filter?: KpiFilter): Observable<Kpis> {
    let params: HttpParams = new HttpParams();
    if (seasonName) {
      params = params.set('season', seasonName);
    }
    if (filter) {
      params = params
        .set('filteredBy', filter.name)
        .set('filter', filter.value);
    }

    return this.http.get<Kpis>(this.kpisUrl, {
      params,
    })
      .pipe(
        tap((result: Kpis) => this.seasonKpi = Array.isArray(result) ? result[0].seasonKpiCollection : result.seasonKpiCollection),
        catchError(this.handleError('getLawnFields')),
      ) as Observable<Kpis>;
  }

  /**
   * Returns a function that handles Http operation failures.
   * This error handler lets the app continue to run as if no error occurred.
   *
   * @param operation - name of the operation that failed
   */
  private handleError<T>(operation: string = 'operation'): (error: HttpErrorResponse) => Observable<T> {
    return (error: HttpErrorResponse): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      const message: string = (error.error instanceof ErrorEvent) ?
        error.error.message :
        `server returned code ${error.status} with body "${error.error as string}"`;

      // TODO: better job of transforming error for user consumption
      throw new Error(`${operation} failed: ${message}`);
    };
  }
}
