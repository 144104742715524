<ng-template #rt let-club="result" let-term="term">
  <div>
    <img *ngIf="club" [src]="club.logoImageUrl" [alt]="club.name" class="mr-1" style="width: 16px" />
    <ngb-highlight [result]="club.name" [term]="term"></ngb-highlight>
  </div>
</ng-template>

<input type="text"
       class="form-control"
       placeholder="Club"
       [disabled]="disabled"
       [(ngModel)]="selectedClub"
       [ngbTypeahead]="search"
       [resultTemplate]="rt"
       [inputFormatter]="formatter" />
