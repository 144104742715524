import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BaseEditFormComponent } from '../../../shared/base-edit-form/base-edit-form.component';

@Component({
  selector: 'tbody[lsz-open-space-field-form]',
  templateUrl: './open-space-field-form.component.html',
  styleUrls: ['./open-space-field-form.component.css'],
})
export class OpenSpaceFieldFormComponent extends BaseEditFormComponent {

  public get surface(): AbstractControl {
    return this.form.get('surface');
  }

  public get length(): AbstractControl {
    return this.form.get('length');
  }

  public get width(): AbstractControl {
    return this.form.get('width');
  }

  public get isHeated(): AbstractControl {
    return this.form.get('isHeated');
  }

  constructor() {
    super();
  }
}
