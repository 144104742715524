import { Component } from '@angular/core';
import { SupportPartTimeEmployee } from '../../models/models';
import { AbstractControl } from '@angular/forms';
import { BaseEditFormComponent } from '../../../shared/base-edit-form/base-edit-form.component';

@Component({
  selector: 'tbody[lsz-part-time-employee-support-typed-form]',
  templateUrl: './part-time-employee-support-typed-form.component.html',
  styleUrls: ['./part-time-employee-support-typed-form.component.css'],
})
export class PartTimeEmployeeSupportTypedFormComponent extends BaseEditFormComponent<SupportPartTimeEmployee> {

  public get personnelCount(): AbstractControl {
    return this.form.get('personnelCount');
  }

  constructor() {
    super();
  }

  public onClickSave(): void {
    this.entrySaved.emit({
      ...this.entry,
      personnelCount: this.personnelCount.value as number,
    } as SupportPartTimeEmployee);
  }
}
