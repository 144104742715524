import { AbstractControl, ValidatorFn } from '@angular/forms';
import { SortConfig, SortDirection } from '../models/utility';
import { HttpParams } from '@angular/common/http';

export const repeatedNameValidatorFn = (array: any[]): ValidatorFn => (control: AbstractControl): { [key: string]: any } | null => {
  if (typeof control.value === 'string'
    && array.filter(item => item.name === (control.value as string).trim()).length > 0
  ) {
    return { repeatedName: { value: control.value } };
  }
  return null;
};

export const toInteger = (value: string | number): number => parseInt(`${value}`, 10);

export const toString = (value: number): string => (value !== undefined && value !== null) ? `${value}` : '';

export const getValueInRange = (value: number, max: number, min: number = 0): number => Math.max(Math.min(value, max), min);

export const isString = (value: unknown): value is string => typeof value === 'string';

export const isNumber = (value: number | string): boolean => !isNaN(toInteger(value));

export const isInteger = (value: unknown): value is number => typeof value === 'number' && isFinite(value) && Math.floor(value) === value;

export const isDefined = (value: unknown): boolean => value !== undefined && value !== null;

export const padNumber = (value: number): string => {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
};

export const regExpEscape = (text: string): string => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

export const hasClassName = (element: HTMLElement, className: string): boolean =>
  element && element.className && element.className.split &&
  element.className.split(/\s+/).indexOf(className) >= 0;


if (typeof Element !== 'undefined' && !Element.prototype.closest) {
  // polyfill for ie10+

  if (!Element.prototype.matches) {
    // iE uses the non-standard name: msMatchesSelector
    Element.prototype.matches = (Element.prototype as any).msMatchesSelector || Element.prototype.webkitMatchesSelector;
  }

  Element.prototype.closest = function (s: string) {
    let el: any = this;
    if (!document.documentElement.contains(el)) {
      return null;
    }
    do {
      if (el.matches(s)) {
        return el;
      }
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

export const closest = (element: HTMLElement, selector: any): HTMLElement => {
  if (!selector) {
    return null;
  }

  return element.closest(selector) as HTMLElement;
};

export const sort = (entries: unknown[], config: SortConfig): unknown[] => {
  entries.sort((a, b) => a[config.key].localeCompare(b[config.key]));
  if (config.direction === SortDirection.DESC) {
    entries.reverse();
  }

  return entries;
}

export const isHttpParams = (obj: any): obj is HttpParams => {
  return obj instanceof HttpParams;
}
