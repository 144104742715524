import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbAccordionModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';

import { HeaderModule } from '../shared/header/header.module';
import { SharedModule } from '../shared/shared.module';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { IsKpiJobCreatedInSessionPipe } from './partials/recalculate-season/pipes/is-kpi-job-created-in-session/is-kpi-job-created-in-session.pipe';
import { RecalculateSeasonComponent } from './partials/recalculate-season/recalculate-season.component';
import { SetLockPeriodComponent } from './partials/set-lock-period/set-lock-period.component';
import { DataImportComponent } from './partials/data-import/data-import.component';
import { TranslatedJobStatePipe } from './partials/recalculate-season/pipes/translated-job-state/translated-job-state.pipe';
import { DataEditorSharedModule } from '../data-editor/modules/data-editor-shared/data-editor-shared.module';
import { DataEditorModule } from '../data-editor/data-editor.module';

@NgModule({
  declarations: [
    AdminComponent,
    SetLockPeriodComponent,
    RecalculateSeasonComponent,
    TranslatedJobStatePipe,
    IsKpiJobCreatedInSessionPipe,
    DataImportComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    NgbDatepickerModule,
    NgxSpinnerModule,
    NgbAccordionModule,
    HeaderModule,
    AdminRoutingModule,
    DataEditorSharedModule,
    DataEditorModule,
  ],
})
export class AdminModule {
}
