import { Component, OnChanges } from '@angular/core';
import { RegenerationFacility } from '../../models/models';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Validators } from '../../validators';
import { RegenerationFacilityService } from '../../services/regeneration-facility.service';
import { ConfirmationDialogService } from '../../../shared/confirmation-dialog/services/confirmation-dialog.service';
import { BaseTableViewComponent } from '../../../shared/base-table-view/base-table-view.component';

@Component({
  selector: 'lsz-regeneration-facility-form-collection',
  templateUrl: './regeneration-facility-form-collection.component.html',
  styleUrls: ['./regeneration-facility-form-collection.component.css'],
})
export class RegenerationFacilityFormCollectionComponent extends BaseTableViewComponent<RegenerationFacility>
  implements OnChanges {

  public customTypedEntries: RegenerationFacility[];
  public coldChamber: RegenerationFacility;
  public iceBarrel: RegenerationFacility;
  public relaxationBath: RegenerationFacility;
  public relaxationRoom: RegenerationFacility;
  public sauna: RegenerationFacility;

  public get amount(): number {
    return this.typedEntries.filter(entry => entry.isAvailable).length + this.customTypedEntries.length;
  }

  constructor(protected service: RegenerationFacilityService,
              protected confirmationDialog: ConfirmationDialogService) {
    super(service, confirmationDialog);
    this.customTypedEntries = [];
  }

  public ngOnChanges(): void {
    if (this.soccerClub && this.season) {
      this.service.get(this.soccerClub, this.season.id).subscribe(entries => {
        this.entries = entries;
        this.customTypedEntries = this.entries.filter(entry => entry.facilityType === 'Custom');
        this.typedEntries = this.entries.filter(entry => entry.facilityType !== 'Custom');

        this.allForms = this.initializeAllForms();
        for (const entry of this.customTypedEntries) {
          this.customEntryForms.push(this.formGroup(entry));
        }

        this.updateTypedEntries();

        const formNames: string[] = ['coldChamber', 'iceBarrel', 'relaxationBath', 'relaxationRoom', 'sauna'];

        for (const formName of formNames) {
          const form = this.allForms.get(formName) as UntypedFormGroup;
          form.patchValue(this.objectToForm(this[formName]));
        }
      });
    }
  }

  public initializeAllForms(): UntypedFormGroup {
    return new UntypedFormGroup({
      customEntries: new UntypedFormArray([]),
      newEntry: this.formGroup(),
      coldChamber: this.customForm(),
      iceBarrel: this.customForm(),
      relaxationBath: this.customForm(),
      relaxationRoom: this.customForm(),
      sauna: this.customForm(),
    });
  }

  public createNewEntry(): void {
    const entry: Partial<RegenerationFacility> = {
      facilityType: 'Custom',
      isAvailable: true,
    };

    this.addEntry(entry, this.customTypedEntries);
  }

  public onUpdate(index: number): void {
    this.updateEntry(index, null, this.customTypedEntries);
  }

  public exitEditMode(): void {
    this.exitAddMode(false);
    this.editMode = false;

    const resetValues = {
      coldChamber: this.objectToForm(this.coldChamber),
      iceBarrel: this.objectToForm(this.iceBarrel),
      relaxationBath: this.objectToForm(this.relaxationBath),
      relaxationRoom: this.objectToForm(this.relaxationRoom),
      sauna: this.objectToForm(this.sauna),
      customEntries: [],
    };
    for (const entry of this.customTypedEntries) {
      resetValues.customEntries.push(this.formGroup(entry).value);
    }
    this.allForms.reset(resetValues);
  }

  public override async onDelete(index: number): Promise<void> {
    await this.deleteEntry(index, this.customTypedEntries);
  }

  protected formGroup(entry?: RegenerationFacility): UntypedFormGroup {
    return new UntypedFormGroup({
      name: new UntypedFormControl(entry?.name, [Validators.required, this.repeatedNameValidator()]),
      remark: new UntypedFormControl(entry?.remark),
      updatedAtUtc: new UntypedFormControl(entry?.updatedAtUtc),
      createdAtUtc: new UntypedFormControl(entry?.createdAtUtc),
    });
  }

  protected objectToForm(entry: RegenerationFacility): Partial<RegenerationFacility> {
    return {
      remark: entry?.remark,
      isAvailable: entry?.isAvailable,
      updatedAtUtc: entry?.updatedAtUtc,
      createdAtUtc: entry?.createdAtUtc,
    };
  }

  protected updateTypedEntries(): void {
    this.iceBarrel = this.findEntryByType('IceBarrel');
    this.coldChamber = this.findEntryByType('ColdChamber');
    this.relaxationBath = this.findEntryByType('RelaxationBath');
    this.relaxationRoom = this.findEntryByType('RelaxationRoom');
    this.sauna = this.findEntryByType('Sauna');
  }

  private customForm(entry?: RegenerationFacility): UntypedFormGroup {
    return new UntypedFormGroup({
      remark: new UntypedFormControl(entry?.remark),
      isAvailable: new UntypedFormControl(entry?.isAvailable, [Validators.required]),
      updatedAtUtc: new UntypedFormControl(entry?.updatedAtUtc),
      createdAtUtc: new UntypedFormControl(entry?.createdAtUtc),
    });
  }

  private findEntryByType(type: string): RegenerationFacility {
    for (const entry of this.entries) {
      if (entry.facilityType === type) {
        return entry;
      }
    }
    return {
      id: null,
      soccerClubId: this.soccerClub.id,
      seasonId: this.season.id,
      name: null,
      remark: null,
      facilityType: type,
      isAvailable: false,
      createdAtUtc: null,
      updatedAtUtc: null,
    };
  }
}
