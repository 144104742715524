import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbAccordionModule, NgbDatepickerModule, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';

import { HeaderModule } from '../shared/header/header.module';
import { SharedModule } from '../shared/shared.module';

import { DataEditorRoutingModule } from './data-editor-routing.module';
import { DataEditorComponent } from './data-editor.component';
import { CommonCooperationFormCollectionComponent } from './edit-forms/common-cooperation-form-collection/common-cooperation-form-collection.component';
import { CommonCooperationFormComponent } from './edit-forms/common-cooperation-form/common-cooperation-form.component';
import { DatabaseSystemFormCollectionComponent } from './edit-forms/database-system-form-collection/database-system-form-collection.component';
import { DatabaseSystemFormComponent } from './edit-forms/database-system-form/database-system-form.component';
import { EducationEmployeeFormCollectionComponent } from './edit-forms/education-employee-form-collection/education-employee-form-collection.component';
import { FunctionalRoomFormCollectionComponent } from './edit-forms/functional-room-form-collection/functional-room-form-collection.component';
import { FunctionalRoomTypedFormComponent } from './edit-forms/functional-room-typed-form/functional-room-typed-form.component';
import { IndoorHallFormCollectionComponent } from './edit-forms/indoor-hall-form-collection/indoor-hall-form-collection.component';
import { IndoorHallFormComponent } from './edit-forms/indoor-hall-form/indoor-hall-form.component';
import { LawnFieldFormCollectionComponent } from './edit-forms/lawn-field-form-collection/lawn-field-form-collection.component';
import { LawnFieldFormComponent } from './edit-forms/lawn-field-form/lawn-field-form.component';
import { OpenSpaceFieldFormCollectionComponent } from './edit-forms/open-space-field-form-collection/open-space-field-form-collection.component';
import { OpenSpaceFieldFormComponent } from './edit-forms/open-space-field-form/open-space-field-form.component';
import { PartTimeEmployeeLeadershipFormCollectionComponent } from './edit-forms/part-time-employee-leadership-form-collection/part-time-employee-leadership-form-collection.component';
import { PartTimeEmployeeLeadershipTypedFormComponent } from './edit-forms/part-time-employee-leadership-typed-form/part-time-employee-leadership-typed-form.component';
import { PartTimeEmployeeManagementFormCollectionComponent } from './edit-forms/part-time-employee-management-form-collection/part-time-employee-management-form-collection.component';
import { PartTimeEmployeeManagementTypedFormComponent } from './edit-forms/part-time-employee-management-typed-form/part-time-employee-management-typed-form.component';
import { PartTimeEmployeeMiscFormCollectionComponent } from './edit-forms/part-time-employee-misc-form-collection/part-time-employee-misc-form-collection.component';
import { PartTimeEmployeeMiscTypedFormComponent } from './edit-forms/part-time-employee-misc-typed-form/part-time-employee-misc-typed-form.component';
import { PartTimeEmployeeSoccerTrainingFormCollectionComponent } from './edit-forms/part-time-employee-soccer-training-form-collection/part-time-employee-soccer-training-form-collection.component';
import { PartTimeEmployeeSoccerTrainingTypedFormComponent } from './edit-forms/part-time-employee-soccer-training-typed-form/part-time-employee-soccer-training-typed-form.component';
import { PartTimeEmployeeSupportFormCollectionComponent } from './edit-forms/part-time-employee-support-form-collection/part-time-employee-support-form-collection.component';
import { PartTimeEmployeeSupportTypedFormComponent } from './edit-forms/part-time-employee-support-typed-form/part-time-employee-support-typed-form.component';
import { RegenerationFacilityFormCollectionComponent } from './edit-forms/regeneration-facility-form-collection/regeneration-facility-form-collection.component';
import { RegenerationFacilityFormComponent } from './edit-forms/regeneration-facility-form/regeneration-facility-form.component';
import { RegenerationFacilityTypedFormComponent } from './edit-forms/regeneration-facility-typed-form/regeneration-facility-typed-form.component';
import { RoomFormCollectionComponent } from './edit-forms/room-form-collection/room-form-collection.component';
import { RoomTypedFormComponent } from './edit-forms/room-typed-form/room-typed-form.component';
import { SchoolCooperationFormCollectionComponent } from './edit-forms/school-cooperation-form-collection/school-cooperation-form-collection.component';
import { SchoolCooperationTypedFormComponent } from './edit-forms/school-cooperation-typed-form/school-cooperation-typed-form.component';
import { SmallSizedFieldFormCollectionComponent } from './edit-forms/small-sized-field-form-collection/small-sized-field-form-collection.component';
import { SmallSizedFieldFormComponent } from './edit-forms/small-sized-field-form/small-sized-field-form.component';
import { SoccerHallFormCollectionComponent } from './edit-forms/soccer-hall-form-collection/soccer-hall-form-collection.component';
import { SoccerHallFormComponent } from './edit-forms/soccer-hall-form/soccer-hall-form.component';
import { SpecialEquipmentFormCollectionComponent } from './edit-forms/special-equipment-form-collection/special-equipment-form-collection.component';
import { SpecialEquipmentFormComponent } from './edit-forms/special-equipment-form/special-equipment-form.component';
import { SpecialEquipmentTypedFormComponent } from './edit-forms/special-equipment-typed-form/special-equipment-typed-form.component';
import { CategoryFullTimeEmployeesModule } from './modules/category-full-time-employees/category-full-time-employees.module';
import { DataEditorSharedModule } from './modules/data-editor-shared/data-editor-shared.module';
import { CommonCooperationService } from './services/common-cooperation.service';
import { DatabaseSystemService } from './services/database-system.service';
import { EducationEmployeeService } from './services/education-employee.service';
import { FullTimeEmployeeService } from './services/full-time-employee.service';
import { FunctionalRoomService } from './services/functional-room.service';
import { IndoorHallService } from './services/indoor-hall.service';
import { LawnFieldService } from './services/lawn-field.service';
import { ManagementPartTimeEmployeeService } from './services/management-part-time-employee.service';
import { MiscPartTimeEmployeeService } from './services/misc-part-time-employee.service';
import { OpenSpaceFieldService } from './services/open-space-field.service';
import { RegenerationFacilityService } from './services/regeneration-facility.service';
import { RoomService } from './services/room.service';
import { SchoolCooperationService } from './services/school-cooperation.service';
import { SmallSizedFieldService } from './services/small-sized-field.service';
import { SoccerHallService } from './services/soccer-hall.service';
import { SoccerTrainingPartTimeEmployeeService } from './services/soccer-training-part-time-employee.service';
import { SpecialEquipmentService } from './services/special-equipment.service';
import { SupportPartTimeEmployeeService } from './services/support-part-time-employee.service';
import { EducationEmployeeFormComponent } from './edit-forms/education-employee-form/education-employee-form.component';
import { FilterByPersonnelAreaPipe } from './edit-forms/education-employee-form-collection/pipe/filter-by-personnel-area.pipe';
import { ActiveTeamsFormComponent } from './edit-forms/active-teams-form/active-teams-form.component';
import { ActiveTeamsFormCollectionComponent } from './edit-forms/active-teams-form-collection/active-teams-form-collection.component';

@NgModule({
  declarations: [
    DataEditorComponent,
    LawnFieldFormComponent,
    LawnFieldFormCollectionComponent,
    SmallSizedFieldFormComponent,
    SmallSizedFieldFormCollectionComponent,
    OpenSpaceFieldFormComponent,
    OpenSpaceFieldFormCollectionComponent,
    SoccerHallFormComponent,
    SoccerHallFormCollectionComponent,
    IndoorHallFormComponent,
    IndoorHallFormCollectionComponent,
    SpecialEquipmentFormComponent,
    SpecialEquipmentFormCollectionComponent,
    SpecialEquipmentTypedFormComponent,
    FunctionalRoomTypedFormComponent,
    FunctionalRoomFormCollectionComponent,
    RegenerationFacilityFormComponent,
    RegenerationFacilityTypedFormComponent,
    RegenerationFacilityFormCollectionComponent,
    RoomTypedFormComponent,
    RoomFormCollectionComponent,
    DatabaseSystemFormComponent,
    DatabaseSystemFormCollectionComponent,
    SchoolCooperationTypedFormComponent,
    SchoolCooperationFormCollectionComponent,
    CommonCooperationFormComponent,
    CommonCooperationFormCollectionComponent,
    PartTimeEmployeeLeadershipTypedFormComponent,
    PartTimeEmployeeLeadershipFormCollectionComponent,
    PartTimeEmployeeManagementTypedFormComponent,
    PartTimeEmployeeManagementFormCollectionComponent,
    PartTimeEmployeeSoccerTrainingTypedFormComponent,
    PartTimeEmployeeSoccerTrainingFormCollectionComponent,
    PartTimeEmployeeSupportTypedFormComponent,
    PartTimeEmployeeSupportTypedFormComponent,
    PartTimeEmployeeSupportFormCollectionComponent,
    PartTimeEmployeeMiscTypedFormComponent,
    PartTimeEmployeeMiscFormCollectionComponent,
    EducationEmployeeFormComponent,
    EducationEmployeeFormCollectionComponent,
    FilterByPersonnelAreaPipe,
    FilterByPersonnelAreaPipe,
    ActiveTeamsFormComponent,
    ActiveTeamsFormCollectionComponent,
  ],
  imports: [
    CommonModule,
    HeaderModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
    DataEditorRoutingModule,
    FlexModule,
    NgbModule,
    NgbAccordionModule,
    NgbDatepickerModule,
    NgbTooltipModule,
    NgxSpinnerModule,
    CategoryFullTimeEmployeesModule,
    DataEditorSharedModule,
  ],
  exports: [
    ActiveTeamsFormCollectionComponent,
  ],
  providers: [
    LawnFieldService,
    SmallSizedFieldService,
    OpenSpaceFieldService,
    SoccerHallService,
    IndoorHallService,
    SpecialEquipmentService,
    FunctionalRoomService,
    RegenerationFacilityService,
    RoomService,
    DatabaseSystemService,
    SchoolCooperationService,
    CommonCooperationService,
    FullTimeEmployeeService,
    ManagementPartTimeEmployeeService,
    SoccerTrainingPartTimeEmployeeService,
    SupportPartTimeEmployeeService,
    MiscPartTimeEmployeeService,
    EducationEmployeeService,
  ],
})
export class DataEditorModule {
}
