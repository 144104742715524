import { Component, OnChanges } from '@angular/core';
import { SupportPartTimeEmployee } from '../../models/models';
import { SupportPartTimeEmployeeService } from '../../services/support-part-time-employee.service';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Validators } from '../../validators';
import { ConfirmationDialogService } from '../../../shared/confirmation-dialog/services/confirmation-dialog.service';
import { BaseTableViewComponent } from '../../../shared/base-table-view/base-table-view.component';

@Component({
  selector: 'lsz-part-time-employee-support-form-collection',
  templateUrl: './part-time-employee-support-form-collection.component.html',
  styleUrls: ['./part-time-employee-support-form-collection.component.css'],
})
export class PartTimeEmployeeSupportFormCollectionComponent
  extends BaseTableViewComponent<SupportPartTimeEmployee>
  implements OnChanges {

  public get amount(): number {
    let amount = 0;
    for (const entry of this.entries) {
      amount += entry.personnelCount;
    }
    return amount;
  }

  public pedagogicalSupport: SupportPartTimeEmployee;
  public scouting: SupportPartTimeEmployee;
  public medicine: SupportPartTimeEmployee;

  constructor(protected service: SupportPartTimeEmployeeService,
              protected confirmationDialog: ConfirmationDialogService) {
    super(service, confirmationDialog);
  }

  public ngOnChanges(): void {
    if (this.soccerClub && this.season) {
      this.service.get(this.soccerClub, this.season.id).subscribe(entries => {
        this.entries = entries;

        this.initializeAllForms();

        this.updateTypedEntries();

        const formNames = [
          'pedagogicalSupport',
          'scouting',
          'medicine',
        ];
        for (const formName of formNames) {
          const form: AbstractControl = this.allForms.get(formName);
          form.patchValue({
            personnelCount: this[formName].personnelCount as number,
            updatedAtUtc: this[formName].updatedAtUtc,
            createdAtUtc: this[formName].createdAtUtc,
          });
        }
      });
    }
  }

  public initializeAllForms(): UntypedFormGroup {
    return this.formGroup();
  }

  public exitEditMode(): void {
    this.editMode = false;

    const resetValues = {
      pedagogicalSupport: {
        personnelCount: this.pedagogicalSupport.personnelCount,
        updatedAtUtc: this.pedagogicalSupport.updatedAtUtc,
        createdAtUtc: this.pedagogicalSupport.createdAtUtc,
      },
      scouting: {
        personnelCount: this.scouting.personnelCount,
        updatedAtUtc: this.scouting.updatedAtUtc,
        createdAtUtc: this.scouting.createdAtUtc,
      },
      medicine: {
        personnelCount: this.medicine.personnelCount,
        updatedAtUtc: this.medicine.updatedAtUtc,
        createdAtUtc: this.medicine.createdAtUtc,
      },
    };
    this.allForms.reset(resetValues);
  }

  protected formGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      pedagogicalSupport: new UntypedFormGroup({
        personnelCount: new UntypedFormControl('', Validators.required),
        updatedAtUtc: new UntypedFormControl(''),
        createdAtUtc: new UntypedFormControl(''),
      }),
      scouting: new UntypedFormGroup({
        personnelCount: new UntypedFormControl('', Validators.required),
        updatedAtUtc: new UntypedFormControl(''),
        createdAtUtc: new UntypedFormControl(''),
      }),
      medicine: new UntypedFormGroup({
        personnelCount: new UntypedFormControl('', Validators.required),
        updatedAtUtc: new UntypedFormControl(''),
        createdAtUtc: new UntypedFormControl(''),
      }),
    });
  }

  protected updateTypedEntries(): void {
    this.pedagogicalSupport = this.findEntryByType('PedagogicalSupport');
    this.scouting = this.findEntryByType('Scouting');
    this.medicine = this.findEntryByType('Medicine');
  }

  private findEntryByType(type: string): SupportPartTimeEmployee {
    for (const entry of this.entries) {
      if (entry.personnelFunction === type) {
        return entry;
      }
    }
    return {
      id: null,
      soccerClubId: this.soccerClub.id,
      seasonId: this.season.id,
      personnelCount: 0,
      personnelFunction: type,
      updatedAtUtc: null,
      createdAtUtc: null,
    };
  }
}
