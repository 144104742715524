import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'lsz-last-edit',
  templateUrl: './last-edit.component.html',
  styleUrls: ['./last-edit.component.scss'],
})
export class LastEditComponent {

  @Input() public form: UntypedFormGroup;

  constructor() {
  }
}
