import { SoccerClub } from '../../models/soccerClub';

import { Kpi } from './kpi';
import { KpiType } from './kpi-type.enum';
import NumberFormat = Intl.NumberFormat;

const CLUSTER_COUNT: number = 8;

export interface Detail {
  baseline: string;
  baselineValue: number;
  clusterDetail?: ClusterDetail;
  distribution: { [s: string]: number };
  leagues?: string[];
  max: number;
  min: number;
  multiValues?: { [s: string]: number }[];
  name: string;
  numClubs: number;
  own: number;
  soccerClub: SoccerClub | null;
  type: KpiType;
}

export interface ClusterDetail {
  labels: string[];
  own: number;
}

export abstract class DetailCalculator {
  public calculate(kpi: Kpi, soccerClub: SoccerClub | null): Detail {
    let numClubs: number = 0;
    for (const key in kpi.summary.values) {
      if (!kpi.summary.values.hasOwnProperty(key)) {
        continue;
      }
      numClubs += kpi.summary.values[key];
    }

    let detail: Detail = {
      name: kpi.name,
      type: kpi.type,
      min: kpi.summary.min,
      max: kpi.summary.max,
      baseline: undefined,
      baselineValue: undefined,
      distribution: kpi.summary.values,
      multiValues: kpi.summary.multiValues,
      own: !soccerClub?.id ? -1 : kpi.rating.own,
      soccerClub,
      numClubs,
      leagues: kpi.summary.leagues,
    };

    if (this.shouldCluster(detail)) {
      detail = this.clusterDetail(detail, CLUSTER_COUNT);
    }

    return detail;
  }

  private clusterDetail(detail: Detail, clusterCount: number): Detail {
    const hundreds: number = 100; // round to hundreds

    const numberFormat: NumberFormat = new Intl.NumberFormat();
    const clusteredDetail: Detail = {...detail, distribution: {}};

    let clusterRange: number = clusteredDetail.max / clusterCount;
    clusterRange = Math.round(clusterRange / hundreds) * hundreds;

    // initially set own cluster value to 0
    clusteredDetail.clusterDetail = {
      own: 0,
      labels: [],
    };

    // keep '0' as single chart value / cluster
    const zeroLabel: string = '0';
    clusteredDetail.distribution[zeroLabel] = detail.distribution[zeroLabel];
    clusteredDetail.clusterDetail.labels.push(zeroLabel);

    for (let i: number = 0; i < clusterCount; i++) {
      const clusterIndex: number = i + 1;
      const rangeMin: number = clusterRange * i;
      let rangeMax: number = clusterRange * clusterIndex;
      let clubCount: number = 0;

      if (clusterIndex === clusterCount) {
        rangeMax = detail.max;
      }

      Object.entries(detail.distribution).forEach(([key, value]: [string, number]) => {
        const amount: number = parseInt(key, 10);
        if (amount > rangeMin && amount <= rangeMax) {
          clubCount = clubCount + value;
        }
      });

      // find club cluster
      if (clusteredDetail.own > rangeMin && clusteredDetail.own <= rangeMax) {
        clusteredDetail.clusterDetail.own = rangeMax;
      }

      const label: string = '≤ ' + numberFormat.format(rangeMax);
      clusteredDetail.distribution[rangeMax.toString()] = clubCount;
      clusteredDetail.clusterDetail.labels.push(label);
    }

    return clusteredDetail;
  }

  private shouldCluster(detail: Detail): boolean {
    return [
      'TrainedPlayersCompetitiveMinutes', // [#] Gesamteinsatzminuten ausgebildeter Spieler
      'TrainedPlayersAverageCompetitiveMinutes', // [Ø] Einsatzminuten ausgebildeter Spieler
      'ForeignTrainedPlayersCompetitiveMinutes', // [#] Gesamteinsatzminuten ausgebildeter Spieler
      'ForeignTrainedPlayersAverageCompetitiveMinutes', // [Ø] Einsatzminuten ausgebildeter Spieler
      'ForeignTrainedPlayersCompetitiveMinutesPremierLeague', // [#] Gesamteinsatzminuten - BL
      'ForeignTrainedPlayersAverageCompetitiveMinutesPremierLeague', // [Ø] Einsatzminuten - BL
      'ForeignTrainedPlayersCompetitiveMinutesSecondLeague', // [#] Gesamteinsatzminuten - 2. BL
      'ForeignTrainedPlayersAverageCompetitiveMinutesSecondLeague', // [Ø] Einsatzminuten - 2. BL
      'ForeignTrainedPlayersCompetitiveMinutesThirdLeague', // [#] Gesamteinsatzminuten - 3. L
      'ForeignTrainedPlayersAverageCompetitiveMinutesThirdLeague', // [Ø] Einsatzminuten - 3. L
    ].some((detailName: string) => detailName === detail.name);
  }
}
