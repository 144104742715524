<div class="form-wrapper">
  <lsz-edit-form-header
    [headerText]="'Leitung (Bitte nur Teilzeitmitarbeiter eintragen!)'"
    [numEntries]="amount"
    [editMode]="editMode"
    (clickEdit)="onClickEdit()"
    (clickEditDone)="onClickEditDone()"
  ></lsz-edit-form-header>

  <table [class.editMode]="editMode" [formGroup]="allForms">
    <thead>
    <tr>
      <th i18n="@@tableHeaderName">Name</th>
      <th i18n="@@tableHeaderCount">Anzahl</th>
      <th *ngIf="editMode" i18n="@@tableHeaderOptions" class="column-options">Optionen</th>
      <th>{{'lastEdit' | translate}}</th>
    </tr>
    </thead>
    <tbody *ngIf="entry"
           lsz-part-time-employee-leadership-typed-form
           [entry]="entry"
           (entrySaved)="onTypedEntrySaved($event)"
           [editMode]="editMode"
           [form]="allForms.get('leadershipPartTimeEmployee')"
    ></tbody>
  </table>
</div>
