import { Component } from '@angular/core';
import { RegenerationFacility } from '../../models/models';
import { AbstractControl } from '@angular/forms';
import { BaseEditFormComponent } from '../../../shared/base-edit-form/base-edit-form.component';

@Component({
  selector: 'tbody[lsz-regeneration-facility-typed-form]',
  templateUrl: './regeneration-facility-typed-form.component.html',
  styleUrls: ['./regeneration-facility-typed-form.component.css'],
})
export class RegenerationFacilityTypedFormComponent extends BaseEditFormComponent<RegenerationFacility> {

  public get isAvailable(): AbstractControl {
    return this.form.get('isAvailable');
  }

  constructor() {
    super();
  }

  public onClickSave(): void {
    this.entrySaved.emit({
      ...this.entry,
      remark: this.remark.value as string,
      isAvailable: this.isAvailable.value as boolean,
      name: null, // The API dictates: Specifically typed facilities, like cold chamber or ice barrel, mustn't have a name set.
    } as RegenerationFacility);
  }
}
