import { SoccerClub } from '../../models/soccerClub';
import { ClubActions, EClubActions } from '../actions/club.actions';
import { initialSoccerClubState } from '../states/club.state';

export const clubReducers = (state: SoccerClub = initialSoccerClubState, action: ClubActions): SoccerClub => {
  switch (action.type) {
    case EClubActions.SET_CLUB: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
