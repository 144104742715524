import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmationDialogResult } from '../confirmationDialogResult';

@Component({
  selector: 'lsz-recalculate-season-confirm-dialog',
  templateUrl: '../confirmation-dialog.component.html',
  styleUrls: ['../confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  @Input() public labelCancel: string = 'Abbrechen';
  @Input() public labelConfirm: string = 'Ja';
  @Input() public text: string;
  @Input() public title: string;

  public confirmationDialogResult = ConfirmationDialogResult;

  constructor(public modal: NgbActiveModal) {
  }

}
