import { Component } from '@angular/core';
import { SoccerHall } from '../../models/models';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Validators } from '../../validators';
import { SoccerHallService } from '../../services/soccer-hall.service';
import { ConfirmationDialogService } from '../../../shared/confirmation-dialog/services/confirmation-dialog.service';
import { BaseTableViewComponent } from '../../../shared/base-table-view/base-table-view.component';

@Component({
  selector: 'lsz-soccer-hall-form-collection',
  templateUrl: './soccer-hall-form-collection.component.html',
  styleUrls: ['./soccer-hall-form-collection.component.css'],
})
export class SoccerHallFormCollectionComponent extends BaseTableViewComponent<SoccerHall> {

  public get surface(): AbstractControl {
    return this.newEntryForm.get('surface');
  }

  public get length(): AbstractControl {
    return this.newEntryForm.get('length');
  }

  public get width(): AbstractControl {
    return this.newEntryForm.get('width');
  }

  public get isOnLzArea(): AbstractControl {
    return this.newEntryForm.get('isOnLzArea');
  }

  public get isLzExclusive(): AbstractControl {
    return this.newEntryForm.get('isLzExclusive');
  }

  public get hasBoards(): AbstractControl {
    return this.newEntryForm.get('hasBoards');
  }


  constructor(protected service: SoccerHallService,
              protected confirmationDialog: ConfirmationDialogService) {
    super(service, confirmationDialog);
  }

  public createNewEntry(): void {
    const entry: Partial<SoccerHall> = {
      size: {
        length: this.length.value as number,
        width: this.width.value as number,
      },
    };

    this.addEntry(entry);
  }

  public onUpdate(index: number): void {
    const form: UntypedFormGroup = this.customEntryForms.at(index) as UntypedFormGroup;
    const entry: Partial<SoccerHall> = {
      size: {
        length: form.get('length').value as number,
        width: form.get('width').value as number,
      },
    };

    this.updateEntry(index, entry);
  }

  protected formGroup(entry?: SoccerHall): UntypedFormGroup {
    return new UntypedFormGroup({
      name: new UntypedFormControl(entry?.name, [Validators.required, this.repeatedNameValidator()]),
      remark: new UntypedFormControl(entry?.remark),
      length: new UntypedFormControl(entry?.size?.length, Validators.required),
      width: new UntypedFormControl(entry?.size?.width, Validators.required),
      surface: new UntypedFormControl(entry?.surface, Validators.required),
      isOnLzArea: new UntypedFormControl(entry?.isOnLzArea, Validators.required),
      isLzExclusive: new UntypedFormControl(entry?.isLzExclusive, Validators.required),
      hasBoards: new UntypedFormControl(entry?.hasBoards, Validators.required),
      updatedAtUtc: new UntypedFormControl(entry?.updatedAtUtc),
      createdAtUtc: new UntypedFormControl(entry?.createdAtUtc),
    });
  }

  protected objectToForm(entry: SoccerHall): any {
    return {
      name: entry.name,
      remark: entry.remark,
      length: entry.size.length,
      width: entry.size.width,
      surface: entry.surface,
      isOnLzArea: entry.isOnLzArea,
      isLzExclusive: entry.isLzExclusive,
      hasBoards: entry.hasBoards,
      updatedAtUtc: entry?.updatedAtUtc,
      createdAtUtc: entry?.createdAtUtc,
    };
  }
}
