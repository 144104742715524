<tr [formGroup]="form">
  <td i18n="@@schoolCooperationType">
    {entry.schoolCooperationType, select,
      EliteSchool {Eliteschulen des Fußballs}
      CommonSchool {Weitere Schulkooperationen}
    }
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{count.value}}</ng-container>
    <div *ngIf="editMode">
      <input type="number" min="0" formControlName="count" />
      <div *ngIf="count.invalid && (count.dirty || count.touched)">
        <div class="input-error" *ngIf="count.errors.required">Eingabe wird benötigt.</div>
      </div>
    </div>
  </td>
  <td *ngIf="editMode">
    <lsz-dataset-options [form]="form" [withoutDelete]="true" (save)="onClickSave()"></lsz-dataset-options>
  </td>
  <td>
    <lsz-last-edit [form]="form"></lsz-last-edit>
  </td>
</tr>
