<div class="form-wrapper">
  <lsz-edit-form-header
    [headerText]="'Funktionsräume'"
    [numEntries]="amount"
    [editMode]="editMode"
    (clickEdit)="onClickEdit()"
    (clickEditDone)="onClickEditDone()"
  ></lsz-edit-form-header>

  <table [class.editMode]="editMode" [formGroup]="allForms">
    <thead>
    <tr>
      <th i18n="@@tableHeaderName">Name</th>
      <th i18n="@@tableHeaderIsAvailable">Vorhanden</th>
      <th i18n="@@tableHeaderRemark">Bemerkung</th>
      <th *ngIf="editMode" i18n="@@tableHeaderOptions" class="column-options">Optionen</th>
      <th>{{'lastEdit' | translate}}</th>
    </tr>
    </thead>
    <tbody
      *ngIf="weightRoom"
      lsz-functional-room-typed-form
      [entry]="weightRoom"
      [toggle]="false"
      (entrySaved)="onTypedEntrySaved(weightRoom, $event, 'weightRoom')"
      [editMode]="editMode"
      [form]="allForms.get('weightRoom')"
    ></tbody>
    <tbody
      *ngIf="playersCabin"
      lsz-functional-room-typed-form
      [entry]="playersCabin"
      [toggle]="false"
      (entrySaved)="onTypedEntrySaved(playersCabin, $event, 'playersCabin')"
      [editMode]="editMode"
      [form]="allForms.get('playersCabin')"
    ></tbody>
    <tbody
      *ngIf="trainersCabin"
      lsz-functional-room-typed-form
      [entry]="trainersCabin"
      [toggle]="false"
      (entrySaved)="onTypedEntrySaved(trainersCabin, $event, 'trainersCabin')"
      [editMode]="editMode"
      [form]="allForms.get('trainersCabin')"
    ></tbody>
    <tbody
      *ngIf="physio"
      lsz-functional-room-typed-form
      [entry]="physio"
      [toggle]="false"
      (entrySaved)="onTypedEntrySaved(physio, $event, 'physio')"
      [editMode]="editMode"
      [form]="allForms.get('physio')"
    ></tbody>
    <tbody
      *ngIf="medicalRoom"
      lsz-functional-room-typed-form
      [entry]="medicalRoom"
      [toggle]="true"
      (entrySaved)="onTypedEntrySaved(medicalRoom, $event, 'medicalRoom')"
      [editMode]="editMode"
      [form]="allForms.get('medicalRoom')"
    ></tbody>
    <tbody
      *ngIf="refereeCabin"
      lsz-functional-room-typed-form
      [entry]="refereeCabin"
      [toggle]="false"
      (entrySaved)="onTypedEntrySaved(refereeCabin, $event, 'refereeCabin')"
      [editMode]="editMode"
      [form]="allForms.get('refereeCabin')"
    ></tbody>
    <tbody
      *ngIf="dopingControlRoom"
      lsz-functional-room-typed-form
      [entry]="dopingControlRoom"
      [toggle]="true"
      (entrySaved)="onTypedEntrySaved(dopingControlRoom, $event, 'dopingControlRoom')"
      [editMode]="editMode"
      [form]="allForms.get('dopingControlRoom')"
    ></tbody>
  </table>
</div>
