import { Component, OnChanges } from '@angular/core';
import { ManagementPartTimeEmployee } from '../../data-editor/models/models';
import { UntypedFormGroup } from '@angular/forms';
import { ManagementPartTimeEmployeeService } from '../../data-editor/services/management-part-time-employee.service';
import { ConfirmationDialogService } from '../confirmation-dialog/services/confirmation-dialog.service';
import { BaseTableViewComponent } from '../base-table-view/base-table-view.component';

@Component({
  selector: 'lsz-base-part-time-employee',
  templateUrl: './base-part-time-employee.component.html',
  styleUrls: ['./base-part-time-employee.component.scss'],
})
export abstract class BaseManagementPartTimeEmployeeComponent
  extends BaseTableViewComponent<ManagementPartTimeEmployee>
  implements OnChanges {

  public entry: ManagementPartTimeEmployee;

  public get amount(): number {
    let amount: number = 0;
    for (const entry of this.entries) {
      amount += entry.personnelCount;
    }
    return amount;
  }

  public abstract get formName(): string;

  public abstract get type(): string;

  protected constructor(protected service: ManagementPartTimeEmployeeService,
                        protected confirmationDialog: ConfirmationDialogService) {
    super(service, confirmationDialog);
  }

  public ngOnChanges(): void {
    if (this.soccerClub && this.season) {
      this.service.get(this.soccerClub, this.season.id).subscribe(entries => {
        this.entries = entries.filter(entry => entry.personnelArea === this.type);

        this.initializeAllForms();
        this.updateTypedEntries();

        const form = this.allForms.get(this.formName) as UntypedFormGroup;

        form.patchValue({
          personnelCount: this.entry.personnelCount,
        });
      });
    }
  }

  public initializeAllForms(): UntypedFormGroup {
    return this.formGroup();
  }

  public exitEditMode(): void {
    this.editMode = false;
    this.allForms.reset(this.resetValues());
  }

  public onTypedEntrySaved(alteredEntry: ManagementPartTimeEmployee): void {
    if (alteredEntry.id === null) {
      this.service.add(alteredEntry).subscribe(newEntry => {
        this.entries.push(newEntry);
        this.updateTypedEntries();
      });
    } else {
      this.service.update(alteredEntry).subscribe((updatedEntry: ManagementPartTimeEmployee) => {
        const index = this.entries.indexOf(this.entry);
        this.entries[index] = updatedEntry;
        this.updateTypedEntries();
      });
    }
  }


  protected formGroup(): UntypedFormGroup {
    return new UntypedFormGroup({});
  }

  protected resetValues(): { [key: string]: { [key: string]: number | string } } {
    return null;
  }

  protected updateTypedEntries(): void {
    this.entry = this.findEntryByType(this.type);
    this.allForms.get(this.formName).get('updatedAtUtc').setValue(this.entry.updatedAtUtc);
    this.allForms.get(this.formName).markAsPristine();
  }

  private findEntryByType(type: string): ManagementPartTimeEmployee {
    for (const entry of this.entries) {
      if (entry.personnelArea === type) {
        return entry;
      }
    }

    return {
      id: null,
      soccerClubId: this.soccerClub.id,
      seasonId: this.season.id,
      personnelCount: 0,
      personnelArea: type,
      createdAtUtc: null,
      updatedAtUtc: null,
    };
  }
}
