import { Component } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ConfirmationDialogService } from '../../../shared/confirmation-dialog/services/confirmation-dialog.service';
import { DatabaseSystem } from '../../models/models';
import { DatabaseSystemService } from '../../services/database-system.service';
import { Validators } from '../../validators';
import { BaseTableViewComponent } from '../../../shared/base-table-view/base-table-view.component';

@Component({
  selector: 'lsz-database-system-form-collection',
  templateUrl: './database-system-form-collection.component.html',
  styleUrls: ['./database-system-form-collection.component.css'],
})
export class DatabaseSystemFormCollectionComponent extends BaseTableViewComponent<DatabaseSystem> {

  public get medicine(): AbstractControl {
    return this.newEntryForm.get('medicine');
  }

  public get players(): AbstractControl {
    return this.newEntryForm.get('players');
  }

  public get scouting(): AbstractControl {
    return this.newEntryForm.get('scouting');
  }

  public get staff(): AbstractControl {
    return this.newEntryForm.get('staff');
  }

  public get strategy(): AbstractControl {
    return this.newEntryForm.get('strategy');
  }

  public get support(): AbstractControl {
    return this.newEntryForm.get('support');
  }

  public get video(): AbstractControl {
    return this.newEntryForm.get('video');
  }

  constructor(protected service: DatabaseSystemService,
              protected confirmationDialog: ConfirmationDialogService) {
    super(service, confirmationDialog);
  }


  public createNewEntry(): void {
    const categories: string[] = [];

    if (this.medicine.value || this.players.value || this.scouting.value || this.staff.value || this.strategy.value
      || this.support.value || this.video.value) {
      if (this.medicine.value) {
        categories.push('Medicine');
      }
      if (this.players.value) {
        categories.push('Players');
      }
      if (this.scouting.value) {
        categories.push('Scouting');
      }
      if (this.staff.value) {
        categories.push('Staff');
      }
      if (this.strategy.value) {
        categories.push('Strategy');
      }
      if (this.support.value) {
        categories.push('Support');
      }
      if (this.video.value) {
        categories.push('Video');
      }
    } else {
      categories.push('None');
    }

    this.addEntry({ databaseCategories: categories.join(', ') });
  }

  public onUpdate(index: number): void {
    const form: UntypedFormGroup = this.customEntryForms.at(index) as UntypedFormGroup;

    const categories: string[] = [];
    if (form.get('medicine').value || form.get('players').value || form.get('scouting').value || form.get('staff').value
      || form.get('strategy').value || form.get('support').value || form.get('video').value) {

      if (form.get('medicine').value) {
        categories.push('Medicine');
      }
      if (form.get('players').value) {
        categories.push('Players');
      }
      if (form.get('scouting').value) {
        categories.push('Scouting');
      }
      if (form.get('staff').value) {
        categories.push('Staff');
      }
      if (form.get('strategy').value) {
        categories.push('Strategy');
      }
      if (form.get('support').value) {
        categories.push('Support');
      }
      if (form.get('video').value) {
        categories.push('Video');
      }
    } else {
      categories.push('None');
    }

    const entry: DatabaseSystem = {
      ...this.entries[index],
      ...form.value as DatabaseSystem,
      databaseCategories: categories.join(', '),
    };

    this.updateEntry(index, entry);
  }

  protected formGroup(entry?: DatabaseSystem): UntypedFormGroup {
    const categories: string[] = entry?.databaseCategories?.split(', ');

    return new UntypedFormGroup({
      name: new UntypedFormControl(entry?.name, [Validators.required, this.repeatedNameValidator()]),
      medicine: new UntypedFormControl(categories?.includes('Medicine'), Validators.required),
      players: new UntypedFormControl(categories?.includes('Players'), Validators.required),
      scouting: new UntypedFormControl(categories?.includes('Scouting'), Validators.required),
      staff: new UntypedFormControl(categories?.includes('Staff'), Validators.required),
      strategy: new UntypedFormControl(categories?.includes('Strategy'), Validators.required),
      support: new UntypedFormControl(categories?.includes('Support'), Validators.required),
      video: new UntypedFormControl(categories?.includes('Video'), Validators.required),
      updatedAtUtc: new UntypedFormControl(entry?.updatedAtUtc),
      createdAtUtc: new UntypedFormControl(entry?.createdAtUtc),
    });
  }

  protected objectToForm(entry?: DatabaseSystem): any {
    const categories: string[] = entry.databaseCategories.split(', ');
    return {
      name: entry.name,
      medicine: categories.includes('Medicine'),
      players: categories.includes('Players'),
      scouting: categories.includes('Scouting'),
      staff: categories.includes('Staff'),
      strategy: categories.includes('Strategy'),
      support: categories.includes('Support'),
      video: categories.includes('Video'),
      updatedAtUtc: entry.updatedAtUtc,
      createdAtUtc: entry.createdAtUtc,
    };
  }
}
