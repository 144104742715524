import { Injectable } from '@angular/core';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';

const I18N_VALUES = {
  en: {
    weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  },
  de: {
    weekdays: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
    months: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
  },
};

@Injectable()
export class CustomDatepickerLanguage extends NgbDatepickerI18n {

  public getDayAriaLabel(): string {
    return 'e';
  }

  public getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  public getMonthShortName(month: number): string {
    return I18N_VALUES.de.months[month - 1];
  }

  public getWeekdayLabel(weekday: number): string {
    return I18N_VALUES.de.weekdays[weekday - 1];
  }

}
