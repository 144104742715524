import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { faCalendar, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';

const availableTeams: string[] = [
  'U12',
  'U13',
  'U14',
  'U15',
  'U16',
  'U17',
  'U1617',
  'U19',
  'U2123',
  'AllTeams',
  'PerformanceArea',
  'BuildUpArea',
  'FoundationArea',
];

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[lsz-full-time-employee-form]',
  templateUrl: './full-time-employee-form.component.html',
  styleUrls: ['./full-time-employee-form.component.scss'],
})
export class FullTimeEmployeeFormComponent {

  public get additionalPersonnelArea(): UntypedFormControl {
    return this.form.get('additionalPersonnelArea') as UntypedFormControl;
  }

  public get additionalPersonnelFunction(): UntypedFormControl {
    return this.form.get('additionalPersonnelFunction') as UntypedFormControl;
  }

  public get assistedTeams(): string[] {
    const selectedTeams: string[] = [];
    for (const team of this.availableTeams) {
      if (this.form.get('assistedTeam' + team).value) {
        selectedTeams.push(team);
      }
    }
    if (!selectedTeams.length) {
      selectedTeams.push('None');
    }

    return selectedTeams;
  }

  public get birthdate(): UntypedFormControl {
    return this.form.get('birthdate') as UntypedFormControl;
  }

  public get dateOfJoiningClub(): UntypedFormControl {
    return this.form.get('dateOfJoiningClub') as UntypedFormControl;
  }

  public get dateOfJoiningFunction(): UntypedFormControl {
    return this.form.get('dateOfJoiningFunction') as UntypedFormControl;
  }

  public get firstName(): UntypedFormControl {
    return this.form.get('firstName') as UntypedFormControl;
  }

  public get goalKeeperLicense(): UntypedFormControl {
    return this.form.get('goalKeeperLicense') as UntypedFormControl;
  }

  public get lastName(): UntypedFormControl {
    return this.form.get('lastName') as UntypedFormControl;
  }

  public get personnelFunction(): UntypedFormControl {
    return this.form.get('personnelFunction') as UntypedFormControl;
  }

  public get primaryResponsible(): UntypedFormControl {
    return this.form.get('primaryResponsible') as UntypedFormControl;
  }

  public get professionalGroup(): UntypedFormControl {
    return this.form.get('professionalGroup') as UntypedFormControl;
  }

  public get professionalQualification(): UntypedFormControl {
    return this.form.get('professionalQualification') as UntypedFormControl;
  }

  public get trainerCertification(): UntypedFormControl {
    return this.form.get('trainerCertification') as UntypedFormControl;
  }

  @Input() public editMode: boolean;
  @Input() public form: UntypedFormGroup;
  @Input() public index: number;
  @Input() public type: 'Leadership' | 'Management' | 'SoccerTraining' | 'Support';
  @Output() public entryDeleted: EventEmitter<number>;
  @Output() public entrySaved: EventEmitter<number>;

  public faCalendar: unknown;
  public availableTeams: string[];
  public faSave: unknown;
  public faTrash: unknown;

  constructor() {
    this.entryDeleted = new EventEmitter<number>();
    this.entrySaved = new EventEmitter<number>();
    this.faCalendar = faCalendar;
    this.faSave = faSave;
    this.faTrash = faTrash;
    this.availableTeams = availableTeams;
  }

  public onClickDelete(): void {
    this.entryDeleted.emit(this.index);
  }

  public onClickSave(): void {
    this.entrySaved.emit(this.index);
  }

  public onProfessionalGroupChange(): void {
    if (this.professionalGroup.value === 'None') {
      this.professionalQualification.disable();
      this.professionalQualification.setValue('None');
    } else {
      this.professionalQualification.enable();
    }
  }
}
