<tr [formGroup]="form">
  <td i18n="@@roomType">
    {entry.roomType, select,
      Canteen {Kantine}
      ConferenceRoom {Besprechungsräume}
      Office {Büroräume}
      StudyRoom {Lernräume (inkl. Arbeitsplätze)}
      Workplace {Arbeitsplätze}
    }
  </td>
  <td>
    <ng-container *ngIf="!editMode">
      <ng-container *ngIf="!toggle">{{count.value}}</ng-container>
      <lsz-binary-display *ngIf="toggle" [value]="!!count.value"></lsz-binary-display>
    </ng-container>
    <ng-container *ngIf="editMode">
      <input *ngIf="!toggle" type="number" min="0" formControlName="count"/>
      <lsz-binary-select *ngIf="toggle" [form]="form" [name]="'count'"></lsz-binary-select>
      <div *ngIf="count.invalid && (count.dirty || count.touched)">
        <div class="input-error" *ngIf="count.errors.required">Eingabe wird benötigt.</div>
      </div>
    </ng-container>
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{remark.value}}</ng-container>
    <textarea *ngIf="editMode" formControlName="remark"></textarea>
  </td>
  <td *ngIf="editMode">
    <lsz-dataset-options [form]="form" [withoutDelete]="true" (save)="onClickSave()"></lsz-dataset-options>
  </td>
  <td>
    <lsz-last-edit [form]="form"></lsz-last-edit>
  </td>
</tr>
