import { Injectable } from '@angular/core';
import { InfrastructureService } from './infrastructure.service';
import { League } from '../models/models';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LeagueService extends InfrastructureService<League> {
  public readonly endpoint: string = '/Leagues';

  constructor(protected http: HttpClient) {
    super(http);
  }
}