import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbAccordionModule, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../shared.module';

import { HeaderComponent } from './header.component';
import { AdminDropdownComponent } from './partials/admin-dropdown/admin-dropdown.component';
import { BaselineSwitchComponent } from './partials/baseline-switch/baseline-switch.component';
import { BudgetGroupComponent } from './partials/budget-group/budget-group.component';
import { BudgetSelectComponent } from './partials/budget-select/budget-select.component';
import { FilterSwitchComponent } from './partials/filter-switch/filter-switch.component';
import { HeaderLogoComponent } from './partials/header-logo/header-logo.component';
import { LeagueSelectComponent } from './partials/league-select/league-select.component';
import { TemporalReferenceSwitchComponent } from './partials/temporal-reference-switch/temporal-reference-switch.component';
import { CoreModule } from '../../core/core.module';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderLogoComponent,
    BaselineSwitchComponent,
    BudgetGroupComponent,
    BudgetSelectComponent,
    FilterSwitchComponent,
    LeagueSelectComponent,
    TemporalReferenceSwitchComponent,
    LeagueSelectComponent,
    AdminDropdownComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgbTooltipModule,
    NgbModule,
    RouterModule,
    FormsModule,
    FlexModule,
    NgbAccordionModule,
    ReactiveFormsModule,
    CoreModule,
  ],
  exports: [
    HeaderComponent,
  ],
  providers: [
    DecimalPipe,
  ],
})
export class HeaderModule {
}
