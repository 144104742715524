<tr
  *ngIf="form"
  [formGroup]="form">
  <td>
    <img
      *ngIf="clubLogo"
      [src]="clubLogo"
      [alt]=""
      class="mr-1"
      style="width: 16px" />
    {{ name.value }}
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{ form.controls.leagueName.value }}</ng-container>
    <ng-container *ngIf="editMode">
      <select
        formControlName="league"
        required
        placeholder="Liga wählen..."
        i18n-placeholder="@@placeholderChooseLeague">
        <option
          *ngFor="let l of allLeagues"
          [value]="l.id">
          {{ l.name }}
        </option>
      </select>
    </ng-container>
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{ form.controls.budgetName.value }}</ng-container>
    <ng-container *ngIf="editMode">
      <select
        formControlName="budget"
        required
        placeholder="Budget wählen..."
        i18n-placeholder="@@placeholderChoosebudget">
        <option
          *ngFor="let b of allBudgets"
          [value]="b.id">
          {{ b.name }}
        </option>
      </select>
    </ng-container>
  </td>
  <td *ngIf="editMode">
    <lsz-dataset-options
      [form]="form"
      (save)="entrySaved.emit()"
      (delete)="entryDeleted.emit()"></lsz-dataset-options>
  </td>
  <td>
    <lsz-last-edit [form]="form"></lsz-last-edit>
  </td>
</tr>
