import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { SoccerClub } from '../../models/soccerClub';
import { SoccerClubService } from '../../services/soccer-club/soccer-club.service';
import { SetClub } from '../../store/actions/club.actions';
import { getClub } from '../../store/selectors/club.selectors';
import { AppState } from '../../store/states/app.state';
import { initialSoccerClubState } from '../../store/states/club.state';

@Component({
  selector: 'lsz-club-switch',
  templateUrl: './club-switch.component.html',
  styleUrls: ['./club-switch.component.css'],
})
export class ClubSwitchComponent implements OnInit, OnDestroy {
  @Input() public disabled: boolean;

  private destroy$: Subject<void>;
  private clubs: SoccerClub[] = [];
  private _selectedClub: SoccerClub;

  public get selectedClub(): SoccerClub {
    return this._selectedClub;
  }

  public set selectedClub(soccerClub: SoccerClub) {
    if (typeof soccerClub === 'string') {
      return;
    }
    // soccerClub.club.isAdmin is readonly for some reason.
    // create new object from club to be able to alter its attributes.
    let newClub: SoccerClub = {...soccerClub};

    if (newClub === null) {
      newClub = initialSoccerClubState;
      newClub.isAdmin = true;
    } else {
      newClub.isAdmin = false;
    }

    if (newClub.id !== this._selectedClub?.id) {
      this._selectedClub = soccerClub;
      this.store.dispatch(new SetClub((newClub)));
    }
  }

  constructor(private store: Store<AppState>,
              private service: SoccerClubService) {
    this.destroy$ = new Subject<void>();
  }

  public ngOnInit(): void {
    this.service.getSoccerClubs(false)?.pipe(
      takeUntil(this.destroy$),
    ).subscribe((clubs: SoccerClub[]) => {
      this.clubs = clubs;
    });

    this.store.pipe(
      takeUntil(this.destroy$),
      select(getClub),
    ).subscribe((soccerClub: SoccerClub) => {
      if (soccerClub.id) {
        this.selectedClub = soccerClub;
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public formatter = (soccerClub: SoccerClub): string => {
    return soccerClub.name;
  };

  public search = (text$: Observable<string>): Observable<SoccerClub[]> =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term: string) => {
        if (term.length < 2) {
          return [];
        } else {
          return this.clubs.filter((club: SoccerClub) => club.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10);
        }
      }),
    );
}
