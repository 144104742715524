<div class="form-wrapper">
  <lsz-edit-form-header
    [headerText]="'Fußballausbildung (Bitte nur Teilzeitmitarbeiter eintragen!)'"
    [numEntries]="numAvailables"
    [editMode]="editMode"
    (clickEdit)="onClickEdit()"
    (clickEditDone)="onClickEditDone()"
  ></lsz-edit-form-header>

  <div class="frozen-column-table-scroller">
    <table class="personnel-table small-table" [class.editMode]="editMode" [formGroup]="allForms">
      <thead>
      <tr>
        <th i18n="@@tableHeaderFunction">
          <div class="frozen-column-header">Funktion</div>
        </th>
        <th i18n="@@tableHeaderCLicense">C-Lizenz/keine FB-Lizenz</th>
        <th i18n="@@tableHeaderBLicense">B-Lizenz</th>
        <th i18n="@@tableHeaderBPlusLicense">B+-Lizenz</th>
        <th i18n="@@tableHeaderALicense">A-Lizenz</th>
        <th i18n="@@tableHeaderAPlusLicense">A+-Lizenz</th>
        <th i18n="@@tableHeaderProLicense">Pro-Lizenz</th>
        <th i18n="@@tableHeaderGoalkeeperTrainerLicense">Torwarttrainer-Lizenz</th>
        <th *ngIf="editMode" i18n="@@tableHeaderOptions" class="frozen-column-options column-options">
          <div class="frozen-column-header">Optionen</div>
        </th>
        <th>{{'lastEdit' | translate}}</th>
      </tr>
      </thead>
      <tbody
        *ngIf="sportAreaManager"
        lsz-part-time-employee-soccer-training-typed-form
        [entry]="sportAreaManager"
        (entrySaved)="onTypedEntrySaved(sportAreaManager, $event, 'sportAreaManager')"
        [editMode]="editMode"
        [form]="allForms.get('sportAreaManager')"
      ></tbody>
      <tbody
        *ngIf="teamTrainer"
        lsz-part-time-employee-soccer-training-typed-form
        [entry]="teamTrainer"
        (entrySaved)="onTypedEntrySaved(teamTrainer, $event, 'teamTrainer')"
        [editMode]="editMode"
        [form]="allForms.get('teamTrainer')"
      ></tbody>
      <tbody
        *ngIf="goalKeeperTrainer"
        lsz-part-time-employee-soccer-training-typed-form
        [entry]="goalKeeperTrainer"
        (entrySaved)="onTypedEntrySaved(goalKeeperTrainer, $event, 'goalKeeperTrainer')"
        [editMode]="editMode"
        [form]="allForms.get('goalKeeperTrainer')"
      ></tbody>
      <tbody
        *ngIf="specialTrainer"
        lsz-part-time-employee-soccer-training-typed-form
        [entry]="specialTrainer"
        (entrySaved)="onTypedEntrySaved(specialTrainer, $event, 'specialTrainer')"
        [editMode]="editMode"
        [form]="allForms.get('specialTrainer')"
      ></tbody>
      <tbody
        *ngIf="sportPsychologist"
        lsz-part-time-employee-soccer-training-typed-form
        [entry]="sportPsychologist"
        (entrySaved)="onTypedEntrySaved(sportPsychologist, $event, 'sportPsychologist')"
        [editMode]="editMode"
        [form]="allForms.get('sportPsychologist')"
      ></tbody>
      <tbody
        *ngIf="matchAnalysis"
        lsz-part-time-employee-soccer-training-typed-form
        [entry]="matchAnalysis"
        (entrySaved)="onTypedEntrySaved(matchAnalysis, $event, 'matchAnalysis')"
        [editMode]="editMode"
        [form]="allForms.get('matchAnalysis')"
      ></tbody>
      <tbody
        *ngIf="athleticTrainer"
        lsz-part-time-employee-soccer-training-typed-form
        [entry]="athleticTrainer"
        (entrySaved)="onTypedEntrySaved(athleticTrainer, $event, 'athleticTrainer')"
        [editMode]="editMode"
        [form]="allForms.get('athleticTrainer')"
      ></tbody>
      <tbody
        *ngIf="sportScientist"
        lsz-part-time-employee-soccer-training-typed-form
        [entry]="sportScientist"
        (entrySaved)="onTypedEntrySaved(sportScientist, $event, 'sportScientist')"
        [editMode]="editMode"
        [form]="allForms.get('sportScientist')"
      ></tbody>
      <tbody
        *ngIf="transitionCoordinator"
        lsz-part-time-employee-soccer-training-typed-form
        [entry]="transitionCoordinator"
        (entrySaved)="onTypedEntrySaved(transitionCoordinator, $event, 'transitionCoordinator')"
        [editMode]="editMode"
        [form]="allForms.get('transitionCoordinator')"
      ></tbody>
    </table>
  </div>
</div>
