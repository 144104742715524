<router-outlet></router-outlet>

<footer>
  <div id="footer-links">
    <a routerLink="/rechtliche-hinweise">Rechtliche Hinweise</a>
    <span>&middot;</span>
    <a routerLink="/datenschutz">Datenschutz</a>
    <span>&middot;</span>
    <a routerLink="/impressum">Impressum</a>
  </div>

  <div id="footer-copyright">
    <div>
      <img src="/assets/Deutsche_Fußball_Liga_logo.svg" alt="Logo der DFL" i18n-alt="@@logoDfl" class="logo-dfl"/>
      DFL Deutsche Fußball Liga e.V.
    </div>
    <span>&middot;</span>
    <span>&copy; 2022</span>
    <span>&middot;</span>
    <div>
      <img src="/assets/Deutscher_Fußball-Bund_logo.svg" alt="Logo des DFB" i18n-alt="@@logoDfb" class="logo-dfb"/>
      Deutscher Fußball-Bund e.V. (DFB)
    </div>
  </div>
</footer>
