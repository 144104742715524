import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { ApiInterceptor } from './interceptors/api.interceptor';
import { BreakpointsModule } from './breakpoints/breakpoints.module';

@NgModule({
  imports: [
    BreakpointsModule,
  ],
  exports: [
    BreakpointsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // import guard
    if (parentModule) {
      throw new Error(`${parentModule.toString()} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
