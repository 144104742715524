import { BaselineActions, EBaselineAction } from '../actions/baseline.actions';
import { Baseline, initialBaselineState } from '../states/baseline.state';

export const baselineReducers = (state: Baseline = initialBaselineState, action: BaselineActions): Baseline => {
  switch (action.type) {
    case EBaselineAction.SET_BASELNE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
