<header id="data-presenter-header">
  <div id="data-presenter-header-content" fxLayout="row" fxLayoutAlign="space-between center">

    <!-- Logo -->
    <div id="griditem1">
      <lsz-header-logo></lsz-header-logo>
    </div>

    <!-- Navigation -->
    <ng-container *ngIf="showMode">
      <div fxHide.tb id="griditem2" class="nav">
        <nav>
          <ul>
            <li *ngFor="let nav of navigationItems">
              <a [attr.data-title]="nav.title" [href]="nav.route">{{nav.title}}</a>
            </li>
          </ul>
        </nav>
      </div>

      <!-- Switches -->
      <div id="griditem3" fxLayout="row" fxLayoutGap="20px">
        <!-- out-commented until functionality is implemented -->
        <div id="temporal-options" fxFlex="130px">
          <lsz-temporal-reference-switch (switched)="onTemporalSwitch($event)"></lsz-temporal-reference-switch>
        </div>

        <div id="filter-options" fxFlex="135px">
          <lsz-filter-switch
            [disabled]="adminFilter.type !== 'club' || temporalReference === temporalReferenceEnum.HISTORY"
            [value]="filter"
            (switched)="onFilterSwitch($event)">
          </lsz-filter-switch>
        </div>

        <lsz-league-select *ngIf="showLeagueSelect" (selected)="onLeagueSelect($event)"></lsz-league-select>
        <lsz-budget-select *ngIf="showBudgetSelect" (selected)="onBudgetSelect($event)"></lsz-budget-select>
        <div id="display-options" fxFlex="110px">
          <lsz-baseline-switch></lsz-baseline-switch>
        </div>
      </div>
    </ng-container>

    <!-- User info -->
    <div id="griditem4">
      <div *ngIf="user$ | async as user" class="admin-dropdown-container">

        <lsz-admin-dropdown [hidden]="!(showMode && (canUseAdminDropdown$ | async))"
                            [user$]="user$"
                            [closeOnMouseLeave]="false"
                            [(show)]="showAdminDropdown"
                            (filterChange)="onAdminFilterSwitch($event)">
        </lsz-admin-dropdown>

        <div fxLayout="row" fxLayoutAlign="start center">
          <div class="club-logo-container">
            <img class="club-logo" *ngIf="soccerClub?.id && adminFilter.type === ADMIN_TYPE_CLUB"
                 [src]="soccerClub.logoImageUrl"
                 [alt]="'Vereinslogo von ' + soccerClub.name" />
          </div>
          <fa-icon *ngIf="showMode && (canUseAdminDropdown$ | async)"
                   (click)="toggleAdminDropdown(true)" class="fa-lg iconcolor clickable toggle"
                   [icon]="showAdminDropdown ? faChevronCircleUp : faChevronCircleDown"></fa-icon>

          <!-- If is admin, link to admin area -->
          <div *ngIf="(isAdmin$ | async); else notAdmin" fxLayout="row" fxLayoutAlign="start center">
            <a class="admin-link" routerLink="/admin">
              <span>{{ user?.username }}</span>
            </a>
          </div>
          <!-- Else, normal username -->
          <ng-template #notAdmin>
            <span>{{ user?.username }}</span>
          </ng-template>

        </div>

        <lsz-club-switch *ngIf="(canSwitchClub$ | async) && !showMode"></lsz-club-switch>
      </div>

      <a *ngIf="canEditData$ | async" routerLink="/edit-data" class="nav-button" style="margin-left: 20px;">
        <fa-icon [icon]="faPen" size="lg" placement="bottom" tooltipClass="tipclass"
                 ngbTooltip="Zur Dateneingabe"></fa-icon>
      </a>
      <a (click)="logout()" class="nav-button" style="margin-left: 10px;">
        <fa-icon [icon]="faSignOutAlt" size="lg" placement="bottom" tooltipClass="tipclass"
                 ngbTooltip="Ausloggen"></fa-icon>
      </a>
    </div>

  </div>

  <!-- Budget -->
  <div class="budget-groups-container" *ngIf="season">
    <div fxLayout="row" fxLayoutAlign="start center" class="budget-groups-container-flex">
      <div *ngIf="filter === 'budget'" fxFlex="66" fxLayoutAlign="end center">
        <lsz-budget-group [hideClubLogo]="adminFilter.type !== ADMIN_TYPE_CLUB" fxFlex="50"></lsz-budget-group>
      </div>
      <div [fxFlex]="filter === 'budget' ? '33' : '100'" fxLayout="row" fxLayoutAlign="end end" class="season-name">
        <span>Saison: {{ season?.name }}</span>
      </div>
    </div>
    <hr>
  </div>
</header>
