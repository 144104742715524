<div class="login" fxFlex>
  <img class="login-background" src="../../assets/login-background.jpg" alt="Login Background">


  <div class="login-div" fxLayout="column" fxLayoutAlign="start center">
    <div class="login-logo-div" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="75px">
      <img src="/assets/Deutsche_Fußball_Liga_logo.svg" alt="Logo der DFL" i18n-alt="@@logoDfl" class="logo-dfl"/>
      <img src="/assets/Deutscher_Fußball-Bund_logo.svg" alt="Logo des DFB" i18n-alt="@@logoDfb" class="logo-dfb"/>
    </div>

    <div class="login-title" fxLayout="column" fxLayoutAlign="center center">
      <span>SPORTDATENBANK</span>
      <span>LEISTUNGSZENTREN</span>
    </div>
    <button class="login-button" (click)="login()">Login</button>
  </div>
</div>
