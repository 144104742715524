import { Pipe, PipeTransform } from '@angular/core';
import { Job } from '../../../../model/job';


@Pipe({
  name: 'isKpiJobCreatedInSession',
})
export class IsKpiJobCreatedInSessionPipe implements PipeTransform {

  public transform(value: Job, sessionJobs: Job[]): boolean {
    return sessionJobs.some((kpiJob: Job) => kpiJob.id === value.id);
  }

}
