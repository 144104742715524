<div class="form-wrapper">
  <lsz-edit-form-header
    [headerText]="headerText"
    [numEntries]="entries.length"
    [editMode]="editMode"
    (clickEdit)="onClickEdit()"
    (clickEditDone)="onClickEditDone()"
  ></lsz-edit-form-header>

  <div class="frozen-column-table-scroller">
    <table class="personnel-table" [class.editMode]="editMode" [formGroup]="allForms">
      <thead>
      <tr>
        <th i18n="@@tableHeaderLastName">
          <div class="frozen-column-header cursor-pointer"
                (click)="sortColumn()">
            Name
            <fa-icon [icon]="sortIcon"></fa-icon>
          </div>
        </th>
        <th i18n="@@tableHeaderFirstName">Vorname</th>
        <th i18n="@@tableHeaderBirthdate">Geburtsdatum</th>
        <th i18n="@@tableHeaderFunction">Funktion</th>
        <th i18n="@@tableHeaderEmployedSince">beschäftigt seit</th>
        <th i18n="@@tableHeaderInFunctionSince">in der Funktion seit</th>
        <th i18n="@@tableHeaderAdditionalFunction">weitere Funktion im LZ</th>
        <th i18n="@@tableHeaderAdditionalArea">weitere Funktion im Lizenzbereich</th>
        <th i18n="@@tableHeaderTrainerCertification">Trainerschein</th>
        <th i18n="@@tableHeaderGoalkeepingCoachLicense" *ngIf="type === 'SoccerTraining'">TWT-Lizenz</th>
        <th i18n="@@tableHeaderVocationalEducation">Berufsausbildung (zusätzlich zu Trainerschein)</th>
        <th i18n="@@tableHeaderQualification">Abschluss</th>
        <th i18n="@@tableHeaderAssistedTeams">Betreute Mannschaften</th>
        <th *ngIf="editMode" i18n="@@tableHeaderOptions" class="frozen-column-options column-options">
          <div class="frozen-column-header">Optionen</div>
        </th>
        <th [class.editMode]="editMode" class="sticky-last-edit">
          <div class="frozen-column-header">{{'lastEdit' | translate}}</div>
        </th>
      </tr>
      </thead>
      <tbody
        lsz-full-time-employee-form
        *ngFor="let form of customEntryForms.controls; let i = index"
        (entrySaved)="onEntrySaved($event)"
        (entryDeleted)="onEntryDeleted($event)"
        [editMode]="editMode"
        formArrayName="customEntries"
        [index]="i"
        [form]="form"
        [type]="type"
      >
      </tbody>
      <tbody
        lsz-full-time-employee-form
        (entrySaved)="onClickSave()"
        (entryDeleted)="onClickCancel()"
        [form]="newEntryForm"
        [index]="-1"
        [editMode]="editMode"
        [type]="type"
        *ngIf="addMode"
      ></tbody>
    </table>

  </div>

  <button *ngIf="editMode && !addMode" type="button" (click)="onClickAdd()" i18n="@@dataButtonAdd" class="add-button">
    <fa-icon [icon]="faPlus"></fa-icon>
    Neuen Eintrag hinzufügen
  </button>
</div>
