import { Component } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Validators } from '../../validators';
import { SmallSizedFieldService } from '../../services/small-sized-field.service';
import { SmallSizedField } from '../../models/models';
import { ConfirmationDialogService } from '../../../shared/confirmation-dialog/services/confirmation-dialog.service';
import { BaseTableViewComponent } from '../../../shared/base-table-view/base-table-view.component';

@Component({
  selector: 'lsz-small-sized-field-form-collection',
  templateUrl: './small-sized-field-form-collection.component.html',
  styleUrls: ['./small-sized-field-form-collection.component.css'],
})
export class SmallSizedFieldFormCollectionComponent extends BaseTableViewComponent<SmallSizedField> {

  public get surface(): AbstractControl {
    return this.newEntryForm.get('surface');
  }

  public get length(): AbstractControl {
    return this.newEntryForm.get('length');
  }

  public get width(): AbstractControl {
    return this.newEntryForm.get('width');
  }

  public get isHeated(): AbstractControl {
    return this.newEntryForm.get('isHeated');
  }

  public get hasBoards(): AbstractControl {
    return this.newEntryForm.get('hasBoards');
  }

  public get isCompetitive(): AbstractControl {
    return this.newEntryForm.get('isCompetitive');
  }

  constructor(protected service: SmallSizedFieldService,
              protected confirmationDialog: ConfirmationDialogService) {
    super(service, confirmationDialog);
  }

  public createNewEntry(): void {
    const entry: Partial<SmallSizedField> = {
      size: {
        length: this.length.value as number,
        width: this.width.value as number,
      },
    };

    this.addEntry(entry);
  }

  public onUpdate(index: number): void {
    const form: UntypedFormGroup = this.customEntryForms.at(index) as UntypedFormGroup;
    const entry: Partial<SmallSizedField> = {
      size: {
        length: form.get('length').value as number,
        width: form.get('width').value as number,
      },
    };

    this.updateEntry(index, entry);
  }

  protected formGroup(entry?: SmallSizedField): UntypedFormGroup {
    return new UntypedFormGroup({
      name: new UntypedFormControl(entry?.name ? entry.name : '', [Validators.required, this.repeatedNameValidator()]),
      remark: new UntypedFormControl(entry?.remark ? entry.remark : ''),
      length: new UntypedFormControl(entry?.size.length, Validators.required),
      width: new UntypedFormControl(entry?.size.width, Validators.required),
      surface: new UntypedFormControl(entry?.surface, Validators.required),
      isHeated: new UntypedFormControl(entry?.isHeated, Validators.required),
      hasBoards: new UntypedFormControl(entry?.hasBoards, Validators.required),
      isCompetitive: new UntypedFormControl(entry?.isCompetitive, Validators.required),
      updatedAtUtc: new UntypedFormControl(entry?.updatedAtUtc),
      createdAtUtc: new UntypedFormControl(entry?.createdAtUtc),
    });
  }

  protected objectToForm(entry?: SmallSizedField): any {
    return {
      name: entry.name,
      remark: entry.remark,
      length: entry.size.length,
      width: entry.size.width,
      surface: entry.surface,
      isHeated: entry.isHeated,
      hasBoards: entry.hasBoards,
      isCompetitive: entry.isCompetitive,
      updatedAtUtc: entry?.updatedAtUtc,
      createdAtUtc: entry?.createdAtUtc,
    };
  }
}
