import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AuthGuard } from '../core/guards/auth.guard';

import { AuthCallbackComponent } from './auth-callback.component';

const routes: Routes = [
  {
    path: 'callback',
    component: AuthCallbackComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [AuthCallbackComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgxSpinnerModule,
  ],
})
export class AuthCallbackModule { }
