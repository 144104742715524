import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { DateValidator } from '../../../../shared/datepicker/date.validator';
import { FromDatepickerPipe } from '../../../../shared/pipes/from-datepicker.pipe';
import { ToDatepickerPipe } from '../../../../shared/pipes/to-datepicker.pipe';
import { FullTimeEmployee } from '../../../models/models';
import { FullTimeEmployeeFormEntry } from '../models/fullTimeEmployeeFormEntry';

@Injectable({
  providedIn: 'root',
})
export class FullTimeEmployeeFormService {

  public availableTeams: string[] = [
    'U12',
    'U13',
    'U14',
    'U15',
    'U16',
    'U17',
    'U1617',
    'U19',
    'U2123',
    'AllTeams',
    'PerformanceArea',
    'BuildUpArea',
    'FoundationArea',
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private toDatepickerPipe: ToDatepickerPipe,
    private fromDatepickerPipe: FromDatepickerPipe,
  ) {
  }

  public convertToFormEntries(fullTimeEmployees: FullTimeEmployee[]): FullTimeEmployeeFormEntry[] {
    return fullTimeEmployees.map((fullTimeEmployee: FullTimeEmployee) => this.convertToFormEntry(fullTimeEmployee));
  }

  public convertToFormEntry(fullTimeEmployee: FullTimeEmployee): FullTimeEmployeeFormEntry {
    return {
      ...fullTimeEmployee,
      createdAtUtc: fullTimeEmployee.createdAtUtc,
      updatedAtUtc: fullTimeEmployee.updatedAtUtc,
      birthdate: this.toDatepickerPipe.transform(fullTimeEmployee.birthdate),
      dateOfJoiningClub: this.toDatepickerPipe.transform(fullTimeEmployee.dateOfJoiningClub),
      dateOfJoiningFunction: this.toDatepickerPipe.transform(fullTimeEmployee.dateOfJoiningFunction),
      assistedTeamU12: fullTimeEmployee.assistedTeam.includes('U12'),
      assistedTeamU13: fullTimeEmployee.assistedTeam.includes('U13'),
      assistedTeamU14: fullTimeEmployee.assistedTeam.includes('U14'),
      assistedTeamU15: fullTimeEmployee.assistedTeam.includes('U15'),
      assistedTeamU16: fullTimeEmployee.assistedTeam.includes('U16'),
      assistedTeamU17: fullTimeEmployee.assistedTeam.includes('U17'),
      assistedTeamU1617: fullTimeEmployee.assistedTeam.includes('U1617'),
      assistedTeamU19: fullTimeEmployee.assistedTeam.includes('U19'),
      assistedTeamU2123: fullTimeEmployee.assistedTeam.includes('U2123'),
      assistedTeamAllTeams: fullTimeEmployee.assistedTeam.includes('AllTeams'),
      assistedTeamPerformanceArea: fullTimeEmployee.assistedTeam.includes('PerformanceArea'),
      assistedTeamBuildUpArea: fullTimeEmployee.assistedTeam.includes('BuildUpArea'),
      assistedTeamFoundationArea: fullTimeEmployee.assistedTeam.includes('FoundationArea'),
    };
  }

  public convertToFullTimeEmployee(original: Partial<FullTimeEmployee>,
                                   entry: Partial<FullTimeEmployeeFormEntry>): FullTimeEmployee {
    const selectedTeams: string[] = this.availableTeams.filter((team: string) => entry['assistedTeam' + team]);

    return {
      ...original,
      ...entry,
      firstName: entry.firstName.trim(),
      lastName: entry.lastName.trim(),
      birthdate: this.fromDatepickerPipe.transform(entry.birthdate),
      personnelFunction: entry.personnelFunction.trim(),
      dateOfJoiningClub: this.fromDatepickerPipe.transform(entry.dateOfJoiningClub),
      dateOfJoiningFunction: this.fromDatepickerPipe.transform(entry.dateOfJoiningFunction),
      additionalPersonnelFunction: entry.additionalPersonnelFunction.trim(),
      additionalPersonnelArea: entry.additionalPersonnelArea.trim(),
      trainerCertification: entry.trainerCertification.trim(),
      goalKeeperLicense: entry.goalKeeperLicense || 'None',
      professionalGroup: entry.professionalGroup.trim(),
      professionalQualification: entry.professionalQualification.trim(),
      assistedTeam: selectedTeams.length ? selectedTeams.join(', ') : 'None',
    } as FullTimeEmployee;
  }

  public initEntry(entry?: Partial<FullTimeEmployeeFormEntry>): UntypedFormGroup {
    const newEntry: UntypedFormGroup = this.fb.group({
      createdAtUtc: [''],
      updatedAtUtc: [''],
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      birthdate: [null, Validators.compose([Validators.required, DateValidator.dateValidator])],
      personnelFunction: ['', Validators.required],
      dateOfJoiningClub: ['', Validators.compose([Validators.required, DateValidator.dateValidator])],
      dateOfJoiningFunction: ['', Validators.compose([Validators.required, DateValidator.dateValidator])],
      additionalPersonnelFunction: ['', Validators.required],
      additionalPersonnelArea: ['', Validators.required], // weitere Funktion im Lizenzbereich
      trainerCertification: ['', Validators.required],
      goalKeeperLicense: [''],
      professionalGroup: ['', Validators.required],
      professionalQualification: ['', Validators.required],
      assistedTeamU12: [false],
      assistedTeamU13: [false],
      assistedTeamU14: [false],
      assistedTeamU15: [false],
      assistedTeamU16: [false],
      assistedTeamU17: [false],
      assistedTeamU1617: [false],
      assistedTeamU19: [false],
      assistedTeamU2123: [false],
      assistedTeamAllTeams: [false],
      assistedTeamPerformanceArea: [false],
      assistedTeamBuildUpArea: [false],
      assistedTeamFoundationArea: [false],
    });
    if (entry) {
      newEntry.patchValue(entry);
      if (entry.professionalGroup === 'None') {
        newEntry.get('professionalQualification').disable();
      }
    }

    return newEntry;
  }

  public initForm(entries?: Partial<FullTimeEmployeeFormEntry>[]): UntypedFormGroup {
    const newForm: UntypedFormGroup = this.fb.group({
      customEntries: this.fb.array([]),
      newEntry: this.initEntry(),
    });
    const customEntries: UntypedFormArray = newForm.get('customEntries') as UntypedFormArray;
    for (const entry of entries || []) {
      customEntries.push(this.initEntry(entry));
    }

    return newForm;
  }

  public initFormWithFullTimeEmployees(entries: FullTimeEmployee[]): UntypedFormGroup {
    const formEntries: FullTimeEmployeeFormEntry[] = this.convertToFormEntries(entries);

    return this.initForm(formEntries);
  }
}
