import { Pipe, PipeTransform } from '@angular/core';
import { JobState } from '../../../../model/job';


const TRANSLATIONS: { [key: string]: string } = {
  None: '',
  Scheduled: 'eingeplant',
  InProgress: 'läuft',
  Succeeded: 'erfolgreich',
  Failed: 'fehlgeschlagen',
};

@Pipe({
  name: 'translatedJobState',
})
export class TranslatedJobStatePipe implements PipeTransform {

  public transform(value: JobState): unknown {
    return TRANSLATIONS[value];
  }
}
