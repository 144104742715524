import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Category } from '../../models/category';
import { Detail } from '../../models/detailCalculations';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AllClubsTableModalComponent } from './partials/all-clubs-table-modal/all-clubs-table-modal.component';
import { SeasonKpi, SoccerClubKpi } from '../../models/kpis';
import { KpiService } from '../../services/kpi.service';
import { map } from 'rxjs/operators';
import { User } from '../../../services/user/model/user';
import { RoleGuard } from '../../../core/guards/role.guard';
import { CAN_SELECT_LEAGUE } from '../../../core/permissionGroups';
import { UserService } from '../../../services/user/user.service';
import { Observable } from 'rxjs';
import { SoccerClubService } from '../../../services/soccer-club/soccer-club.service';
import { SoccerClub } from '../../../models/soccerClub';

@Component({
  selector: 'lsz-category-kpi-details',
  templateUrl: './category-kpi-details.component.html',
  styleUrls: ['./category-kpi-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryKpiDetailsComponent {
  @Input() public category: Category;
  @Input() public criterion: string;

  @Input() public detail: Detail;

  public isDFL: Observable<boolean>;

  constructor(private modalService: NgbModal,
              private userService: UserService,
              private soccerClubService: SoccerClubService,
              private kpiService: KpiService) {
    this.isDFL = this.userService.userInformation$.pipe(
      map((user: User) =>
        RoleGuard.isAllowed(CAN_SELECT_LEAGUE, user.roles)),
    );
  }

  public showAllClubs(): void {
    this.soccerClubService.getSoccerClubs().subscribe((soccerClubs: SoccerClub[]) => {
      const modalRef: NgbModalRef = this.modalService.open(AllClubsTableModalComponent, {
        centered: true,
        windowClass: 'all-clubs-modal',
      });

      const seasonKpi: SeasonKpi = this.kpiService.seasonKpi.find((seasonKpi: SeasonKpi) => seasonKpi.kpiName === this.detail.name);
      const soccerClubCollection: SoccerClubKpi[] = seasonKpi.soccerClubKpiCollection;

      const mergedSoccerClubs: SoccerClub[] = soccerClubs.map((soccerClub: SoccerClub) => {
        const club: SoccerClubKpi = soccerClubCollection.find((soccerClubCollection: SoccerClubKpi) => soccerClubCollection.soccerClubId === soccerClub.id);
        return { ...club, ...soccerClub };
      });
      modalRef.componentInstance.clubs = mergedSoccerClubs;
      modalRef.componentInstance.kpiName = this.detail.name;
      modalRef.componentInstance.kpiType = seasonKpi.kpiType;
    });


  }
}
