import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ErrorDialogComponent } from '../../data-editor/modules/data-editor-shared/components/error-dialog/error-dialog.component';
import { StsAuthService } from 'sts-auth';
import { AuthResult } from 'sts-auth/lib/models/authResult';

const UNAUTHORIZED_ERROR: number = 401;
const CONFLICT_ERROR: number = 409;

/**
 * Prefixes all requests with `environment.serverUrl`.
 */
@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  private accessToken: string;

  constructor(private authService: StsAuthService,
              private modalService: NgbModal) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.accessToken = this.authService.accessToken;

    if (this.accessToken) {
      return this.handleRequest(request, next);
    }

    return this.authService.isAuthenticated$.pipe(
      switchMap((accessToken: string) => {
        if (accessToken) {
          this.accessToken = accessToken;

          return this.handleRequest(request, next);
        }
      }),
    );

  }

  private cloneRequest(request: HttpRequest<any>): HttpRequest<any> {
    const url: string = request.url.includes('.json') ? request.url : environment.serverUrl + request.url;
    return request.clone({
      url,
      headers: this.setHeaders(request),
    });
  }

  private handleRefreshToken(request: HttpRequest<any>,
                             next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.refreshedAuthResult$.pipe(
      switchMap((authResult: AuthResult) => {
        if (authResult) {
          this.accessToken = authResult.accessToken;

          return next.handle(this.cloneRequest(request));
        }
      }),
    );
  }

  private handleRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.cloneRequest(request)).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === UNAUTHORIZED_ERROR && this.accessToken) {
          return this.handleRefreshToken(request, next);
        }

        if (request.method === 'PUT' || request.method === 'POST' || request.method === 'DELETE') {
          const modalRef: NgbModalRef = this.modalService.open(ErrorDialogComponent);
          if (request.method !== 'DELETE' && error.status === CONFLICT_ERROR &&
            ((error.error.message as string) || '').includes('not unique')) {
            modalRef.componentInstance.infoText =
              'Bitte überprüfe deine Eingaben, es gibt bereits einen Eintrag mit diesem Namen.';
          }
        }

        return throwError(error);

      }),
    );
  }

  private setHeaders(request: HttpRequest<any>): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();

    headers = headers.set('Authorization', `Bearer ${this.accessToken}`);
    if (!request.url.includes('import')) {
      headers = headers.set('Content-Type', 'application/json');
    }
    if (request.url.includes('import') && request.url.includes('reason')) {
      headers = headers.set('Accept', 'application/json');
    }

    return headers;
  }
}
