import { Component, Input } from '@angular/core';
import { faExclamation, faThumbsUp } from '@fortawesome/free-solid-svg-icons';

import { CategoryRating } from '../../models/categoryRater';

@Component({
  selector: 'lsz-category-rating',
  templateUrl: './category-rating.component.html',
  styleUrls: ['./category-rating.component.scss'],
})
export class CategoryRatingComponent {
  @Input() public rating: CategoryRating;

  public faExclamation: unknown;
  public faThumbsUp: unknown;
  public negativeRating: number;
  public neutralRating: number;
  public noneRating: number;
  public positiveRating: number;

  constructor() {
    this.faExclamation = faExclamation;
    this.faThumbsUp = faThumbsUp;
    this.negativeRating = CategoryRating.Negative;
    this.neutralRating = CategoryRating.Neutral;
    this.noneRating = CategoryRating.None;
    this.positiveRating = CategoryRating.Positive;
  }

}
