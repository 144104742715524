import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'lsz-league-select',
  templateUrl: './league-select.component.html',
  styleUrls: ['./league-select.component.css'],
})
export class LeagueSelectComponent {
  @Output() public selected: EventEmitter<string>;

  constructor() {
    this.selected = new EventEmitter<string>();
  }

  public selectLeague(league: string): void {
    this.selected.emit(league);
  }
}
