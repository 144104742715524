<h4 i18n="@@headerCategoryFullTimeEmployees" id="personnel-fulltimeemployees">Vollzeitmitarbeiter</h4>

<lsz-full-time-employees-collection
  headerText="Leitung"
  [soccerClub]="soccerClub"
  [season]="season"
  [entries]="fullTimeEmployeesLeadership$ | async"
  type="Leadership">
</lsz-full-time-employees-collection>

<lsz-full-time-employees-collection
  headerText="Management & Administration"
  [soccerClub]="soccerClub"
  [season]="season"
  [entries]="fullTimeEmployeesManagement$ | async"
  type="Management">
</lsz-full-time-employees-collection>

<lsz-full-time-employees-collection
  headerText="Fußballausbildung"
  [soccerClub]="soccerClub"
  [season]="season"
  [entries]="fullTimeEmployeesSoccerTraining$ | async"
  type="SoccerTraining">
</lsz-full-time-employees-collection>

<lsz-full-time-employees-collection
  headerText="Unterstützung"
  [soccerClub]="soccerClub"
  [season]="season"
  [entries]="fullTimeEmployeesSupport$ | async"
  type="Support">
</lsz-full-time-employees-collection>
