import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DatabaseSystem } from '../models/models';

import { InfrastructureService } from './infrastructure.service';

@Injectable({
  providedIn: 'root',
})
export class DatabaseSystemService extends InfrastructureService<DatabaseSystem> {
  protected readonly endpoint: string = '/DatabaseSystems';

  constructor(protected http: HttpClient) {
    super(http);
  }
}
