import { AsyncValidatorFn, UntypedFormControl, ValidatorFn, Validators as V } from '@angular/forms';

// angular doesn't trim form control values automatically.
// see https://github.com/angular/angular/issues/8503
export class Validators {
  public static required(control: UntypedFormControl): { required: boolean } {
    if ((!control.value && control.value !== false && control.value !== '0' && control.value !== 0)
      || (typeof control.value === 'string' && !control.value.trim() && control.value.trim() !== '0')
    ) {
      return {
        required: true,
      };
    }

    return null;
  }

  public static minLength(length: number): ValidatorFn {
    return (control: UntypedFormControl) => {
      if (!control.value || typeof control.value === 'string' && control.value.trim().length < length) {
        return {
          minlength: true,
        };
      }

      return null;
    };
  }

  public static maxLength(length: number): ValidatorFn {
    return (control: UntypedFormControl) => {
      if (control.value && typeof control.value === 'string' && control.value.trim().length > length) {
        return {
          maxlength: true,
        };
      }

      return null;
    };
  }

  public static pattern(pattern: string): ValidatorFn {
    return V.pattern(pattern);
  }

  public static minAmount(amount: number): ValidatorFn {
    return (control: UntypedFormControl) => {
      if (control.value && control.value.length < amount) {
        return {
          minamount: true,
        };
      }

      return null;
    };
  }

  public static maxAmount(amount: number): ValidatorFn {
    return (control: UntypedFormControl) => {
      if (control.value && control.value.length > amount) {
        return {
          maxamount: true,
        };
      }

      return null;
    };
  }

  public static compose(validators: ValidatorFn[]): ValidatorFn {
    return V.compose(validators);
  }

  public static composeAsync(validators: AsyncValidatorFn[]): AsyncValidatorFn {
    return V.composeAsync(validators);
  }
}
