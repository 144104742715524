import { Job } from '../../../model/job';

export interface DataImportJob extends Job {
  importer: string;
}

export enum DataImporterEnum {
  SOCCER_CLUBS = 'SoccerClubs',
  COLLEGES = 'Colleges',
  EFFECTIVITY = 'SoccerPlayerEffectiveness',
}

export interface ImportError {
  detail: string;
  requestId: string;
  status: number;
  title: string;
  traceId: string;
  type: string;
}