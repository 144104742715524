<div class="modal-container">
  <h1 class="title">Detailansicht |
    <lsz-category-kpi-name [name]="kpiName"></lsz-category-kpi-name>
  </h1>
  <button class="lsz-button dismiss" (click)="dismiss()">
    <fa-icon [icon]="faTimes"></fa-icon>
  </button>
  <lsz-aggregation-table *ngIf="kpiType === kpiTypeEnum.Aggregation"
                         [mappedClubs]="mappedClubs"
                         [mappedClubKeys]="mappedClubKeys"
                         [kpiName]="kpiName">
  </lsz-aggregation-table>

  <lsz-binary-table *ngIf="kpiType === kpiTypeEnum.Binary"
                    [mappedClubs]="mappedClubs"
                    [mappedClubKeys]="mappedClubKeys">
  </lsz-binary-table>
</div>
