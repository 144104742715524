import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import * as Chart from 'chart.js';

@Component({
  selector: 'lsz-category-kpi-history-tooltip',
  templateUrl: './category-kpi-history-tooltip.component.html',
  styleUrls: ['./category-kpi-history-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryKpiHistoryTooltipComponent {
  @Input() public pointStyleLeague: string[];
  @Input() public pointStyleOwn: string;
  @Input() public tooltip: Chart.TooltipModel<keyof Chart.ChartTypeRegistry>;
}
