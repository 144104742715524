<header id="data-presenter-header">
  <div id="data-presenter-header-content">
    <div id="griditem1">
      <a routerLink="/">
        <img src="/assets/Deutsche_Fußball_Liga_logo.svg" alt="Logo der DFL" i18n-alt="@@logoDfl" class="logo-dfl"/>
        <img src="/assets/Deutscher_Fußball-Bund_logo.svg" alt="Logo des DFB" i18n-alt="@@logoDfb" class="logo-dfb"/>
      </a>
      <div i18n="@@headerAppTitle" class="header-title" fxLayout="column" fxLayoutGap="2px">
        <span>Sportdatenbank</span><br>
        <span>Leistungszentren</span>
      </div>
    </div>
    <div id="griditem2">

    </div>
    <div id="griditem3">

    </div>
    <div id="griditem4">
    </div>
  </div>
</header>
<div class="legal-notice-content">
  <h2>Rechtliche Hinweise</h2>

  <p>Der DFL Deutsche Fußball Liga e.V. („DFL e.V.“) und der Deutsche Fußball-Bund e.V. („DFB e.V.“) freuen sich über Ihr Interesse und den Besuch der Seiten der LZ Vergleichsdatenbank unter lz-vergleichsdatenbank.de sowie deren Unterdomänen ("Portal").</p>

  <h3>1. Haftungsbeschränkung</h3>
  <p>Die Inhalte des Portals wurden mit größtmöglicher Sorgfalt erstellt. DFL e.V. und DFB e.V. übernehmen jedoch keine Gewähr für die Richtigkeit, Vollständigkeit, Aktualität oder sonstige Qualität der bereitgestellten Inhalte. Die Nutzung der Inhalte des Portals erfolgt auf eigene Gefahr des Nutzers. Namentlich gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors und nicht immer die Meinung des DFL e.V. und des DFB e.V. wieder. Mit der reinen Nutzung des Portals kommt keinerlei Vertragsverhältnis zwischen dem Nutzer sowie dem DFL e.V. und dem DFB e.V. zustande. Die Haftung für Schäden, gleich aus welchem Rechtsgrund und welcher Art, wird ausgeschlossen, soweit diese nicht auf Vorsatz oder grober Fahrlässigkeit beruhen.</p>

  <h3>2. Geistiges Eigentum, Urheber- und Schutzrechte</h3>
  <p>Alle Rechte sind vorbehalten. Das auf bzw. in diesem Portal enthaltene geistige Eigentum ist geschützt. Mit diesem Portal wird dem Nutzer keine Lizenz zur Nutzung oder Verwertung des geistigen Eigentums des DFL e.V. und des DFB e.V. eingeräumt. Insbesondere unterliegen die auf diesem Portal veröffentlichten Inhalte, wie z.B. Text, Bilder, Grafiken, Sound, Animationen und Videos, sowie deren Anordnung dem deutschen Urheber- und Leistungsschutzrecht sowie anderen Schutzgesetzen. Jede vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene Nutzung oder Verwertung bedarf der vorherigen schriftlichen Zustimmung des DFL e.V. und des DFB e.V. oder des jeweiligen Rechteinhabers. Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet. Lediglich die Herstellung von Kopien und Downloads für den persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt. Die Darstellung dieses Portals in fremden Frames ist nur mit schriftlicher Erlaubnis zulässig. Der Nutzer wird darauf hingewiesen, dass die auf bzw. in dem Portal enthaltenen Inhalte teilweise den Urheberrechten Dritter unterliegen. Sollte der Nutzer auf eine Rechtsverletzung aufmerksam werden, bitten der DFL e.V. und der DFB e.V. um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden DFL e.V. und DFB e.V. derartige Inhalte umgehend entfernen.</p>

  <h3>3. Marken- und Kennzeichenrechte</h3>
  <p>Sämtliche auf bzw. in diesem Portal genannten oder abgebildeten Marken- und Kennzeichen des DFL e.V. und des DFB e.V. sind rechtlich geschützt. Dies gilt insbesondere für Marken, Logos und Embleme. Auch alle übrigen innerhalb des Portals genannten oder abgebildeten Marken- und Kennzeichen Dritter unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Marken- und Kennzeichenrechts der jeweiligen Inhaber. Allein die Nennung oder Abbildung bedeutet ausdrücklich nicht, dass solche Marken- oder Warenzeichen nicht durch die Rechte Dritter geschützt sind.</p>

  <h3>4. Verweise und Links</h3>
  <p>Dieses Portal enthält Verknüpfungen zu Websites Dritter ("externe Links"). Diese Websites unterliegen der Haftung der jeweiligen Betreiber. DFL e.V. und DFB e.V. haben bei der erstmaligen Verknüpfung der externen Links die fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich. DFL e.V. und DFB e.V. haben keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der verknüpften Seiten. Das Setzen von externen Links bedeutet nicht, dass sich DFL e.V. und DFB e.V. die hinter dem Verweis oder Link liegenden Inhalte zu Eigen machen. Eine ständige Kontrolle der externen Links ist für DFL e.V. und DFB e.V. ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen werden jedoch derartige externe Links unverzüglich gelöscht.</p>

  <h3>5. Leistungen Dritter</h3>
  <p>Werden auf dem Portal von Dritten Waren oder Dienstleistungen angeboten, muss der Nutzer im Fall der Bestellung deren vertragliche Bedingungen akzeptieren, da für das Vertragsverhältnis zwischen dem Nutzer und dem Dritten ausschließlich die vertraglichen Bedingungen des Dritten gelten. Insbesondere ist es über das Portal möglich, Waren und Dienstleistungen von Partnern des DFL e.V. und des DFB e.V. bei diesen Partnern zu bestellen oder an deren Gewinnspielen oder sonstigen Aktionen teilzunehmen. DFL e.V. und DFB e.V. sind in diesen Fällen ausschließlich als Vermittler tätig. Die Vermittlung erfolgt z.B. über einen Link, der im Rahmen der Angebote der Partner auf dem Portal geschaltet ist und auf die eigene Website der Partner zu deren konkretem Angebot führt. Fragen und Reklamationen sind daher direkt an den jeweiligen Partner zu richten, dessen vertragliche Bedingungen für die jeweilige Bestellung bzw. Teilnahme gelten.</p>

  <h3>6. Datenschutz</h3>
  <p>Die Bestimmungen zum Datenschutz (Datenschutzerklärung) können unter folgendem <a routerLink="/datenschutz">Link</a> eingesehen werden.</p>
</div>
