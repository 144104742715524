import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CommonCooperation } from '../models/models';

import { InfrastructureService } from './infrastructure.service';

@Injectable({
  providedIn: 'root',
})
export class CommonCooperationService extends InfrastructureService<CommonCooperation> {
  protected readonly endpoint: string = '/CommonCooperations';

  constructor(protected http: HttpClient) {
    super(http);
  }
}
