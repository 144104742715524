import { Component, Input } from '@angular/core';

import { Detail } from '../../models/detailCalculations';

@Component({
  selector: 'lsz-category-summary',
  templateUrl: './category-summary.component.html',
  styleUrls: ['./category-summary.component.scss'],
})
export class CategorySummaryComponent {

  @Input() public baseline: string;
  @Input() public detail: Detail;

}
