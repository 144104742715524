import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'lsz-budget-select',
  templateUrl: './budget-select.component.html',
  styleUrls: ['./budget-select.component.css'],
})
export class BudgetSelectComponent {
  @Output() public selected: EventEmitter<string>;

  constructor() {
    this.selected = new EventEmitter<string>();
  }

  public selectBudget(budget: string): void {
    this.selected.emit(budget);
  }
}
