import { Component, Input } from '@angular/core';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'lsz-binary-display',
  templateUrl: './binary-display.component.html',
  styleUrls: ['./binary-display.component.css'],
})
export class BinaryDisplayComponent {
  @Input() public value: boolean;

  public iconNo: unknown;
  public iconYes: unknown;

  constructor() {
    this.iconNo = faTimes;
    this.iconYes = faCheck;
  }
}
