<h4>Aktive Teams</h4>

<select
  class="lsz-select mb-3"
  size="1"
  [formControl]="formControlSeason">
  <option
    *ngFor="let season of seasons"
    [value]="season.id">
    {{ season.name }}
  </option>
</select>

<div class="form-wrapper">
  <lsz-edit-form-header
    [headerText]="'Teams'"
    [numEntries]="entries.length"
    [editMode]="editMode"
    (clickEdit)="onClickEdit()"
    (clickEditDone)="onClickEditDone()"></lsz-edit-form-header>

  <table
    [class.editMode]="editMode"
    [formGroup]="allForms">
    <thead>
      <tr>
        <th i18n="@@tableHeaderTeamName">Teamname</th>
        <th i18n="@@tableHeaderLeague">Liga</th>
        <th i18n="@@tableHeaderBudget">Budget</th>
        <th
          *ngIf="editMode"
          i18n="@@tableHeaderOptions"
          class="column-options">
          Optionen
        </th>
        <th>{{ 'lastEdit' | translate }}</th>
      </tr>
    </thead>
    <tbody
      lsz-active-teams-form
      *ngFor="let form of customEntryForms.controls; let i = index"
      (entrySaved)="onUpdate(i)"
      (entryDeleted)="onDelete(i)"
      [editMode]="editMode"
      formArrayName="customEntries"
      [index]="i"
      [clubLogo]="clubLogos.get(form.value.name)"
      [form]="form"
      [allLeagues]="allLeagues"
      [allBudgets]="allBudgets">
    </tbody>
    <tbody
      *ngIf="addMode"
      formGroupName="newEntry">
      <tr>
        <td>
          <select
            *ngIf="filteredClubs.length"
            formControlName="name"
            required
            placeholder="Team wählen..."
            i18n-placeholder="@@placeholderChooseTeam">
            <option
              *ngFor="let club of filteredClubs"
              [value]="club.id">
              {{ club.name }}
            </option>
          </select>

          <div *ngIf="!filteredClubs.length">Alle Teams zugewiesen.</div>

          <ng-container *ngIf="name.invalid && (name.dirty || name.touched)">
            <div
              class="input-error"
              *ngIf="name.errors.required">
              Teamname wird benötigt.
            </div>
          </ng-container>
        </td>
        <td>
          <select
            *ngIf="filteredClubs.length"
            formControlName="league"
            required
            placeholder="Liga wählen..."
            i18n-placeholder="@@placeholderChooseLeague">
            <option
              *ngFor="let l of allLeagues"
              [value]="l.id">
              {{ l.name }}
            </option>
          </select>

          <ng-container *ngIf="league.invalid && (league.dirty || league.touched)">
            <div
              class="input-error"
              *ngIf="league.errors.required">
              Liga wird benötigt.
            </div>
          </ng-container>
        </td>
        <td>
          <select
            *ngIf="filteredClubs.length"
            formControlName="budget"
            required
            placeholder="Liga wählen..."
            i18n-placeholder="@@placeholderChoosebudget">
            <option
              *ngFor="let b of allBudgets"
              [value]="b.id">
              {{ b.name }}
            </option>
          </select>

          <ng-container *ngIf="budget.invalid && (budget.dirty || budget.touched)">
            <div
              class="input-error"
              *ngIf="budget.errors.required">
              Budget wird benötigt.
            </div>
          </ng-container>
        </td>
        <td>
          <div
            class="form-options"
            fxLayout="row"
            fxLayoutGap="5px"
            fxLayoutAlign="center center">
            <button
              type="button"
              [disabled]="!newEntryForm.valid"
              (click)="createNewEntry()"
              class="nav-button"
              [ngClass]="{ notice: newEntryForm.valid }">
              <fa-icon [icon]="faSave"></fa-icon>
            </button>
            <button
              type="button"
              (click)="exitAddMode()"
              class="nav-button">
              <fa-icon [icon]="faTrash"></fa-icon>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <button
    *ngIf="editMode && !addMode"
    type="button"
    (click)="addNewRow()"
    i18n="@@dataButtonAdd"
    class="add-button">
    <fa-icon [icon]="faPlus"></fa-icon>
    Neuen Eintrag hinzufügen
  </button>
</div>
