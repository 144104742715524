<tr [formGroup]="form">
  <td>
    <ng-container *ngIf="!editMode">{{name.value}}</ng-container>
    <input *ngIf="editMode" type="text" formControlName="name" required/>
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{birthdate.value}}</ng-container>
    <ng-container *ngIf="editMode">
      <input ngbDatepicker #dpBirthdate="ngbDatepicker" type="text" formControlName="birthdate" required/>
      <button (click)="dpBirthdate.toggle()" type="button">
        <fa-icon [icon]="faCalendar"></fa-icon>
      </button>
      <div *ngIf="birthdate.invalid && (birthdate.dirty || birthdate.touched)">
        <div class="input-error" *ngIf="birthdate.errors.required">Angabe wird benötigt.</div>
        <div class="input-error" *ngIf="birthdate.errors.dateValidator">Fehlerhaftes Datum</div>
      </div>
    </ng-container>
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{personnelFunction.value}}</ng-container>
    <ng-container *ngIf="editMode">
      <input type="text" formControlName="personnelFunction" required/>
      <div *ngIf="personnelFunction.invalid && (personnelFunction.dirty || personnelFunction.touched)">
        <div class="input-error" *ngIf="personnelFunction.errors.required">Angabe wird benötigt.</div>
      </div>
    </ng-container>
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{dateOfJoiningClub.value | yearsAgo}}</ng-container>
    <ng-container *ngIf="editMode">
      <input ngbDatepicker #dpDateOfJoiningClub="ngbDatepicker" type="text" formControlName="dateOfJoiningClub" required/>
      <button (click)="dpDateOfJoiningClub.toggle()" type="button">
        <fa-icon [icon]="faCalendar"></fa-icon>
      </button>
      <div *ngIf="dateOfJoiningClub.invalid && (dateOfJoiningClub.dirty || dateOfJoiningClub.touched)">
        <div class="input-error" *ngIf="dateOfJoiningClub.errors.required">Angabe wird benötigt.</div>
        <div class="input-error" *ngIf="dateOfJoiningClub.errors.dateValidator">Fehlerhaftes Datum</div>
        <div class="input-error" *ngIf="dateOfJoiningClub.errors.dateValidatorAtLeastThreeYears">Mind. 3 Jahre
          Zugehörigkeit.
        </div>
      </div>
    </ng-container>
  </td>
  <td *ngIf="editMode">
    <lsz-dataset-options [form]="form" (save)="entrySaved.emit()" (delete)="entryDeleted.emit()"></lsz-dataset-options>
  </td>
  <td>
    <lsz-last-edit [form]="form"></lsz-last-edit>
  </td>
</tr>
