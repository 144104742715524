import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { BinaryDisplayComponent } from './components/binary-display/binary-display.component';
import { BinarySelectComponent } from './components/binary-select/binary-select.component';
import { DatasetOptionsComponent } from './components/dataset-options/dataset-options.component';
import { EditFormHeaderComponent } from './components/edit-form-header/edit-form-header.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';

@NgModule({
  declarations: [
    EditFormHeaderComponent,
    BinaryDisplayComponent,
    BinarySelectComponent,
    DatasetOptionsComponent,
    ErrorDialogComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    FlexModule,
  ],
  exports: [
    EditFormHeaderComponent,
    BinaryDisplayComponent,
    BinarySelectComponent,
    DatasetOptionsComponent,
    ErrorDialogComponent,
  ],
})
export class DataEditorSharedModule {
}
