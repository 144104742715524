import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { BudgetGroup } from '../models/budgetGroup';

@Injectable({
  providedIn: 'root',
})
export class BudgetGroupService {

  public budgetGroups$: BehaviorSubject<BudgetGroup[]>;

  constructor() {
    this.budgetGroups$ = new BehaviorSubject([
      { name: 'A', max: 800000 },
      { name: 'B', min: 800000, max: 1400000 },
      { name: 'C', min: 1400000, max: 2900000 },
      { name: 'D', min: 2900000, max: 6300000 },
      { name: 'E', min: 6300000 },
    ]);
  }
}
