import { SoccerClub } from '../../models/soccerClub';

export const initialSoccerClubState: SoccerClub = {
  id: null,
  name: null,
  slug: null,
  league: null,
  budget: null,
  logoImageUrl: null,
  season: null,
  isAdmin: false,
  threeLetterCode: null,
};
