import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BaseEditFormComponent } from '../../../shared/base-edit-form/base-edit-form.component';

@Component({
  selector: 'tbody[lsz-small-sized-field-form]',
  templateUrl: './small-sized-field-form.component.html',
  styleUrls: ['./small-sized-field-form.component.css'],
})
export class SmallSizedFieldFormComponent extends BaseEditFormComponent {

  public get surface(): AbstractControl {
    return this.form.get('surface');
  }

  public get length(): AbstractControl {
    return this.form.get('length');
  }

  public get width(): AbstractControl {
    return this.form.get('width');
  }

  public get isHeated(): AbstractControl {
    return this.form.get('isHeated');
  }

  public get hasBoards(): AbstractControl {
    return this.form.get('hasBoards');
  }

  public get isCompetitive(): AbstractControl {
    return this.form.get('isCompetitive');
  }

  constructor() {
    super();
  }
}
