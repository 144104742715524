<div class="headline">Zeitlicher&nbsp;Bezug
  <ng-template #tipContent>Auswahlregler zur Anpassung des zeitlichen Bezugs (Aktuell, Historie)</ng-template>
  <fa-icon class="fa-lg iconcolor"
           [icon]="faInfoCircle"
           placement="bottom"
           tooltipClass="tipclass"
           [ngbTooltip]="tipContent">
  </fa-icon>
</div>
<lsz-tick-slider [(value)]="numValue"
                 [labels]="sliderLabels"
                 [disabled]="false"
                 (valueChange)="switch($event)">
</lsz-tick-slider>
