import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'lsz-header-logo',
  templateUrl: './header-logo.component.html',
  styleUrls: ['./header-logo.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderLogoComponent {

}
