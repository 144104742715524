import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { select, Store } from '@ngrx/store';

import { SetBaseline } from '../../../../store/actions/baseline.actions';
import { getBaseline } from '../../../../store/selectors/baseline.selectors';
import { AppState } from '../../../../store/states/app.state';
import { Baseline } from '../../../../store/states/baseline.state';

@Component({
  selector: 'lsz-baseline-switch',
  templateUrl: './baseline-switch.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./baseline-switch.component.css'],
})
export class BaselineSwitchComponent implements OnInit {
  public faInfoCircle: unknown;
  public tooltipText: string;
  public baselineValue: number;

  constructor(private store: Store<AppState>) {
    this.tooltipText = 'Möglichkeit zwischen Mittelwert und Median zu wechseln';
    this.baselineValue = 0;
    this.faInfoCircle = faInfoCircle;
  }

  public ngOnInit(): void {
    this.store.pipe(select(getBaseline)).subscribe((baseline: Baseline) => {
      this.baselineValue = baseline.baseline === 'median' ? 0 : 1;
    });
  }

  public switch(newValue: number): void { // type {newValue: number}? template doesn't like it
    this.store.dispatch(new SetBaseline({ baseline: newValue === 0 ? 'median' : 'average' }));
  }
}
