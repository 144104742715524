import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { Duration, Moment } from 'moment/moment';
import { Observable, Subject, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { RoleGuard } from '../../core/guards/role.guard';
import { CAN_EDIT_DATA } from '../../core/permissionGroups';
import { Role } from '../../core/services/auth/models/role.enum';
import { User } from '../../services/user/model/user';
import { UserService } from '../../services/user/user.service';

import { RemainingDataEntryTime } from './models/remainingDataEntryTime';
import { Season } from '../../services/season/models/season';

@Component({
  selector: 'lsz-data-entry-count-down',
  templateUrl: './data-entry-count-down.component.html',
  styleUrls: ['./data-entry-count-down.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataEntryCountDownComponent implements OnInit, OnDestroy {

  @Input() public isPresenter: boolean;
  @Input() public season: Season;

  public activePanelIds: string;
  public canEditData$: Observable<boolean>;
  public faChevronDown: unknown;
  public faChevronUp: unknown;
  public noEntryPossible: boolean;
  public remainingDataEntryTime: RemainingDataEntryTime;
  public toggleIcon: unknown;

  private initClosedPanel: boolean;
  private onDestroy$: Subject<void>;

  constructor(private userService: UserService,
              private cdr: ChangeDetectorRef) {
    this.faChevronUp = faChevronUp;
    this.faChevronDown = faChevronDown;
    this.toggleIcon = this.faChevronUp;
    this.activePanelIds = 'panel-Countdown';
    this.remainingDataEntryTime = new RemainingDataEntryTime();
    this.initClosedPanel = false;
    this.canEditData$ = this.isAllowed(CAN_EDIT_DATA);
    this.onDestroy$ = new Subject();
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  public ngOnInit(): void {
    const period: number = 1000;
    timer(0, period).pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      if (this.season) {
        const start: Moment = moment();
        const end: Moment = moment(this.season.dataInputEndsAtUtc);
        let diff: number = end.diff(start);
        if (diff < 0) {
          diff = 0;
          this.noEntryPossible = true;
        } else {
          this.noEntryPossible = false;
        }
        const diffDuration: Duration = moment.duration(diff);
        this.remainingDataEntryTime.days = Math.floor(diffDuration.asDays());
        this.remainingDataEntryTime.hours = diffDuration.hours();
        this.remainingDataEntryTime.minutes = diffDuration.minutes();
        const numberOfDays: number = 30;
        if (this.isPresenter && (diffDuration.asDays() > numberOfDays || diff === 0) && !this.initClosedPanel) {
          this.activePanelIds = '';
          this.toggleIcon = this.faChevronDown;
          this.initClosedPanel = true;
        }
        this.cdr.markForCheck();
      }
    });
  }

  private isAllowed(allowedRoles: Role[]): Observable<boolean> {
    return this.userService.userInformation$.pipe(
      map((userInformation: User) => userInformation.roles),
      map((userRoles: Role[]) =>
        RoleGuard.isAllowed(allowedRoles, userRoles)),
    );
  }

}
