import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LawnField } from '../models/models';

import { InfrastructureService } from './infrastructure.service';

@Injectable({
  providedIn: 'root',
})
export class LawnFieldService extends InfrastructureService<LawnField> {
  protected readonly endpoint: string = '/LawnFields';

  constructor(protected http: HttpClient) {
    super(http);
  }

}
