<div class="modal-header">
  <h4 class="modal-title text-danger" id="modal-title">Vorgang nicht erfolgreich</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <p><strong>Der aktuelle Vorgang war leider nicht erfolgreich. {{ infoText }}</strong></p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="modal.close()">OK</button>
</div>
