<div fxLayout="column" class="time-input">
  <ngb-timepicker [formControl]="formCtrl"
                  [seconds]="seconds"
                  [secondStep]="secondStep"
                  [minuteStep]="minuteStep"
                  [hourStep]="hourStep"
                  [size]="size"
                  [readonlyInputs]="readonlyInputs">
  </ngb-timepicker>
  <div *ngIf="formCtrl.invalid && (formCtrl.dirty || formCtrl.touched)">
    <div class="input-error" *ngIf="formCtrl.errors.required">{{requiredErrorMsg}}</div>
    <div class="input-error" *ngIf="formCtrl.errors.timeValidator">{{invalidErrorMsg}}</div>
  </div>
</div>
