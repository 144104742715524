import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { RoleGuard } from '../../../../core/guards/role.guard';
import { CAN_SELECT_ALL, CAN_SELECT_BUDGET_GROUP, CAN_SELECT_LEAGUE, CAN_SWITCH_CLUB } from '../../../../core/permissionGroups';
import { User } from '../../../../services/user/model/user';

import { AdminFilterSetting } from './model/adminFilterSetting';
import { AdminFilter, DEFAULT_ADMIN_FILTER_SETTING } from './model/constants';
import { BudgetGroupService } from '../../../../data-presenter/services/budget-group.service';
import { switchMap, tap } from 'rxjs/operators';
import { Option } from './model/option';
import { DecimalPipe } from '@angular/common';
import { OptionGroup } from './model/optionGroup';
import { BudgetGroup } from '../../../../data-presenter/models/budgetGroup';

const CLICK_OUTSIDE_TIMEOUT_MS: number = 100;

@Component({
  selector: 'lsz-admin-dropdown',
  templateUrl: './admin-dropdown.component.html',
  styleUrls: ['./admin-dropdown.component.scss'],
})
export class AdminDropdownComponent implements OnInit, OnChanges, OnDestroy {

  @Output() public filterChange: EventEmitter<AdminFilterSetting>;
  @Output() public showChange: EventEmitter<boolean>;
  @Input() public user$: Observable<User>;
  @Input() public show: boolean;
  @Input() public closeOnMouseLeave: boolean;

  public canSelectAll: boolean;
  public canSelectBudgetGroup: boolean;
  public canSelectLeague: boolean;
  public canSwitchClub: boolean;
  public optionGroups: OptionGroup[];
  public selected: AdminFilterSetting;

  private unsubscribe: Subject<void>;
  private outsideClickTimeout: NodeJS.Timeout;
  private handleOutsideClick: boolean;
  private budgetGroups: BudgetGroup[];

  constructor(private budgetGroupService: BudgetGroupService,
              private decimalPipe: DecimalPipe) {
    this.filterChange = new EventEmitter<AdminFilterSetting>();
    this.showChange = new EventEmitter<boolean>();
    this.unsubscribe = new Subject<void>();
    this.selected = DEFAULT_ADMIN_FILTER_SETTING;
  }

  @HostListener('mouseleave')
  private onMouseLeave(): void {
    if (this.closeOnMouseLeave) {
      this.close();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.handleOutsideClick = false;

    if (changes.show) {
      // prevent onOutsideClick from closing the dropdown for some time.
      // otherwise, dropdown would be closed immediately.
      clearTimeout(this.outsideClickTimeout);
      this.outsideClickTimeout = setTimeout(() => {
        this.handleOutsideClick = true;
      }, CLICK_OUTSIDE_TIMEOUT_MS);
    }
  }

  public ngOnDestroy(): void {
    clearTimeout(this.outsideClickTimeout);
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public ngOnInit(): void {
    this.budgetGroupService.budgetGroups$.pipe(
      tap((budgetGroups: BudgetGroup[]) => {
        this.budgetGroups = budgetGroups;
      }),
      switchMap(() => this.user$),
    ).subscribe((user: User) => {
      this.canSwitchClub = RoleGuard.isAllowed(CAN_SWITCH_CLUB, user.roles);
      this.canSelectLeague = RoleGuard.isAllowed(CAN_SELECT_LEAGUE, user.roles);
      this.canSelectBudgetGroup = RoleGuard.isAllowed(CAN_SELECT_BUDGET_GROUP, user.roles);
      this.canSelectAll = RoleGuard.isAllowed(CAN_SELECT_ALL, user.roles);

      this.optionGroups = [
        {
          label: undefined,
          show: true,
          optionType: AdminFilter.CLUB,
          options: [
            {
              label: 'Einzelnes Team',
              value: AdminFilter.CLUB,
            },
          ],
        },
        {
          label: undefined,
          show: this.canSelectAll,
          optionType: AdminFilter.ALL,
          options: [
            {
              label: 'Alle',
              value: AdminFilter.ALL,
            },
          ],
        },
        {
          label: 'Liga',
          show: this.canSelectLeague,
          optionType: AdminFilter.LEAGUE,
          options: [
            {
              label: 'Bundesliga',
              value: 'PremierLeague',
            },
            {
              label: '2. Bundesliga',
              value: 'SecondLeague',
            },
            {
              label: '3. Liga',
              value: 'ThirdLeague',
            },
            {
              label: '4. Liga (+ darunter)',
              value: 'FourthLeague',
            },
          ],
        },
        {
          label: 'Budgetgruppe',
          show: this.canSelectBudgetGroup,
          optionType: AdminFilter.BUDGET,
          options: this.setBudgetRangeLabels([
            {
              label: '',
              value: 'A',
            },
            {
              label: '',
              value: 'B',
            },
            {
              label: '',
              value: 'C',
            },
            {
              label: '',
              value: 'D',
            },
            {
              label: '',
              value: 'E',
            },
          ]),
        },
      ];
    });
  }

  public onOutsideClick(): void {
    if (this.handleOutsideClick) {
      this.close();
    }
  }

  public onTopClick(): void {
    this.close();
  }

  public onValueChange(type: AdminFilter, value: string): void {
    this.selected = {
      type,
      value,
    };
    this.filterChange.emit(this.selected);
  }

  private close(): void {
    this.showChange.emit(false);
  }

  private setBudgetRangeLabels(budgetGroupOptions: Option[]): Option[] {
    return budgetGroupOptions.map((option: Option) => {
      const _emptyIfNull = (value: string) => value === null ? '' : value;

      const budgetGroup: BudgetGroup = this.budgetGroups.find((group: BudgetGroup) => group.name === option.value);
      let minFormatted: string = this.decimalPipe.transform(budgetGroup.min, '1.0');
      let maxFormatted: string = this.decimalPipe.transform(budgetGroup.max, '1.0');

      minFormatted = _emptyIfNull(minFormatted);
      maxFormatted = _emptyIfNull(maxFormatted);

      let separator: string = ' - ';
      let comparator: string = '';

      if (!minFormatted || !maxFormatted) {
        separator = '';

        if (!minFormatted) {
          comparator = '< ';
        } else {
          comparator = '> ';
        }
      }

      return {
        ...option,
        label: `${option.value} (${comparator}${minFormatted}${separator}${maxFormatted})`,
      };
    });
  }
}
