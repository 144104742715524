
export abstract class DetailIsPercentage {

  public static detailNamesWithPercentage: string[] = [
    'U14Minimum3SeasonsAffiliationQuote',
    'U15Minimum3SeasonsAffiliationQuote',
    'U15Minimum4SeasonsAffiliationQuote',
    'U16Minimum3SeasonsAffiliationQuote',
    'U16Minimum5SeasonsAffiliationQuote',
    'U17Minimum3SeasonsAffiliationQuote',
    'U17Minimum5SeasonsAffiliationQuote',
    'U17Minimum6SeasonsAffiliationQuote',
    'U18Minimum3SeasonsAffiliationQuote',
    'U18Minimum5SeasonsAffiliationQuote',
    'U18Minimum7SeasonsAffiliationQuote',
    'U19Minimum3SeasonsAffiliationQuote',
    'U19Minimum5SeasonsAffiliationQuote',
    'U19Minimum8SeasonsAffiliationQuote',
    'U14OverallAffiliationQuote',
    'U15OverallAffiliationQuote',
    'U16OverallAffiliationQuote',
    'U17OverallAffiliationQuote',
    'U18OverallAffiliationQuote',
    'U19OverallAffiliationQuote',
    'U20U22Minimum3SeasonsAffiliationQuote',
    'U20U22Minimum5SeasonsAffiliationQuote',
    'U20U22Minimum9SeasonsAffiliationQuote',
    'U20U22OverallAffiliationQuote',
    'TrainedPlayersMinimum3SeasonsPercentage',
    'TrainedPlayersPercentageCompetitiveMinutes',
    'TrainedPlayersOverallAffiliation',
    'ForeignTrainedPlayersOverallAffiliation',
  ];

  public static isPercentage(detailName: string): boolean {
    return this.detailNamesWithPercentage.includes(detailName);
  }
}
