<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{title}}</h4>
  <button (click)="modal.dismiss(confirmationDialogResult.CLOSE)" aria-describedby="modal-title" class="close"
          type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <p><strong>{{text}}</strong></p>
</div>
<div class="modal-footer">
  <button (click)="modal.close(confirmationDialogResult.CANCEL)" class="btn btn-secondary"
          type="button">{{labelCancel}}</button>
  <button (click)="modal.close(confirmationDialogResult.CONFIRM)" class="btn btn-danger"
          type="button">{{labelConfirm}}</button>
</div>
