import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BaseEditFormComponent } from '../../../shared/base-edit-form/base-edit-form.component';

@Component({
  selector: 'tbody[lsz-common-cooperation-form]',
  templateUrl: './common-cooperation-form.component.html',
  styleUrls: ['./common-cooperation-form.component.css'],
})
export class CommonCooperationFormComponent extends BaseEditFormComponent {

  public get cooperationContent(): AbstractControl {
    return this.form.get('cooperationContent');
  }

  public get cooperationPartner(): AbstractControl {
    return this.form.get('cooperationPartner');
  }

  constructor() {
    super();
  }
}
