import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment/moment';

@Pipe({
  name: 'yearsAgo',
})
export class YearsAgoPipe implements PipeTransform {

  public transform(value: string, format: string = 'DD.MM.YYYY'): number {
    const date: Moment = moment(value, format);

    return moment().diff(date, 'years');
  }

}
