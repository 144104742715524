import { Action } from '@ngrx/store';

import { Baseline } from '../states/baseline.state';

export enum EBaselineAction {
  SET_BASELNE = '[Baseline] Set Baseline',
}

export class SetBaseline implements Action {
  public readonly type: string = EBaselineAction.SET_BASELNE;

  constructor(public payload: Baseline) {
  }
}

export type BaselineActions = SetBaseline;
