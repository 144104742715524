<div class="form-wrapper">
  <lsz-edit-form-header
    [headerText]="'Indoorflächen'"
    [numEntries]="entries.length"
    [editMode]="editMode"
    (clickEdit)="onClickEdit()"
    (clickEditDone)="onClickEditDone()"
  ></lsz-edit-form-header>

  <table [class.editMode]="editMode" [formGroup]="allForms">
    <thead>
    <tr>
      <th i18n="@@tableHeaderName">Name</th>
      <th i18n="@@tableHeaderRemark">Bemerkung</th>
      <th i18n="@@tableHeaderArea">Größe in m</th>
      <th i18n="@@tableHeaderSurface">Belag</th>
      <th i18n="@@tableHeaderOnLzArea">Auf LZ-Gelände</th>
      <th i18n="@@tableHeaderLzExclusive">Exkl. LZ-Nutzung</th>
      <th *ngIf="editMode" i18n="@@tableHeaderOptions" class="column-options">Optionen</th>
      <th>{{'lastEdit' | translate}}</th>
    </tr>
    </thead>
    <tbody
      lsz-indoor-hall-form
      *ngFor="let form of customEntryForms.controls; let i = index"
      (entrySaved)="onUpdate(i)"
      (entryDeleted)="onDelete(i)"
      [editMode]="editMode"
      formArrayName="customEntries"
      [index]="i"
      [form]="form"
    >
    </tbody>
    <tbody *ngIf="addMode" formGroupName="newEntry">
    <tr>
      <td>
        <input type="text" placeholder="Neuer Eintrag" i18n-placeholder="@@placeholderNewEntry" formControlName="name" required/>
        <div *ngIf="name.invalid && (name.dirty || name.touched)">
          <div class="input-error" *ngIf="name.errors.required">Name wird benötigt.</div>
          <div class="input-error" *ngIf="name.errors.repeatedName">Name kann sich nicht wiederholen.</div>
        </div>
      </td>
      <td><textarea formControlName="remark"></textarea></td>
      <td>
        <input class="field-size-input" type="text" formControlName="length"/>
        <span class="input-divider">x</span>
        <input class="field-size-input" type="text" formControlName="width"/>
        <div *ngIf="length.invalid && length.touched">
          <div class="input-error" *ngIf="length.errors.required">Längenangabe wird benötigt.</div>
        </div>
        <div *ngIf="width.invalid && width.touched">
          <div class="input-error" *ngIf="width.errors.required">Breitenangabe wird benötigt.</div>
        </div>
      </td>
      <td>
        <select size="1" formControlName="surface">
          <option value="DropdownLawn">Rasen</option>
          <option value="ArtificialLawn">Kunstrasen</option>
          <option value="Tartan">Tartan</option>
          <option value="Pvc">PVC</option>
        </select>
        <div *ngIf="surface.invalid && surface.touched">
          <div class="input-error" *ngIf="surface.errors.required">Auswahl wird benötigt.</div>
        </div>
      </td>
      <td>
        <lsz-binary-select [form]="newEntryForm" [name]="'isOnLzArea'"></lsz-binary-select>
      </td>
      <td>
        <lsz-binary-select [form]="newEntryForm" [name]="'isLzExclusive'"></lsz-binary-select>
      </td>
      <td>
        <div class="form-options" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
          <button
            type="button"
            [disabled]="!newEntryForm.valid"
            (click)="createNewEntry()"
            class="nav-button"
            [ngClass]="{'notice': newEntryForm.valid}"
          >
            <fa-icon [icon]="faSave"></fa-icon>
          </button>
          <button type="button" (click)="exitAddMode()" class="nav-button">
            <fa-icon [icon]="faTrash"></fa-icon>
          </button>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

  <button *ngIf="editMode && !addMode" type="button" (click)="addNewRow()" i18n="@@dataButtonAdd" class="add-button">
    <fa-icon [icon]="faPlus"></fa-icon>
    Neuen Eintrag hinzufügen
  </button>
</div>
