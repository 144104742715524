<header id="data-presenter-header">
  <div id="data-presenter-header-content">
    <div id="griditem1">
      <a routerLink="/">
        <img src="/assets/Deutsche_Fußball_Liga_logo.svg" alt="Logo der DFL" i18n-alt="@@logoDfl" class="logo-dfl"/>
        <img src="/assets/Deutscher_Fußball-Bund_logo.svg" alt="Logo des DFB" i18n-alt="@@logoDfb" class="logo-dfb"/>
      </a>
      <div i18n="@@headerAppTitle" class="header-title" fxLayout="column" fxLayoutGap="2px">
        <span>Sportdatenbank</span><br>
        <span>Leistungszentren</span>
      </div>
    </div>
    <div id="griditem2">

    </div>
    <div id="griditem3">

    </div>
    <div id="griditem4">
    </div>
  </div>
</header>
<div class="legal-notice-content">

  <h2>Impressum</h2>

  <p>
    DFL Deutsche Fußball Liga e.V.
    <br />Guiollettstraße 44-46
    <br />60325 Frankfurt/Main
    <br />Deutschland
    <br />Telefon: +49 (0)69-65005-0
    <br />Telefax: +49 (0)69-65005-555
    <br />E-Mail: <a href="mailto:info@dfl.de">info@dfl.de</a>
    <br />
    <br />Sprecher des Präsidiums: Hans-Joachim Watzke
    <br />VR 12031 Frankfurt/Main, Deutschland
    <br />Umsatzsteuer-IdNr.: DE 215 955 013
  </p>

  <p>
    <br />DFB GmbH & Co. KG
    <br />Schwarzwaldstraße 121
    <br />60528 Frankfurt/Main
    <br />
    <br />Telefon: +49 (0)69-6788-0
    <br />Telefax: +49 (0)69-6788-266
    <br />E-Mail: <a href="mailto:info@dfb.de">info@dfb.de</a>
    <br />
    <br />Registergericht: Amtsgericht Frankfurt/Main
    <br />Registernummer: HRA 52430
    <br />Sitz der Gesellschaft: Frankfurt/Main
  </p>
  <p>
    <br />Persönlich haftende Gesellschafterin der DFB GmbH & Co. KG:
    <br />DFB Verwaltungsgesellschaft mbH
    <br />Schwarzwaldstraße 121
    <br />60528 Frankfurt/Main
    <br />
    <br />Geschäftsführer der DFB-Verwaltungsgesellschaft mbH:
    <br />Dr. Frank Biendara
    <br />Dr. Holger Blask
    <br />Manuel Hartmann
    <br />
    <br />Registergericht: Amtsgericht Frankfurt/Main
    <br />Registernummer: HRB 125153
    <br />
    <br />Umsatzsteuer-Identifikationsnummer: DE11423696
  </p>
</div>
