import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BaseEditFormComponent } from '../../../shared/base-edit-form/base-edit-form.component';

@Component({
  selector: 'tbody[lsz-database-system-form]',
  templateUrl: './database-system-form.component.html',
  styleUrls: ['./database-system-form.component.css'],
})
export class DatabaseSystemFormComponent extends BaseEditFormComponent {

  public get medicine(): AbstractControl {
    return this.form.get('medicine');
  }

  public get players(): AbstractControl {
    return this.form.get('players');
  }

  public get scouting(): AbstractControl {
    return this.form.get('scouting');
  }

  public get staff(): AbstractControl {
    return this.form.get('staff');
  }

  public get strategy(): AbstractControl {
    return this.form.get('strategy');
  }

  public get support(): AbstractControl {
    return this.form.get('support');
  }

  public get video(): AbstractControl {
    return this.form.get('video');
  }

  constructor() {
    super();
  }
}
