import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { faChevronDown, faChevronUp, faCog, faPen, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { SoccerClub, SoccerClubLeague } from '../models/soccerClub';
import { SeasonService } from '../services/season/season.service';
import { SoccerClubService } from '../services/soccer-club/soccer-club.service';
import { User } from '../services/user/model/user';
import { AdminFilterSetting } from '../shared/header/partials/admin-dropdown/model/adminFilterSetting';
import { AdminFilter, DEFAULT_ADMIN_FILTER_SETTING } from '../shared/header/partials/admin-dropdown/model/constants';
import { Filter } from '../shared/header/partials/filter-switch/model/constants';
import { TemporalReference } from '../shared/header/partials/temporal-reference-switch/model/constants';
import { getBaseline } from '../store/selectors/baseline.selectors';
import { getClub } from '../store/selectors/club.selectors';
import { AppState } from '../store/states/app.state';
import { Baseline } from '../store/states/baseline.state';

import { BaseKpisProcessor } from './models/baseKpisProcessor';
import { FilterArgument } from './models/filterArgument';
import { KpiRater } from './models/kpiRater';
import { Kpis,  SoccerClubKpi } from './models/kpis';
import { KpisHistoryProcessor } from './models/kpisHistoryProcessor';
import { KpisProcessResult } from './models/kpisProcessResult';
import { KpisProcessor } from './models/kpisProcessor';
import { KpiFilter, KpiService } from './services/kpi.service';
import { RoleGuard } from '../core/guards/role.guard';
import { CAN_SWITCH_CLUB, CAN_SWITCH_SEASONS_IN_EDIT } from '../core/permissionGroups';
import { UserService } from '../services/user/user.service';
import { Season } from '../services/season/models/season';

@Component({
  selector: 'lsz-show-data',
  templateUrl: './data-presenter.component.html',
  styleUrls: ['./data-presenter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataPresenterComponent implements OnInit, OnDestroy {

  public accordionPanelToggleIcons = {
    Infrastructure: {
      Fields: faChevronDown,
      Halls: faChevronDown,
      SpecialEquipments: faChevronDown,
      FunctionalRooms: faChevronDown,
      RegenerationFacilities: faChevronDown,
      Rooms: faChevronDown,
      Colleges: faChevronDown,
    },
    Personnel: {
      Employment: faChevronDown,
      PartTimeEmployment: faChevronDown,
      Qualification: faChevronDown,
      Membership: faChevronDown,
    },
    Effectiveness: {
      MinimumAffiliation: faChevronDown,
      OverallAffiliation: faChevronDown,
      U20U22: faChevronDown,
      LicenseTeam: faChevronDown,
      ForeignLicenseTeam: faChevronDown,
    },
  };
  public ADMIN_FILTER_CLUB: string;
  public adminFilter: AdminFilterSetting;
  public baseline: string;
  public budget: string;
  public faChevronDown: unknown;
  public faChevronUp: unknown;
  public faCog: unknown;
  public faPen: unknown;
  public faSignOutAlt: unknown;
  public filter: Filter;
  public kpiLoadingFailed: boolean;
  public soccerClubHasKpis: boolean;

  public kpisProcessResult: KpisProcessResult;

  /**
   * Set either implicitly through the club a user belongs to or the club that an admin selected, or explicitly through
   * filter options available to admins.
   */
  public league: string;

  public onDestroy$: Subject<void>;
  public openSeason: Season;
  public season: Season;

  public seasons: Season[];
  public showBudgetSelect: boolean;
  public showLeagueSelect: boolean;
  public soccerClub: SoccerClub;
  public temporalReference: TemporalReference;
  private initialized: boolean;

  private readonly kpiRater: KpiRater;
  private sub: Subscription;
  private user: User;
  private canSwitchClub: boolean;
  private isAdmin: boolean;
  private kpis: Kpis | Kpis[];

  constructor(private service: KpiService,
              private store: Store<AppState>,
              private userService: UserService,
              private spinnerService: NgxSpinnerService,
              private seasonService: SeasonService,
              private soccerClubService: SoccerClubService,
              private cdr: ChangeDetectorRef) {
    this.faChevronDown = faChevronDown;
    this.faChevronUp = faChevronUp;
    this.faCog = faCog;
    this.faPen = faPen;
    this.faSignOutAlt = faSignOutAlt;
    this.onDestroy$ = new Subject<void>();
    this.ADMIN_FILTER_CLUB = AdminFilter.CLUB;
    this.filter = Filter.ALL;
    this.adminFilter = DEFAULT_ADMIN_FILTER_SETTING;

    this.kpiRater = new KpiRater();
  }

  public beforeAccordionPanelChange($event: NgbPanelChangeEvent): void {
    const [, criterion, category] = $event.panelId.split('-');
    this.accordionPanelToggleIcons[criterion][category] = $event.nextState ? faChevronUp : faChevronDown;
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  public ngOnInit(): void {

    this.userService.userInformation$.pipe(
    ).subscribe((user) => {
      this.user = user;
      this.canSwitchClub = RoleGuard.isAllowed(CAN_SWITCH_CLUB, user.roles);
      this.isAdmin = RoleGuard.isAllowed(CAN_SWITCH_SEASONS_IN_EDIT, user.roles);
    });

    this.seasonService.getSeasons().pipe(
      tap((seasons: Season[]) => {
        this.seasons = seasons;
        // find the latest closed season
        this.season = seasons.reverse().find((season: Season) => season.isClosed);            
        // find (the only) unclosed season
        this.openSeason = seasons.find((season: Season) => !season.isClosed);
      }),
      takeUntil(this.onDestroy$),
      switchMap(() => this.store.pipe(
        select(getClub),
      )),
    ).subscribe((soccerClub: SoccerClub) => {
      if (this.soccerClub?.id === soccerClub.id) {
        return;
      }

      if (soccerClub.id || soccerClub.isAdmin) {
        if (!this.initialized ||
          (this.filter === Filter.LEAGUE && soccerClub.league !== this.league) ||
          (this.filter === Filter.BUDGET && soccerClub.budget !== this.budget) ||
          this.temporalReference === TemporalReference.HISTORY
        ) {
          this.soccerClub = soccerClub;
          this.applyFilter(soccerClub);
        } else {
          this.soccerClub = soccerClub;
          const kpisProcessor: KpisProcessor = new KpisProcessor(this.kpiRater, soccerClub);
          const kpisToRate: Kpis = !Array.isArray(this.kpis) ? this.kpis : null;
          // The rating is only necessary when we have a single KPIs object.
          // For historical reference 'kpis' is an array with all KPIs from all seasons
          // and because these values are compared to the total value of the league there is no rating needed.
          kpisProcessor.rateAll(this.kpisProcessResult, kpisToRate, soccerClub);
          this.soccerClubHasKpis = this.getSoccerClubHasKpis();
        }

        this.initialized = true;
        this.cdr.markForCheck();
      }
    }, (err: unknown) => {
      console.error(err);
      this.spinnerService.hide();
    });

    this.store.pipe(
      select(getBaseline),
      takeUntil(this.onDestroy$),
    ).subscribe((baseline: Baseline) => {
      if (baseline.baseline) {
        this.baseline = baseline.baseline;
        this.cdr.markForCheck();
      }
    });
  }

  public onAdminFilterSwitch(filter: AdminFilterSetting): void {
    this.adminFilter = filter;

    if (filter.type === AdminFilter.LEAGUE) {
      this.league = filter.value;
    }
    if (filter.type === AdminFilter.BUDGET) {
      this.budget = filter.value;
    }

    let soccerClub: SoccerClub;
    if (filter.type === AdminFilter.CLUB) {
      soccerClub = this.soccerClub;
    }

    this.applyFilter(soccerClub);
  }

  public onBudgetSelect(budget: string): void {
    this.budget = budget;
    const filterArgument: FilterArgument = {
      type: Filter.BUDGET,
      value: budget,
    };
    this.getFilteredData(filterArgument);
  }

  public onFilterSwitch(filter: Filter): void {
    this.filter = filter;
    this.applyFilter(this.soccerClub);
  }

  public onLeagueSelect(league: string): void {
    this.league = league;
    const filterArgument: FilterArgument = {
      type: Filter.LEAGUE,
      value: league,
    };
    this.getFilteredData(filterArgument);
  }

  public onTemporalSwitch(temporalReference: TemporalReference): void {
    this.temporalReference = temporalReference;
    this.applyFilter(this.soccerClub);
  }

  private applyFilter(soccerClub: SoccerClub): void {
    this.spinnerService.show();
    const filterArgument: FilterArgument = this.createFilterArgument(soccerClub);
    if (this.temporalReference === TemporalReference.HISTORY) {
      let obs: Observable<unknown>;
      if (this.canSwitchClub) {
        obs = this.soccerClubService.getLeaguesBySeason(this.soccerClub.id, this.seasons);
      } else {
        obs = this.soccerClubService.getLeaguesBySeasons(this.user.metaData.clubId, this.seasons);
      }
      obs.subscribe((leagues: SoccerClubLeague[]) => {
        const _leagues: SoccerClubLeague[] = [AdminFilter.LEAGUE, AdminFilter.BUDGET].includes(this.adminFilter.type)
          ? leagues.map((league: SoccerClubLeague) => ({
            season: league.season,
            league: this.adminFilter.type === AdminFilter.LEAGUE ? this.league : this.budget,
          }))
          : leagues;
        const _soccerClub: SoccerClub = {
          ...this.soccerClub,
          leagues: _leagues,
        };
        if (this.adminFilter.type === AdminFilter.ALL) {
          _soccerClub.leagues = _soccerClub.leagues.map(() => undefined) as undefined[];
        }
        this.callFilteredData(filterArgument, _soccerClub);
      });
    } else {
      this.callFilteredData(filterArgument, soccerClub);
    }
  }

  private callFilteredData(filterArgument: FilterArgument, soccerClub: SoccerClub): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.sub = this.getFilteredData(filterArgument).subscribe((kpis: Kpis) => {
      if (soccerClub) {
        this.soccerClub = soccerClub;
        this.league = soccerClub.league;
        this.budget = soccerClub.budget;
      }
      this.kpis = kpis;
      let kpisProcessor: BaseKpisProcessor = new KpisProcessor(this.kpiRater, this.soccerClub);
      if (this.temporalReference === TemporalReference.HISTORY) {
        kpisProcessor = new KpisHistoryProcessor(this.kpiRater, this.soccerClub);
      }
      // rATEKPIS implicitly
      this.kpisProcessResult = kpisProcessor.process(kpis);
      this.cdr.markForCheck();
      
      // check if current club has KPIs
      this.soccerClubHasKpis = this.getSoccerClubHasKpis();
      
    }, (error: Error) => {
      console.error(error);
      this.kpiLoadingFailed = true;
      this.cdr.markForCheck();

      if (this.soccerClub.isAdmin) {
        if (this.filter === Filter.LEAGUE) {
          this.showBudgetSelect = false;
          this.showLeagueSelect = true;
        } else if (this.filter === Filter.BUDGET) {
          this.showLeagueSelect = false;
          this.showBudgetSelect = true;
        } else {
          this.showLeagueSelect = false;
          this.showBudgetSelect = false;
        }
      }
    }, () => {
      this.spinnerService.hide();
    });
  }

  private getSoccerClubHasKpis(): boolean {
    if (this.soccerClub && this.kpisProcessResult) {
      if (this.adminFilter.type != AdminFilter.CLUB && this.isAdmin) {
        return true;
      }
      
      // in case of historical reference, this.kpis is an array of kpis...
      const kpiToCheck: Kpis = Array.isArray(this.kpis) ? this.kpis[0] : this.kpis;      
      for (const seasonKpi of kpiToCheck.seasonKpiCollection) {
        if (seasonKpi.soccerClubKpiCollection.findIndex((soccerKpi:SoccerClubKpi) => soccerKpi.soccerClubId === this.soccerClub.id) > -1) {
          return true;
        }        
      }
      return false;        
    }   
    
    return false;
  }
  
  private createFilterArgument(soccerClub: SoccerClub): FilterArgument {
    let filterArgument: FilterArgument;

    if (this.adminFilter.type !== AdminFilter.CLUB) {
      if (this.adminFilter.type === AdminFilter.LEAGUE) {
        filterArgument = {
          type: Filter.LEAGUE,
          value: this.league,
        };
      }
      if (this.adminFilter.type === AdminFilter.BUDGET) {
        filterArgument = {
          type: Filter.BUDGET,
          value: this.budget,
        };
      }
    } else {
      if (this.filter === Filter.LEAGUE) {
        filterArgument = {
          type: Filter.LEAGUE,
          value: soccerClub.league,
        };
      }
      if (this.filter === Filter.BUDGET) {
        filterArgument = {
          type: Filter.BUDGET,
          value: soccerClub.budget,
        };
      }
    }

    return filterArgument;
  }

  private getFilteredData(filterArgument?: FilterArgument): Observable<Kpis> {
    let kpiFilter: KpiFilter;
    if (filterArgument?.type === Filter.LEAGUE) {
      kpiFilter = { name: 'League', value: filterArgument.value };
    } else if (filterArgument?.type === Filter.BUDGET) {
      kpiFilter = { name: 'Budget', value: filterArgument.value };
    }
    let seasonName: string = this.season.name;
    if (this.temporalReference === TemporalReference.HISTORY) {
      seasonName = undefined;
    }

    return this.service.getKpis(seasonName, kpiFilter).pipe(
      takeUntil(this.onDestroy$),
    ) as Observable<Kpis>;
  }

}
