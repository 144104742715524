<ng-container i18n="@@kpiNames">
  {name, select,
    AllFields {Plätze gesamt}
    AllFieldsWithFloodlight {Plätze mit Flutlicht gesamt}
    AllHeatedFields {Plätze mit Heizung}
    AllSmallSizedFields {Kleinspielfelder}
    ArtificialLawnFields {Kunstrasenplätze}
    CompetitionCompliantFields {Plätze Wettbewerbskonform}
    LawnFields {Rasenplätze}
    LawnFieldsWithFloodlight {Rasenplätze mit Flutlicht}
    LawnFieldOnLzArea {Plätze auf LZ-Gelände}
    OpenSpaceFieldSize {Freiflächen (in m²)}
    SmallSizedFieldsWithBoards {Kleinspielfelder mit Bande}
    LzExclusiveFields {Plätze exklusive Nutzung}

    IndoorHalls {Indoorfläche auf LZ Gelände}
    SoccerHalls {Fußballhalle auf LZ Gelände}

    AltitudeChamber {Höhenkammer}
    FootballTennis {Fußballtennis}
    Footbonaut {Footbonaut o.Ä.}
    HeaderPendulum {Kopfballpendel}
    Helix {Helix}
    OtherSports {andere Sportarten (Beachvolleyball, Basketball, Kletterwand etc.)}
    Speedcourt {Speedcourt}
    SprintHill {Sprinthügel}
    SprintTrack {Sprintbahn}
    VirtualReality {Virtual Reality}

    Physio {Physio Arbeitsplätze (Bänke)}
    PlayersCabin {Spielerkabinen (exklusive Nutzung)}
    RefereeCabin {Schiedsrichterkabinen}
    TrainersCabin {Trainerkabinen (exklusive Nutzung)}
    WeightRoom {Krafträume}

    ColdChamber {Kältekammer}
    IceBarrel {Eistonne}
    RelaxationBath {Entmüdungsbecken}
    RelaxationRoom {Ruheraum}
    Sauna {Sauna}

    Canteen {Kantine}
    ConferenceRoom {Besprechungsräume}
    Office {Büroräume}
    StudyRoom {Lernplätze}
    Workplace {Arbeitsplätze}

    Players {Untergebrachte Spieler gesamt}
    PlayersInCollege {Untergebrachte Spieler im Internat}
    PlayersInGuestFamily {Untergebrachte Spieler bei Gasteltern}
    PlayersU12ToU15 {Untergebrachte Spieler U12-U15}
    PlayersU16ToU23 {Untergebrachte Spieler U16-U23}


    FullTimeEmployees {Mitarbeiter in VZ}
    FullTimeEmployeesInLeadership {Mitarbeiter in VZ im Bereich Leitung}
    FullTimeEmployeesInManagement {Mitarbeiter in VZ im Bereich Management & Administration}
    FullTimeEmployeesInMisc {Gesamtanzahl Mitarbeiter in VZ im Bereich Sonstiges}
    FullTimeEmployeesInSoccerTraining {Mitarbeiter in VZ im Bereich Sport}
    FullTimeEmployeesInSupport {Mitarbeiter in VZ im Bereich Unterstützung}

    FullTimeEmployeesDoubleArea {Mitarbeiter mit DF Lizenz}
    FullTimeEmployeesInLeadershipDoubleArea {Mitarbeiter mit DF Lizenz im Bereich Leitung}
    FullTimeEmployeesInManagementDoubleArea {Mitarbeiter mit DF Lizenz im Bereich Management & Administration}
    FullTimeEmployeesInSoccerTrainingDoubleArea {Mitarbeiter mit DF Lizenz im Bereich Sport}
    FullTimeEmployeesInSupportDoubleArea {Mitarbeiter mit DF Lizenz im Bereich Unterstützung}

    PartTimeEmployees {Mitarbeiter in TZ}
    PartTimeEmployeesLeadership {Mitarbeiter in TZ im Bereich Leitung}
    PartTimeEmployeesManagement {Mitarbeiter in TZ im Bereich Management & Administration}
    PartTimeEmployeesMisc {Gesamtanzahl Mitarbeiter in TZ im Bereich Sonstiges}
    PartTimeEmployeesSoccerTraining {Mitarbeiter in TZ im Bereich Sport}
    PartTimeEmployeesSupport {Mitarbeiter in TZ im Bereich Unterstützung}

    ALicenseEmployees {Mitarbeiter mit A-Lizenz}
    APlusLicenseEmployees {Mitarbeiter mit A+-Lizenz}
    BLicenseEmployees {Mitarbeiter mit B-Lizenz}
    BPlusLicenseEmployees {Mitarbeiter mit B+-Lizenz}
    CLicenseEmployees {Mitarbeiter mit C-Lizenz}
    ProLicenseEmployees {Mitarbeiter mit Pro-Lizenz}

    AverageClubMembershipTime {Durchschnittliche Zugehörigkeitszeit zum Club (Gesamt)}
    LeadershipAverageClubMembershipTime {Durchschnittliche Zugehörigkeitszeit zum Club im Bereich Leitung}
    ManagementAverageClubMembershipTime {Durchschnittliche Zugehörigkeitszeit zum Club im Bereich Management & Administration}
    SoccerTrainingAverageClubMembershipTime {Durchschnittliche Zugehörigkeitszeit zum Club im Bereich Sport}
    SupportAverageClubMembershipTime {Durchschnittliche Zugehörigkeitszeit zum Club im Bereich Unterstützung}


    U14Minimum3SeasonsAffiliationQuote {[%] U14 – mind. 3 Saisons (max)}
    U15Minimum3SeasonsAffiliationQuote {[%] U15 – mind. 3 Saisons}
    U15Minimum4SeasonsAffiliationQuote {[%] U15 – mind. 4 Saisons (max)}
    U16Minimum3SeasonsAffiliationQuote {[%] U16 – mind. 3 Saisons}
    U16Minimum5SeasonsAffiliationQuote {[%] U16 – mind. 5 Saisons (max)}
    U17Minimum3SeasonsAffiliationQuote {[%] U17 – mind. 3 Saisons}
    U17Minimum5SeasonsAffiliationQuote {[%] U17 – mind. 5 Saisons}
    U17Minimum6SeasonsAffiliationQuote {[%] U17 – mind. 6 Saisons (max)}
    U18Minimum3SeasonsAffiliationQuote {[%] U18 – mind. 3 Saisons}
    U18Minimum5SeasonsAffiliationQuote {[%] U18 – mind. 5 Saisons}
    U18Minimum7SeasonsAffiliationQuote {[%] U18 – mind. 7 Saisons (max)}
    U19Minimum3SeasonsAffiliationQuote {[%] U19 – mind. 3 Saisons}
    U19Minimum5SeasonsAffiliationQuote {[%] U19 – mind. 5 Saisons}
    U19Minimum8SeasonsAffiliationQuote {[%] U19 – mind. 8 Saisons (max)}

    U14OverallAffiliationQuote {[%] U14 - Gesamtausbildungsanteil}
    U15OverallAffiliationQuote {[%] U15 - Gesamtausbildungsanteil}
    U16OverallAffiliationQuote {[%] U16 - Gesamtausbildungsanteil}
    U17OverallAffiliationQuote {[%] U17 - Gesamtausbildungsanteil}
    U18OverallAffiliationQuote {[%] U18 - Gesamtausbildungsanteil}
    U19OverallAffiliationQuote {[%] U19 - Gesamtausbildungsanteil}

    U20U22Minimum3SeasonsAffiliationQuote {[%] Ausbildungszeit - mind. 3 Jahre}
    U20U22Minimum5SeasonsAffiliationQuote {[%] Ausbildungszeit - mind. 5 Jahre}
    U20U22Minimum9SeasonsAffiliationQuote {[%] Ausbildungszeit - mind. 9 Jahre}
    U20U22OverallAffiliationQuote {[%] Gesamtausbildungsanteil}
    U20U22TotalPlayers {[#] Anzahl Spieler}

    TrainedPlayersAverageCompetitiveMinutes {[Ø] Einsatzminuten ausgebildeter Spieler}
    TrainedPlayersCompetitiveMinutes {[#] Gesamteinsatzminuten ausgebildeter Spieler}
    TrainedPlayersMinimum3Seasons {[#] Ausgebildete Spieler - mind. 3 Jahre}
    TrainedPlayersMinimum3SeasonsPercentage {[%] Ausgebildete Spieler - mind. 3 Jahre}
    TrainedPlayersMinimum5Seasons {[#] Ausgebildete Spieler - mind. 5 Jahre}
    TrainedPlayersMinimum8Seasons {[#] Ausgebildete Spieler - mind. 8 Jahre}
    TrainedPlayersOverallAffiliation {[%] Gesamtausbildungsanteil}
    TrainedPlayersPercentageCompetitiveMinutes {[%] Einsatzminuten ausgebildeter Spieler}

    ForeignTrainedPlayersAverageCompetitiveMinutes {[Ø] Einsatzminuten ausgebildeter Spieler}
    ForeignTrainedPlayersAverageCompetitiveMinutesPremierLeague {[Ø] Einsatzminuten - BL}
    ForeignTrainedPlayersAverageCompetitiveMinutesSecondLeague {[Ø] Einsatzminuten - 2. BL}
    ForeignTrainedPlayersAverageCompetitiveMinutesThirdLeague {[Ø] Einsatzminuten - 3. L}
    ForeignTrainedPlayersCompetitiveMinutes {[#] Gesamteinsatzminuten ausgebildeter Spieler}
    ForeignTrainedPlayersCompetitiveMinutesPremierLeague {[#] Gesamteinsatzminuten - BL}
    ForeignTrainedPlayersCompetitiveMinutesSecondLeague {[#] Gesamteinsatzminuten - 2. BL}
    ForeignTrainedPlayersCompetitiveMinutesThirdLeague {[#] Gesamteinsatzminuten - 3. L}
    ForeignTrainedPlayersOverallAffiliation {[%] Gesamtausbildungsanteil}
    ForeignTrainedPlayersPremierLeague {[#] Ausgebildete Spieler - Bundesliga}
    ForeignTrainedPlayersSecondLeague {[#] Ausgebildete Spieler - 2. Bundesliga}
    ForeignTrainedPlayersThirdLeague {[#] Ausgebildete Spieler - 3. Liga}
  }
</ng-container>
