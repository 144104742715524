import { SoccerClub } from '../../models/soccerClub';
import { Detail, DetailCalculator } from './detailCalculations';
import { Kpi } from './kpi';

export class DetailCalculatorMedian extends DetailCalculator {
  public calculate(kpi: Kpi, soccerClub: SoccerClub | null): Detail {
    const detail: Detail = super.calculate(kpi, soccerClub);
    detail.baseline = 'median';
    detail.baselineValue = kpi.summary.median;
    detail.multiValues = kpi.summary.multiValues ? [kpi.summary.multiValues[0], kpi.summary.multiValues[2]] : undefined;

    return detail;
  }
}
