import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faExclamation, faThumbsUp } from '@fortawesome/free-solid-svg-icons';

import { Kpi } from '../../models/kpi';

@Component({
  selector: 'lsz-category-kpi-select',
  templateUrl: './category-kpi-select.component.html',
  styleUrls: ['./category-kpi-select.component.scss'],
})
export class CategoryKpiSelectComponent implements OnInit {

  public get kpis(): Kpi[] {
    return this._kpis;
  }

  @Input()
  public set kpis(ratedKpis: Kpi[]) {
    this._kpis = ratedKpis;
    if (this.initialized) {
      for (const kpi of this.kpis) {
        if (kpi.name === this.selectedKpi.name) {
          this.onKpiClicked(kpi);
          break;
        }
      }
    }
  }

  @Input() public baseline: string;
  @Output() public kpiClicked: EventEmitter<Kpi>;
  public faExclamation: unknown;
  public faThumbsUp: unknown;
  public selectedKpi: Kpi;
  private initialized: boolean;

  private _kpis: Kpi[];

  constructor() {
    this.kpiClicked = new EventEmitter<Kpi>();
    this.faExclamation = faExclamation;
    this.faThumbsUp = faThumbsUp;
    this.initialized = false;
  }

  public ngOnInit(): void {
    this.onKpiClicked(this.kpis[0]);
    this.initialized = true;
  }

  public onKpiClicked(kpi: Kpi): void {
    this.kpiClicked.emit(kpi);
    this.selectedKpi = kpi;
  }
}
