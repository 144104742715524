import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BaseEditFormComponent } from '../../../shared/base-edit-form/base-edit-form.component';

@Component({
  selector: 'tbody[lsz-indoor-hall-form]',
  templateUrl: './indoor-hall-form.component.html',
  styleUrls: ['./indoor-hall-form.component.css'],
})
export class IndoorHallFormComponent extends BaseEditFormComponent {

  public get surface(): AbstractControl {
    return this.form.get('surface');
  }

  public get length(): AbstractControl {
    return this.form.get('length');
  }

  public get width(): AbstractControl {
    return this.form.get('width');
  }

  public get isOnLzArea(): AbstractControl {
    return this.form.get('isOnLzArea');
  }

  public get isLzExclusive(): AbstractControl {
    return this.form.get('isLzExclusive');
  }

  constructor() {
    super();
  }
}
