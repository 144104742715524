<span *ngIf="form?.get('updatedAtUtc')?.value as updatedAt; else createdAt">
      {{updatedAt | date:'dd.MM.yyyy'}}
    </span>

<ng-template #createdAt>
      <span *ngIf="form?.get('createdAtUtc')?.value as createdAt; else noDate">
        {{createdAt | date:'dd.MM.yyyy'}}</span>
</ng-template>

<ng-template #noDate>
  <span>-</span>
</ng-template>