<ngb-accordion (panelChange)="panelChange($event)" [animation]="false">
  <ngb-panel [id]="'panel-Budget'">
    <ng-template ngbPanelHeader>
      <button ngbPanelToggle class="btn btn-link container-fluid category-header">
        <span></span>
        <span i18n>Budgetgruppen anzeigen</span>
        <fa-icon [icon]="budgetHeaderIcon" size="2x"></fa-icon>
      </button>
    </ng-template>
    <ng-template ngbPanelContent>
      <div fxLayout fxLayoutAlign="space-between" class="budget-groups-container">

        <h5 i18n>Budgetgruppen:</h5>

        <table class="budget-groups">
          <tr *ngFor="let budgetGroup of budgetGroups$ | async">
            <td class="club-icon">
              <ng-container *ngIf="club$ | async as club">
                <img *ngIf="club?.budget === budgetGroup?.name && !hideClubLogo" [src]="club?.logoImageUrl" [alt]="'Vereinslogo von ' + club?.name">
              </ng-container>
            </td>
            <td class="name">{{ budgetGroup?.name }}:</td>
            <td>
              <ng-container *ngIf="budgetGroup?.max === undefined">&gt;</ng-container>
              {{ budgetGroup?.min | number:'1.0' }}
            </td>
            <td>
              <ng-container *ngIf="budgetGroup?.min !== undefined && budgetGroup?.max !== undefined">-</ng-container>
            </td>
            <td>
              <ng-container *ngIf="budgetGroup?.min === undefined">&lt;</ng-container>
              {{ budgetGroup?.max | number:'1.0' }}
            </td>
          </tr>
        </table>

      </div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
