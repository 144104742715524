import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'lsz-category-kpi-name',
  templateUrl: './category-kpi-name.component.html',
  styleUrls: ['./category-kpi-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryKpiNameComponent {

  @Input() public name: string;

}
