import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { FunctionalRoom } from '../../models/models';
import { BaseEditFormComponent } from '../../../shared/base-edit-form/base-edit-form.component';

@Component({
  selector: 'tbody[lsz-functional-room-typed-form]',
  templateUrl: './functional-room-typed-form.component.html',
  styleUrls: ['./functional-room-typed-form.component.css'],
})
export class FunctionalRoomTypedFormComponent extends BaseEditFormComponent<FunctionalRoom> {

  public get count(): AbstractControl {
    return this.form.get('count');
  }

  @Input() public entry: FunctionalRoom;
  @Input() public toggle: boolean;

  constructor() {
    super();
  }

  public onClickSave(): void {
    this.entrySaved.emit({
      ...this.entry,
      count: Number(this.count.value),
      remark: this.remark.value as string,
    });
  }
}
