import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'fromDatepicker',
})
export class FromDatepickerPipe implements PipeTransform {

  public transform(value: string): string {
    return moment(value, 'DD.MM.YYYY').format('YYYY-MM-DDThh:mm:ss');
  }

}
