import { SoccerClubLeague } from '../../models/soccerClub';

import { BaseKpisProcessor } from './baseKpisProcessor';
import { Criteria } from './criteria';
import { Kpi } from './kpi';
import { KpiRating } from './kpiRater';
import { KpiSummary } from './kpiSummary';
import { Kpis, SeasonKpi } from './kpis';
import { KpisProcessResult } from './kpisProcessResult';

export class KpisHistoryProcessor extends BaseKpisProcessor {

  public process(kpis: Kpis[]): KpisProcessResult {
    const result: Criteria = {};
    const daysOfYear: number = 365;

    if (Array.isArray(kpis)) {
      this.initCriteria(kpis[0], result);
      this.initCategories(kpis[0], result);

      kpis.forEach((_kpis: Kpis) => {
        _kpis.seasonKpiCollection.forEach((seasonKpi: SeasonKpi) => {

          if (seasonKpi.kpiCriteria === 'Personnel' && seasonKpi.kpiCategory === 'Membership') {
            const mySummaryValues: Record<string, any> = {};

            seasonKpi.summary.avg = Math.floor(seasonKpi.summary.avg / daysOfYear);
            seasonKpi.summary.median = Math.floor(seasonKpi.summary.median / daysOfYear);

            for (const [key] of Object.entries(seasonKpi.summary.values)) {
              const newKey: string = Math.floor(Number(key) / daysOfYear).toString();
              if (newKey in mySummaryValues) {
                mySummaryValues[newKey] += seasonKpi.summary.values[key];
              } else {
                mySummaryValues[newKey] = seasonKpi.summary.values[key];
              }
            }
            seasonKpi.summary.values = mySummaryValues;

            for (const myClub of seasonKpi.soccerClubKpiCollection) {
              myClub.sum = Math.floor(myClub.sum / daysOfYear);
            }
          }

          const kpisData: Kpi[] = result[seasonKpi.kpiCriteria].categories[seasonKpi.kpiCategory].kpis;

          const currentKpi: Kpi = kpisData.find((_currentKpi: Kpi) => _currentKpi.name === seasonKpi.kpiName);
          if (currentKpi === undefined) {
            kpisData.push({
              name: seasonKpi.kpiName,
              type: seasonKpi.kpiType,
              teamsSummaries: this.rateTeams(seasonKpi, true),
              summary: this.convertSummary(seasonKpi),
              rating: undefined,
            });
          } else {
            if (currentKpi) {
              const rating: KpiRating = this.rater.rate(seasonKpi,
                !this.soccerClub?.isAdmin ? this.soccerClub?.id : null,
                true);
              const [own, avg, median] = currentKpi.summary.multiValues;
              own[seasonKpi.season] = rating.own;
              avg[seasonKpi.season] = seasonKpi.summary.avg;
              median[seasonKpi.season] = seasonKpi.summary.median;
            }
          }
        });
      });
      const kpisProcessResult: KpisProcessResult = { criteria: result };

      kpis.forEach((_kpis: Kpis) => this.rateAll(kpisProcessResult, _kpis, this.soccerClub, true));

      return kpisProcessResult;
    }

    return undefined;
  }

  private convertSummary(seasonKpi: SeasonKpi): KpiSummary {
    const rating: KpiRating = this.rater.rate(seasonKpi, !this.soccerClub?.isAdmin ? this.soccerClub?.id : null, true);

    return {
      ...seasonKpi.summary,
      multiValues: [
        { [seasonKpi.season]: rating.own },
        { [seasonKpi.season]: seasonKpi.summary.avg },
        { [seasonKpi.season]: seasonKpi.summary.median },
      ],
      leagues: this.soccerClub.leagues.map((league: SoccerClubLeague) => league?.league),
    };
  }

}
