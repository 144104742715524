<div fxLayout="column" class="input-container">
  <div class="lsz-input-group" fxLayout="row">
    <input ngbDatepicker #dp="ngbDatepicker" type="text" [formControl]="formCtrl"/>
    <button (click)="dp.toggle()" type="button">
      <fa-icon [icon]="faCalendar"></fa-icon>
    </button>
  </div>
  <div *ngIf="formCtrl.invalid && (formCtrl.dirty || formCtrl.touched)">
    <div class="input-error" *ngIf="formCtrl.errors.required">{{requiredErrorMsg}}</div>
    <div class="input-error" *ngIf="formCtrl.errors.dateValidator">{{invalidErrorMsg}}</div>
    <div class="input-error" *ngIf="formCtrl.errors.dateValidatorIsNotPast">{{datePastErrorMsg}}</div>
    <div class="input-error" *ngIf="formCtrl.errors.dateValidatorIsBeforeOtherDate">{{dateBeforeOtherErrorMsg}}</div>
  </div>
</div>
