import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faCheck, faPen } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'lsz-edit-form-header',
  templateUrl: './edit-form-header.component.html',
  styleUrls: ['./edit-form-header.component.scss'],
})
export class EditFormHeaderComponent {
  @Input() public headerText: string;
  @Input() public numEntries: number;
  @Input() public editMode: boolean;
  @Output() public clickEdit: EventEmitter<void>;
  @Output() public clickEditDone: EventEmitter<void>;

  public faCheck: unknown;
  public faPen: unknown;

  constructor() {
    this.clickEdit = new EventEmitter<void>();
    this.clickEditDone = new EventEmitter<void>();
    this.faCheck = faCheck;
    this.faPen = faPen;
  }

  public onClickEdit(): void {
    this.clickEdit.emit();
  }

  public onClickEditDone(): void {
    this.clickEditDone.emit();
  }
}
