import { Component } from '@angular/core';
import { ManagementPartTimeEmployee } from '../../models/models';
import { ManagementPartTimeEmployeeService } from '../../services/management-part-time-employee.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Validators } from '../../validators';
import { ConfirmationDialogService } from '../../../shared/confirmation-dialog/services/confirmation-dialog.service';
import { BaseManagementPartTimeEmployeeComponent } from '../../../shared/base-part-time-employee/base-management-part-time-employee.component';

@Component({
  selector: 'lsz-part-time-employee-management-form-collection',
  templateUrl: './part-time-employee-management-form-collection.component.html',
  styleUrls: ['./part-time-employee-management-form-collection.component.css'],
})
export class PartTimeEmployeeManagementFormCollectionComponent extends BaseManagementPartTimeEmployeeComponent {

  public get formName(): string {
    return 'managementPartTimeEmployee';
  }

  public get type(): string {
    return 'Management';
  }

  constructor(protected service: ManagementPartTimeEmployeeService,
              protected confirmationDialog: ConfirmationDialogService) {
    super(service, confirmationDialog);

  }

  protected formGroup(entry?: ManagementPartTimeEmployee): UntypedFormGroup {
    return new UntypedFormGroup({
      managementPartTimeEmployee: new UntypedFormGroup({
        personnelCount: new UntypedFormControl(entry?.personnelCount, Validators.required),
        updatedAtUtc: new UntypedFormControl(entry?.updatedAtUtc),
        createdAtUtc: new UntypedFormControl(entry?.createdAtUtc),
      }),
    });
  }

  protected resetValues(): { [key: string]: { [key: string]: number | string } } {
    return {
      managementPartTimeEmployee: {
        personnelCount: this.entry.personnelCount,
        updatedAtUtc: this.entry?.updatedAtUtc,
        createdAtUtc: this.entry?.createdAtUtc,
      },
    };
  }
}
