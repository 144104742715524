import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BaseEditFormComponent } from '../../../shared/base-edit-form/base-edit-form.component';

@Component({
  selector: 'tbody[lsz-education-employee-form]',
  templateUrl: './education-employee-form.component.html',
  styleUrls: ['./education-employee-form.component.css'],
})
export class EducationEmployeeFormComponent extends BaseEditFormComponent {

  public get birthdate(): AbstractControl {
    return this.form.get('birthdate');
  }

  public get dateOfJoiningClub(): AbstractControl {
    return this.form.get('dateOfJoiningClub');
  }

  public get personnelFunction(): AbstractControl {
    return this.form.get('personnelFunction');
  }

  constructor() {
    super();
  }
}
