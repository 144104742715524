export interface Job {
  createdAtUtc: string;
  createdBy: string;
  finishedAtUtc: string;
  id: string;
  reason: string;
  season: string;
  startAtUtc: string;
  state: JobState;
  updatedAtUtc: string;
  updatedBy: string;
  importer: string;
}

export enum JobState {
  NONE = 'None',
  SCHEDULED = 'Scheduled',
  IN_PROGRESS = 'InProgress',
  SUCCEEDED = 'Succeeded',
  FAILED = 'Failed',
}
