import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Pipe({
  pure: false,
  name: 'filterByPersonnelArea',
})
export class FilterByPersonnelAreaPipe implements PipeTransform {

  transform(value: AbstractControl[], personnelArea: string): AbstractControl[] {
    if (value) {
      return value.filter((control: AbstractControl) => control.value.personnelArea === personnelArea);
    }

    return value;
  }

}
