<tr [formGroup]="form">
  <td i18n="@@schoolCooperationType">
    {entry.personnelFunction, select,
      Provisioning {Mitarbeiter Verpflegung}
      ShuttleService {Mitarbeiter Fahrdienst}
      Greenkeeper {Mitarbeiter Greenkeeping}
      Miscellaneous {Sonstige Mitarbeiter}
    }
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{fullTimePersonnel.value}}</ng-container>
    <input *ngIf="editMode" type="number" min="0" formControlName="fullTimePersonnel" />
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{partTimePersonnel.value}}</ng-container>
    <input *ngIf="editMode" type="number" min="0" formControlName="partTimePersonnel" />
  </td>
  <td *ngIf="editMode">
    <lsz-dataset-options [form]="form" [withoutDelete]="true" (save)="onClickSave()"></lsz-dataset-options>
  </td>
  <td>
    <lsz-last-edit [form]="form"></lsz-last-edit>
  </td>
</tr>
