<tr [formGroup]="form">
  <td>
    <ng-container *ngIf="!editMode">{{lastName.value}}</ng-container>
    <div *ngIf="editMode">
      <input type="text" formControlName="lastName" required [placeholder]="index === -1 ? 'Neuer Eintrag' : ''" i18n-placeholder="@@placeholderNewEntry"/>
      <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
        <div class="input-error" *ngIf="lastName.errors.required">Angabe wird benötigt.</div>
      </div>
    </div>
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{firstName.value}}</ng-container>
    <div *ngIf="editMode">
      <input type="text" formControlName="firstName" required/>
      <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
        <div class="input-error" *ngIf="firstName.errors.required">Angabe wird benötigt.</div>
      </div>
    </div>
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{birthdate.value}}</ng-container>
    <div *ngIf="editMode">
      <input ngbDatepicker #dpBirthdate="ngbDatepicker" type="text" formControlName="birthdate" required/>
      <button (click)="dpBirthdate.toggle()" type="button">
        <fa-icon [icon]="faCalendar"></fa-icon>
      </button>
      <div *ngIf="birthdate.invalid && (birthdate.dirty || birthdate.touched)">
        <div class="input-error" *ngIf="birthdate.errors.required">Angabe wird benötigt.</div>
        <div class="input-error" *ngIf="birthdate.errors.dateValidator">Fehlerhaftes Datum</div>
      </div>
    </div>
  </td>
  <td>
    <ng-container *ngIf="!editMode" i18n="@@personnelFunction">
      {personnelFunction.value, select,
        None {keine}
        LszLeadership {Leiter LZ}
        SportLeadership {Sportlicher Leiter}
        OrganizationalLeadership {Organisatorischer Leiter}
        AdministrativeCoworker {Administrative Mitarbeiter}
        SportAreaManager {Sportlicher Bereichsleiter}
        TeamTrainer {Mannschaftstrainer}
        GoalKeeperTrainer {Torwarttrainer}
        SpecialTrainer {Spezialtrainer}
        SportPsychologist {Sportpsychologische Begleitung (Sport/Soziales)}
        MatchAnalysis {Spielanalyse}
        AthleticTrainer {Athletik-/Reha-Trainer}
        SportScientist {Sportwissenschaftler (inkl. Leistungsdiagnostik)}
        TransitionCoordinator {Übergangsbereich}
        PedagogicalSupport {Pädagogische Begleitung (Schule/Sozialpädagogik)}
        Scouting {Scouting}
        Physio {Physiotherapeut}
        Doctor {Ärzte}
      }
    </ng-container>
    <div *ngIf="editMode">
      <select formControlName="personnelFunction" required size="1" *ngIf="type === 'Leadership'">
          <option value="LszLeadership">Leiter LZ</option>
          <option value="SportLeadership">Sportlicher Leiter</option>
          <option value="OrganizationalLeadership">Organisatorischer Leiter</option>
      </select>
      <select formControlName="personnelFunction" required size="1" *ngIf="type === 'Management'">
        <option value="AdministrativeCoworker">Administrative Mitarbeiter</option>
      </select>

      <select formControlName="personnelFunction" required size="1" *ngIf="type === 'SoccerTraining'">
        <option value="SportAreaManager">Sportlicher Bereichsleiter</option>
        <option value="TeamTrainer">Mannschaftstrainer</option>
        <option value="GoalKeeperTrainer">Torwarttrainer</option>
        <option value="SpecialTrainer">Spezialtrainer</option>
        <option value="SportPsychologist">Sportpsychologische Begleitung (Sport/Soziales)</option>
        <option value="MatchAnalysis">Spielanalyse</option>
        <option value="AthleticTrainer">Athletik-/Reha-Trainer</option>
        <option value="SportScientist">Sportwissenschaftler (inkl. Leistungsdiagnostik)</option>
        <option value="TransitionCoordinator">Übergangsbereich</option>
      </select>
      <select formControlName="personnelFunction" required size="1" *ngIf="type === 'Support'">
        <option value="PedagogicalSupport">Pädagogische Begleitung (Schule/Sozialpädagogik)</option>
        <option value="Scouting">Scouting</option>
        <option value="Physio">Physiotherapeut</option>
        <option value="Doctor">Ärzte</option>
      </select>
      <div *ngIf="personnelFunction.invalid && (personnelFunction.dirty || personnelFunction.touched)">
        <div class="input-error" *ngIf="personnelFunction.errors.required">Angabe wird benötigt.</div>
      </div>
    </div>
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{dateOfJoiningClub.value | yearsAgo}}</ng-container>
    <div *ngIf="editMode">
      <input ngbDatepicker #dpJoiningClub="ngbDatepicker" type="text" formControlName="dateOfJoiningClub" required/>

      <button (click)="dpJoiningClub.toggle()" type="button">
        <fa-icon [icon]="faCalendar"></fa-icon>
      </button>
      <div *ngIf="dateOfJoiningClub.invalid && (dateOfJoiningClub.dirty || dateOfJoiningClub.touched)">
        <div class="input-error" *ngIf="dateOfJoiningClub.errors.required">Angabe wird benötigt.</div>
        <div class="input-error" *ngIf="dateOfJoiningClub.errors.dateValidator">Fehlerhaftes Datum</div>
      </div>
    </div>
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{dateOfJoiningFunction.value | yearsAgo}}</ng-container>
    <div *ngIf="editMode">
      <input ngbDatepicker #dpJoiningFunction="ngbDatepicker" type="text" formControlName="dateOfJoiningFunction" required/>
      <button (click)="dpJoiningFunction.toggle()" type="button">
        <fa-icon [icon]="faCalendar"></fa-icon>
      </button>
      <div *ngIf="dateOfJoiningFunction.invalid && (dateOfJoiningFunction.dirty || dateOfJoiningFunction.touched)">
        <div class="input-error" *ngIf="dateOfJoiningFunction.errors.required">Angabe wird benötigt.</div>
        <div class="input-error" *ngIf="dateOfJoiningFunction.errors.dateValidator">Fehlerhaftes Datum</div>
      </div>
    </div>
  </td>
  <td>
    <ng-container *ngIf="!editMode" i18n="@@personnelFunction">
      {additionalPersonnelFunction.value, select,
        None {keine}
        LszLeadership {Leiter LZ}
        SportLeadership {Sportlicher Leiter}
        OrganizationalLeadership {Organisatorischer Leiter}
        AdministrativeCoworker {Administrative Mitarbeiter}
        SportAreaManager {Sportlicher Bereichsleiter}
        TeamTrainer {Mannschaftstrainer}
        GoalKeeperTrainer {Torwarttrainer}
        SpecialTrainer {Spezialtrainer}
        SportPsychologist {Sportpsychologische Begleitung (Sport/Soziales)}
        MatchAnalysis {Spielanalyse}
        AthleticTrainer {Athletik-/Reha-Trainer}
        SportScientist {Sportwissenschaftler (inkl. Leistungsdiagnostik)}
        TransitionCoordinator {Übergangsbereich}
        PedagogicalSupport {Pädagogische Begleitung (Schule/Sozialpädagogik)}
        Scouting {Scouting}
        Physio {Physiotherapeut}
        Doctor {Ärzte}
      }
    </ng-container>
    <div *ngIf="editMode">
      <select formControlName="additionalPersonnelFunction" required size="1">
        <option value="None">keine</option>
        <optgroup label="Leitung">
          <option value="LszLeadership">Leiter LZ</option>
          <option value="SportLeadership">Sportlicher Leiter</option>
          <option value="OrganizationalLeadership">Organisatorischer Leiter</option>
        </optgroup>
        <optgroup label="Management &amp; Administration">
          <option value="AdministrativeCoworker">Administrative Mitarbeiter</option>
        </optgroup>
        <optgroup label="Fußballausbildung">
          <option value="SportAreaManager">Sportlicher Bereichsleiter</option>
          <option value="TeamTrainer">Mannschaftstrainer</option>
          <option value="GoalKeeperTrainer">Torwarttrainer</option>
          <option value="SpecialTrainer">Spezialtrainer</option>
          <option value="SportPsychologist">Sportpsychologische Begleitung (Sport/Soziales)</option>
          <option value="MatchAnalysis">Spielanalyse</option>
          <option value="AthleticTrainer">Athletik-/Reha-Trainer</option>
          <option value="SportScientist">Sportwissenschaftler (inkl. Leistungsdiagnostik)</option>
          <option value="TransitionCoordinator">Übergangsbereich</option>
        </optgroup>
        <optgroup label="Unterstützung">
          <option value="PedagogicalSupport">Pädagogische Begleitung (Schule/Sozialpädagogik)</option>
          <option value="Scouting">Scouting</option>
          <option value="Physio">Physiotherapeut</option>
          <option value="Doctor">Ärzte</option>
        </optgroup>
      </select>
      <div
        *ngIf="additionalPersonnelFunction.invalid && (additionalPersonnelFunction.dirty || additionalPersonnelFunction.touched)">
        <div class="input-error" *ngIf="additionalPersonnelFunction.errors.required">Angabe wird benötigt.</div>
      </div>
    </div>
  </td>
  <td>
    <ng-container *ngIf="!editMode" i18n="@@additionalPersonnelArea">
      {additionalPersonnelArea.value, select,
        None {keine}
        Management {Management}
        Support {Unterstützung}
        SoccerTraining {Fußballausbildung}
        Misc {Sonstiges}
      }
    </ng-container>
    <div *ngIf="editMode">
      <select formControlName="additionalPersonnelArea" required size="1">
        <option value="None">keine</option>
        <option value="Management">Management</option>
        <option value="Support">Unterstützung</option>
        <option value="SoccerTraining">Fußballausbildung</option>
        <option value="Misc">Sonstiges</option>
      </select>
      <div
        *ngIf="additionalPersonnelArea.invalid && (additionalPersonnelArea.dirty || additionalPersonnelArea.touched)">
        <div class="input-error" *ngIf="additionalPersonnelArea.errors.required">Angabe wird benötigt.</div>
      </div>
    </div>
  </td>
  <td>
    <ng-container *ngIf="!editMode" i18n="@@trainerCertifications">
      {trainerCertification.value, select,
        CLicense {C-Lizenz}
        BLicense {B-Lizenz}
        BPlusLicense {B+-Lizenz}
        ALicense {A-Lizenz}
        APlusLicense {A+-Lizenz}
        ProLicense {Pro-Lizenz}
        None {keine}
      }
    </ng-container>
    <div *ngIf="editMode">
      <select formControlName="trainerCertification" required size="1">
        <option value="CLicense">C-Lizenz</option>
        <option value="BLicense">B-Lizenz</option>
        <option value="BPlusLicense">B+-Lizenz</option>
        <option value="ALicense">A-Lizenz</option>
        <option value="APlusLicense">A+-Lizenz</option>
        <option value="ProLicense">Pro-Lizenz</option>
        <option value="None">keine</option>
      </select>
      <div *ngIf="trainerCertification.invalid && (trainerCertification.dirty || trainerCertification.touched)">
        <div class="input-error" *ngIf="trainerCertification.errors.required">Angabe wird benötigt.</div>
      </div>
    </div>
  </td>
  <td *ngIf="type === 'SoccerTraining'">
    <ng-container *ngIf="!editMode" i18n="@@goalKeeperLicenses">
      {goalKeeperLicense.value, select,
        Basic {TWT - Lizenz Basis}
        Performance {TWT - Lizenz Leistung}
        UefaWithALicense {UEFA-Torwarttrainer mit A-Lizenz}
        None {keine}
      }
    </ng-container>
    <div *ngIf="editMode">
      <select *ngIf="editMode" formControlName="goalKeeperLicense" required size="1">
        <option value="Basic">TWT - Lizenz Basis</option>
        <option value="Performance">TWT - Lizenz Leistung</option>
        <option value="UefaWithALicense">UEFA-Torwarttrainer mit A-Lizenz</option>
        <option value="None">keine</option>
      </select>
      <div *ngIf="goalKeeperLicense.invalid && (goalKeeperLicense.dirty || goalKeeperLicense.touched)">
        <div class="input-error" *ngIf="goalKeeperLicense.errors.required">Angabe wird benötigt.</div>
      </div>
    </div>
  </td>
  <td>
    <ng-container *ngIf="!editMode" i18n="@@professionalGroups">
      {professionalGroup.value, select,
        Management {Management}
        Sport {Sport}
        Psychology {Psychologie}
        Pedagogy {Pädagogik}
        Medicine {Medizin}
        Other {Sonstiges}
        None {keine}
      }
    </ng-container>
    <div *ngIf="editMode">
      <select formControlName="professionalGroup" required size="1" (change)="onProfessionalGroupChange()">
        <option value="Management">Management</option>
        <option value="Sport">Sport</option>
        <option value="Psychology">Psychologie</option>
        <option value="Pedagogy">Pädagogik</option>
        <option value="Medicine">Medizin</option>
        <option value="Other">Sonstiges</option>
        <option value="None">keine</option>
      </select>
      <div *ngIf="professionalGroup.invalid && (professionalGroup.dirty || professionalGroup.touched)">
        <div class="input-error" *ngIf="professionalGroup.errors.required">Angabe wird benötigt.</div>
      </div>
    </div>
  </td>
  <td>
    <ng-container *ngIf="!editMode" i18n="@@professionalQualifications">
      {professionalQualification.value, select,
        None {keiner}
        Study {Studium}
        VocationalTraining {Berufsausbildung}
        AdditionalQualification {Zusatzqualifikation}
      }
    </ng-container>
    <div *ngIf="editMode">
      <select formControlName="professionalQualification" required size="1">
        <option value="None">keiner</option>
        <option value="Study">Studium</option>
        <option value="VocationalTraining">Berufsausbildung</option>
        <option value="AdditionalQualification">Zusatzqualifikation</option>
      </select>
      <div *ngIf="professionalQualification.invalid && (professionalQualification.dirty || professionalQualification.touched)">
        <div class="input-error" *ngIf="professionalQualification.errors.required">Angabe wird benötigt.</div>
      </div>
    </div>
  </td>
  <td style="white-space: nowrap;">
    <ng-container *ngIf="!editMode">
      <ng-container *ngFor="let team of assistedTeams; let i = index" i18n="@@assistedTeams">
        <ng-container *ngIf="i > 0">,</ng-container>
        {team, select,
          None {keine}
          U12 {U12}
          U13 {U13}
          U14 {U14}
          U15 {U15}
          U16 {U16}
          U17 {U17}
          U1617 {U16/17}
          U19 {U19}
          U2123 {U21/U23}
          AllTeams {Alle LZ-Mannschaften}
          PerformanceArea {Leistungsbereich}
          BuildUpArea {Aufbaubereich}
          FoundationArea {Grundlagenbereich}
        }
      </ng-container>
    </ng-container>
    <ng-container *ngIf="editMode">
      <label><input type="checkbox" formControlName="assistedTeamU12" value="U12"/>U12</label>
      <label><input type="checkbox" formControlName="assistedTeamU13" value="U13"/>U13</label>
      <label><input type="checkbox" formControlName="assistedTeamU14" value="U14"/>U14</label>
      <label><input type="checkbox" formControlName="assistedTeamU15" value="U15"/>U15</label>
      <label><input type="checkbox" formControlName="assistedTeamU16" value="U16"/>U16</label>
      <label><input type="checkbox" formControlName="assistedTeamU17" value="U17"/>U17</label>
      <label><input type="checkbox" formControlName="assistedTeamU1617" value="U1617"/>U16/17</label>
      <label><input type="checkbox" formControlName="assistedTeamU19" value="U19"/>U19</label>
      <label><input type="checkbox" formControlName="assistedTeamU2123" value="U2123"/>U21/U23</label>
      <label><input type="checkbox" formControlName="assistedTeamAllTeams" value="AllTeams"/>Alle LZ-Manns.</label>
      <label><input type="checkbox" formControlName="assistedTeamPerformanceArea"
                    value="PerformanceArea"/>Leistungs.</label>
      <label><input type="checkbox" formControlName="assistedTeamBuildUpArea" value="BuildUpArea"/>Aufbaub.</label>
      <label><input type="checkbox" formControlName="assistedTeamFoundationArea"
                    value="FoundationArea" />Grundlagenb.</label>
    </ng-container>
  </td>
  <td *ngIf="editMode" class="frozen-column-options">
    <lsz-dataset-options
      [form]="form"
      [hasDeleteCheck]="index !== -1"
      (save)="onClickSave()"
      (delete)="onClickDelete()">
    </lsz-dataset-options>
  </td>
  <td [class.editMode]="editMode" class="sticky-last-edit">
    <lsz-last-edit [form]="form"></lsz-last-edit>
  </td>
</tr>
