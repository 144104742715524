<div id="admin-dropdown" *ngIf="show" lszHandleClickOutside (outsideClick)="onOutsideClick()">
  <div class="spacer" (click)="onTopClick()"></div>
  <div class="content">

    <div *ngIf="canSwitchClub" class="club-switch-container">
      <lsz-club-switch [disabled]="selected.type !== 'club'">
      </lsz-club-switch>
    </div>

    <div>

      <ng-container *ngFor="let optionGroup of optionGroups; let groupIndex = index">

        <ng-container *ngIf="optionGroup.show">

          <div>
            <p *ngIf="optionGroup.label" class="options-header">{{optionGroup.label}}</p>

            <div *ngFor="let option of optionGroup.options; let optionIndex = index;" class="custom-control custom-radio">
              <input class="custom-control-input" type="radio"
                     name="options"
                     [id]="'option_'+groupIndex+'_'+optionIndex"
                     [value]="option.value"
                     [checked]="selected.value === option.value"
                     (change)="onValueChange(optionGroup.optionType, option.value)">
              <label class="custom-control-label" [for]="'option_'+groupIndex+'_'+optionIndex">
                {{option.label}}
              </label>
            </div>
          </div>


        </ng-container>

      </ng-container>

    </div>

  </div>
</div>
