<tr [formGroup]="form">
  <td>
    <ng-container *ngIf="!editMode">{{name.value}}</ng-container>
    <div *ngIf="editMode">
      <input type="text" formControlName="name"/>
      <div *ngIf="name.invalid && (name.dirty || name.touched)">
        <div class="input-error" *ngIf="name.errors.required">Name wird benötigt.</div>
        <div class="input-error" *ngIf="name.errors.repeatedName">Name kann sich nicht wiederholen.</div>
      </div>
    </div>
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{remark.value}}</ng-container>
    <textarea *ngIf="editMode" formControlName="remark"></textarea>
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{length.value}} x {{width.value}}</ng-container>
    <ng-container *ngIf="editMode">
      <input class="field-size-input" type="text" formControlName="length"/>
      <span class="input-divider">x</span>
      <input class="field-size-input" type="text" formControlName="width"/>
      <div *ngIf="length.invalid && length.touched">
        <div class="input-error" *ngIf="length.errors.required">Längenangabe wird benötigt.</div>
      </div>
      <div *ngIf="width.invalid && width.touched">
        <div class="input-error" *ngIf="width.errors.required">Breitenangabe wird benötigt.</div>
      </div>
    </ng-container>
  </td>
  <td>
    <ng-container *ngIf="!editMode" i18n="@@surfaces">
      {surface.value, select,
        DropdownLawn {Rasen}
        ArtificialLawn {Kunstrasen}
        Tartan {Tartan}
        Pvc {PVC}
      }
    </ng-container>
    <select *ngIf="editMode" formControlName="surface" size="1">
      <option value="DropdownLawn">Rasen</option>
      <option value="ArtificialLawn">Kunstrasen</option>
      <option value="Tartan">Tartan</option>
      <option value="Pvc">PVC</option>
    </select>
    <div *ngIf="surface.invalid && surface.touched">
      <div class="input-error" *ngIf="surface.errors.required">Auswahl wird benötigt.</div>
    </div>
  </td>
  <td>
    <lsz-binary-display *ngIf="!editMode" [value]="isOnLzArea.value"></lsz-binary-display>
    <lsz-binary-select *ngIf="editMode" [form]="form" [name]="'isOnLzArea'"></lsz-binary-select>
  </td>
  <td>
    <lsz-binary-display *ngIf="!editMode" [value]="isLzExclusive.value"></lsz-binary-display>
    <lsz-binary-select *ngIf="editMode" [form]="form" [name]="'isLzExclusive'"></lsz-binary-select>
  </td>
  <td *ngIf="editMode">
    <lsz-dataset-options [form]="form" (save)="entrySaved.emit()" (delete)="entryDeleted.emit()"></lsz-dataset-options>
  </td>
  <td>
    <lsz-last-edit [form]="form"></lsz-last-edit>
  </td>
</tr>
