import { Component, OnChanges } from '@angular/core';
import { MiscPartTimeEmployee } from '../../models/models';
import { MiscPartTimeEmployeeService } from '../../services/misc-part-time-employee.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Validators } from '../../validators';
import { ConfirmationDialogService } from '../../../shared/confirmation-dialog/services/confirmation-dialog.service';
import { BaseTableViewComponent } from '../../../shared/base-table-view/base-table-view.component';

@Component({
  selector: 'lsz-part-time-employee-misc-form-collection',
  templateUrl: './part-time-employee-misc-form-collection.component.html',
  styleUrls: ['./part-time-employee-misc-form-collection.component.css'],
})
export class PartTimeEmployeeMiscFormCollectionComponent
  extends BaseTableViewComponent<MiscPartTimeEmployee>
  implements OnChanges {

  public get numAvailables(): number {
    let numAvailables = 0;
    for (const entry of this.entries) {
      numAvailables += entry.fullTimePersonnel + entry.partTimePersonnel;
    }
    return numAvailables;
  }

  public provisioning: MiscPartTimeEmployee;
  public shuttleService: MiscPartTimeEmployee;
  public greenkeeper: MiscPartTimeEmployee;
  public miscellaneous: MiscPartTimeEmployee;

  constructor(protected service: MiscPartTimeEmployeeService,
              protected confirmationDialog: ConfirmationDialogService) {
    super(service, confirmationDialog);
  }

  public ngOnChanges(): void {
    if (this.soccerClub && this.season) {
      this.service.get(this.soccerClub, this.season.id).subscribe(entries => {
        this.entries = entries;

        this.initializeAllForms();

        this.updateTypedEntries();

        const formNames = [
          'provisioning',
          'shuttleService',
          'greenkeeper',
          'miscellaneous',
        ];
        for (const formName of formNames) {
          const form = this.allForms.get(formName) as UntypedFormGroup;
          form.patchValue({
            fullTimePersonnel: this[formName].fullTimePersonnel as number,
            partTimePersonnel: this[formName].partTimePersonnel as number,
            updatedAtUtc: this[formName].updatedAtUtc as string,
            createdAtUtc: this[formName].createdAtUtc as string,
          });
        }
      });
    }
  }

  public initializeAllForms(): UntypedFormGroup {
    return this.formGroup();
  }

  public exitEditMode(): void {
    this.editMode = false;

    const resetValues = {
      provisioning: {
        fullTimePersonnel: this.provisioning.fullTimePersonnel,
        partTimePersonnel: this.provisioning.partTimePersonnel,
        updatedAtUtc: this.provisioning.updatedAtUtc,
        createdAtUtc: this.provisioning.createdAtUtc,
      },
      shuttleService: {
        fullTimePersonnel: this.shuttleService.fullTimePersonnel,
        partTimePersonnel: this.shuttleService.partTimePersonnel,
        updatedAtUtc: this.shuttleService.updatedAtUtc,
        createdAtUtc: this.shuttleService.createdAtUtc,
      },
      greenkeeper: {
        fullTimePersonnel: this.greenkeeper.fullTimePersonnel,
        partTimePersonnel: this.greenkeeper.partTimePersonnel,
        updatedAtUtc: this.greenkeeper.updatedAtUtc,
        createdAtUtc: this.greenkeeper.createdAtUtc,
      },
      miscellaneous: {
        fullTimePersonnel: this.miscellaneous.fullTimePersonnel,
        partTimePersonnel: this.miscellaneous.partTimePersonnel,
        updatedAtUtc: this.miscellaneous.updatedAtUtc,
        createdAtUtc: this.miscellaneous.createdAtUtc,
      },
    };
    this.allForms.reset(resetValues);
  }

  public updateTypedEntries(): void {
    this.provisioning = this.findEntryByType('Provisioning');
    this.shuttleService = this.findEntryByType('ShuttleService');
    this.greenkeeper = this.findEntryByType('Greenkeeper');
    this.miscellaneous = this.findEntryByType('Miscellaneous');
  }

  protected formGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      provisioning: new UntypedFormGroup({
        fullTimePersonnel: new UntypedFormControl('', Validators.required),
        partTimePersonnel: new UntypedFormControl('', Validators.required),
        updatedAtUtc: new UntypedFormControl(''),
        createdAtUtc: new UntypedFormControl(''),
      }),
      shuttleService: new UntypedFormGroup({
        fullTimePersonnel: new UntypedFormControl('', Validators.required),
        partTimePersonnel: new UntypedFormControl('', Validators.required),
        updatedAtUtc: new UntypedFormControl(''),
        createdAtUtc: new UntypedFormControl(''),
      }),
      greenkeeper: new UntypedFormGroup({
        fullTimePersonnel: new UntypedFormControl('', Validators.required),
        partTimePersonnel: new UntypedFormControl('', Validators.required),
        updatedAtUtc: new UntypedFormControl(''),
        createdAtUtc: new UntypedFormControl(''),
      }),
      miscellaneous: new UntypedFormGroup({
        fullTimePersonnel: new UntypedFormControl('', Validators.required),
        partTimePersonnel: new UntypedFormControl('', Validators.required),
        updatedAtUtc: new UntypedFormControl(''),
        createdAtUtc: new UntypedFormControl(''),
      }),
    });
  }

  private findEntryByType(type: string): MiscPartTimeEmployee {
    for (const entry of this.entries) {
      if (entry.personnelFunction === type) {
        return entry;
      }
    }
    return {
      id: null,
      soccerClubId: this.soccerClub.id,
      seasonId: this.season.id,
      personnelFunction: type,
      fullTimePersonnel: 0,
      partTimePersonnel: 0,
      updatedAtUtc: null,
      createdAtUtc: null,
    };
  }
}
