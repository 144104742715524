import { Component } from '@angular/core';
import { LawnField } from '../../models/models';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Validators } from '../../validators';
import { LawnFieldService } from '../../services/lawn-field.service';
import { ConfirmationDialogService } from '../../../shared/confirmation-dialog/services/confirmation-dialog.service';
import { BaseTableViewComponent } from '../../../shared/base-table-view/base-table-view.component';

@Component({
  selector: 'lsz-lawn-field-form-collection',
  templateUrl: './lawn-field-form-collection.component.html',
  styleUrls: ['./lawn-field-form-collection.component.css'],
})
export class LawnFieldFormCollectionComponent extends BaseTableViewComponent<LawnField> {

  public get hasArtificialLawn(): AbstractControl {
    return this.newEntryForm.get('hasArtificialLawn');
  }

  constructor(protected service: LawnFieldService,
              protected confirmationDialog: ConfirmationDialogService) {
    super(service, confirmationDialog);
  }

  public createNewEntry(): void {
    const entry: LawnField = {
      id: null,
      soccerClubId: this.soccerClub.id,
      seasonId: this.season.id,
      ...this.newEntryForm.value as LawnField,
      remark: this.remark?.trim(),
    };

    this.addEntry(entry);
  }

  protected formGroup(entry?: LawnField): UntypedFormGroup {
    return new UntypedFormGroup({
      name: new UntypedFormControl(entry?.name ? entry.name : '', [Validators.required, this.repeatedNameValidator()]),
      remark: new UntypedFormControl(entry?.remark ? entry.remark : ''),
      hasFloodlight: new UntypedFormControl(entry?.hasFloodlight, Validators.required),
      isHeated: new UntypedFormControl(entry?.isHeated, Validators.required),
      isCompetitionCompliant: new UntypedFormControl(entry?.isCompetitionCompliant, Validators.required),
      hasArtificialLawn: new UntypedFormControl(entry?.hasArtificialLawn, Validators.required),
      isStadium: new UntypedFormControl(entry?.isStadium, Validators.required),
      isOnLzArea: new UntypedFormControl(entry?.isOnLzArea, Validators.required),
      isLzExclusive: new UntypedFormControl(entry?.isLzExclusive, Validators.required),
      updatedAtUtc: new UntypedFormControl(entry?.updatedAtUtc),
      createdAtUtc: new UntypedFormControl(entry?.createdAtUtc),
    });
  }

  protected objectToForm(entry?: LawnField): any {
    return {
      name: entry.name,
      remark: entry.remark,
      hasFloodlight: entry.hasFloodlight,
      isHeated: entry.isHeated,
      isCompetitionCompliant: entry.isCompetitionCompliant,
      hasArtificialLawn: entry.hasArtificialLawn,
      isStadium: entry.isStadium,
      isOnLzArea: entry.isOnLzArea,
      isLzExclusive: entry.isLzExclusive,
      updatedAtUtc: entry.updatedAtUtc,
      createdAtUtc: entry.createdAtUtc,
    };
  }
}
