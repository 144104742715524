import { Component, OnChanges } from '@angular/core';
import { SpecialEquipment } from '../../models/models';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Validators } from '../../validators';
import { SpecialEquipmentService } from '../../services/special-equipment.service';
import { ConfirmationDialogService } from '../../../shared/confirmation-dialog/services/confirmation-dialog.service';
import { BaseTableViewComponent } from '../../../shared/base-table-view/base-table-view.component';

@Component({
  selector: 'lsz-special-equipment-form-collection',
  templateUrl: './special-equipment-form-collection.component.html',
  styleUrls: ['./special-equipment-form-collection.component.css'],
})
export class SpecialEquipmentFormCollectionComponent extends BaseTableViewComponent<SpecialEquipment> implements OnChanges {

  public customTypedEntries: SpecialEquipment[];
  public helix: SpecialEquipment;
  public footbonaut: SpecialEquipment;
  public sprintHill: SpecialEquipment;
  public headerPendulum: SpecialEquipment;
  public footballTennis: SpecialEquipment;
  public otherSports: SpecialEquipment;
  public sprintTrack: SpecialEquipment;
  public virtualReality: SpecialEquipment;
  public speedCourt: SpecialEquipment;
  public altitudeChamber: SpecialEquipment;

  public get amount(): number {
    return this.typedEntries.filter(entry => entry.isAvailable).length + this.customTypedEntries.length;
  }

  constructor(protected service: SpecialEquipmentService,
              protected confirmationDialog: ConfirmationDialogService) {
    super(service, confirmationDialog);
    this.customTypedEntries = [];
  }

  public ngOnChanges(): void {
    if (this.soccerClub && this.season) {
      this.service.get(this.soccerClub, this.season.id).subscribe((entries: SpecialEquipment[]) => {
        this.entries = entries;
        this.customTypedEntries = this.entries.filter(entry => entry.equipmentType === 'Custom');
        this.typedEntries = this.entries.filter(entry => entry.equipmentType !== 'Custom');

        this.allForms = this.initializeAllForms();
        for (const entry of this.customTypedEntries) {
          this.customEntryForms.push(this.formGroup(entry));
        }

        this.updateTypedEntries();

        const typedFormNames = [
          'helix',
          'footbonaut',
          'sprintHill',
          'headerPendulum',
          'footballTennis',
          'otherSports',
          'sprintTrack',
          'virtualReality',
          'speedCourt',
          'altitudeChamber',
        ];
        for (const formName of typedFormNames) {
          const form = this.allForms.get(formName) as UntypedFormGroup;
          form.patchValue(this.objectToForm(this[formName]));
        }

      });
    }
  }

  public initializeAllForms(): UntypedFormGroup {
    return new UntypedFormGroup({
      customEntries: new UntypedFormArray([]),
      newEntry: this.formGroup(),
      helix: this.customForm(),
      footbonaut: this.customForm(),
      sprintHill: this.customForm(),
      headerPendulum: this.customForm(),
      footballTennis: this.customForm(),
      otherSports: this.customForm(),
      sprintTrack: this.customForm(),
      virtualReality: this.customForm(),
      speedCourt: this.customForm(),
      altitudeChamber: this.customForm(),
    });
  }

  public createNewEntry(): void {
    const entry: Partial<SpecialEquipment> = {
      equipmentType: 'Custom',
      isAvailable: true,
    };

    this.addEntry(entry, this.customTypedEntries);
  }

  public onUpdate(index: number): void {
    this.updateEntry(index, null, this.customTypedEntries);
  }

  public exitEditMode(): void {
    this.exitAddMode(false);
    this.editMode = false;

    const resetValues = {
      helix: this.objectToForm(this.helix),
      footbonaut: this.objectToForm(this.footbonaut),
      sprintHill: this.objectToForm(this.sprintHill),
      headerPendulum: this.objectToForm(this.headerPendulum),
      footballTennis: this.objectToForm(this.footballTennis),
      otherSports: this.objectToForm(this.otherSports),
      sprintTrack: this.objectToForm(this.sprintTrack),
      virtualReality: this.objectToForm(this.virtualReality),
      speedCourt: this.objectToForm(this.speedCourt),
      altitudeChamber: this.objectToForm(this.altitudeChamber),
      customEntries: [],
    };

    for (const entry of this.customTypedEntries) {
      resetValues.customEntries.push(this.formGroup(entry).value);
    }

    this.allForms.reset(resetValues);
  }

  public override async onDelete(index: number): Promise<void> {
    await this.deleteEntry(index, this.customTypedEntries);
  }

  protected formGroup(entry?: SpecialEquipment): UntypedFormGroup {
    return new UntypedFormGroup({
      name: new UntypedFormControl(entry?.name, [Validators.required/* , this.repeatedNameValidator()*/]),
      remark: new UntypedFormControl(entry?.remark),
      updatedAtUtc: new UntypedFormControl(entry?.updatedAtUtc),
      createdAtUtc: new UntypedFormControl(entry?.createdAtUtc),
    });
  }

  protected objectToForm(entry: SpecialEquipment): Partial<SpecialEquipment> {
    return {
      remark: entry?.remark,
      isAvailable: entry?.isAvailable,
      updatedAtUtc: entry?.updatedAtUtc,
      createdAtUtc: entry?.createdAtUtc,
    };
  }

  protected updateTypedEntries(): void {
    this.helix = this.findEntryByType('Helix');
    this.footbonaut = this.findEntryByType('Footbonaut');
    this.sprintHill = this.findEntryByType('SprintHill');
    this.headerPendulum = this.findEntryByType('HeaderPendulum');
    this.footballTennis = this.findEntryByType('FootballTennis');
    this.otherSports = this.findEntryByType('OtherSports');
    this.sprintTrack = this.findEntryByType('SprintTrack');
    this.virtualReality = this.findEntryByType('VirtualReality');
    this.speedCourt = this.findEntryByType('SpeedCourt');
    this.altitudeChamber = this.findEntryByType('AltitudeChamber');
  }

  private customForm(entry?: SpecialEquipment): UntypedFormGroup {
    return new UntypedFormGroup({
      remark: new UntypedFormControl(entry?.remark),
      isAvailable: new UntypedFormControl(entry?.isAvailable, [Validators.required]),
      updatedAtUtc: new UntypedFormControl(entry?.updatedAtUtc),
      createdAtUtc: new UntypedFormControl(entry?.createdAtUtc),
    });
  }

  private findEntryByType(type: string): SpecialEquipment {
    for (const entry of this.entries) {
      if (entry.equipmentType === type) {
        return entry;
      }
    }
    return {
      id: null,
      soccerClubId: this.soccerClub.id,
      seasonId: this.season.id,
      name: null,
      remark: null,
      equipmentType: type,
      isAvailable: false,
      createdAtUtc: null,
      updatedAtUtc: null,
    };
  }
}
