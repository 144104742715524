import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Season } from '../../services/season/models/season';


@Component({
  selector: 'lsz-season-selection-bar',
  templateUrl: './season-selection-bar.component.html',
  styleUrls: ['./season-selection-bar.component.scss'],
})
export class SeasonSelectionBarComponent {
  @Input() public allSeasons: Season[];
  @Input() public currentSeason: Season;
  @Output() public seasonChanged: EventEmitter<Season>;
  @ViewChild('seasonBar') private seasonBar: ElementRef;

  constructor() {
    this.seasonChanged = new EventEmitter<Season>();
  }

  public loadSelectedSeason(season: Season, event: MouseEvent): void {
    this.currentSeason = season;
    const target: HTMLElement = event.target as HTMLElement;
    const offset: number = target.offsetLeft;
    let margin: number = parseInt(target.style.marginRight, 10);
    if (Number.isNaN(margin)) {
      margin = 0;
    }
    const width: number = target.offsetWidth + margin;
    const scrollPos: number = offset - width;
    this.seasonBar.nativeElement.scrollTo(scrollPos, 0);
    this.seasonChanged.emit(this.currentSeason);
  }
}
