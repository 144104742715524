<div class="summary">
  <h3 class="detail-category-header" i18n>Beschreibung KPI</h3>
  <div class="detail-category-content">

    <div>
      <ng-container i18n="@@kpiDescription">
        {detail?.name, select,
          AllFields {Alle angegebenen Rasen- und Kunstrasenplätze}
          AllFieldsWithFloodlight {Alle angegebenen Plätze mit Flutlicht}
          AllHeatedFields {Alle Plätze, die eine Rasenheizung haben (Natur- und Kunstrasenplätze)}
          AllSmallSizedFields {Kleinspielfelder}
          ArtificialLawnFields {Alle angegebenen Kleinspielfelder (kleine Fußballspielfläche mit Toren)}
          CompetitionCompliantFields {Alle Plätze, die die Kriterien für A- und B-Jugend Bundesliga erfüllen}
          LawnFields {Alle angegebenen Rasenplätze}
          LawnFieldsWithFloodlight {Alle angegebenen Rasenplätze mit Flutlicht}
          LawnFieldOnLzArea {Alle Plätze, die auf LZ-Gelände liegen }
          OpenSpaceFieldSize {zusätzlich für Trainingszwecke genutzte Freifläche in m<sup>2</sup>}
          SmallSizedFieldsWithBoards {Alle Kleinspielfelder mit Bande (DFB-Spielfelder)}
          LzExclusiveFields {Alle angegebenen Plätze, die dem Leistungszentrum exklusiv zu Verfügung stehen}

          IndoorHalls {Indoorfläche auf LZ Gelände Indoorbereich mit Möglichkeit zur Durchführung von Stabilisationstraining o.Ä. für über 10 Spieler}
          SoccerHalls {Fußballhalle auf LZ Gelände Fußballhalle auf dem LZ-Gelände mit Erstzugriff durch das LZ}

          AltitudeChamber {Gerät zur Durchführung von Höhentraining}
          FootballTennis {Fußballtennis Flächen und Netze für Fußballtennis}
          Footbonaut {Footbonaut o.Ä. Trainingsgerät mit Fokus auf Handlungsschnelligkeit, Technik, Wahrnehmung}
          HeaderPendulum {Installierte Kopfballpendel auf dem LZ-Gelände}
          Helix {Gerät zum digital gestützen Wahrnehmungstraining}
          OtherSports {Installierte Beachfelder oder Sportgeräte wie Basketballkörbe etc.}
          Speedcourt {Gerät zur Förderung der kognitiven und motorischen Schnelligkeit}
          SprintHill {Vom LZ nutzbarer Sprinthügel}
          SprintTrack {Vorhandene Sprintbahn im LZ}
          VirtualReality {Virtual Reality Brillen und passende Software zur Trainingsunterstützung}

          Physio {Anzahl Physio-Arbeitsplätze (Bänke) im LZ}
          PlayersCabin {Anzahl Spielerkabinen mit exklusiver Nutzung durch das LZ}
          RefereeCabin {Anzahl Schiedsrichterkabinen}
          TrainersCabin {Anzahl Trainerkabinen mit exklusiver Nutzung durch das LZ}
          WeightRoom {Anzahl Krafträume für das LZ}

          ColdChamber {Vorhandene Kältkammer für das LZ}
          IceBarrel {Vorhandene Eistonne im LZ}
          RelaxationBath {Vorhandenes Entmüdungsbecken für das LZ}
          RelaxationRoom {Vorhandener Ruheraum mit Liegemöglichkeiten für das LZ}
          Sauna {Vorhandene Sauna für das LZ}

          Canteen {Vorhandene Kantine für Mitarbeiter und Spieler im LZ}
          ConferenceRoom {Anzahl Besprechungsräume im LZ}
          Office {Anzahl Büroräume im LZ}
          StudyRoom {Anzahl Lernplätze im LZ}
          Workplace {Anzahl vorhandener Arbeitsplätze (Büroeinrichtung) für LZ-Mitarbeiter}

          Players {Gesamtanzahl vom LZ untergebrachter Spieler}
          PlayersInCollege {Anzahl vom LZ untergebrachter Spieler im Internat (vom Club betriebenes Internat/extern über Kooperationspartner, wie z.B. Haus des Sports, Kolpinghaus)}
          PlayersInGuestFamily {Anzahl vom LZ untergebrachter Spieler bei Gasteltern}
          PlayersU12ToU15 {Gesamtzahl untergebrachter Spieler in den Altersklassen U12-U15}
          PlayersU16ToU23 {Gesamtzahl untergebrachter Spieler in den Altersklassen U16-U23}


          FullTimeEmployees {Alle Mitarbeiter, die in Vollzeit ausschließlich für das LZ arbeiten}
          FullTimeEmployeesInLeadership {Alle Mitarbeiter im Bereich Leitung, die in Vollzeit ausschließlich für das LZ arbeiten
          <br><br><i>(Gesamtleitung, sportliche Leitung, organisatorische Leitung)</i>}
          FullTimeEmployeesInManagement {Alle Mitarbeiter im Bereich Management & Administration, die in Vollzeit ausschließlich für das LZ arbeiten}
          FullTimeEmployeesInMisc {Alle Mitarbeiter im Bereich Sonstiges, die in Vollzeit ausschließlich für das LZ arbeiten}
          FullTimeEmployeesInSoccerTraining {Alle Mitarbeiter im Bereich Sport, die in Vollzeit ausschließlich für das LZ arbeiten}
          FullTimeEmployeesInSupport {Alle Mitarbeiter im Bereich Unterstützung, die in Vollzeit ausschließlich für das LZ arbeiten}

          FullTimeEmployeesDoubleArea {Alle Mitarbeiter des LZ mit Doppelfunktion im Lizenzbereich, die in Vollzeit beim Club beschäftigt sind}
          FullTimeEmployeesInLeadershipDoubleArea {Alle Mitarbeiter des LZ im Bereich Leitung mit Doppelfunktion im Lizenzbereich, die in Vollzeit beim Club beschäftigt sind
          <br><br><i>(Gesamtleitung, sportliche Leitung, organisatorische Leitung)</i>}
          FullTimeEmployeesInManagementDoubleArea {Alle Mitarbeiter des LZ im Bereich Management & Administration mit Doppelfunktion im Lizenzbereich, die in Vollzeit beim Club beschäftigt sind}
          FullTimeEmployeesInSoccerTrainingDoubleArea {Alle Mitarbeiter des LZ im Bereich Sport mit Doppelfunktion im Lizenzbereich, die in Vollzeit beim Club beschäftigt sind}
          FullTimeEmployeesInSupportDoubleArea {Alle Mitarbeiter des LZ im Bereich Unterstützung mit Doppelfunktion im Lizenzbereich, die in Vollzeit beim Club beschäftigt sind}

          PartTimeEmployees {Alle Mitarbeiter, die in Teilzeit im LZ beschäftigt sind}
          PartTimeEmployeesLeadership {Alle Mitarbeiter im Bereich Leitung, die in Teilzeit im LZ beschäftigt sind<br>
          <br><i>(Gesamtleitung, sportliche Leitung, organisatorische Leitung)</i>}
          PartTimeEmployeesManagement {Alle Mitarbeiter im Bereich Management & Administration, die in Teilzeit im LZ beschäftigt sind}
          PartTimeEmployeesMisc {Alle Mitarbeiter im Bereich Sonstiges, die in Teilzeit im LZ beschäftigt sind}
          PartTimeEmployeesSoccerTraining {Alle Mitarbeiter im Bereich Sport, die in Teilzeit im LZ beschäftigt sind}
          PartTimeEmployeesSupport {Alle Mitarbeiter im Bereich Unterstützung, die in Teilzeit im LZ beschäftigt sind}

          ALicenseEmployees {Alle Mitarbeiter des LZ mit einer A-Lizenz}
          APlusLicenseEmployees {Alle Mitarbeiter des LZ mit einer A+-Lizenz}
          BLicenseEmployees {Alle Mitarbeiter des LZ mit einer B-Lizenz}
          BPlusLicenseEmployees {Alle Mitarbeiter des LZ mit einer B+-Lizenz}
          CLicenseEmployees {Alle Mitarbeiter des LZ mit einer C-Lizenz oder niedriger}
          ProLicenseEmployees {Alle Mitarbeiter des LZ mit einer Pro-Lizenz (oder UEFA Pro Lizenz)}

          AverageClubMembershipTime {Die durchschnittliche Zugehörigkeit zum Club über alle Vollzeitmitarbeiter des LZ in (Jahren)}
          LeadershipAverageClubMembershipTime {Die durchschnittliche Zugehörigkeit zum Club über alle Vollzeitmitarbeiter des LZ im Bereich Leitung in (Jahren)}
          ManagementAverageClubMembershipTime {Die durchschnittliche Zugehörigkeit zum Club über alle Vollzeitmitarbeiter des LZ im Bereich Management & Administration in (Jahren)}
          SoccerTrainingAverageClubMembershipTime {Die durchschnittliche Zugehörigkeit zum Club über alle Vollzeitmitarbeiter des LZ im Bereich Sport in (Jahren)}
          SupportAverageClubMembershipTime {Die durchschnittliche Zugehörigkeit zum Club über alle Vollzeitmitarbeiter des LZ im Bereich Unterstützung in (Jahren)}


          U14Minimum3SeasonsAffiliationQuote {Prozentualer Anteil an Spielern, die im <b>Jahrgang U14</b> mind. <b>3
            Saisons</b> im LZ ausgebildet wurden<br><br><i>(Datengrundlage = Ende vergangene Saison)<br>(max = maximal
            mögliche Zugehörigkeit ab U12)</i>}
          U15Minimum3SeasonsAffiliationQuote {Prozentualer Anteil an Spielern, die im <b>Jahrgang U15</b> mind. <b>3
            Saisons</b> im LZ ausgebildet wurden<br><br><i>(Datengrundlage = Ende vergangene Saison)</i>}
          U15Minimum4SeasonsAffiliationQuote {Prozentualer Anteil an Spielern, die im <b>Jahrgang U15</b> mind. <b>4
            Saisons</b> im LZ ausgebildet wurden<br><br><i>(Datengrundlage = Ende vergangene Saison)<br>(max = maximal
            mögliche Zugehörigkeit ab U12)</i>}
          U16Minimum3SeasonsAffiliationQuote {Prozentualer Anteil an Spielern, die im <b>Jahrgang U16</b> mind. <b>3
            Saisons</b> im LZ ausgebildet wurden<br><br><i>(Datengrundlage = Ende vergangene Saison)</i>}
          U16Minimum5SeasonsAffiliationQuote {Prozentualer Anteil an Spielern, die im <b>Jahrgang U16</b> mind. <b>5
            Saisons</b> im LZ ausgebildet wurden<br><br><i>(Datengrundlage = Ende vergangene Saison)<br>(max = maximal
            mögliche Zugehörigkeit ab U12)</i>}
          U17Minimum3SeasonsAffiliationQuote {Prozentualer Anteil an Spielern, die im <b>Jahrgang U17</b> mind. <b>3
            Saisons</b> im LZ ausgebildet wurden<br><br><i>(Datengrundlage = Ende vergangene Saison)</i>}
          U17Minimum5SeasonsAffiliationQuote {Prozentualer Anteil an Spielern, die im <b>Jahrgang U17</b> mind. <b>5
            Saisons</b> im LZ ausgebildet wurden<br><br><i>(Datengrundlage = Ende vergangene Saison)</i>}
          U17Minimum6SeasonsAffiliationQuote {Prozentualer Anteil an Spielern, die im <b>Jahrgang U17</b> mind. <b>6
            Saisons</b> im LZ ausgebildet wurden<br><br><i>(Datengrundlage = Ende vergangene Saison)<br>(max = maximal
            mögliche Zugehörigkeit ab U12)</i>}
          U18Minimum3SeasonsAffiliationQuote {Prozentualer Anteil an Spielern, die im <b>Jahrgang U18</b> mind. <b>3
            Saisons</b> im LZ ausgebildet wurden<br><br><i>(Datengrundlage = Ende vergangene Saison)</i>}
          U18Minimum5SeasonsAffiliationQuote {Prozentualer Anteil an Spielern, die im <b>Jahrgang U18</b> mind. <b>5
            Saisons</b> im LZ ausgebildet wurden<br><br><i>(Datengrundlage = Ende vergangene Saison)</i>}
          U18Minimum7SeasonsAffiliationQuote {Prozentualer Anteil an Spielern, die im <b>Jahrgang U18</b> mind. <b>7
            Saisons</b> im LZ ausgebildet wurden<br><br><i>(Datengrundlage = Ende vergangene Saison)<br>(max = maximal
            mögliche Zugehörigkeit ab U12)</i>}
          U19Minimum3SeasonsAffiliationQuote {Prozentualer Anteil an Spielern, die im <b>Jahrgang U19</b> mind. <b>3
            Saisons</b> im LZ ausgebildet wurden<br><br><i>(Datengrundlage = Ende vergangene Saison)</i>}
          U19Minimum5SeasonsAffiliationQuote {Prozentualer Anteil an Spielern, die im <b>Jahrgang U19</b> mind. <b>5
            Saisons</b> im LZ ausgebildet wurden<br><br><i>(Datengrundlage = Ende vergangene Saison)</i>}
          U19Minimum8SeasonsAffiliationQuote {Prozentualer Anteil an Spielern, die im <b>Jahrgang U19</b> mind. <b>8
            Saisons</b> im LZ ausgebildet wurden<br><br><i>(Datengrundlage = Ende vergangene Saison)<br>(max = maximal
            mögliche Zugehörigkeit ab U12)</i>}

          U14OverallAffiliationQuote {Gesamtausbildungsanteil des Clubs am <b>Jahrgang U14</b><br><br><i>(Datengrundlage
            = Ende vergangene Saison)<br>(Hier zählt die tatsächliche Ausbildungszeit jedes Spielers im Jahrgang mit
            ein. Auch wenn diese z.B. nur 6 Monate betragen sollte)</i>}
          U15OverallAffiliationQuote {Gesamtausbildungsanteil des Clubs am <b>Jahrgang U15</b><br><br><i>(Datengrundlage
            = Ende vergangene Saison)<br>(Hier zählt die tatsächliche Ausbildungszeit jedes Spielers im Jahrgang mit
            ein. Auch wenn diese z.B. nur 6 Monate betragen sollte)</i>}
          U16OverallAffiliationQuote {Gesamtausbildungsanteil des Clubs am <b>Jahrgang U16</b><br><br><i>(Datengrundlage
            = Ende vergangene Saison)<br>(Hier zählt die tatsächliche Ausbildungszeit jedes Spielers im Jahrgang mit
            ein. Auch wenn diese z.B. nur 6 Monate betragen sollte)</i>}
          U17OverallAffiliationQuote {Gesamtausbildungsanteil des Clubs am <b>Jahrgang U17</b><br><br><i>(Datengrundlage
            = Ende vergangene Saison)<br>(Hier zählt die tatsächliche Ausbildungszeit jedes Spielers im Jahrgang mit
            ein. Auch wenn diese z.B. nur 6 Monate betragen sollte)</i>}
          U18OverallAffiliationQuote {Gesamtausbildungsanteil des Clubs am <b>Jahrgang U18</b><br><br><i>(Datengrundlage
            = Ende vergangene Saison)<br>(Hier zählt die tatsächliche Ausbildungszeit jedes Spielers im Jahrgang mit
            ein. Auch wenn diese z.B. nur 6 Monate betragen sollte)</i>}
          U19OverallAffiliationQuote {Gesamtausbildungsanteil des Clubs am <b>Jahrgang U19</b><br><br><i>(Datengrundlage
            = Ende vergangene Saison)<br>(Hier zählt die tatsächliche Ausbildungszeit jedes Spielers im Jahrgang mit
            ein. Auch wenn diese z.B. nur 6 Monate betragen sollte)</i>}

          U20U22TotalPlayers {<b>Gesamtanzahl</b> Spieler in den <b>Jahrgängen U20-U22</b>, im Club<br><br><i>(Datengrundlage
            = Ende vergangene Saison)</i>}
          U20U22Minimum3SeasonsAffiliationQuote {Prozentualer Anteil an Spielern in den <b>Jahrgängen
            U20-U22</b>, die mind. <b>3 Saisons</b> im Club ausgebildet wurden<br><br><i>(Datengrundlage = Ende
            vergangene Saison)</i>}
          U20U22Minimum5SeasonsAffiliationQuote {Prozentualer Anteil an Spielern in den <b>Jahrgängen
            U20-U22</b>, die mind. <b>5 Saisons</b> im Club ausgebildet wurden<br><br><i>(Datengrundlage = Ende
            vergangene Saison)</i>}
          U20U22Minimum9SeasonsAffiliationQuote {Prozentualer Anteil an Spielern in den <b>Jahrgängen
            U20-U22</b>, die mind. <b>9 Saisons</b> im Club ausgebildet wurden<br><br><i>(Datengrundlage = Ende
            vergangene Saison)</i>}
          U20U22OverallAffiliationQuote {Gesamtausbildungsanteil an den <b>Jahrgängen U20-U22</b><br><br><i>(Datengrundlage
            = Ende vergangene Saison)<br>(Hier zählt die tatsächliche Ausbildungszeit jedes Spielers im Jahrgang mit
            ein. Auch wenn diese z.B. nur 6 Monate betragen sollte)</i>}

          TrainedPlayersMinimum3Seasons {<b>Anzahl</b> der Spieler, die <b>mind. 3 Saisons</b> ausgebildet wurden<br>
          <br><i>(Datengrundlage = Ende vergangene Saison)<br>(Bedingungen: mind. 1x im offiziellen Spieltagskader;
            Ausbildungszeitraum U12-U22)</i>}
          TrainedPlayersMinimum5Seasons {<b>Anzahl</b> der Spieler, die <b>mind. 5 Saisons</b> ausgebildet wurden<br>
          <br><i>(Datengrundlage = Ende vergangene Saison)<br>(Bedingungen: mind. 1x im offiziellen Spieltagskader;
            Ausbildungszeitraum U12-U22)</i>}
          TrainedPlayersMinimum8Seasons {<b>Anzahl</b> der Spieler, die <b>mind. 8 Saisons</b> ausgebildet wurden<br>
          <br><i>(Datengrundlage = Ende vergangene Saison)<br>(Bedingungen: mind. 1x im offiziellen Spieltagskader;
            Ausbildungszeitraum U12-U22)</i>}
          TrainedPlayersMinimum3SeasonsPercentage {<b>Prozentualer Anteil</b> der Spieler, die <b>mind. 3
            Saisons</b> ausgebildet wurden<br><br><i>(Datengrundlage = Ende vergangene Saison)<br>(Bedingungen: mind. 1x
            im offiziellen Spieltagskader; Ausbildungszeitraum U12-U22)</i>}
          TrainedPlayersOverallAffiliation {<b>Gesamtausbildungsanteil</b> an den ausgebildeten Spielern<br><br><i>(Datengrundlage
            = Ende vergangene Saison)<br>(Bedingungen: Ausbildungszeitraum U12-U22; mind. 3 Saisons; mind. 1x im
            offiziellen Spieltagskader)</i>}
          TrainedPlayersCompetitiveMinutes {<b>Gesamteinsatzminuten im Lizenzbereich</b> im Club ausgebildeter Spieler
          <br><br><i>(Bedingungen: Ausbildungszeitraum U12-U22; mind. 3 Saisons; mind. 1x im offiziellen
            Spieltagskader)</i>}
          TrainedPlayersAverageCompetitiveMinutes {<b>Durchschnittliche Einsatzzeit im
            Lizenzbereich</b> im Club ausgebildeter Spieler<br>(Datengrundlage = Ende vergangene Saison)
          <br>(Bedingungen: Ausbildungszeitraum U12-U22; mind. 3 Saisons; mind. 1x im offiziellen Spieltagskader)}
          TrainedPlayersPercentageCompetitiveMinutes {<b>Prozentualer Anteil Einsatzminuten im
            Lizenzbereich</b> im Club ausgebildeter Spieler an den Gesamteinsatzminuten aller Spieler im Lizenzbereich
          <br><br><i>(Datengrundlage = Ende vergangene Saison)<br>(Bedingungen: Ausbildungszeitraum U12-U22; mind. 3
            Saisons; mind. 1x im offiziellen Spieltagskader)</i>}

          ForeignTrainedPlayersPremierLeague {
            <b>Anzahl</b> der Spieler, die im Club ausgebildet wurden und mind. 1x im offiziellen Spieltagskader bei anderen Clubs in der
            <b>Bundesliga</b> standen<br><br><i>(Datengrundlage = Ende vergangene Saison)<br>(Bedingungen:
              Ausbildungszeitraum U12-U22; mind. 3 Saisons)</i>}
          ForeignTrainedPlayersSecondLeague {
            <b>Anzahl</b> der Spieler, die im Club ausgebildet wurden und mind. 1x im offiziellen Spieltagskader bei anderen Clubs in der
            <b>2. Bundesliga</b> standen<br><br><i>(Datengrundlage = Ende vergangene Saison)<br>(Bedingungen:
              Ausbildungszeitraum U12-U22; mind. 3 Saisons)</i>}
          ForeignTrainedPlayersThirdLeague {
            <b>Anzahl</b> der Spieler, die im Club ausgebildet wurden und mind. 1x im offiziellen Spieltagskader bei anderen Clubs in der
            <b>3. Liga</b> standen<br><br><i>(Datengrundlage = Ende vergangene Saison)<br>(Bedingungen:
              Ausbildungszeitraum U12-U22; mind. 3 Saisons)</i>}
          ForeignTrainedPlayersOverallAffiliation {
            <b>Gesamtausbildungsanteil</b> an den im Club ausgebildeten Spielern, die mind. 1x im offiziellen Spieltagskader bei anderen Clubs in den
            <b>3 ersten Ligen</b> standen<br><br><i>(Datengrundlage = Ende vergangene Saison)<br>(Bedingungen:
              Ausbildungszeitraum U12-U22; mind. 3 Saisons)</i>}
          ForeignTrainedPlayersCompetitiveMinutes {
            <b>Gesamteinsatzminuten</b> im Club ausgebildeter Spieler bei einem Clubs in den <b>3 ersten Ligen</b><br>
            <br><i>(Datengrundlage = Ende vergangene Saison)<br>(Bedingungen: Ausbildungszeitraum U12-U22; mind. 3
              Saisons)</i>}
          ForeignTrainedPlayersAverageCompetitiveMinutes {<b>Durchschnittliche
            Einsatzzeit</b> im Club ausgebildeter Spieler bei anderen Clubs in den <b>3 ersten Ligen</b><br><br><i>(Datengrundlage
            = Ende vergangene Saison)<br>(Bedingungen: Ausbildungszeitraum U12-U22; mind. 3 Saisons)</i>}
          ForeignTrainedPlayersCompetitiveMinutesPremierLeague {
            <b>Gesamteinsatzminuten</b> im Club ausgebildeter Spieler bei anderen Clubs in der <b>Bundesliga</b><br><br>
            <i>(Datengrundlage = Ende vergangene Saison)<br>(Bedingungen: Ausbildungszeitraum U12-U22; mind. 3 Saisons)</i>}
          ForeignTrainedPlayersAverageCompetitiveMinutesPremierLeague {<b>Durchschnittliche
            Einsatzzeit</b> im Club ausgebildeter Spieler bei anderen Clubs in der <b>Bundesliga</b><br><br><i>(Datengrundlage
            = Ende vergangene Saison)<br>(Bedingungen: Ausbildungszeitraum U12-U22; mind. 3 Saisons)</i>}
          ForeignTrainedPlayersCompetitiveMinutesSecondLeague {
            <b>Gesamteinsatzminuten</b> im Club ausgebildeter Spieler bei anderen Clubs in der <b>2. Bundesliga</b><br>
            <br><i>(Datengrundlage = Ende vergangene Saison)<br>(Bedingungen: Ausbildungszeitraum U12-U22; mind. 3
              Saisons)</i>}
          ForeignTrainedPlayersAverageCompetitiveMinutesSecondLeague {<b>Durchschnittliche
            Einsatzzeit</b> im Club ausgebildeter Spieler bei anderen Clubs in der <b>2. Bundesliga</b><br><br><i>(Datengrundlage
            = Ende vergangene Saison)<br>(Bedingungen: Ausbildungszeitraum U12-U22; mind. 3 Saisons)</i>}
          ForeignTrainedPlayersCompetitiveMinutesThirdLeague {
            <b>Gesamteinsatzminuten</b> im Club ausgebildeter Spieler bei anderen Clubs in der <b>3. Liga</b>
            <br>(Datengrundlage = Ende vergangene Saison)
            <br>(Bedingungen: Ausbildungszeitraum U12-U22; mind. 3 Saisons)}
          ForeignTrainedPlayersAverageCompetitiveMinutesThirdLeague {<b>Durchschnittliche
            Einsatzzeit</b> im Club ausgebildeter Spieler bei anderen Clubs in der <b>3. Liga</b> in der Bezugssaison
          <br>(Datengrundlage = Ende vergangene Saison)<br>(Bedingungen: Ausbildungszeitraum U12-U22; mind. 3 Saisons)}
          }
      </ng-container>
    </div>

  </div>
</div>
