import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'lsz-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TimeInputComponent {
  @Input() public formCtrl: UntypedFormControl;
  @Input() public hourStep: number;
  @Input() public invalidErrorMsg: string;
  @Input() public minuteStep: number;
  @Input() public readonlyInputs: boolean;
  @Input() public requiredErrorMsg: string;
  @Input() public seconds: boolean;
  @Input() public secondStep: number;
  @Input() public size: 'small' | 'medium' | 'large';

  constructor() {
    this.invalidErrorMsg = 'Fehlerhafte Uhrzeit';
    this.requiredErrorMsg = 'Angabe wird benötigt';
  }
}
