import { Component, Input } from '@angular/core';
import { SpecialEquipment } from '../../models/models';
import { AbstractControl } from '@angular/forms';
import { BaseEditFormComponent } from '../../../shared/base-edit-form/base-edit-form.component';

@Component({
  selector: 'tbody[lsz-special-equipment-typed-form]',
  templateUrl: './special-equipment-typed-form.component.html',
  styleUrls: ['./special-equipment-typed-form.component.css'],
})
export class SpecialEquipmentTypedFormComponent extends BaseEditFormComponent<SpecialEquipment> {
  @Input() entry: SpecialEquipment;

  public get isAvailable(): AbstractControl {
    return this.form.get('isAvailable');
  }

  constructor() {
    super();
  }

  public onClickSave(): void {
    this.entrySaved.emit({
      ...this.entry,
      remark: this.remark.value as string,
      isAvailable: this.isAvailable.value as boolean,
      name: null, // The API dictates: Specifically typed equipment, like helix or sprint court, mustn't have a name set.
    });
  }
}
