import { Component, Input } from '@angular/core';
import { BaseEditFormComponent } from '../../../shared/base-edit-form/base-edit-form.component';
import { Budget, League } from '../../models/models';

@Component({
  selector: 'tbody[lsz-active-teams-form]',
  templateUrl: './active-teams-form.component.html',
  styleUrls: ['./active-teams-form.component.scss'],
})
export class ActiveTeamsFormComponent extends BaseEditFormComponent {
  @Input() public clubLogo: string;
  @Input() public allLeagues: League[];
  @Input() public allBudgets: Budget[];

  constructor() {
    super();
  }
}
