import { SoccerClub } from '../../models/soccerClub';

import { Category } from './category';
import { CategoryRater, CategoryRating } from './categoryRater';
import { Criteria } from './criteria';
import { Criterion } from './criterion';
import { KpiRater, KpiRating } from './kpiRater';
import { Kpis, SeasonKpi } from './kpis';
import { KpisProcessResult } from './kpisProcessResult';
import { TeamsSummaries } from './teamsSummaries';
import { Summaries } from './summaries';

export abstract class BaseKpisProcessor {
  public seasonKpiCollection: SeasonKpi[];
  constructor(protected rater: KpiRater, protected soccerClub: SoccerClub) {
  }

  public rateAll(kpisProcessResult: KpisProcessResult,
                 kpis: Kpis, soccerClub: SoccerClub | null, hideRatings?: boolean): void {
    const criteriaCategoriesSummaries: Record<string, any> = {};
    if (kpis && kpis.seasonKpiCollection) {
      this.seasonKpiCollection = kpis.seasonKpiCollection;
      for (const seasonKpi of kpis.seasonKpiCollection) {
        if (!(seasonKpi.kpiCriteria in criteriaCategoriesSummaries)) {
          criteriaCategoriesSummaries[seasonKpi.kpiCriteria] = {};
        }
        if (!(seasonKpi.kpiCategory in criteriaCategoriesSummaries[seasonKpi.kpiCriteria])) {
          criteriaCategoriesSummaries[seasonKpi.kpiCriteria][seasonKpi.kpiCategory] = {
            median: {
              above: 0,
              on: 0,
              below: 0,
            },
            average: {
              above: 0,
              on: 0,
              below: 0,
            },
          };
        }
        const summaries: Summaries = criteriaCategoriesSummaries[seasonKpi.kpiCriteria][seasonKpi.kpiCategory] as Summaries;
        const category: Category = kpisProcessResult.criteria[seasonKpi.kpiCriteria].categories[seasonKpi.kpiCategory];

        for (const processedKpi of category.kpis) {
          if (processedKpi.name === seasonKpi.kpiName) {
            const rating: KpiRating = {
              ...processedKpi?.rating,
              ...this.rater.rate(seasonKpi, !soccerClub.isAdmin ? soccerClub.id : null, hideRatings),
            };

            if (rating.median === 1) {
              summaries.median.above += 1;
            } else if (rating.median === 0) {
              summaries.median.on += 1;
            } else if (rating.median === -1) {
              summaries.median.below += 1;
            }

            if (rating.average === 1) {
              summaries.average.above += 1;
            } else if (rating.average === 0) {
              summaries.average.on += 1;
            } else if (rating.average === -1) {
              summaries.average.below += 1;
            }

            processedKpi.rating = rating;
          }
        }
      }
    }

    if (kpisProcessResult) {
      for (const criterionName in kpisProcessResult.criteria) {
        if (!kpisProcessResult.criteria.hasOwnProperty(criterionName)) {
          continue;
        }

        const criterion: Criterion = kpisProcessResult.criteria[criterionName];
        for (const categoryName in criterion.categories) {
          if (!criterion.categories.hasOwnProperty(categoryName)) {
            continue;
          }

          const category: Category = criterion.categories[categoryName];
          category.summaries = criteriaCategoriesSummaries[criterionName][categoryName] as Summaries;
        }
      }

      for (const criterionName in kpisProcessResult.criteria) {
        if (!kpisProcessResult.criteria.hasOwnProperty(criterionName)) {
          continue;
        }

        let numPositivesMedian: number = 0;
        let numNeutralsMedian: number = 0;
        let numNegativesMedian: number = 0;
        let numPositivesAverage: number = 0;
        let numNeutralsAverage: number = 0;
        let numNegativesAverage: number = 0;

        for (const categoryName in kpisProcessResult.criteria[criterionName].categories) {
          if (!kpisProcessResult.criteria[criterionName].categories.hasOwnProperty(categoryName)) {
            continue;
          }

          const category: Category = kpisProcessResult.criteria[criterionName].categories[categoryName];
          if (this.soccerClub.id) {
            const categoryRater: CategoryRater = new CategoryRater();
            category.ratings = categoryRater.rate(category.kpis);

            if (category.ratings.median === CategoryRating.Positive) {
              numPositivesMedian += 1;
            } else if (category.ratings.median === CategoryRating.Neutral) {
              numNeutralsMedian += 1;
            } else if (category.ratings.median === CategoryRating.Negative) {
              numNegativesMedian += 1;
            }

            if (category.ratings.average === CategoryRating.Positive) {
              numPositivesAverage += 1;
            } else if (category.ratings.average === CategoryRating.Neutral) {
              numNeutralsAverage += 1;
            } else if (category.ratings.average === CategoryRating.Negative) {
              numNegativesAverage += 1;
            }
          } else {
            category.ratings = null;
          }
        }

        kpisProcessResult.criteria[criterionName].ratings.median.positive = numPositivesMedian;
        kpisProcessResult.criteria[criterionName].ratings.median.neutral = numNeutralsMedian;
        kpisProcessResult.criteria[criterionName].ratings.median.negative = numNegativesMedian;
        kpisProcessResult.criteria[criterionName].ratings.average.positive = numPositivesAverage;
        kpisProcessResult.criteria[criterionName].ratings.average.neutral = numNeutralsAverage;
        kpisProcessResult.criteria[criterionName].ratings.average.negative = numNegativesAverage;
      }
    }
  }

  protected addKpis(kpis: Kpis, result: Criteria): void {
    for (const seasonKpi of kpis.seasonKpiCollection) {
      this.calculatePersonnelAndMembership(seasonKpi);

      result[seasonKpi.kpiCriteria].categories[seasonKpi.kpiCategory].kpis.push({
        name: seasonKpi.kpiName,
        type: seasonKpi.kpiType,
        teamsSummaries: this.rateTeams(seasonKpi),
        summary: seasonKpi.summary,
        rating: undefined,
      });
    }
  }

  protected calculatePersonnelAndMembership(seasonKpi: SeasonKpi): void {
    const daysOfYear: number = 365;

    if (seasonKpi.kpiCriteria === 'Personnel' && seasonKpi.kpiCategory === 'Membership') {
      const mySummaryValues: Record<string, any> = {};

      seasonKpi.summary.avg = Math.floor(seasonKpi.summary.avg / daysOfYear);
      seasonKpi.summary.median = Math.floor(seasonKpi.summary.median / daysOfYear);

      for (const key in seasonKpi.summary.values) {
        if (!seasonKpi.summary.values.hasOwnProperty(key)) {
          continue;
        }

        const newKey: string = Math.floor(Number(key) / daysOfYear).toString();
        if (newKey in mySummaryValues) {
          mySummaryValues[newKey] += seasonKpi.summary.values[key];
        } else {
          mySummaryValues[newKey] = seasonKpi.summary.values[key];
        }
      }
      seasonKpi.summary.values = mySummaryValues;

      for (const myClub of seasonKpi.soccerClubKpiCollection) {
        myClub.sum = Math.floor(myClub.sum / daysOfYear);
      }
    }
  }

  protected initCategories(kpis: Kpis, result: Criteria): void {
    for (const seasonKpi of kpis.seasonKpiCollection) {
      if (!(seasonKpi.kpiCategory in result[seasonKpi.kpiCriteria].categories)) {
        result[seasonKpi.kpiCriteria].categories[seasonKpi.kpiCategory] = {
          name: seasonKpi.kpiCategory,
          kpis: [],
          ratings: undefined,
          summaries: undefined,
        };
        result[seasonKpi.kpiCriteria].numCategories += 1;
      }
    }
  }

  protected initCriteria(kpis: Kpis, result: Criteria): void {
    for (const seasonKpi of kpis.seasonKpiCollection) {
      if (!(seasonKpi.kpiCriteria in result)) {
        result[seasonKpi.kpiCriteria] = {
          name: seasonKpi.kpiCriteria,
          numCategories: 0,
          categories: {},
          ratings: {
            median: {
              positive: 0,
              neutral: 0,
              negative: 0,
            },
            average: {
              positive: 0,
              neutral: 0,
              negative: 0,
            },
          },
        };
      }
    }
  }

  protected rateTeams(seasonKpi: SeasonKpi, hideRatings?: boolean): TeamsSummaries {
    let numAboveMedian: number = 0;
    let numAboveAverage: number = 0;
    let numOnMedian: number = 0;
    let numOnAverage: number = 0;
    let numBelowMedian: number = 0;
    let numBelowAverage: number = 0;

    const num: number = seasonKpi.soccerClubKpiCollection.length;

    for (const club of seasonKpi.soccerClubKpiCollection) {
      const rating: KpiRating = this.rater.rate(seasonKpi, club.soccerClubId, hideRatings);

      if (rating.median === 1) {
        numAboveMedian += 1;
      } else if (rating.median === 0) {
        numOnMedian += 1;
      } else {
        numBelowMedian += 1;
      }

      if (rating.average === 1) {
        numAboveAverage += 1;
      } else if (rating.average === 0) {
        numOnAverage += 1;
      } else {
        numBelowAverage += 1;
      }
    }

    return {
      median: {
        above: numAboveMedian,
        on: numOnMedian,
        below: numBelowMedian,
        baseline: 'median',
        num,
      },
      average: {
        above: numAboveAverage,
        on: numOnAverage,
        below: numBelowAverage,
        baseline: 'average',
        num,
      },
    };
  }

  public abstract process(kpis: Kpis | Kpis[]): KpisProcessResult;
}
