<div class="form-wrapper">
  <lsz-edit-form-header
    [headerText]="headerText"
    [numEntries]="entries.length"
    [editMode]="editMode"
    (clickEdit)="onClickEdit()"
    (clickEditDone)="onClickEditDone()"
  ></lsz-edit-form-header>

  <table [class.editMode]="editMode" [formGroup]="allForms">
    <thead>
    <tr>
      <th i18n="@@tableHeaderLastName">Name</th>
      <th i18n="@@tableHeaderBirthdate">Geburtsdatum</th>
      <th i18n="@@tableHeaderFunction">Funktion</th>
      <th i18n="@@tableHeaderEmployedSince">beschäftigt seit</th>
      <th *ngIf="editMode" i18n="@@tableHeaderOptions" class="column-options">Optionen</th>
      <th>{{'lastEdit' | translate}}</th>
    </tr>
    </thead>
    <tbody lsz-education-employee-form
           formArrayName="customEntries"
           *ngFor="let form of customEntryForms.controls; let i = index"
           (entrySaved)="onUpdate(i)"
           (entryDeleted)="onDelete(i)"
           [editMode]="editMode"
           [index]="i"
           [form]="form">
    </tbody>
    <tbody *ngIf="addMode" formGroupName="newEntry">
    <tr>
      <td>
        <input type="text" formControlName="name" required placeholder="Neuer Eintrag"
               i18n-placeholder="@@placeholderNewEntry" />
      </td>
      <td>
        <input ngbDatepicker #dpBirthdate="ngbDatepicker" type="text" formControlName="birthdate" required />
        <button (click)="dpBirthdate.toggle()" type="button">
          <fa-icon [icon]="faCalendar"></fa-icon>
        </button>
        <div *ngIf="birthdate.invalid && (birthdate.dirty || birthdate.touched)">
          <div class="input-error" *ngIf="birthdate.errors.required">Angabe wird benötigt.</div>
          <div class="input-error" *ngIf="birthdate.errors.dateValidator">Fehlerhaftes Datum</div>
        </div>
      </td>
      <td>
        <input type="text" formControlName="personnelFunction" required />
        <div *ngIf="personnelFunction.invalid && (personnelFunction.dirty || personnelFunction.touched)">
          <div class="input-error" *ngIf="personnelFunction.errors.required">Angabe wird benötigt.</div>
        </div>
      </td>
      <td>
        <input ngbDatepicker #dpDateOfJoiningClub="ngbDatepicker" type="text" formControlName="dateOfJoiningClub"
               required />
        <button (click)="dpDateOfJoiningClub.toggle()" type="button">
          <fa-icon [icon]="faCalendar"></fa-icon>
        </button>
        <div *ngIf="dateOfJoiningClub.invalid && (dateOfJoiningClub.dirty || dateOfJoiningClub.touched)">
          <div class="input-error" *ngIf="dateOfJoiningClub.errors.required">Angabe wird benötigt.</div>
          <div class="input-error" *ngIf="dateOfJoiningClub.errors.dateValidator">Fehlerhaftes Datum.</div>
          <div class="input-error" *ngIf="dateOfJoiningClub.errors.dateValidatorAtLeastThreeYears">Mind. 3 Jahre
            Zugehörigkeit.
          </div>
        </div>
      </td>
      <td>
        <div class="form-options" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
          <button
            type="button"
            [disabled]="!newEntryForm.valid"
            (click)="createNewEntry()"
            class="nav-button"
            [ngClass]="{'notice': newEntryForm.valid}">
            <fa-icon [icon]="faSave"></fa-icon>
          </button>
          <button type="button" (click)="exitAddMode()" class="nav-button">
            <fa-icon [icon]="faTrash"></fa-icon>
          </button>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

  <button *ngIf="editMode && !addMode" type="button" (click)="addNewRow()" i18n="@@dataButtonAdd" class="add-button">
    <fa-icon [icon]="faPlus"></fa-icon>
    Neuen Eintrag hinzufügen
  </button>
</div>
