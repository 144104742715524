import { SoccerClub } from '../../models/soccerClub';
import { Detail, DetailCalculator } from './detailCalculations';
import { Kpi } from './kpi';

export class DetailCalculatorAverage extends DetailCalculator {
  public calculate(kpi: Kpi, soccerClub: SoccerClub | null): Detail {
    const detail: Detail = super.calculate(kpi, soccerClub);
    detail.baseline = 'average';
    detail.baselineValue = kpi.summary.avg;
    detail.multiValues = kpi.summary.multiValues ? [kpi.summary.multiValues[0], kpi.summary.multiValues[1]] : undefined;

    return detail;
  }
}
