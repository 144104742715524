import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { RegenerationFacility } from '../models/models';

import { InfrastructureService } from './infrastructure.service';

@Injectable({
  providedIn: 'root',
})
export class RegenerationFacilityService extends InfrastructureService<RegenerationFacility> {
  public readonly endpoint: string = '/RegenerationFacilities';

  constructor(protected http: HttpClient) {
    super(http);
  }

}
