<div class="edit-form-header">
  <div>
    <h5 i18n="@@headerDataLawnFields">{{headerText}}</h5>
    <div class="number-badge">{{numEntries}}</div>
  </div>
  &nbsp;
  <div style="width: 135px;">
    <button *ngIf="!editMode" type="button" (click)="onClickEdit()" class="nav-button">
      <fa-icon [icon]="faPen"></fa-icon>
    </button>
    <button *ngIf="editMode" type="button" (click)="onClickEditDone()" class="nav-button" style="margin-left: 105px;">
      <fa-icon [icon]="faCheck"></fa-icon>
    </button>
  </div>
</div>
