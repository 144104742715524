<lsz-header [showLeagueSelect]="false"
            [showBudgetSelect]="false"
            [soccerClub]="soccerClub">
</lsz-header>

<div id="editor-content">
  <div>
    <div id="editor-nav">
      <nav>
        <header class="header-title">Infrastruktur</header>
        <ul>
          <li><a href="/edit-data#infrastructure-fields">Plätze</a></li>
          <li><a href="/edit-data#infrastructure-halls">Hallen</a></li>
          <li><a href="/edit-data#infrastructure-specialequipment">Sonderausstattung</a></li>
          <li><a href="/edit-data#infrastructure-functionalrooms">Funktionsräume (Sport)</a></li>
          <li><a href="/edit-data#infrastructure-regenerationfacilities">Regenerationsanlagen</a></li>
          <li><a href="/edit-data#infrastructure-rooms">Büros/Besprechungsräume</a></li>
          <li><a href="/edit-data#infrastructure-databasesystems">Datenbanksysteme</a></li>
          <li><a href="/edit-data#infrastructure-cooperations">Kooperationen</a></li>
        </ul>
      </nav>

      <nav>
        <header>Personal</header>
        <ul>
          <li><a href="/edit-data#personnel-fulltimeemployees">Vollzeitmitarbeiter</a></li>
          <li><a href="/edit-data#personnel-parttimeemployees">Teilzeitmitarbeiter</a></li>
          <li><a href="/edit-data#personnel-educatedpersonnel">Ausgebildetes Personal im eigenen Lizenzbereich
            <br />(Mindestzugehörigkeit zum LZ: 3 Jahre)</a></li>
        </ul>
      </nav>
    </div>
  </div>

  <div id="editor-main" *ngIf="soccerClub && currentSeason">
    <ngx-spinner [fullScreen]="false" type="ball-clip-rotate" size="medium" bdColor="" color="#b3c1ca">
      <p class="loading">Daten werden geladen...</p>
    </ngx-spinner>

    <h2 i18n="@@headerEnterData">Daten hinterlegen</h2>

    <div class="data-entry-count-down">
      <lsz-data-entry-count-down [season]="currentSeason"></lsz-data-entry-count-down>
    </div>

    <lsz-season-selection-bar *ngIf="isAdmin"
                              [allSeasons]="allSeasons"
                              (seasonChanged)="currentSeason = $event"
                              [currentSeason]="currentSeason">
    </lsz-season-selection-bar>

    <h3 class="table-criterion-title" i18n="@@headerCriterionInfrastructure">Infrastruktur</h3>

    <h4 i18n="@@headerCategoryFields" id="infrastructure-fields">Plätze</h4>
    <lsz-lawn-field-form-collection [soccerClub]="soccerClub" [season]="currentSeason"></lsz-lawn-field-form-collection>

    <lsz-small-sized-field-form-collection [soccerClub]="soccerClub"
                                           [season]="currentSeason">
    </lsz-small-sized-field-form-collection>

    <lsz-open-space-field-form-collection [soccerClub]="soccerClub"
                                          [season]="currentSeason">
    </lsz-open-space-field-form-collection>
    <h4 i18n="@@headerCategoryHalls" id="infrastructure-halls">Hallen</h4>
    <lsz-soccer-hall-form-collection [soccerClub]="soccerClub"
                                     [season]="currentSeason">
    </lsz-soccer-hall-form-collection>

    <lsz-indoor-hall-form-collection [soccerClub]="soccerClub"
                                     [season]="currentSeason">
    </lsz-indoor-hall-form-collection>

    <h4 i18n="@@headerCategorySpecialEquipment" id="infrastructure-specialequipment">Sonderausstattung</h4>
    <lsz-special-equipment-form-collection [soccerClub]="soccerClub"
                                           [season]="currentSeason">
    </lsz-special-equipment-form-collection>

    <h4 i18n="@@headerCategoryFunctionalRooms" id="infrastructure-functionalrooms">Funktionsräume (Sport)</h4>
    <lsz-functional-room-form-collection [soccerClub]="soccerClub"
                                         [season]="currentSeason">
    </lsz-functional-room-form-collection>

    <h4 i18n="@@headerCategoryRegenerationFacilities" id="infrastructure-regenerationfacilities">
      Regenerationsanlagen</h4>
    <lsz-regeneration-facility-form-collection [soccerClub]="soccerClub"
                                               [season]="currentSeason">
    </lsz-regeneration-facility-form-collection>

    <h4 i18n="@@headerCategoryRooms" id="infrastructure-rooms">Büros/Besprechungsräume</h4>
    <lsz-room-form-collection [soccerClub]="soccerClub" [season]="currentSeason"></lsz-room-form-collection>

    <h4 i18n="@@headerCategoryDatabaseSystems" id="infrastructure-databasesystems">Datenbanksysteme</h4>
    <lsz-database-system-form-collection [soccerClub]="soccerClub"
                                         [season]="currentSeason">
    </lsz-database-system-form-collection>

    <h4 i18n="@@headerCategoryCooperations" id="infrastructure-cooperations">Kooperationen</h4>
    <lsz-school-cooperation-form-collection [soccerClub]="soccerClub"
                                            [season]="currentSeason">
    </lsz-school-cooperation-form-collection>

    <lsz-common-cooperation-form-collection [soccerClub]="soccerClub"
                                            [season]="currentSeason">
    </lsz-common-cooperation-form-collection>

    <h3 class="table-criterion-title" i18n="@@headerCriterionPersonnel">Personal</h3>

    <lsz-category-full-time-employees [soccerClub]="soccerClub"
                                      [season]="currentSeason"></lsz-category-full-time-employees>

    <h4 i18n="@@headerCategoryPartTimeEmployees" id="personnel-parttimeemployees">Teilzeitmitarbeiter</h4>
    <lsz-part-time-employee-leadership-form-collection [soccerClub]="soccerClub"
                                                       [season]="currentSeason">
    </lsz-part-time-employee-leadership-form-collection>

    <lsz-part-time-employee-management-form-collection [soccerClub]="soccerClub"
                                                       [season]="currentSeason">
    </lsz-part-time-employee-management-form-collection>

    <lsz-part-time-employee-soccer-training-form-collection [soccerClub]="soccerClub"
                                                            [season]="currentSeason">
    </lsz-part-time-employee-soccer-training-form-collection>

    <lsz-part-time-employee-support-form-collection [soccerClub]="soccerClub"
                                                    [season]="currentSeason">
    </lsz-part-time-employee-support-form-collection>

    <lsz-part-time-employee-misc-form-collection [soccerClub]="soccerClub"
                                                 [season]="currentSeason">
    </lsz-part-time-employee-misc-form-collection>

    <h4 i18n="@@headerCategoryEducatedPersonnel" id="personnel-educatedpersonnel">Ausgebildetes Personal im eigenen
      Lizenzbereich<br /><span class="info">(Mindestzugehörigkeit zum LZ: 3 Jahre)</span></h4>
    <lsz-education-employee-form-collection [soccerClub]="soccerClub"
                                            [personnelArea]="'Management'"
                                            [headerText]="'Management & Administration'"
                                            [season]="currentSeason">
    </lsz-education-employee-form-collection>

    <lsz-education-employee-form-collection [soccerClub]="soccerClub"
                                            [personnelArea]="'Support'"
                                            [headerText]="'Unterstützung'"
                                            [season]="currentSeason">
    </lsz-education-employee-form-collection>

    <lsz-education-employee-form-collection [soccerClub]="soccerClub"
                                            [personnelArea]="'SoccerTraining'"
                                            [headerText]="'Fußballausbildung'"
                                            [season]="currentSeason">
    </lsz-education-employee-form-collection>

    <lsz-education-employee-form-collection [soccerClub]="soccerClub"
                                            [personnelArea]="'Misc'"
                                            [headerText]="'Sonstiges'"
                                            [season]="currentSeason">
    </lsz-education-employee-form-collection>
  </div>
</div>
