<div class="distribution">
  <button class="lsz-button float-right" *ngIf="isDFL | async" (click)="showAllClubs()">C</button>

  <ng-template #tipContent>Detailansicht Darstellung der Verteilung innerhalb der Bezugsgruppe</ng-template>
  <h3 class="detail-category-header" placement="bottom" tooltipClass="tipclass" [ngbTooltip]="tipContent">Detailansicht
    |
    <lsz-category-kpi-name [name]="detail.name"></lsz-category-kpi-name>
  </h3>
  <div class="detail-category-content">

    <div class="detail-category-content-sub" fxLayout="row" fxLayoutGap="25px">
      <div *ngIf="!detail.multiValues" class="detail-values-container" fxLayout="column" fxLayoutGap="40px">
        <ng-container *ngIf="detail.type === 'Aggregation'">
          <div *ngIf="detail.own > -1" class="club-value-container">
            <div class="first-value club-value" [title]="detail?.own | convertToPercentage: detail?.name">
              <ng-container *ngIf="detail?.name | isDetailPercentage; else own">
                {{ detail?.own | percent: '1.0' }}
              </ng-container>
              <ng-template #own>
                {{ detail?.own | number: '1.0-0' || '-'}}
              </ng-template>
            </div>
            <div class="club-value-label">Eigener Wert</div>
          </div>
          <div>
            <div class="second-value baseline-value"
                 [title]="detail.baselineValue | convertToPercentage: detail.name">
              <ng-container *ngIf="detail.name | isDetailPercentage; else baselineValue">
                {{ detail.baselineValue | percent: '1.0' }}
              </ng-container>
              <ng-template #baselineValue>
                {{ detail.baselineValue | number:'1.0-0' || '-'}}
              </ng-template>
            </div>
            <div class="baseline-value-label">{{detail.baseline === 'median' ? 'Median' : 'Mittelwert'}}</div>
          </div>
        </ng-container>

        <ng-container *ngIf="detail.type === 'Binary'">
          <ng-container *ngIf="detail.own > -1">
            <div class="club-value-container">
              <div class="first-value club-value" [title]="detail.own === 1 ? 'ja' : 'nein'">{{detail.own === 1 ? 'ja' : 'nein'}}</div>
              <div class="club-value-label">Eigener Wert</div>
            </div>
            <div>
              <div class="second-value">{{detail.own === 1 ? 'nein' : 'ja'}}</div>
            </div>
          </ng-container>
          <ng-container *ngIf="detail.own === -1">
            <div>
              <div class="first-value">ja</div>
              <div class="club-value-label">&nbsp;</div>
            </div>
            <div>
              <div class="second-value">nein</div>
            </div>
          </ng-container>
        </ng-container>

        <span class="count">n={{detail.numClubs}}</span>
      </div>
      <div fxFlex="100">
        <lsz-category-kpi-chart [detail]="detail | convertDetailToPercentage" [criterion]="criterion" [category]="category"></lsz-category-kpi-chart>
      </div>
    </div>
  </div>
</div>
