<header id="data-presenter-header">
  <div id="data-presenter-header-content">
    <div id="griditem1">
      <a routerLink="/">
        <img src="/assets/Deutsche_Fußball_Liga_logo.svg" alt="Logo der DFL" i18n-alt="@@logoDfl" class="logo-dfl"/>
        <img src="/assets/Deutscher_Fußball-Bund_logo.svg" alt="Logo des DFB" i18n-alt="@@logoDfb" class="logo-dfb"/>
      </a>
      <div i18n="@@headerAppTitle" class="header-title" fxLayout="column" fxLayoutGap="2px">
        <span>Sportdatenbank</span><br>
        <span>Leistungszentren</span>
      </div>
    </div>
    <div id="griditem2">

    </div>
    <div id="griditem3">

    </div>
    <div id="griditem4">
    </div>
  </div>
</header>
<div class="legal-notice-content">

  <h2>DATENSCHUTZERKLÄRUNG UND COOKIE-RICHTLINIE</h2>

  <p>Als gemeinsam Verantwortliche im Sinn der Europäischen Datenschutzgrundverordnung („DSGVO“) und des Bundesdatenschutzgesetzes („BDSG“) erheben, verarbeiten und nutzen der DFL Deutsche Fußball Liga e.V., Guiollettstraße 44-46, D-60325 Frankfurt am Main („DFL e.V.“) und der Deutsche Fußball-Bund e.V., Otto-Fleck-Schneise 6, D-60528 Frankfurt am Main („DFB e.V.“) (DFL e.V. und DFB e.V. nachfolgend gemeinsam: die „Verantwortlichen“) personenbezogene Daten, die bei dem Besuch und Nutzung der Internetseite <a href="lz-vergleichsdatenbank.de">lz-vergleichsdatenbank.de</a> sowie deren Unterdomänen (insgesamt: „Portal“) erhoben und gespeichert werden, unter Beachtung der in der Bundesrepublik Deutschland geltenden datenschutzrechtlichen Bestimmungen, insbesondere der DSGVO und des BDSG. Diese Datenschutzerklärung und Cookie-Richtlinie (nachfolgend zusammengefasst „Erklärung“) erläutert, welche Daten der Besucher („Nutzer“) des Portals erfasst und wie diese Daten verarbeitet werden.</p>

  <h3>1. Personenbezogene Daten</h3>
  <p>Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen. Als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind, identifiziert werden kann. Dazu zählen z.B. der Name, personalisierte E-Mail-Adressen, die Wohnadresse, die Telefonnummer oder das Geburtsdatum.</p>

  <h3>2. Gemeinsame Verantwortlichkeit</h3>
  <p>DFL e.V. und DFB e.V. legen gemeinsam die Zwecke und Mittel der Verarbeitung der personenbezogenen Daten der Nutzer des Portals fest. Die Verantwortlichen haben eine Vereinbarung gemäß Art. 26 Abs. 1 DSGVO abgeschlossen. In dieser Vereinbarung haben die Verantwortlichen festgelegt, dass der DFL e.V. für die Wahrnehmung der Rechte der betroffenen Personen und die Informationspflichten gemäß Art. 13 DSGVO und Art. 14 DSGVO zuständig ist. Weiterhin ist der DFL e.V. dafür zuständig, die Anforderungen an die Sicherheit der Datenverarbeitung mittels des Portals gemäß Art. 32 DSGVO sicherzustellen. Die Verantwortlichen haben eine Anlaufstelle für die betroffenen Personen gemäß Art. 26 Abs. 1 S. 3 DSGVO eingerichtet. Diese können die Nutzer bei Fragen zur gemeinsamen Verantwortlichkeit, zur Geltendmachung ihrer Rechte oder bei sonstigen Fragen zum Datenschutz unter <a href="mailto:datenschutz@dfl.de">datenschutz@dfl.de</a> erreichen.</p>

  <h3>3. Datenerhebung, -verarbeitung und -nutzung bei Zugriffen auf das Portal</h3>
  <p>Bei jedem Zugriff eines Nutzers auf das Portal übermittelt der Internetbrowser des Nutzers aus technischen Gründen automatisch die folgenden Daten an den Webserver der Verantwortlichen:</p>

  <ul style="list-style-type: disc;">
    <li>IP-Adresse des anfragenden Endgeräts</li>
    <li>Datum und Uhrzeit des Zugriffs</li>
    <li>Name und URL der aufgerufenen Seite</li>
    <li>Übertragene Datenmenge</li>
    <li>Zugriffsstatus (Datei übertragen, Datei nicht gefunden etc.)</li>
    <li>Erkennungsdaten des verwendeten Browser- und Betriebssystems des Endgeräts des Nutzers</li>
    <li>Name des Internetproviders des Nutzers</li>
    <li>Website, von der aus der Zugriff erfolgt</li>
  </ul>
  <p>Die Erhebung, Verarbeitung und Nutzung dieser Daten erfolgt zum Zweck der Ermöglichung der Nutzung des -Portals (Verbindungsaufbau), der Systemsicherheit sowie der technischen Administration der Netzinfrastruktur. Ein Abgleich mit anderen Datenbeständen oder eine Weitergabe an Dritte, auch in Auszügen, findet nicht statt.</p>

  <p>Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 f) DSGVO. Das berechtigte Interesse der Verantwortlichen besteht darin, eine sichere und funktionierende Website zur Verfügung zu stellen.</p>

  <h3>4. Datenerhebung, -verarbeitung und -nutzung im Rahmen der Registrierung und Nutzung des Portals</h3>
  <h4>4.1 Registrierung und Login</h4>
  <p>Die Nutzung des Portals erfordert eine vorherige Registrierung einschließlich der Angabe personenbezogener Daten (Name, Kontaktdaten etc.) durch den Nutzer. Zur Registrierung und zum Login verwenden die Verantwortlichen die Authentifizierungs- und Nutzerverwaltungsanwendung von Auth0, Inc. (USA) („Auth0“), die als Auftragsverarbeiter agiert. Auth0 nimmt aus den USA und ggfs. weiteren Drittländern Zugriff auf diese Daten. Auth0 ist unter dem <a href="https://www.privacyshield.gov/list" target="_blank">EU-US Privacy Shield</a> zertifiziert; soweit Zugriff aus weiteren Drittländern erfolgt, ist dies durch EU-Standardvertragsklauseln abgesichert.</p>

  <p>Die Daten werden ausschließlich zum Betrieb und zur Verwaltung der Services sowie zur Begründung, Durchführung oder Beendigung des zugrundeliegenden Vertrags mit dem Nutzer über die Registrierung und Nutzung des Portals verwendet. Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 b) DSGVO.</p>

  <h4>4.2 Funktion „Keep me logged in”</h4>
  <p>Bei Anmeldung wird das Login des Nutzers (E-Mail-Adresse, Passwort) gespeichert. Sollte er sich nicht selbst aktiv ausloggen, muss der Nutzer sich erst nach 8 Stunden Inaktivität oder ansonsten spätestens nach 24 Stunden erneut einloggen. Um diese Funktion sicherzustellen, setzen die Verantwortlichen für diesen Zeitraum einen Session-Cookie. Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 f) DSGVO, um eine möglichst komfortable und effiziente Nutzung des Portals zu ermöglichen.</p>

  <p>Um einen unbefugten Accountzugriff zu vermeiden, sollte der Nutzer sich nicht auf Endgeräten einloggen, die auch andere Personen nutzen bzw. sich andernfalls nach Ende seiner Nutzung aktiv ausloggen.</p>

  <h4>4.3 Single Sign On</h4>
  <p>Ist der Nutzer zur Nutzung anderer registrierungs- und loginpflichtiger Services des DFL e.V. berechtigt, die ebenfalls die Authentifizierungs- und Nutzerverwaltungsanwendung von Auth0 verwenden, dient diese Anwendung angesichts der vielfältigen Überschneidungen in den Nutzergruppen dieser anderen Services des DFL e.V. und dieses Portals als Single Sign On. D.h. der Nutzer muss sich bei diesen anderen Services des DFL e.V. nicht erneut einloggen, sobald er sich bei diesem Portal eingeloggt und sein Session-Cookie noch gültig ist (siehe Ziffer 4.2). Umgekehrt lesen die Verantwortlichen ebenfalls einen bei Anmeldung zu den vorstehend genannten registrierungs- und loginpflichtigen Services des DFL e.V. gesetzten Session-Cookie aus, um auch ein Single Sign On für dieses Portal zu ermöglichen.</p>

  <p>Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 f) DSGVO, um eine möglichst komfortable und effiziente Nutzung des Portals zu ermöglichen.</p>

  <h3>5. Zweckbindung der Verarbeitung und Nutzung personenbezogener Daten</h3>
  <p>Jegliche Verarbeitung oder Nutzung von personenbezogenen Daten des Nutzers erfolgt nur zu den in dieser Erklärung genannten Zwecken und in dem zur Erreichung des jeweiligen Zwecks erforderlichen Umfang.</p>

  <p>Personenbezogene Daten werden von den Verantwortlichen weder veröffentlicht noch unberechtigt an Dritte weitergegeben.</p>

  <p>Übermittlungen personenbezogener Daten an staatliche Einrichtungen und Behörden erfolgen nur im Rahmen zwingender nationaler Rechtsvorschriften oder wenn die Weitergabe im Fall von Angriffen auf die Netzinfrastruktur zur Rechts- oder Strafverfolgung erforderlich ist. Rechtsgrundlage der Verarbeitung ist in diesem Fall Art. 6 Abs. 1 c) DSGVO in Verbindung mit § 24 Abs. 1 Nr. 1 BDSG.</p>

  <h3>6. Aufbewahrung und Löschung personenbezogener Daten</h3>
  <p>Sämtliche gespeicherten personenbezogenen Daten und pseudonymisierten Nutzungsdaten werden - sobald sie für die Zwecke, für die sie erhoben wurden, nicht mehr benötigt werden oder der Nutzer dies verlangt - unmittelbar und unwiederbringlich gelöscht, sofern die Verantwortlichen nicht aufgrund gesetzlicher Vorschriften zur Aufbewahrung verpflichtet oder berechtigt sind. Sind die Verantwortlichen aufgrund gesetzlicher Vorschriften zur Aufbewahrung verpflichtet oder berechtigt, so werden die gespeicherten personenbezogenen Daten und pseudonymisierten Nutzungsdaten mit Ablauf der gesetzlich vorgeschriebenen Aufbewahrungsfristen unwiederbringlich gelöscht.</p>

  <h3>7. Sicherheit</h3>
  <p>Die Verantwortlichen setzen technische und organisatorische Sicherheitsmaßnahmen ein, um die personenbezogenen Daten der Nutzer gegen zufällige oder vorsätzliche Manipulationen, Verlust, Zerstörung oder gegen den Zugriff unberechtigter Personen zu schützen. Die Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend angepasst.</p>

  <h3>8. Links zu Websites anderer Anbieter</h3>
  <p>Das Portal kann Links zu Websites anderer Anbieter enthalten. Diese Erklärung gilt ausschließlich für das Portal. Die Verantwortlichen haben keinen Einfluss darauf und kontrollieren nicht, dass andere Anbieter die geltenden Datenschutzbestimmungen einhalten.</p>

  <h3>9. Rechte des Nutzers</h3>
  <p>Dem Nutzer stehen nach der DSGVO einige Rechte zu. Insbesondere hat der Nutzer</p>

  <ul style="list-style-type: disc;">
    <li>ein Auskunftsrecht bezüglich der über ihn gespeicherten personenbezogenen Daten (Art. 15 DSGVO);</li>
    <li>ein Recht auf Berichtigung unrichtiger Daten (Art. 16 DSGVO); </li>
    <li>ein Recht auf Löschung der Daten unter den Voraussetzungen des Art. 17 DSGVO;</li>
    <li>ein Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO); </li>
    <li>ein Recht auf Datenübertragbarkeit nach Art. 20 DSGVO; und </li>
    <li>ein Recht auf Widerspruch gegen die Verarbeitung, soweit diese zur Wahrung unserer berechtigten Interessen erfolgt (Art. 21 DSGVO).</li>
  </ul>

  <p>Der Nutzer kann seine Rechte durch eine E-Mail an die gemeinsame Anlaufstelle der Verantwortlichen unter <a href="mailto:info@dfl.de">info@dfl.de</a> oder über die eingangs aufgeführten Adressen per Brief geltend machen. Fragen zum Datenschutz an den DFL e.V. können an <a href="mailto:datenschutz@dfl.de">datenschutz@dfl.de</a> gerichtet werden; der von dem DFB benannte Datenschutzbeauftragte ist wiederum unter <a href="mailto:datenschutz@dfl.de">datenschutz@dfl.de</a> erreichbar. Unter den beiden im vorstehenden Satz genannten E-Mail-Adressen werden lediglich datenschutzbezogene Anfragen beantwortet.</p>

  <p>Weiterhin kann der Nutzer sich bei einer zuständigen Aufsichtsbehörde über die Datenverarbeitung beschweren. Für die Verantwortlichen zuständig ist der Hessische Beauftragte für Datenschutz und Informationsfreiheit, bei dem der Nutzer unter folgendem <a href="https://datenschutz.hessen.de/service/beschwerde">Link</a> Beschwerde einreichen kann.</p>

  <h3>10. Wo kann der Nutzer die relevanten Gesetzestexte finden?</h3>
  <p>Die DSGVO kann der Nutzer unter folgendem <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=celex%3A32016R0679">Link</a> abrufen, das BDSG und sonstige relevante deutsche Gesetzestexte wiederum unter folgendem <a href="https://www.gesetze-im-internet.de/">Link</a>.</p>

  <h3>11. Einbeziehung, Gültigkeit und Aktualität der Erklärung</h3>
  <p>Im Rahmen der Nutzung des Portals gelten für den Nutzer die in dieser Erklärung beschriebenen Regeln zur Erhebung, Verarbeitung und Nutzung seiner Daten. Die Erklärung ist aktuell und datiert vom 1. August 2019. Die Verantwortlichen behalten sich vor, die Erklärung bei Bedarf, insbesondere zur Anpassung an eine Weiterentwicklung des Portals oder Implementierung neuer Technologien, jederzeit mit Wirkung für die Zukunft zu ändern.</p>
</div>
