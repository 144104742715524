import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SoccerClub } from '../../../../../models/soccerClub';
import { ConfirmationDialogService } from '../../../../../shared/confirmation-dialog/services/confirmation-dialog.service';
import { FullTimeEmployee } from '../../../../models/models';
import { FullTimeEmployeeService } from '../../../../services/full-time-employee.service';
import { FullTimeEmployeeFormEntry } from '../../models/fullTimeEmployeeFormEntry';
import { FullTimeEmployeeFormService } from '../../services/full-time-employee-form.service';
import { ConfirmationDialogResult } from '../../../../../shared/confirmation-dialog/confirmationDialogResult';
import { Season } from '../../../../../services/season/models/season';
import { sort } from 'src/app/shared/utils/utils';
import { SortConfig, SortDirection } from 'src/app/shared/models/utility';

@Component({
  selector: 'lsz-full-time-employees-collection',
  templateUrl: './full-time-employees-collection.component.html',
  styleUrls: ['./full-time-employees-collection.component.scss'],
})
export class FullTimeEmployeesCollectionComponent implements OnInit, OnChanges, OnDestroy {

  public get customEntryForms(): UntypedFormArray {
    return this.allForms.get('customEntries') as UntypedFormArray;
  }

  public get newEntryForm(): UntypedFormGroup {
    return this.allForms.get('newEntry') as UntypedFormGroup;
  }

  public get professionalGroup(): UntypedFormControl {
    return this.newEntryForm.get('professionalGroup') as UntypedFormControl;
  }

  public get professionalQualification(): UntypedFormControl {
    return this.newEntryForm.get('professionalQualification') as UntypedFormControl;
  }

  public get sortIcon(): IconDefinition {
    return this.sortConfig.direction === SortDirection.ASC ? faArrowUp : faArrowDown;
  }

  @Input() public entries: FullTimeEmployee[] = [];
  @Input() public headerText: string;
  @Input() public season: Season;
  @Input() public soccerClub: SoccerClub;
  @Input() public type: 'Leadership' | 'Management' | 'SoccerTraining' | 'Support';
  public addMode: boolean;
  public allForms: UntypedFormGroup;
  public editMode: boolean;
  public faPlus: unknown;
  public faSave: unknown;
  public faTrash: unknown;
  public faCalendar: unknown;
  public faArrowDown: unknown;
  public faArrowUp: unknown;
  public sortConfig: SortConfig;

  public onDestroy$: Subject<void>;

  constructor(private service: FullTimeEmployeeService,
    private formService: FullTimeEmployeeFormService,
    private confirmationDialog: ConfirmationDialogService,
    private cdr: ChangeDetectorRef) {
    this.faPlus = faPlus;
    this.faSave = faSave;
    this.faTrash = faTrash;
    this.faCalendar = faCalendar;
    this.faArrowDown = faArrowDown;
    this.faArrowUp = faArrowUp;
    this.sortConfig = { key: 'lastName', direction: SortDirection.ASC }

    this.onDestroy$ = new Subject();
  }

  public ngOnInit(): void {
    this.allForms = this.formService.initForm();
  }

  public ngOnChanges(): void {
    if (this.soccerClub && this.season && this.entries) {
      this.allForms = this.initForm(this.entries, this.sortConfig);
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  public exitAddMode(resetForm: boolean = true): void {
    if (resetForm) {
      this.newEntryForm.reset();
    }
    this.addMode = false;
    this.cdr.detectChanges();
  }

  public exitEditMode(): void {
    this.exitAddMode(false);
    this.editMode = false;
    this.allForms = this.initForm(this.entries, this.sortConfig);
    this.cdr.detectChanges();
  }

  public onClickAdd(): void {
    this.addMode = true;
  }

  public onClickCancel(): void {
    this.exitAddMode();
  }

  public onClickEdit(): void {
    this.editMode = true;
  }

  public onClickEditDone(): void {
    if (this.allForms.dirty) {
      const modalRef: NgbModalRef = this.confirmationDialog.openEditDoneConfirmation();
      modalRef.closed.subscribe((result: ConfirmationDialogResult) => {
        if (result === ConfirmationDialogResult.CONFIRM) {
          this.exitEditMode();
        }
      });
    } else {
      this.exitEditMode();
    }
  }

  public onClickSave(): void {
    const fullTimeEmployee: FullTimeEmployee = this.formService.convertToFullTimeEmployee(
      {
        id: null,
        soccerClubId: this.soccerClub.id,
        seasonId: this.season.id,
        name: null,
        personnelArea: this.type,
        updatedAtUtc: null,
        createdAtUtc: null,
      },
      this.newEntryForm.getRawValue());

    this.service.add(fullTimeEmployee)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((newEntry: FullTimeEmployee) => {
        this.entries.push(newEntry);
        this.addFormForCustomEntry(newEntry);
        this.exitAddMode();
      });
  }

  public onEntryDeleted(index: number): void {
    this.service.delete(this.entries[index])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.entries.splice(index, 1);
        /**
         For cases in which the FormGroup to delete has been modified beforehand: The following removeAt() won't update
         the FormArray'spristine/dirty state, so we set this FormGroups's state to pristine so that any changes to it
         are ignored (it's about to get deleted anyway) and the FormArray's pristine-state gets updated.
         */
        this.customEntryForms.at(index).markAsPristine();
        this.customEntryForms.removeAt(index);
        this.cdr.detectChanges();
      });
  }

  public onEntrySaved(index: number): void {
    const form: UntypedFormGroup = this.customEntryForms.at(index) as UntypedFormGroup;
    const alteredEntry: FullTimeEmployee =
      this.formService.convertToFullTimeEmployee(this.entries[index], form.getRawValue());

    this.service.update(alteredEntry)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((updatedEntry: FullTimeEmployee) => {
        this.entries[index] = updatedEntry;
        form.get('updatedAtUtc').setValue(updatedEntry.updatedAtUtc);
        form.markAsPristine();
        this.cdr.detectChanges();
      });
  }

  public onProfessionalGroupChange(): void {
    if (this.professionalGroup.value === 'None') {
      this.professionalQualification.disable();
      this.professionalQualification.setValue('None');
    } else {
      this.professionalQualification.enable();
    }
  }

  public sortColumn() {
    this.sortConfig.direction = this.sortConfig.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
    this.customEntryForms.patchValue(sort(this.customEntryForms.value, this.sortConfig));
  }

  private addFormForCustomEntry(entry: FullTimeEmployee): void {
    const formEntry: FullTimeEmployeeFormEntry = this.formService.convertToFormEntry(entry);
    this.customEntryForms.push(this.formService.initEntry(formEntry));
    this.cdr.detectChanges();
  }

  private initForm(entries: FullTimeEmployee[], sortConfig: SortConfig) {
    entries = sort(entries, sortConfig) as FullTimeEmployee[];
    return this.formService.initFormWithFullTimeEmployees(entries);
  }
}
