import { Component, OnChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ConfirmationDialogService } from '../../../shared/confirmation-dialog/services/confirmation-dialog.service';
import { FunctionalRoom } from '../../models/models';
import { FunctionalRoomService } from '../../services/functional-room.service';
import { Validators } from '../../validators';
import { BaseTableViewComponent } from '../../../shared/base-table-view/base-table-view.component';

@Component({
  selector: 'lsz-functional-room-form-collection',
  templateUrl: './functional-room-form-collection.component.html',
  styleUrls: ['./functional-room-form-collection.component.css'],
})
export class FunctionalRoomFormCollectionComponent extends BaseTableViewComponent<FunctionalRoom> implements OnChanges {

  public dopingControlRoom: FunctionalRoom;
  public editMode: boolean;
  public entries: FunctionalRoom[] = [];
  public medicalRoom: FunctionalRoom;
  public physio: FunctionalRoom;
  public playersCabin: FunctionalRoom;
  public refereeCabin: FunctionalRoom;
  public trainersCabin: FunctionalRoom;
  public weightRoom: FunctionalRoom;

  public get amount(): number {
    let amount: number = 0;
    for (const entry of this.entries) {
      amount += entry.count;
    }

    return amount;
  }

  constructor(protected service: FunctionalRoomService,
              protected confirmationDialog: ConfirmationDialogService) {
    super(service, confirmationDialog);
  }

  public ngOnChanges(): void {
    if (this.soccerClub && this.season) {
      this.service.get(this.soccerClub, this.season.id).subscribe((entries: FunctionalRoom[]) => {
        this.entries = entries;
        this.allForms = this.initializeAllForms();

        this.updateTypedEntries();

        const formNames: string[] = [
          'dopingControlRoom',
          'medicalRoom',
          'physio',
          'playersCabin',
          'refereeCabin',
          'trainersCabin',
          'weightRoom',
        ];
        for (const formName of formNames) {
          const form: UntypedFormGroup = this.allForms.get(formName) as UntypedFormGroup;
          form.patchValue({
            remark: this[formName].remark as string,
            count: (['dopingControlRoom', 'medicalRoom'].includes(formName)) ? this[formName].count === 1 : this[formName].count as number,
          });
        }
      });
    }
  }

  public initializeAllForms(): UntypedFormGroup {
    return new UntypedFormGroup({
      dopingControlRoom: this.formGroup(this.findEntryByType('DopingControlRoom')),
      medicalRoom: this.formGroup(this.findEntryByType('MedicalRoom')),
      physio: this.formGroup(this.findEntryByType('Physio')),
      playersCabin: this.formGroup(this.findEntryByType('PlayersCabin')),
      refereeCabin: this.formGroup(this.findEntryByType('RefereeCabin')),
      trainersCabin: this.formGroup(this.findEntryByType('TrainersCabin')),
      weightRoom: this.formGroup(this.findEntryByType('WeightRoom')),
    });
  }

  public exitEditMode(): void {
    this.editMode = false;

    const resetValues = {
      dopingControlRoom: {
        remark: this.dopingControlRoom.remark,
        count: this.dopingControlRoom.count === 1,
        createdAtUtc: this.dopingControlRoom.createdAtUtc,
        updatedAtUtc: this.dopingControlRoom.updatedAtUtc,
      },
      medicalRoom: {
        remark: this.medicalRoom.remark,
        count: this.medicalRoom.count === 1,
        createdAtUtc: this.medicalRoom.createdAtUtc,
        updatedAtUtc: this.medicalRoom.updatedAtUtc,
      },
      physio: this.objectToForm(this.physio),
      playersCabin: this.objectToForm(this.playersCabin),
      refereeCabin: this.objectToForm(this.refereeCabin),
      trainersCabin: this.objectToForm(this.trainersCabin),
      weightRoom: this.objectToForm(this.weightRoom),
    };

    this.allForms.reset(resetValues);
  }

  public updateTypedEntries(): void {
    this.dopingControlRoom = this.findEntryByType('DopingControlRoom');
    this.medicalRoom = this.findEntryByType('MedicalRoom');
    this.physio = this.findEntryByType('Physio');
    this.playersCabin = this.findEntryByType('PlayersCabin');
    this.refereeCabin = this.findEntryByType('RefereeCabin');
    this.trainersCabin = this.findEntryByType('TrainersCabin');
    this.weightRoom = this.findEntryByType('WeightRoom');
  }


  protected formGroup(entry?: FunctionalRoom): UntypedFormGroup {
    return new UntypedFormGroup({
      remark: new UntypedFormControl(entry?.remark),
      count: new UntypedFormControl(entry?.count, [Validators.required]),
      updatedAtUtc: new UntypedFormControl(entry?.updatedAtUtc),
      createdAtUtc: new UntypedFormControl(entry?.createdAtUtc),
    });
  }

  protected objectToForm(entry: FunctionalRoom): Partial<FunctionalRoom> {
    return {
      remark: entry?.remark,
      count: entry?.count,
      updatedAtUtc: entry?.updatedAtUtc,
      createdAtUtc: entry?.createdAtUtc,
    };
  }

  private findEntryByType(type: string): FunctionalRoom {
    for (const entry of this.entries) {
      if (entry.roomType === type) {
        return entry;
      }
    }

    return {
      id: null,
      soccerClubId: this.soccerClub?.id,
      seasonId: this.season?.id,
      count: 0,
      remark: null,
      roomType: type,
      createdAtUtc: null,
      updatedAtUtc: null,
    };
  }

}
