import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';

import { BudgetGroup } from '../../../../data-presenter/models/budgetGroup';
import { BudgetGroupService } from '../../../../data-presenter/services/budget-group.service';
import { SoccerClub } from '../../../../models/soccerClub';
import { getClub } from '../../../../store/selectors/club.selectors';
import { AppState } from '../../../../store/states/app.state';

@Component({
  selector: 'lsz-budget-group',
  templateUrl: './budget-group.component.html',
  styleUrls: ['./budget-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetGroupComponent implements OnInit {
  @Input() public hideClubLogo: boolean;

  public budgetGroups$: BehaviorSubject<BudgetGroup[]>;
  public club$: Observable<SoccerClub>;
  public budgetHeaderIcon: unknown;

  constructor(private store: Store<AppState>, private service: BudgetGroupService) {
    this.budgetHeaderIcon = faChevronDown;
  }

  public ngOnInit(): void {
    this.club$ = this.store.pipe(select(getClub));
    this.budgetGroups$ = this.service.budgetGroups$;
  }

  public panelChange(e: NgbPanelChangeEvent): void {
    this.budgetHeaderIcon = e.panelId === 'panel-Budget' && !e.nextState
      ? faChevronDown : faChevronUp;
  }

}
