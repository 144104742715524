import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SoccerClub } from '../../models/soccerClub';
import { InfrastructureObject } from '../models/models';
import { isHttpParams } from 'src/app/shared/utils/utils';

@Injectable({
  providedIn: 'root',
})
export abstract class InfrastructureService<T extends InfrastructureObject> {
  protected abstract readonly endpoint: string;

  protected constructor(protected http: HttpClient) {}

  public add(arg: T): Observable<T> {
    const operation: string = 'add';

    return this.http.post<T>(this.endpoint, arg).pipe(catchError(this.handleError(operation)));
  }

  public delete(arg: T | number): Observable<T> {
    const operation: string = 'delete';
    const id: string | number = typeof arg === 'number' ? arg : arg.id;
    const url: string = `${this.endpoint}/${id}`;

    return this.http.delete<T>(url).pipe(catchError(this.handleError(operation)));
  }

  public get(): Observable<T[]>;
  public get(params: HttpParams): Observable<T[]>;
  /** @deprecated Rather use the overload with HttpParams object. */
  public get(soccerClub: SoccerClub, seasonId: string): Observable<T[]>;

  public get(arg?: SoccerClub | HttpParams, seasonId?: string): Observable<T[]> {
    const operation: string = 'get';
    let params: HttpParams = undefined;
    if (arg) {
      params = isHttpParams(arg) ? arg : new HttpParams().set('seasonId', seasonId).set('soccerClubId', arg.id);
    }

    return this.http.get<T[]>(this.endpoint, { params }).pipe(catchError(this.handleError(operation))) as Observable<
      T[]
    >;
  }

  public update(entity: T): Observable<unknown> {
    const operation: string = 'update';

    const id: string | number = typeof entity === 'number' ? entity : entity.id;
    const url: string = `${this.endpoint}/${id}`;

    return this.http.put(url, entity).pipe(catchError(this.handleError(operation)));
  }

  /**
   * Returns a function that handles Http operation failures.
   * This error handler lets the app continue to run as if no error occurred.
   *
   * @param operation - name of the operation that failed
   */
  protected handleError(operation: string): (error: HttpErrorResponse) => Observable<T> {
    return (error: HttpErrorResponse): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      const message: string =
        error.error instanceof ErrorEvent
          ? error.error.message
          : `server returned code ${error.status} with body "${error.error as string}"`;
      // TODO: better job of transforming error for user consumption
      throw new Error(`${operation} failed: ${message}`);
    };
  }

  protected log(message: string): void {
    // eslint-disable-next-line no-console
    console.info(`InfrastructureService ${this.endpoint}: ${message}`);
  }
}
