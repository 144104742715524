<tr [formGroup]="form">
  <td>
    <ng-container *ngIf="!editMode">{{name.value}}</ng-container>
    <div *ngIf="editMode">
      <input *ngIf="editMode" type="text" formControlName="name"/>

      <div *ngIf="name.invalid && (name.dirty || name.touched)">
        <div class="input-error" *ngIf="name.errors.required">Name wird benötigt.</div>
        <div class="input-error" *ngIf="name.errors.repeatedName">Name kann sich nicht wiederholen.</div>
      </div>
    </div>
  </td>
  <td>
    <lsz-binary-display *ngIf="!editMode" [value]="medicine.value"></lsz-binary-display>
    <lsz-binary-select *ngIf="editMode" [form]="form" [name]="'medicine'"></lsz-binary-select>
  </td>
  <td>
    <lsz-binary-display *ngIf="!editMode" [value]="players.value"></lsz-binary-display>
    <lsz-binary-select *ngIf="editMode" [form]="form" [name]="'players'"></lsz-binary-select>
  </td>
  <td>
    <lsz-binary-display *ngIf="!editMode" [value]="scouting.value"></lsz-binary-display>
    <lsz-binary-select *ngIf="editMode" [form]="form" [name]="'scouting'"></lsz-binary-select>
  </td>
  <td>
    <lsz-binary-display *ngIf="!editMode" [value]="staff.value"></lsz-binary-display>
    <lsz-binary-select *ngIf="editMode" [form]="form" [name]="'staff'"></lsz-binary-select>
  </td>
  <td>
    <lsz-binary-display *ngIf="!editMode" [value]="strategy.value"></lsz-binary-display>
    <lsz-binary-select *ngIf="editMode" [form]="form" [name]="'strategy'"></lsz-binary-select>
  </td>
  <td>
    <lsz-binary-display *ngIf="!editMode" [value]="support.value"></lsz-binary-display>
    <lsz-binary-select *ngIf="editMode" [form]="form" [name]="'support'"></lsz-binary-select>
  </td>
  <td>
    <lsz-binary-display *ngIf="!editMode" [value]="video.value"></lsz-binary-display>
    <lsz-binary-select *ngIf="editMode" [form]="form" [name]="'video'"></lsz-binary-select>
  </td>
  <td *ngIf="editMode">
    <lsz-dataset-options [form]="form" (save)="entrySaved.emit()" (delete)="entryDeleted.emit()"></lsz-dataset-options>
  </td>
  <td>
    <lsz-last-edit [form]="form"></lsz-last-edit>
  </td>
</tr>
