<h4>Sperrzeitraum eintragen ({{season?.name}})</h4>

<ngx-spinner [name]="spinnerId" [fullScreen]="false" type="ball-clip-rotate" size="medium" bdColor=""
             color="#b3c1ca">
</ngx-spinner>

<form [formGroup]="formGroup" (ngSubmit)="onSave()">

  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">

    <lsz-date-input [formCtrl]="startDate"></lsz-date-input>
    <lsz-time-input [formCtrl]="startTime"
                    [minuteStep]="5">
    </lsz-time-input>

    <span class="input-divider">-</span>

    <lsz-date-input [formCtrl]="endDate"></lsz-date-input>
    <lsz-time-input [formCtrl]="endTime"
                    [minuteStep]="5">
    </lsz-time-input>

  </div>

  <button class="lsz-button" type="submit" [disabled]="formGroup.invalid">
    Speichern
  </button>

</form>
