import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import { SoccerClub } from '../../models/soccerClub';
import { FullTimeEmployee } from '../models/models';

import { InfrastructureService } from './infrastructure.service';

@Injectable({
  providedIn: 'root',
})
export class FullTimeEmployeeService extends InfrastructureService<FullTimeEmployee> {

  public readonly endpoint: string = '/FullTimeEmployees';

  public fullTimeEmployees$: BehaviorSubject<FullTimeEmployee[]>;

  constructor(protected http: HttpClient) {
    super(http);
    this.fullTimeEmployees$ = new BehaviorSubject<FullTimeEmployee[]>([]);
  }

  public getFullTimeEmployees(soccerClub: SoccerClub, seasonId: string): void {
    this.http.get<FullTimeEmployee[]>(this.endpoint, {
      params: new HttpParams()
        .set('soccerClubId', soccerClub.id)
        .set('seasonId', seasonId),
    })
      .pipe(
        take(1),
        catchError(this.handleError('getFullTimeEmployees')),
      )
      .toPromise()
      .then((fullTimeEmployees: FullTimeEmployee[]) => this.fullTimeEmployees$.next(fullTimeEmployees));
  }

}
