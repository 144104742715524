import { KpiType } from './kpi-type.enum';
import { SeasonKpi, SoccerClubKpi } from './kpis';

export interface KpiRating {
  average: number | null;
  isVisible: boolean;
  median: number | null;
  own: number | null;
}

export class KpiRater {

  public rate(seasonKpi: SeasonKpi, soccerClubId: string | null, hideRatings?: boolean): KpiRating {
    const kpiRating: KpiRating = {median: null, average: null, own: null, isVisible: true};
    if (soccerClubId) {
      let mySoccerClubKpi: SoccerClubKpi = seasonKpi.soccerClubKpiCollection.find((soccerClub: SoccerClubKpi) => soccerClub.soccerClubId === soccerClubId);

      if (mySoccerClubKpi) {
        if (hideRatings || this.isBannedKpi(seasonKpi)) {
          kpiRating.isVisible = false;
        }

        if (seasonKpi.kpiType === KpiType.Aggregation) {
          const median: number = seasonKpi.summary.median;
          const average: number = seasonKpi.summary.avg;

          kpiRating.median = 0;
          if (mySoccerClubKpi.sum > median) {
            kpiRating.median = 1;
          } else if (mySoccerClubKpi.sum < median) {
            kpiRating.median = -1;
          }

          kpiRating.average = 0;
          if (mySoccerClubKpi.sum > average) {
            kpiRating.average = 1;
          } else if (mySoccerClubKpi.sum < average) {
            kpiRating.average = -1;
          }
        } else {
          const numClubsHaving: number = seasonKpi.summary.values['1'];
          const numClubsNotHaving: number = seasonKpi.summary.values['0'];
          const numSoccerClubs: number = numClubsHaving + numClubsNotHaving;
          const percentageClubsHaving: number = numClubsHaving / numSoccerClubs * 100;
          let rating: number = mySoccerClubKpi.sum === 1 ? 1 : 0;

          if (percentageClubsHaving === 50) {
            rating = 0;
          } else if (percentageClubsHaving > 50) {
            rating = mySoccerClubKpi.sum === 1 ? 0 : -1;
          }

          kpiRating.median = rating;
          kpiRating.average = rating;
        }

        kpiRating.own = mySoccerClubKpi.sum;
      }
    }

    return kpiRating;
  }

  private isBannedKpi(seasonKpi: SeasonKpi): boolean {
    return (seasonKpi.kpiCriteria === 'Infrastructure' && seasonKpi.kpiCategory === 'Colleges')
      || (seasonKpi.kpiCriteria === 'Personnel' && seasonKpi.kpiCategory === 'PartTimeEmployment')
      || (seasonKpi.kpiCriteria === 'Personnel' && seasonKpi.kpiCategory === 'EmploymentDoubleArea')
      || (seasonKpi.kpiCriteria === 'Personnel' && seasonKpi.kpiCategory === 'Qualification');
  }
}
