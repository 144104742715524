<tr [formGroup]="form">
  <td>
    <ng-container *ngIf="!editMode">{{name.value}}</ng-container>
    <div *ngIf="editMode">
      <input type="text" formControlName="name" />
      <div *ngIf="name.invalid && (name.dirty || name.touched)">
        <div class="input-error" *ngIf="name.errors.required">Name wird benötigt.</div>
        <div class="input-error" *ngIf="name.errors.repeatedName">Name kann sich nicht wiederholen.</div>
      </div>
    </div>
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{form.get('remark').value}}</ng-container>
    <textarea *ngIf="editMode" formControlName="remark"></textarea>
  </td>
  <td>
    <lsz-binary-display *ngIf="!editMode" [value]="form.get('hasFloodlight').value"></lsz-binary-display>
    <lsz-binary-select *ngIf="editMode" [form]="form" [name]="'hasFloodlight'"></lsz-binary-select>
  </td>
  <td>
    <lsz-binary-display *ngIf="!editMode" [value]="form.get('isHeated').value"></lsz-binary-display>
    <lsz-binary-select *ngIf="editMode" [form]="form" [name]="'isHeated'"></lsz-binary-select>
  </td>
  <td>
    <lsz-binary-display *ngIf="!editMode" [value]="form.get('isCompetitionCompliant').value"></lsz-binary-display>
    <lsz-binary-select *ngIf="editMode" [form]="form" [name]="'isCompetitionCompliant'"></lsz-binary-select>
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{hasArtificialLawn.value ? 'Kunstrasen' : 'Rasen'}}</ng-container>
    <ng-container *ngIf="editMode">
      <select formControlName="hasArtificialLawn" size="1" required>
        <option [ngValue]="false">Rasen</option>
        <option [ngValue]="true">Kunstrasen</option>
      </select>
      <div *ngIf="hasArtificialLawn.invalid && hasArtificialLawn.touched">
        <div class="input-error" *ngIf="hasArtificialLawn.errors.required">Auswahl wird benötigt.</div>
      </div>
    </ng-container>
  </td>
  <td>
    <lsz-binary-display *ngIf="!editMode" [value]="form.get('isStadium').value"></lsz-binary-display>
    <lsz-binary-select *ngIf="editMode" [form]="form" [name]="'isStadium'"></lsz-binary-select>
  </td>
  <td>
    <lsz-binary-display *ngIf="!editMode" [value]="form.get('isOnLzArea').value"></lsz-binary-display>
    <lsz-binary-select *ngIf="editMode" [form]="form" [name]="'isOnLzArea'"></lsz-binary-select>
  </td>
  <td>
    <lsz-binary-display *ngIf="!editMode" [value]="form.get('isLzExclusive').value"></lsz-binary-display>
    <lsz-binary-select *ngIf="editMode" [form]="form" [name]="'isLzExclusive'"></lsz-binary-select>
  </td>
  <td *ngIf="editMode">
    <lsz-dataset-options [form]="form" (save)="entrySaved.emit()" (delete)="entryDeleted.emit()"></lsz-dataset-options>
  </td>
  <td>
    <lsz-last-edit [form]="form"></lsz-last-edit>
  </td>
</tr>
