import { Component, OnChanges } from '@angular/core';
import { SchoolCooperation } from '../../models/models';
import { SchoolCooperationService } from '../../services/school-cooperation.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Validators } from '../../validators';
import { ConfirmationDialogService } from '../../../shared/confirmation-dialog/services/confirmation-dialog.service';
import { BaseTableViewComponent } from '../../../shared/base-table-view/base-table-view.component';

@Component({
  selector: 'lsz-school-cooperation-form-collection',
  templateUrl: './school-cooperation-form-collection.component.html',
  styleUrls: ['./school-cooperation-form-collection.component.css'],
})
export class SchoolCooperationFormCollectionComponent extends BaseTableViewComponent<SchoolCooperation> implements OnChanges {

  public commonSchool: SchoolCooperation;
  public eliteSchool: SchoolCooperation;

  public get amount(): number {
    let amount: number = 0;
    for (const entry of this.entries) {
      amount += entry.count;
    }

    return amount;
  }

  constructor(protected service: SchoolCooperationService,
              protected confirmationDialog: ConfirmationDialogService) {
    super(service, confirmationDialog);
  }

  public ngOnChanges(): void {
    if (this.soccerClub && this.season) {
      this.service.get(this.soccerClub, this.season.id).subscribe(entries => {
        this.entries = entries;

        this.allForms = this.initializeAllForms();

        this.updateTypedEntries();

        const formNames = [
          'commonSchool',
          'eliteSchool',
        ];
        for (const formName of formNames) {
          const form = this.allForms.get(formName) as UntypedFormGroup;
          form.patchValue(this.objectToForm(this[formName]));
        }
      });
    }
  }

  public initializeAllForms(): UntypedFormGroup {
    return new UntypedFormGroup({
      commonSchool: this.formGroup(),
      eliteSchool: this.formGroup(),
    });
  }

  public exitEditMode(): void {
    this.editMode = false;

    const resetValues = {
      commonSchool: this.objectToForm(this.commonSchool),
      eliteSchool: this.objectToForm(this.eliteSchool),
    };
    this.allForms.reset(resetValues);
  }

  protected updateTypedEntries(): void {
    this.commonSchool = this.findEntryByType('CommonSchool');
    this.eliteSchool = this.findEntryByType('EliteSchool');
  }

  protected formGroup(entry?: SchoolCooperation): UntypedFormGroup {
    return new UntypedFormGroup({
      count: new UntypedFormControl(entry?.count, Validators.required),
      updatedAtUtc: new UntypedFormControl(entry?.updatedAtUtc),
      createdAtUtc: new UntypedFormControl(entry?.createdAtUtc),
    });
  }

  protected objectToForm(entry: SchoolCooperation): Partial<SchoolCooperation> {
    return {
      count: entry?.count,
      updatedAtUtc: entry?.updatedAtUtc,
      createdAtUtc: entry?.createdAtUtc,
    };
  }

  private findEntryByType(type: string): SchoolCooperation {
    for (const entry of this.entries) {
      if (entry.schoolCooperationType === type) {
        return entry;
      }
    }
    return {
      id: null,
      soccerClubId: this.soccerClub.id,
      seasonId: this.season.id,
      count: 0,
      schoolCooperationType: type,
      updatedAtUtc: null,
      createdAtUtc: null,
    };
  }

}
