import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment/moment';

import { TimeRepresentation } from '../time-input/model/timeRepresentation';

export class DateValidator {
  public static dateValidator(ac: AbstractControl): ValidationErrors {
    if (ac && ac.value) {
      let date: unknown = ac.value;
      const isDatepickerFormat: boolean = !!ac.value.year;
      if (isDatepickerFormat) {
        date = moment(ac.value);
      }

      if (!moment(date, 'DD.MM.YYYY', true).isValid()) {
        return {dateValidator: true};
      }
    }

    return null;
  }

  public static dateValidatorAtLeastThreeYears(ac: AbstractControl): ValidationErrors {
    if (ac && ac.value) {
      const date: Moment = moment(ac.value, 'DD.MM.YYYY');
      date.add(3, 'years');
      date.toDate();
      const now: Moment = moment(new Date());
      if (date > now) {
        return {dateValidatorAtLeastThreeYears: true};
      }

      return null;
    }

    return null;
  }

  /**
   * Returns a validator function which returns a 'dateValidatorIsNotPast' error if the date value is in the past.
   * If the 'timeControl' argument is passed, the time will be considered in the validation.
   * Else, the date's time will be 00:00.000 UTC.
   *
   * @param timeControl optional control for time value.
   */
  public static isNotPast(timeControl?: AbstractControl): ValidatorFn {
    return (ac: AbstractControl): ValidationErrors => {
      if (ac && ac.value) {
        const today: Moment = moment();
        const date: Moment = moment(ac.value, 'DD.MM.YYYY');

        if (timeControl && timeControl.value) {
          const time: TimeRepresentation = timeControl.value as TimeRepresentation;
          date.set({
            hour: time.hour,
            minute: time.minute,
          });
        } else {
          today.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          });
        }

        if (date < today) {
          return {dateValidatorIsNotPast: true};
        }
      }

      return null;
    };
  }

  /**
   * * Returns a validator function which returns a 'dateValidatorIsBeforeOtherDate' error if the date value is in the
   * past. Requires another control for date comparison.
   * Optionally, additional controls can be passed to consider time in the validation.
   * Without a time control the date's time will be 00:00.000 UTC.
   *
   * @param otherDateControl control for other date value.
   * @param timeControl optional control for the date's time value.
   * @param otherTimeControl optional control for the other date's time value.
   */
  public static isBeforeOtherDate(otherDateControl: AbstractControl,
                                  timeControl?: AbstractControl,
                                  otherTimeControl?: AbstractControl): ValidatorFn {
    return (ac: AbstractControl): ValidationErrors => {
      if (ac && ac.value) {
        const date: Moment = moment(ac.value, 'DD.MM.YYYY');
        const otherDate: Moment = moment(otherDateControl.value, 'DD.MM.YYYY');

        if (timeControl && timeControl.value) {
          const time: TimeRepresentation = timeControl.value as TimeRepresentation;
          date.set({
            hour: time.hour,
            minute: time.minute,
          });
        }

        if (otherTimeControl && otherTimeControl.value) {
          const otherTime: TimeRepresentation = otherTimeControl.value as TimeRepresentation;
          otherDate.set({
            hour: otherTime.hour,
            minute: otherTime.minute,
          });
        }

        if (date >= otherDate) {
          return {dateValidatorIsBeforeOtherDate: true};
        }
      }

      return null;
    };
  }
}
