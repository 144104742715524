import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataImportJob } from '../../models/data-import-job';
import { SeasonService } from '../../../../../services/season/season.service';
import { map, take } from 'rxjs/operators';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Season } from '../../../../../services/season/models/season';

@Injectable({
  providedIn: 'root',
})
export class DataImportService {

  private currentSeason: Season;

  constructor(private http: HttpClient, private seasonService: SeasonService) {
    this.getLatestSeason();
  }

  public getAllImportJobs(): Promise<DataImportJob[]> {
    return this.http.get<DataImportJob[]>('/import/jobs').pipe(
      map((jobs: DataImportJob[]) => {
        jobs.map((job: DataImportJob) => {
          job.startAtUtc = moment.utc(job.startAtUtc).toISOString();
          job.updatedAtUtc = moment.utc(job.updatedAtUtc).toISOString();
          job.createdAtUtc = moment.utc(job.createdAtUtc).toISOString();
          job.finishedAtUtc = moment.utc(job.finishedAtUtc).toISOString();
        });

        return jobs;
      }),
      take(1),
    ).toPromise();
  }

  public getReasonForFailedJob(jobId: string): Observable<string> {
    return this.http.get<string>(`/import/jobs/${jobId}/reason`);
  }

  public upload(file: File, importer: string): Promise<DataImportJob> {
    const formData: FormData = new FormData();
    formData.append('File', file);
    formData.append('Importer', importer);

    const httpHeaders: HttpHeaders = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      'Accept': 'application/json',
    });
    return this.http.post<DataImportJob>(`/import/jobs`, formData, { headers: httpHeaders }).pipe(
      take(1),
    ).toPromise();
  }

  public deleteJob(jobId: string): Promise<unknown> {
    return this.http.delete(`/import/jobs/${jobId}`).pipe(
      take(1),
    ).toPromise();
  }

  public getImportStatus(jobId: string): Observable<DataImportJob> {
    return this.http.get<DataImportJob>(`/import/jobs/${jobId}`);
  }

  private getLatestSeason(): void {
    this.seasonService.getLatestSeason().pipe(
      take(1),
    ).subscribe((season: Season) => this.currentSeason = season);
  }
}
