import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

import { DataEntryCountDownComponent } from './data-entry-count-down.component';

@NgModule({
  declarations: [DataEntryCountDownComponent],
  imports: [
    CommonModule,
    NgbAccordionModule,
    FontAwesomeModule,
    FlexLayoutModule,
    RouterModule,
  ],
  exports: [DataEntryCountDownComponent],
})
export class DataEntryCountDownModule { }
