<div class="form-wrapper">
  <lsz-edit-form-header
    [headerText]="'Räume'"
    [numEntries]="amount"
    [editMode]="editMode"
    (clickEdit)="onClickEdit()"
    (clickEditDone)="onClickEditDone()">
  </lsz-edit-form-header>

  <table [class.editMode]="editMode" [formGroup]="allForms">
    <thead>
    <tr>
      <th i18n="@@tableHeaderName">Name</th>
      <th i18n="@@tableHeaderIsAvailable">Vorhanden</th>
      <th i18n="@@tableHeaderRemark">Bemerkung</th>
      <th *ngIf="editMode" i18n="@@tableHeaderOptions" class="column-options">Optionen</th>
      <th>{{'lastEdit' | translate}}</th>
    </tr>
    </thead>
    <tbody *ngIf="workplace"
           lsz-room-typed-form
           [entry]="workplace"
           [toggle]="false"
           (entrySaved)="onTypedEntrySaved(workplace, $event, 'workplace')"
           [editMode]="editMode"
           [form]="allForms.get('workplace')">
    </tbody>

    <tbody *ngIf="office"
           lsz-room-typed-form
           [entry]="office"
           [toggle]="false"
           (entrySaved)="onTypedEntrySaved(office, $event, 'office')"
           [editMode]="editMode"
           [form]="allForms.get('office')">
    </tbody>

    <tbody *ngIf="conferenceRoom"
           lsz-room-typed-form
           [entry]="conferenceRoom"
           [toggle]="false"
           (entrySaved)="onTypedEntrySaved(conferenceRoom, $event, 'conferenceRoom')"
           [editMode]="editMode"
           [form]="allForms.get('conferenceRoom')">
    </tbody>

    <tbody *ngIf="studyRoom"
           lsz-room-typed-form
           [entry]="studyRoom"
           [toggle]="false"
           (entrySaved)="onTypedEntrySaved(studyRoom, $event, 'studyRoom')"
           [editMode]="editMode"
           [form]="allForms.get('studyRoom')">
    </tbody>

    <tbody
      *ngIf="canteen"
      lsz-room-typed-form
      [entry]="canteen"
      [toggle]="true"
      (entrySaved)="onTypedEntrySaved(canteen, $event, 'canteen')"
      [editMode]="editMode"
      [form]="allForms.get('canteen')">
    </tbody>
  </table>
</div>
