import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbAccordionModule, NgbDatepickerModule, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../../../shared/shared.module';
import { DataEditorSharedModule } from '../data-editor-shared/data-editor-shared.module';

import { CategoryFullTimeEmployeesComponent } from './category-full-time-employees.component';
import { FullTimeEmployeeFormComponent } from './components/full-time-employee-form/full-time-employee-form.component';
import { FullTimeEmployeesCollectionComponent } from './components/full-time-employees-collection/full-time-employees-collection.component';

@NgModule({
  declarations: [
    CategoryFullTimeEmployeesComponent,
    FullTimeEmployeesCollectionComponent,
    FullTimeEmployeeFormComponent,
  ],
  imports: [
    CommonModule,
    DataEditorSharedModule,
    ReactiveFormsModule,
    NgbModule,
    NgbAccordionModule,
    NgbDatepickerModule,
    NgbTooltipModule,
    SharedModule,
  ],
  exports: [CategoryFullTimeEmployeesComponent],
})
export class CategoryFullTimeEmployeesModule { }
