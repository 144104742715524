import { Component, Input, OnChanges } from '@angular/core';
import { SoccerClub } from '../../models/soccerClub';
import { faPlus, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { repeatedNameValidatorFn } from '../utils/utils';
import { ConfirmationDialogService } from '../confirmation-dialog/services/confirmation-dialog.service';
import { InfrastructureService } from '../../data-editor/services/infrastructure.service';
import { ConfirmationDialogResult } from '../confirmation-dialog/confirmationDialogResult';
import { Season } from '../../services/season/models/season';
import { InfrastructureObject } from 'src/app/data-editor/models/models';
import { firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'lsz-base-table-view',
  templateUrl: './base-table-view.component.html',
  styleUrls: ['./base-table-view.component.scss'],
})
export abstract class BaseTableViewComponent<T extends InfrastructureObject> implements OnChanges {
  @Input() soccerClub: SoccerClub;
  @Input() season: Season;

  public entries: T[];
  public typedEntries: T[];
  public allForms: UntypedFormGroup;
  public editMode: boolean;
  public addMode: boolean;
  public faPlus;
  public faSave;
  public faTrash;

  public get newEntryForm(): UntypedFormGroup {
    return this.allForms.get('newEntry') as UntypedFormGroup;
  }

  public get customEntryForms(): UntypedFormArray {
    return this.allForms?.get('customEntries') as UntypedFormArray;
  }

  public get name(): AbstractControl {
    return this.newEntryForm.get('name');
  }

  public get remark(): string {
    return this.newEntryForm.get('remark').value as string;
  }

  protected constructor(
    protected service: InfrastructureService<InfrastructureObject>,
    protected confirmationDialog: ConfirmationDialogService,
  ) {
    this.editMode = false;
    this.addMode = false;
    this.faPlus = faPlus;
    this.faSave = faSave;
    this.faTrash = faTrash;
    this.entries = [];
    this.typedEntries = [];

    this.allForms = this.initializeAllForms();
  }

  public ngOnChanges(): void {
    if (this.soccerClub && this.season) {
      this.service.get(this.soccerClub, this.season.id).subscribe((entries: T[]) => {
        this.entries = entries;
        this.allForms = this.initializeAllForms();

        for (const entry of this.entries) {
          this.customEntryForms.push(this.formGroup(entry));
        }
      });
    }
  }

  public initializeAllForms(): UntypedFormGroup {
    return new UntypedFormGroup({
      customEntries: new UntypedFormArray([]),
      newEntry: this.formGroup(),
    });
  }

  public onUpdate(index: number): void {
    this.updateEntry(index);
  }

  public repeatedNameValidator(): ValidatorFn {
    return repeatedNameValidatorFn(this.entries);
  }

  public onClickEdit(): void {
    this.editMode = true;
  }

  public addNewRow(): void {
    this.addMode = true;
  }

  public onClickEditDone(): void {
    if (this.allForms.dirty) {
      const modalRef = this.confirmationDialog.openEditDoneConfirmation();
      modalRef.closed.subscribe((result: ConfirmationDialogResult) => {
        if (result === ConfirmationDialogResult.CONFIRM) {
          this.exitEditMode();
        }
      });
    } else {
      this.exitEditMode();
    }
  }

  public exitAddMode(resetForm = true): void {
    if (resetForm) {
      this.newEntryForm.reset();
    }
    this.addMode = false;
  }

  public exitEditMode(): void {
    this.exitAddMode(false);
    this.editMode = false;

    const customEntries = [];
    for (const entry of this.entries) {
      customEntries.push(this.objectToForm(entry));
    }

    this.allForms.reset({ customEntries });
  }

  public customEntryFormForIndex(index: number): UntypedFormGroup {
    return this.customEntryForms.at(index) as UntypedFormGroup;
  }

  public async onDelete(index: number): Promise<void> {
    await this.deleteEntry(index, this.entries);
  }

  public addEntry(entry: Partial<T>, entries?: T[]): void {
    if (!entries) {
      entries = this.entries;
    }

    const form: T = this.newEntryForm.value as T;
    const alteredEntry: T = {
      id: null,
      soccerClubId: this.soccerClub?.id,
      seasonId: this.season?.id,
      ...form,
      ...entry,
    };

    this.service.add(alteredEntry).subscribe((newEntry: T) => {
      entries.push(newEntry);
      this.customEntryForms.push(this.formGroup(newEntry));
      this.exitAddMode();
    });
  }

  public updateEntry(index: number, entry?: Partial<T>, entries?: T[]): void {
    if (!entries) {
      entries = this.entries;
    }

    const form: UntypedFormGroup = this.customEntryForms.at(index) as UntypedFormGroup;

    const alteredEntry: T = {
      ...entries[index],
      ...(form.value as T),
      ...entry,
    };

    this.service.update(alteredEntry).subscribe((updatedEntry: T) => {
      entries[index] = updatedEntry;
      form.get('updatedAtUtc').setValue(updatedEntry.updatedAtUtc);
      this.updateTypedEntries();
      form.markAsPristine();
    });
  }

  public onTypedEntrySaved(originalEntry: T, alteredEntry: T, formName: string): void {
    const form: UntypedFormGroup = this.allForms.get(formName) as UntypedFormGroup;
    if (!(alteredEntry as any).id) {
      this.service.add(alteredEntry).subscribe((newEntry: T) => {
        this.entries.push(newEntry);
        this.updateTypedEntries();
        form.markAsPristine();
      });
    } else {
      this.service.update(alteredEntry).subscribe((updatedEntry: T) => {
        const index = this.entries.indexOf(originalEntry);
        this.entries[index] = updatedEntry;
        // @ts-ignore
        form.get('updatedAtUtc').setValue(updatedEntry.updatedAtUtc);
        this.updateTypedEntries();
        form.markAsPristine();
      });
    }
  }

  public async deleteEntry(index: number, entries: T[]): Promise<void> {
    await firstValueFrom(
      this.service.delete(entries[index]).pipe(
        tap(() => {
          entries.splice(index, 1);
          /*
           * For cases in which the FormGroup to delete has been modified beforehand: The following removeAt() won't update the FormArray's
           * pristine/dirty state, so we set this FormGroups's state to pristine so that any changes to it are ignored (it's about to get
           * deleted anyway) and the FormArray's pristine-state gets updated.
           */
          this.customEntryForms.at(index).markAsPristine();
          this.customEntryForms.removeAt(index);
        }),
      ),
    );
  }

  protected formGroup(entry?: T): UntypedFormGroup {
    return new UntypedFormGroup({ entry: new UntypedFormControl(entry) });
  }

  protected objectToForm(entry: T): Partial<T> {
    return entry;
  }

  protected updateTypedEntries(): void {
    return;
  }
}
