import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SchoolCooperation } from '../models/models';

import { InfrastructureService } from './infrastructure.service';

@Injectable({
  providedIn: 'root',
})
export class SchoolCooperationService extends InfrastructureService<SchoolCooperation> {
  public readonly endpoint: string = '/SchoolCooperations';

  constructor(protected http: HttpClient) {
    super(http);
  }

}
