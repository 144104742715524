<div class="form-wrapper">
  <lsz-edit-form-header
    [headerText]="'Sonstiges'"
    [numEntries]="numAvailables"
    [editMode]="editMode"
    (clickEdit)="onClickEdit()"
    (clickEditDone)="onClickEditDone()"
  ></lsz-edit-form-header>

  <table [class.editMode]="editMode" [formGroup]="allForms">
    <thead>
    <tr>
      <th i18n="@@tableHeaderFunction">Funktion</th>
      <th i18n="@@tableHeaderCountFullTime">Anzahl Vollzeit</th>
      <th i18n="@@tableHeaderCountPartTime">Anzahl Teilzeit</th>
      <th *ngIf="editMode" i18n="@@tableHeaderOptions" class="column-options">Optionen</th>
      <th>{{'lastEdit' | translate}}</th>
    </tr>
    </thead>
    <tbody
      *ngIf="provisioning"
      lsz-part-time-employee-misc-typed-form
      [entry]="provisioning"
      (entrySaved)="onTypedEntrySaved(provisioning, $event, 'provisioning')"
      [editMode]="editMode"
      [form]="allForms.get('provisioning')"
    ></tbody>
    <tbody
      *ngIf="shuttleService"
      lsz-part-time-employee-misc-typed-form
      [entry]="shuttleService"
      (entrySaved)="onTypedEntrySaved(shuttleService, $event, 'shuttleService')"
      [editMode]="editMode"
      [form]="allForms.get('shuttleService')"
    ></tbody>
    <tbody
      *ngIf="greenkeeper"
      lsz-part-time-employee-misc-typed-form
      [entry]="greenkeeper"
      (entrySaved)="onTypedEntrySaved(greenkeeper, $event, 'greenkeeper')"
      [editMode]="editMode"
      [form]="allForms.get('greenkeeper')"
    ></tbody>
    <tbody
      *ngIf="miscellaneous"
      lsz-part-time-employee-misc-typed-form
      [entry]="miscellaneous"
      (entrySaved)="onTypedEntrySaved(miscellaneous, $event, 'miscellaneous')"
      [editMode]="editMode"
      [form]="allForms.get('miscellaneous')"
    ></tbody>
  </table>
</div>
