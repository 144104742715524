<div class="lsz-tick-slider" fxLayout="column" [class.disabled]="disabled">
  <mat-slider min="0"
              [max]="labels?.length > 0 ? labels.length - 1 : 0"
              step="1" [disabled]="disabled">
    <input matSliderThumb [(ngModel)]="value" (change)="onChange(value)">
  </mat-slider>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="slider-label">
    <div *ngFor="let label of labels, let i = index"
         (click)="!disabled ? onClick(i) : null"
         class="slider-tick-label">
      {{label}}
    </div>
  </div>
</div>