import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmationDialogService } from '../../../../../shared/confirmation-dialog/services/confirmation-dialog.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationDialogResult } from '../../../../../shared/confirmation-dialog/confirmationDialogResult';

@Component({
  selector: 'lsz-dataset-options',
  templateUrl: './dataset-options.component.html',
  styleUrls: ['./dataset-options.component.scss'],
})
export class DatasetOptionsComponent implements OnInit, OnDestroy {
  @Output() public delete: EventEmitter<void>;
  @Output() public save: EventEmitter<void>;

  @Input() public withoutDelete: boolean;
  @Input() public withoutEdit: boolean;
  @Input() public form: UntypedFormGroup;
  @Input() public hasDeleteCheck: boolean;

  public deleteIcon: unknown;
  public saveIcon: unknown;

  private destroy$: Subject<void>;

  constructor(private confirmationDialog: ConfirmationDialogService, private cdr: ChangeDetectorRef) {
    this.delete = new EventEmitter<void>();
    this.save = new EventEmitter<void>();
    this.withoutDelete = false;
    this.withoutEdit = false;
    this.hasDeleteCheck = true;
    this.deleteIcon = faTrash;
    this.saveIcon = faSave;
    this.destroy$ = new Subject<void>();
  }

  public ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  public onClickDelete(): void {
    if (!this.hasDeleteCheck) {
      this.delete.emit();
    } else {
      const modalRef: NgbModalRef = this.confirmationDialog.open(
        'Löschung bestätigen',
        'Möchten Sie diesen Eintrag wirklich löschen?',
        'Löschen',
      );

      modalRef.closed.subscribe((result: ConfirmationDialogResult) => {
        if (result === ConfirmationDialogResult.CONFIRM) {
          this.delete.emit();
        }
      });
    }
  }

  public onClickSave(): void {
    this.save.emit();
  }
}
