import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { faCalendar, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'lsz-base-edit-form',
  templateUrl: './base-edit-form.component.html',
  styleUrls: ['./base-edit-form.component.scss'],
})
export abstract class BaseEditFormComponent<T = void> {

  @Output() public entryDeleted: EventEmitter<void>;
  @Output() public entrySaved: EventEmitter<void | T>;

  @Input() public editMode: boolean;
  @Input() public index: number;
  @Input() public form: UntypedFormGroup;
  @Input() entry: T;


  public faSave: unknown;
  public faTrash: unknown;
  public faCalendar: unknown;

  public get name(): AbstractControl {
    return this.form.get('name');
  }

  public get remark(): AbstractControl {
    return this.form.get('remark');
  }

  protected constructor() {
    this.faSave = faSave;
    this.faTrash = faTrash;
    this.faCalendar = faCalendar;

    this.entryDeleted = new EventEmitter<void>();
    this.entrySaved = new EventEmitter<void | T>();
  }
}
