import { Component, Input, OnInit } from '@angular/core';
import { SoccerClub } from '../../../../../../../models/soccerClub';

@Component({
  selector: 'lsz-aggregation-table',
  templateUrl: './aggregation-table.component.html',
  styleUrls: ['./aggregation-table.component.scss'],
})
export class AggregationTableComponent implements OnInit {
  @Input() public mappedClubKeys: number[];
  @Input() public mappedClubs: Map<number, SoccerClub[]>;
  @Input() public kpiName: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
