import { BREAKPOINT } from '@angular/flex-layout';

export const TABLET = [{
  alias: 'tb',
  suffix: 'Tb',
  mediaQuery: 'screen and (max-width: 1024px)',
  overlapping: false,
  priority: 1001,
}];

export const TabletBreakpointProvider = {
  provide: BREAKPOINT,
  useValue: TABLET,
  multi: true,
};
