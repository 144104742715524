import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SoccerTrainingPartTimeEmployee } from '../../models/models';
import { BaseEditFormComponent } from '../../../shared/base-edit-form/base-edit-form.component';

@Component({
  selector: 'tbody[lsz-part-time-employee-soccer-training-typed-form]',
  templateUrl: './part-time-employee-soccer-training-typed-form.component.html',
  styleUrls: ['./part-time-employee-soccer-training-typed-form.component.scss'],
})
export class PartTimeEmployeeSoccerTrainingTypedFormComponent
  extends BaseEditFormComponent<SoccerTrainingPartTimeEmployee> {

  constructor() {
    super();
  }

  public onClickSave(): void {
    const aLicenses = this.form.get('aLicenses') as UntypedFormControl;
    const aPlusLicenses = this.form.get('aPlusLicenses') as UntypedFormControl;
    const bLicenses = this.form.get('bLicenses') as UntypedFormControl;
    const bPlusLicenses = this.form.get('bPlusLicenses') as UntypedFormControl;
    const cLicenses = this.form.get('cLicenses') as UntypedFormControl;
    const proLicenses = this.form.get('proLicenses') as UntypedFormControl;
    const goalKeeperLicenses = this.form.get('goalKeeperLicenses') as UntypedFormControl;

    this.entrySaved.emit({
      ...this.entry,
      cLicenses: cLicenses?.value as number ?? 0,
      bLicenses: bLicenses?.value as number ?? 0,
      bPlusLicenses: bPlusLicenses?.value as number ?? 0,
      aLicenses: aLicenses?.value as number ?? 0,
      aPlusLicenses: aPlusLicenses?.value as number ?? 0,
      proLicenses: proLicenses?.value as number ?? 0,
      goalKeeperLicenses: goalKeeperLicenses?.value as number ?? 0,
    } as SoccerTrainingPartTimeEmployee);
  }
}
