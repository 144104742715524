import { Component } from '@angular/core';
import { SchoolCooperation } from '../../models/models';
import { AbstractControl } from '@angular/forms';
import { BaseEditFormComponent } from '../../../shared/base-edit-form/base-edit-form.component';

@Component({
  selector: 'tbody[lsz-school-cooperation-typed-form]',
  templateUrl: './school-cooperation-typed-form.component.html',
  styleUrls: ['./school-cooperation-typed-form.component.css'],
})
export class SchoolCooperationTypedFormComponent extends BaseEditFormComponent<SchoolCooperation> {

  public get count(): AbstractControl {
    return this.form.get('count');
  }

  constructor() {
    super();
  }

  public onClickSave(): void {
    this.entrySaved.emit({
      ...this.entry,
      count: this.count.value as number,
    } as SchoolCooperation);
  }
}
