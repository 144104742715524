import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../../services/user/model/user';
import { UserService } from '../../services/user/user.service';
import { CAN_EDIT_DATA, CAN_ENTER_ADMIN_PAGE, CAN_SEE_DATA } from '../permissionGroups';
import { Role } from '../services/auth/models/role.enum';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard  {

  constructor(private userService: UserService,
              private router: Router) {
  }

  public static isAllowed(allowedRoles: Role[], userRoles: Role[]): boolean {
    const roles: Role[] = userRoles.filter((userRole: Role) =>
      allowedRoles.find((role: Role) =>
        role === userRole));

    return roles.length > 0;
  }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return this.userService.userInformation$.pipe(
      map((user: User) => {
        if (user) {
          if (state.url.indexOf('show-data') > -1) {
            const isAllowed: boolean = RoleGuard.isAllowed(CAN_SEE_DATA, user.roles);
            if (!isAllowed) {
              this.router.navigateByUrl('/login');
            }

            return isAllowed;
          } else if (state.url.indexOf('edit') > -1) {

            const isAllowed: boolean = RoleGuard.isAllowed(CAN_EDIT_DATA, user.roles);
            if (!isAllowed) {
              this.router.navigateByUrl('/show-data');
            }

            return isAllowed;
          } else if (state.url.indexOf('admin') > -1) {

            const isAllowed: boolean = RoleGuard.isAllowed(CAN_ENTER_ADMIN_PAGE, user.roles);
            if (!isAllowed) {
              this.router.navigateByUrl('/show-data');
            }

            return isAllowed;
          }

          return true;
        }

        this.router.navigateByUrl('/login');

        return false;
      }),
    );
  }
}
