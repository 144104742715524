import { AdminFilterSetting } from './adminFilterSetting';

export enum AdminFilter {
  ALL = 'all',
  LEAGUE = 'league',
  BUDGET = 'budget',
  CLUB = 'club',
}

export const DEFAULT_ADMIN_FILTER_SETTING: AdminFilterSetting = {
  type: AdminFilter.CLUB,
  value: AdminFilter.CLUB,
};
