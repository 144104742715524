import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { SeasonService } from '../services/season/season.service';
import { User } from '../services/user/model/user';
import { UserService } from '../services/user/user.service';
import { getClub } from '../store/selectors/club.selectors';
import { AppState } from '../store/states/app.state';
import { RoleGuard } from '../core/guards/role.guard';
import { CAN_SWITCH_SEASONS_IN_EDIT } from '../core/permissionGroups';
import { StsAuthService } from 'sts-auth';
import { SoccerClub } from '../models/soccerClub';
import { Season } from '../services/season/models/season';

@Component({
  selector: 'lsz-edit-data',
  templateUrl: './data-editor.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./data-editor.component.scss'],
})
export class DataEditorComponent implements OnInit, OnDestroy {

  public allSeasons: Season[];
  public currentSeason: Season;
  public isAdmin: boolean;
  public soccerClub: SoccerClub;
  public user: User;

  private unsubscribe: Subject<void>;

  constructor(private store: Store<AppState>,
              private userService: UserService,
              private spinnerService: NgxSpinnerService,
              private authService: StsAuthService,
              private seasonService: SeasonService) {
    this.unsubscribe = new Subject<void>();
  }

  public logout(): void {
    this.authService.logout();
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public ngOnInit(): void {
    this.store.pipe(
      select(getClub),
      takeUntil(this.unsubscribe),
      tap((soccerClub: SoccerClub) => {
        this.spinnerService.show();
        if (soccerClub.id) {
          this.soccerClub = soccerClub;
        }
      }),
      switchMap(() =>
        this.seasonService.getSeasons()),
    ).subscribe((seasons: Season[]) => {
      this.spinnerService.hide();
      for (const season of seasons) {
        if (!season.isClosed) {
          this.currentSeason = season;
          break;
        }
      }
      this.allSeasons = [...seasons].reverse();
    }, (err: unknown) => {
      console.error(err);
      this.spinnerService.hide();
    });

    this.getUserInformation();
  }

  private getUserInformation(): void {
    this.userService.userInformation$.subscribe((user: User) => {
      this.user = user;
      this.isAdmin = RoleGuard.isAllowed(CAN_SWITCH_SEASONS_IN_EDIT, user.roles);
    });
  }
}
