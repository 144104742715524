<div class="data-entry-count-down-wrapper">
  <ngb-accordion [activeIds]="activePanelIds"
                 [animation]="false"
                 (panelChange)="toggleIcon = $event.nextState ? faChevronUp : faChevronDown">
    <ngb-panel [id]="'panel-Countdown'">
      <ng-template ngbPanelHeader>
        <button ngbPanelToggle class="btn btn-link container-fluid category-header">
          <span></span>
          Dateneintragung für die Saison {{ season.name }}
          <fa-icon [icon]="toggleIcon" size="2x"></fa-icon>
        </button>
      </ng-template>
      <ng-template ngbPanelContent>
        <div fxLayout="row" fxLayoutAlign="space-evenly center">
          <div class="data-entry-count-down-display-time" fxLayout="column" fxLayoutAlign="center center" fxFlex="80px">
            {{ remainingDataEntryTime.days }}
            <div class="data-entry-count-down-display-time-unit">Tage</div>
          </div>
          <div class="data-entry-count-down-display-time" fxLayout="column" fxLayoutAlign="center center" fxFlex="80px">
            {{ remainingDataEntryTime.hours }}
            <div class="data-entry-count-down-display-time-unit">Stunden</div>
          </div>
          <div class="data-entry-count-down-display-time" fxLayout="column" fxLayoutAlign="center center" fxFlex="80px">
            {{ remainingDataEntryTime.minutes }}
            <div class="data-entry-count-down-display-time-unit">Minuten</div>
          </div>
        </div>
        <h5 *ngIf="noEntryPossible; else editButton" class="no-entry-possible">Eingabe nicht mehr möglich</h5>
        <ng-template #editButton>
          <div *ngIf="isPresenter && (canEditData$ | async)" fxLayout="row" fxLayoutAlign="center center" class="edit-button-wrapper">
            <button routerLink="/edit-data" class="lsz-button">Daten jetzt eintragen</button>
          </div>
        </ng-template>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
