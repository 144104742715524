<div *ngIf="rating === noneRating" class="empty-rating"></div>
<fa-icon *ngIf="[positiveRating, negativeRating].includes(rating)"
         [icon]="rating === positiveRating ? faThumbsUp : faExclamation"
         [classes]="['category-rating', 'category-rating-' + (rating === positiveRating ? 'positive' : 'negative')]"
></fa-icon>
<img *ngIf="rating === neutralRating"
     src="/assets/icon_ok.svg"
     alt="OK"
     class="category-rating category-rating-neutral"/>

