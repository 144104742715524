import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';

import { Job } from '../../model/job';

// tslint:disable-next-line:no-any
const routes: { [key: string]: () => string } = {
  jobs: () => '/kpi/jobs',
};

@Injectable({
  providedIn: 'root',
})
export class KpiJobService {

  public get sessionJobs(): Job[] {
    return this._sessionJobs;
  }

  private _sessionJobs: Job[] = [];
  private kpiJobs$: Observable<Job[]>;

  constructor(private http: HttpClient) {
    this.kpiJobs$ = this.http.get<Job[]>(routes.jobs()).pipe(
      map((jobs: Job[]) => {
        jobs.map((job: Job) => {
          job.startAtUtc = moment.utc(job.startAtUtc).toISOString();
          job.updatedAtUtc = moment.utc(job.updatedAtUtc).toISOString();
          job.createdAtUtc = moment.utc(job.createdAtUtc).toISOString();
          job.finishedAtUtc = moment.utc(job.finishedAtUtc).toISOString();
        });

        return this.sortJobsByStartDate(jobs);
      }),
      share(),
    );
  }

  public getJobs(): Observable<Job[]> {
    return this.kpiJobs$;
  }

  public submitJob(seasonName: string): Observable<Job> {
    return this.http.post<Job>(routes.jobs(), { season: seasonName }).pipe(
      tap((kpiJob: Job) => {
        this._sessionJobs.push(kpiJob);
      }),
    );
  }

  private sortJobsByStartDate(kpiJobs: Job[]): Job[] {
    return kpiJobs.sort((job1: Job, job2: Job) => {
      const date1: Date = new Date(job1.startAtUtc);
      const date2: Date = new Date(job2.startAtUtc);

      if (date1.getTime() > date2.getTime()) {
        return -1;
      }
      if (date1.getTime() < date2.getTime()) {
        return 1;
      }

      return 0;
    });
  }
}
