import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BaseEditFormComponent } from '../../../shared/base-edit-form/base-edit-form.component';

@Component({
  selector: 'tbody[lsz-lawn-field-form]',
  templateUrl: './lawn-field-form.component.html',
  styleUrls: ['./lawn-field-form.component.css'],
})
export class LawnFieldFormComponent extends BaseEditFormComponent {

  public get hasArtificialLawn(): AbstractControl {
    return this.form.get('hasArtificialLawn');
  }

  constructor() {
    super();
  }
}
