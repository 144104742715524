<div [formGroup]="form" class="binary-select" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
  <label>
    <span class="binary-icon" [ngClass]="'binary-icon-' + (form.get(name).value === true ? '' : 'un') + 'checked'"><fa-icon [icon]="iconYes"></fa-icon></span>
    <input type="radio" [formControlName]="name" [value]="true" required/>
  </label>
  <label>
    <span class="binary-icon" [ngClass]="'binary-icon-' + (form.get(name).value === false ? '' : 'un') + 'checked'"><fa-icon [icon]="iconNo"></fa-icon></span>
    <input type="radio" [formControlName]="name" [value]="false" required/>
  </label>
  <div *ngIf="form.get(name).invalid && form.get(name).touched">
    <div class="input-error" *ngIf="form.get(name).errors.required">Auswahl wird benötigt.</div>
  </div>
</div>
