import { Pipe, PipeTransform } from '@angular/core';

import { DetailIsPercentage } from '../helpers/detailIsPercentage';
import { Detail } from '../models/detailCalculations';

@Pipe({
  name: 'convertDetailToPercentage',
})
export class ConvertDetailToPercentagePipe implements PipeTransform {

  public transform(detail: Detail): Detail {
    const _detail: Detail = { ...detail };
    if (DetailIsPercentage.isPercentage(_detail.name)) {
      _detail.distribution = this.convertDistribution(_detail.distribution);
      if (_detail.multiValues) {
        const newMultiValues: { [s: string]: number }[] = [];
        for (const _values of _detail.multiValues) {
          const newValues: { [s: string]: number } = this.convertMultiValues(_values);
          newMultiValues.push(newValues);
        }
        _detail.multiValues = newMultiValues;
      }
      if (_detail.own && _detail.own !== -1) {
        _detail.own *= 100;
      }
    }

    return _detail;
  }

  private convertDistribution(values: { [s: string]: number }): { [s: string]: number } {
    const newValues: { [s: string]: number } = {};
    for (const [key, value] of Object.entries(values)) {
      const newKey: number = Math.round(Number(key) * 100);
      newValues[newKey] = value;
    }

    return newValues;
  }

  private convertMultiValues(values: { [s: string]: number }): { [s: string]: number } {
    const newValues: { [s: string]: number } = {};
    for (const [key, value] of Object.entries(values)) {
      newValues[key] = Math.round(Number(value) * 100);
    }

    return newValues;
  }
}
