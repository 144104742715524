<tr [formGroup]="form">
  <td>
    <ng-container *ngIf="!editMode">{{name.value}}</ng-container>
    <div *ngIf="editMode">
      <input type="text" formControlName="name"/>
      <div *ngIf="name.invalid && (name.dirty || name.touched)">
        <div class="input-error" *ngIf="name.errors.required">Name wird benötigt.</div>
        <div class="input-error" *ngIf="name.errors.repeatedName">Name kann sich nicht wiederholen.</div>
      </div>
    </div>
  </td>
  <td>&nbsp;</td>
  <td>
    <ng-container *ngIf="!editMode">{{remark.value}}</ng-container>
    <textarea *ngIf="editMode" formControlName="remark"></textarea>
  </td>
  <td *ngIf="editMode">
    <lsz-dataset-options [form]="form" (save)="entrySaved.emit()" (delete)="entryDeleted.emit()"></lsz-dataset-options>
  </td>
  <td>
    <lsz-last-edit [form]="form"></lsz-last-edit>
  </td>
</tr>
