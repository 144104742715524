import { Component } from '@angular/core';
import { BaseEditFormComponent } from '../../../shared/base-edit-form/base-edit-form.component';

@Component({
  selector: 'tbody[lsz-special-equipment-form]',
  templateUrl: './special-equipment-form.component.html',
  styleUrls: ['./special-equipment-form.component.css'],
})
export class SpecialEquipmentFormComponent extends BaseEditFormComponent {

  constructor() {
    super();
  }
}
