import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'lsz-tick-slider',
  templateUrl: './tick-slider.component.html',
  styleUrls: ['tick-slider.component.scss'],
})
export class TickSliderComponent {
  @Output() public valueChange: EventEmitter<number>;
  // @Input() public ticks: number[];
  @Input() public value: number;
  @Input() public labels: string[];
  @Input() public disabled: boolean;


  constructor() {
    this.valueChange = new EventEmitter<number>();
    this.value = 0;
  }

  public onChange(key: number): void {
    this.valueChange.emit(key);
  }

  public onClick(key: number): void {
    this.value = key;
    this.onChange(key);
  }
}